<template>
  <Section
    id="supply"
    v-if="id"
    :title="$t('contractors.supply')"
    :collapsable="true"
    v-model:collapsed="collapse"
  >
    <div class="form-container">
      <form
        id="supply-form"
        @submit.prevent="submitSupply"
        class="two-column-form"
      >
        <div class="column">
          <EODSelect
            id="supply_payment_contitions"
            v-model:value="supply.payment_conditions"
            :label="$t('contractors.paymentConditions')"
            :service="paymentConditionsService"
            :defaultValue="defaultValues.payment_conditions"
            :errors="errors.payment_conditions"
          />

          <EODSelect
            id="trade_credit_type"
            v-model:value="supply.trade_credit_type"
            :label="$t('contractors.tradeCreditType')"
            :service="fetchTradeCreditTypes"
            :defaultValue="defaultValues.trade_credit_type"
            :errors="errors.trade_credit_type"
          />

          <EODInput
            id="maximum_invoice_amount"
            v-model:value="supply.maximum_invoice_amount"
            :label="$t('contractors.maximumInvoiceAmount')"
            type="number"
            step="0.01"
            :maxlength="12"
            :errors="errors.maximum_invoice_amount"
            @invalid="onInvalid()"
          />
        </div>

        <div class="column">
          <EODSelect
            id="currency"
            storeID="contractors_currency"
            v-model:value="supply.currency"
            :label="$t('contractors.currency')"
            :service="currencyService"
            :defaultValue="defaultValues.currency"
            :errors="errors.currency"
          />

          <EODInput
            id="credit_amount"
            v-model:value="supply.credit_amount"
            :label="$t('contractors.creditAmount')"
            type="number"
            step="0.01"
            :maxlength="12"
            :errors="errors.credit_amount"
            @invalid="onInvalid()"
          />

          <EODInput
            id="discount"
            v-model:value="supply.discount"
            :label="$t('contractors.discount')"
            type="number"
            step="0.01"
            :maxlength="10"
            :errors="errors.discount"
            @invalid="onInvalid()"
          />
        </div>
      </form>
    </div>
  </Section>
</template>

<script>
import { EODInput, EODSelect } from "@/components/inputs";
import {
  createSupply,
  editSupply,
  getTradeCreditTypeOption,
} from "@/services/contractors";

import { Section } from "@/components/ui";

export default {
  name: "SupplyForm",
  props: {
    id: Number,
    modelData: Object,
    modelErrors: Object,
    modelCollapse: Boolean,
    isDataEmpty: Function,
    promiseArray: Array,
    paymentConditionsService: Function,
    currencyService: Function,
    defaultValues: Object,
  },
  components: { EODInput, EODSelect, Section },
  computed: {
    supply: {
      get() {
        return this.modelData;
      },
      set(val) {
        this.$emit("update:modelData", val);
      },
    },
    errors: {
      get() {
        return this.modelErrors;
      },
      set(val) {
        this.$emit("update:modelErrors", val);
      },
    },
    collapse: {
      get() {
        return this.modelCollapse;
      },
      set(val) {
        this.$emit("update:modelCollapse", val);
      },
    },
    promises: {
      get() {
        return this.promiseArray;
      },
      set(val) {
        this.$emit("update:promiseArray", val);
      },
    },
    shouldSubmit() {
      return this.$store.state.contractors.submitSupply;
    },
  },
  emits: ["update:modelCollapse", "update:modelErrors"],
  methods: {
    submitSupply() {
      if (this.isDataEmpty(this.supply)) {
        return;
      }
      const service = this.supply.id ? editSupply : createSupply;

      this.promises.push(
        service({ ...this.supply, contractor: this.id }, this.supply.id)
          .then(({ data }) => {
            this.supply.id = data.id;
            this.errors = {};
            return true;
          })
          .catch((err) => {
            this.errors = err.response.data;
            return false;
          }),
      );
    },
    onInvalid() {
      this.collapse = false;
    },
    fetchTradeCreditTypes(params) {
      return getTradeCreditTypeOption(params).then(({ data }) => data);
    },
  },
  watch: {
    shouldSubmit(val) {
      if (val) {
        this.submitSupply();
        this.$store.dispatch("resetSubmitSupply");
      }
    },
  },
};
</script>
