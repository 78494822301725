
import { DeleteOutlined, EditOutlined } from "@ant-design/icons-vue";
import { PropType, defineComponent, inject, ref } from "vue";

import { Comment } from "@/interfaces/tasks";
import { EODButton } from "@/components/ui";
import { MessageApi } from "ant-design-vue/lib/message";
import TypeTag from "./TypeTag.vue";
import { deleteComment } from "@/services/tasks";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";

export default defineComponent({
  name: "CommentRow",
  props: {
    comment: { type: Object as PropType<Comment>, required: true },
    preview: { type: Boolean, default: false },
    customStore: { type: String, required: true },
  },
  setup(props, { emit }) {
    const $message = inject<MessageApi>("$message");
    const store = useStore();
    const { t } = useI18n();

    const handleDelete = () => {
      deleteComment(props.comment.id).then(() => {
        $message.success(t("app.success"));
        store.dispatch("closeModal");
        store.dispatch(`${props.customStore}/refreshTable`);
      });
    };

    const confirmDelete = () => {
      store.dispatch("setModal", {
        message: t("app.doYouWantToDelete_", [t("app.comment").toLowerCase()]),
        title: t("app.deletion_"),
        modalOk: handleDelete,
      });
    };

    const editComment = () => {
      emit("edit", props.comment);
    };

    return { confirmDelete, editComment };
  },
  components: {
    DeleteOutlined,
    EODButton,
    EditOutlined,
    TypeTag,
  },
  emits: ["edit"],
});
