import axiosSession from "@/config/apiClient";

const getFormsList = (params) => {
  return axiosSession.get("/api/forms/", { params });
};

const getFormsSimpleList = (params) => {
  return axiosSession.get("/api/forms/select/", { params });
};

const createForm = (data, _, customErrorHandler) => {
  return axiosSession.post("/api/forms/", data, { customErrorHandler });
};

const createOrUpdateFormsBulk = (data, customErrorHandler) => {
  return axiosSession.put("/api/forms/bulk/", data, { customErrorHandler });
};

const deleteForm = (id) => {
  return axiosSession.delete(`/api/forms/${id}/`);
};

const getForm = (id) => {
  return axiosSession.get(`/api/forms/${id}/`);
};

const editForm = (data, id, customErrorHandler) => {
  return axiosSession.put(`/api/forms/${id}/`, data, { customErrorHandler });
};

const getFormStatuses = () => {
  return axiosSession.get("/api/forms/status/");
};

const getTaskForm = (deploymentID, taskID) => {
  return axiosSession.get("/api/forms/task-form/", {
    params: { deployment: deploymentID, task_definition_key: taskID },
  });
};

export {
  createForm,
  createOrUpdateFormsBulk,
  deleteForm,
  editForm,
  getForm,
  getFormsList,
  getFormsSimpleList,
  getFormStatuses,
  getTaskForm,
};
