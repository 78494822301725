<template>
  <div class="notification-panel">
    <div
      v-for="notification in notifications"
      :key="notification.id"
      class="notification-panel__item"
    >
      <router-link
        :to="{
          name: 'OwnNotifications',
        }"
      >
        <h3>
          <SyncOutlined v-if="notification.category_id" class="icon" />
          <ProfileOutlined v-else />
          {{ notification.category }}
        </h3>

        <!-- TODO: change color -->
        <Badge
          v-if="!notification.isRead"
          :offset="[10, 11]"
          color="#067043"
          class="font--bold"
        >
          <p class="notification-panel__message">{{ notification.title }}</p>
        </Badge>
        <p v-else class="notification-panel__message">
          {{ notification.title }}
        </p>
        <p class="notification-panel__created">{{ notification.created }}</p>
      </router-link>
    </div>
  </div>
</template>

<script>
import { ProfileOutlined, SyncOutlined } from "@ant-design/icons-vue";
import { Badge } from "ant-design-vue";
import { getOwnNotificationList } from "@/services/notifications";
export default {
  name: "OwnNotification",
  data() {
    return {
      results: [],
      notifications: [],
    };
  },
  components: {
    SyncOutlined,
    ProfileOutlined,
    Badge,
  },
  created() {
    getOwnNotificationList({ limit: 3 }).then(({ data: { results } }) => {
      this.results = results;
      this.notifications = this.results.map((item) => {
        return {
          id: item.notification.id,
          created: item.created,
          isRead: item.read,
          title: item.notification.title,
          message: item.notification.template,
          category: item.notification.notifications_category_display,
          category_id: item.notification.notifications_category,
        };
      });
    });
  },
  methods: {
    getOwnNotificationList,
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/Variables";
.icon {
  margin-right: 0.5rem;
}
.notification-panel {
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
  text-align: left;

  &__message {
    color: $font-tertiary;
  }
  &__created {
    font-size: 12px;
    color: $font-tertiary;
  }
}
@media (max-width: 768px) {
  .notification-panel {
    display: none;
  }
}
</style>
