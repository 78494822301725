<template>
  <section class="section pt-050">
    <div class="filters">
      <span class="filter-label mr-050">{{ $t("tasks.deployment") }}:</span>
      <EODSelect
        id="deployment"
        storeID="deployment"
        v-model:value="deployment"
        :placeholder="$t('tasks.deployment')"
        :service="deploymentsService"
      />
    </div>
  </section>

  <section class="section">
    <BaseTable
      :columns="columns"
      :service="getCompletedTasksList"
      :customParams="{ deployment, my_tasks: myTasks }"
      customStore="completedTasks"
    >
      <template #name="{ record }">
        {{ record.name ? record.name : "-" }}
      </template>
      <template #endTime="{ record }">
        {{ record.endTime ? new Date(record.endTime).toLocaleString() : "-" }}
      </template>
      <template v-slot:actions="{ record }">
        <div class="actions">
          <router-link
            class="action"
            :to="{
              name: 'TaskPreview',
              params: { id: record.id },
            }"
            :title="$t('app.preview')"
          >
            <EyeOutlined />
          </router-link>
        </div>
      </template>
    </BaseTable>
  </section>
</template>

<script>
import { BaseTable } from "@/components/table";
import { EODSelect } from "@/components/inputs";
import { EyeOutlined } from "@ant-design/icons-vue";
import dynamicTable from "@/store/modules/dynamicTable";
import { getCompletedTasksList } from "@/services/tasks";

export default {
  name: "CompletedTasks",
  props: {
    deploymentsService: Function,
    myTasks: { type: Boolean, required: true },
  },
  data() {
    return {
      deployment: null,
      columns: [
        {
          title: this.$t("app.name"),
          dataIndex: "taskName",
          sorter: true,
          slots: { customRender: "name" },
        },
        {
          title: this.$t("tasks.assignedTo"),
          dataIndex: "assignee",
          sorter: true,
        },
        {
          title: this.$t("tasks.endTime"),
          dataIndex: "endTime",
          slots: { customRender: "endTime" },
          sorter: true,
        },
        {
          title: this.$t("app.actions"),
          dataIndex: "actions",
          slots: { customRender: "actions" },
          width: "10%",
        },
      ],
    };
  },
  components: {
    BaseTable,
    EODSelect,
    EyeOutlined,
  },
  created() {
    if (!this.$store.hasModule("completedTasks")) {
      this.$store.registerModule("completedTasks", dynamicTable);
    }
  },
  methods: {
    getCompletedTasksList,
  },
};
</script>
