const ISSUE_MODEL = {
  content: "",
  comment: "",
  documents: null,
  jrwa: null,
  unit: null,
  sender: "",
  owner: "",
  created_by: "",
  sender_signature: "",
  sender_date: null,
  initiation_date: new Date().toISOString().substring(0, 10),
};

export { ISSUE_MODEL };
