<template>
  <Breadcrumbs
    :path="path"
    :current="title"
    iconComponent="UserSwitchOutlined"
    :what="$tc('replacements.replacement', 1)"
  >
    <EODButton
      v-if="id && data?.actions?.removable"
      ghost
      type="danger"
      icon="DeleteOutlined"
      :name="$t('app.delete_', [replacement])"
      @click="confirmDelete"
    />
  </Breadcrumbs>

  <Section :title="title">
    <div class="form-container">
      <form @submit.prevent="submit" class="form">
        <EODDatePicker
          id="date-range"
          v-model:value="range"
          :disabledDate="disabledDate"
          :label="`${$t('replacements.from')} - ${$t('replacements.to')}`"
          :range="true"
          required
          :errors="errors.date_from || errors.date_to"
        />

        <EODSelect
          v-if="all"
          id="replaced_user"
          v-model:value="data.replaced_user"
          :label="$t('replacements.replacedUser')"
          required
          :service="fetchUsers"
          :defaultValue="defaultReplacedUser"
          @change="onReplacedUserChange"
          :errors="errors.replaced_user"
        />

        <EODSelect
          id="replacing_users"
          v-model:value="data.replacing_users"
          :label="$t('replacements.replacingUsers')"
          mode="multiple"
          required
          :defaultValue="defaultReplacingUsers"
          :service="fetchReplacingUsers"
          v-model:refetch="refetchReplacingUsers"
          :errors="errors.replacing_users"
        />

        <EODSelect
          id="absence_type"
          v-model:value="data.absence_type"
          :label="$t('replacements.type')"
          required
          :service="fetchReplacementTypes"
          :defaultValue="defaultAbsenceType"
          :errors="errors.absence_type"
        />

        <EODInput
          id="reason"
          v-model:value="data.reason"
          type="textarea"
          :label="$t('replacements.reason')"
          :errors="errors.reason"
        />

        <EODButton
          type="primary"
          htmlType="submit"
          :name="$t('app.save')"
          :loading="loading$"
        />
      </form>
    </div>
  </Section>
</template>

<script>
import { Breadcrumbs, EODButton, Section } from "@/components/ui";
import { EODDatePicker, EODInput, EODSelect } from "@/components/inputs";
import {
  createReplacement,
  deleteReplacement,
  editReplacement,
  getReplacement,
  getReplacementsTypes,
} from "@/services/replacements";

import {
  getReplacementUsersSimpleList,
  getReplacingUsersMyReplacementSimpleList,
} from "@/services/replacements";

export default {
  name: "ReplacementsForm",
  props: { all: { type: Boolean, default: false } },
  data() {
    return {
      id: +this.$route.params.id,
      data: {
        date_from: null,
        date_to: null,
        absence_type: null,
        replacing_users: [],
        replaced_user: this.$store.getters["user/getUser"].id,
        reason: "",
      },
      errors: {},
      range: [],
      replacement: this.$tc("replacements.replacement", 2),
      defaultReplacedUser: {
        id: this.$store.getters["user/getUser"].id,
        name: `${this.$store.getters["user/getUser"].full_name} (${this.$t(
          "replacements.chooseYourself",
        )})`,
      },
      defaultAbsenceType: null,
      defaultReplacingUsers: null,
      refetchReplacingUsers: false,
    };
  },
  components: {
    Breadcrumbs,
    EODButton,
    EODDatePicker,
    EODInput,
    EODSelect,
    Section,
  },
  computed: {
    title() {
      return this.id
        ? this.$t("app.edit_", [this.replacement])
        : this.$t("app.add_", [this.replacement]);
    },
    path() {
      return this.all
        ? [
            {
              path: { name: "ReplacementsAll" },
              title: this.$t("modules.replacementsAll"),
            },
          ]
        : [
            {
              path: { name: "Replacements" },
              title: this.$t("modules.replacements"),
            },
          ];
    },
  },
  created() {
    this.initialFetch();
  },
  methods: {
    submit() {
      const service = this.id ? editReplacement : createReplacement;

      const data = this.data;
      const today = new Date().toISOString();

      if (this.range.length === 2) {
        data.date_from =
          this.range[0].substring(0, 10) === today.substring(0, 10)
            ? today
            : this.range[0];
        data.date_to = new Date(
          new Date(this.range[1]).setHours(23, 59, 59, 0),
        ).toISOString();
      } else {
        this.$message.error(this.$t("replacements.selectRange"));
        return;
      }

      if (data.replacing_users.length === 0) {
        this.$message.error(this.$t("replacements.selectReplacingUsers"));
        return;
      }

      if (!data.absence_type) {
        this.$message.error(this.$t("replacements.selectAbsenceType"));
        return;
      }

      this.loading$ = true;

      service(data, this.id)
        .then(() => {
          this.$router.push({
            name: this.all ? "ReplacementsAll" : "Replacements",
          });
          this.$message.success(this.$t("app.success"));
        })
        .catch((err) => {
          this.errors = err.response.data;
        })
        .finally(() => {
          this.loading$ = false;
        });
    },
    confirmDelete() {
      this.$store.dispatch("setModal", {
        modalOk: this.deleteReplacement,
        title: this.$t("replacements.delete"),
        message: `${this.$t("app.doYouWantToDelete")} ${this.$tc(
          "replacements.replacement",
          1,
        )}?`,
      });
    },
    deleteReplacement() {
      deleteReplacement(this.id).then(() => {
        this.$message.success(this.$t("app.success"));
        this.$store.dispatch("closeModal");
        this.$router.replace({ name: "Replacements" });
      });
    },
    disabledDate(current) {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      return current < yesterday;
    },
    fetchReplacementTypes(params) {
      return getReplacementsTypes(params).then(({ data }) => data);
    },
    async initialFetch() {
      if (this.id) {
        await getReplacement(this.id).then(({ data }) => {
          this.data = data;
          this.defaultAbsenceType = this.$getDefaultValue(data, "absence_type");

          const defaultReplacingUsers = data.replacing_users.map((item) => ({
            ...item,
            name: item.full_name,
          }));
          this.defaultReplacingUsers = this.all
            ? [this.defaultReplacedUser].concat(defaultReplacingUsers)
            : defaultReplacingUsers;

          this.data.replacing_users = data.replacing_users.map(
            (item) => item.id,
          );
          this.range = [data.date_from, data.date_to];
        });
      } else if (this.all) {
        this.defaultReplacingUsers = this.defaultReplacedUser;
      }
    },
    fetchReplacingUsers(params) {
      const service = this.all
        ? getReplacementUsersSimpleList
        : getReplacingUsersMyReplacementSimpleList;
      return this.fetchUsers(params, service);
    },
    fetchUsers(params, service = getReplacementUsersSimpleList) {
      return service(params).then(({ data }) => ({
        ...data,
        results: data.results.map((item) => ({
          id: +item.id,
          name: item.full_name,
        })),
      }));
    },
    onReplacedUserChange() {
      this.refetchReplacingUsers = true;
    },
  },
};
</script>
