<template>
  <div class="input-container">
    <label :for="id" class="label">
      {{ label }}
      <span
        v-if="required"
        class="color--error"
        :title="$t('app.fieldRequired')"
      >
        &nbsp;*
      </span>
    </label>
    <div class="input-wrap">
      <TreeSelect
        :id="id"
        v-model:value="cValue"
        v-model:treeExpandedKeys="cTreeExpandedKeys"
        :required="required"
        :placeholder="cPlaceholder"
        :disabled="disabled"
        :allowClear="!required && allowClear"
        :treeData="treeData"
        :loadData="onLoadData"
        treeDataSimpleMode
        @change="onChange"
        @select="onSelect"
        @search="onSearchDebounce"
        v-model:searchValue="cSearchValue"
        :filterTreeNode="onSearch ? false : undefined"
        :multiple="multiple"
        dropdownMatchSelectWidth
      />

      <slot />
    </div>
    <span class="color--error" v-if="errors">
      {{ errors[0] }}
    </span>
  </div>
</template>

<script>
import { TreeSelect } from "ant-design-vue";

export default {
  name: "EODTreeSelect",
  props: {
    value: [String, Array, Number],
    treeData: { type: Array, default: () => [] },
    onLoadData: Function,
    id: String,
    required: Boolean,
    placeholder: String,
    label: String,
    disabled: { type: Boolean, default: false },
    errors: { type: Array },
    onChange: Function,
    onSelect: Function,
    multiple: Boolean,
    allowClear: {
      type: Boolean,
      default: true,
    },
    treeExpandedKeys: Array,
    searchValue: String,
    onSearch: Function,
  },
  components: { TreeSelect },
  computed: {
    cValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("update:value", newValue);
      },
    },
    cTreeExpandedKeys: {
      get() {
        return this.treeExpandedKeys;
      },
      set(val) {
        this.$emit("update:treeExpandedKeys", val);
      },
    },
    cSearchValue: {
      get() {
        return this.searchValue;
      },
      set(val) {
        this.$emit("update:searchValue", val);
      },
    },
    cPlaceholder() {
      return this.placeholder ?? this.label;
    },
  },
  emits: ["update:value", "update:treeExpandedKeys", "update:searchValue"],
  methods: {
    onSearchDebounce() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(this.onSearch, 250);
    },
  },
};
</script>
