import APPS from "@/consts/apps";
import JRWA from "@/views/JRWA/JRWA.vue";
import JRWAEntriesForm from "@/views/JRWA/JRWAEntriesForm.vue";
import JRWAEntriesPreview from "@/views/JRWA/JRWAEntriesPreview.vue";
import JRWAForm from "@/views/JRWA/JRWAForm.vue";
import JRWAPreview from "@/views/JRWA/JRWAPreview.vue";
import PERMISSIONS from "@/consts/permissions";
import i18n from "@/i18n";

const t = i18n.global.t;

const jrwa = [
  {
    path: "/jrwa/",
    name: "JRWA",
    component: JRWA,
    meta: {
      title: `${t("meta.app")} - ${t("jrwa.meta.list")}`,
      requiresAuth: true,
      permission: PERMISSIONS.JRWA,
      app: APPS.JRWA,
    },
  },
  {
    path: "/jrwa/:id/preview/",
    name: "JRWAPreview",
    component: JRWAPreview,
    meta: {
      title: `${t("meta.app")} - ${t("jrwa.meta.preview")}`,
      requiresAuth: true,
      permission: PERMISSIONS.JRWA,
      app: APPS.JRWA,
    },
  },
  {
    path: "/jrwa/create/",
    name: "JRWACreate",
    component: JRWAForm,
    meta: {
      title: `${t("meta.app")} - ${t("jrwa.meta.add")}`,
      requiresAuth: true,
      permission: PERMISSIONS.JRWA_ADD,
      app: APPS.JRWA,
    },
  },
  {
    path: "/jrwa/:id/edit/",
    name: "JRWAEdit",
    component: JRWAForm,
    meta: {
      title: `${t("meta.app")} - ${t("jrwa.meta.edit")}`,
      requiresAuth: true,
      permission: PERMISSIONS.JRWA_EDIT,
      app: APPS.JRWA,
    },
  },
  {
    path: "/jrwa/:versionID/entry/create/",
    name: "JRWAKeyCreate",
    component: JRWAEntriesForm,
    meta: {
      title: `${t("meta.app")} - ${t("jrwa.meta.addEntry")}`,
      requiresAuth: true,
      permission: PERMISSIONS.JRWA_ENTRY_ADD,
      app: APPS.JRWA,
    },
  },
  {
    path: "/jrwa/:versionID/entry/:id/preview/",
    name: "JRWAKeyPreview",
    component: JRWAEntriesPreview,
    meta: {
      title: `${t("meta.app")} - ${t("jrwa.meta.previewEntry")}`,
      requiresAuth: true,
      permission: PERMISSIONS.JRWA_ENTRY,
      app: APPS.JRWA,
    },
  },
  {
    path: "/jrwa/:versionID/entry/:id/edit/",
    name: "JRWAKeyEdit",
    component: JRWAEntriesForm,
    meta: {
      title: `${t("meta.app")} - ${t("jrwa.meta.editEntry")}`,
      requiresAuth: true,
      permission: PERMISSIONS.JRWA_ENTRY_EDIT,
      app: APPS.JRWA,
    },
  },
];

export default jrwa;
