<template>
  <AntTable
    :columns="columns"
    :rowSelection="rowSelection"
    :data-source="data"
    :rowClassName="rowClassName"
    rowKey="id"
    :loading="loading"
    :scroll="scroll"
    :pagination="
      !pagination
        ? false
        : {
            showQuickJumper: true,
            showTotal: (total) => `${total} ${$t('app.results')}`,
            pageSizeOptions: ['10', '25', '50'],
            showSizeChanger: true,
            hideOnSinglePage: pagination.total < 10,
            ...pagination,
          }
    "
    :showHeader="showHeader"
    @change="changeHandler"
    @expand="expandHandler"
    v-model:expandedRowKeys="cExpandedRowKeys"
  >
    <template v-for="column in Object.keys($slots)" v-slot:[column]="scope">
      <slot :name="column" v-bind="scope" />
    </template>
  </AntTable>
</template>

<script>
import { Table as AntTable } from "ant-design-vue";

export default {
  name: "Table",
  props: {
    rowSelection: {
      type: Object,
      default: null,
    },
    columns: Array,
    data: Array,
    pagination: {
      type: [Boolean, Object],
      default() {
        return {
          total: 0,
          current: 1,
          pageSize: 10,
        };
      },
    },
    loading: { type: Boolean, default: false },
    rowClassName: Function,
    scroll: {
      type: Object,
      default() {
        return {};
      },
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    expandedRowKeys: Array,
  },
  components: { AntTable },
  computed: {
    cExpandedRowKeys: {
      get() {
        return this.expandedRowKeys;
      },
      set(newValue) {
        this.$emit("update:expandedRowKeys", newValue);
      },
    },
  },
  emits: ["table-change", "table-expand", "update:expandedRowKeys"],
  methods: {
    changeHandler(pagination, filters, sorter) {
      this.$emit("table-change", { pagination, filters, sorter });
    },
    expandHandler(expanded, record) {
      this.$emit("table-expand", { expanded, record });
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/Variables.scss";
</style>
