<template>
  <div class="form-container">
    <form id="task-form" class="form" @submit.prevent="submit">
      <template
        v-for="[key, val] in Object.entries(properties).sort(
          ([key1], [key2]) => {
            return schemaUI[key1]?.ordering - schemaUI[key2]?.ordering;
          },
        )"
        :key="key"
      >
        <template v-if="conditionalRender(key)">
          <template v-if="schemaUI[key].component === 'multiValue'">
            <MultiValue
              v-for="[index] in model[key].entries()"
              :key="`${key}-${index}`"
              :index="index"
              :schemaUI="schemaUI"
              v-model:modelValue="model[key][index]"
              v-model:modelAdditionalValues="additionalValues"
              v-model:modelRefetch="refetch"
              :required="required"
              :errors="errors"
              :fieldKey="key"
              :fieldValue="val"
              :defaultValues="defaultValues"
              :removeEntity="removeEntity"
              :showDelete="model[key].length > 1 && showMultiValueButton(key)"
            />

            <EODButton
              v-if="
                schemaUI[key].multiValueType !== 'address' &&
                showMultiValueButton(key)
              "
              :name="schemaUI[key].buttonTitle"
              type="primary"
              @click="addEntity(key)"
            />
            <EODSeparator v-if="schemaUI[key].multiValueType !== 'address'" />
          </template>

          <FormBuilderInputs
            v-else
            :fieldKey="key"
            :val="val"
            :schemaUI="schemaUI"
            :properties="properties"
            :required="required"
            :errors="errors"
            :defaultValues="defaultValues"
            v-model:modelValue="model"
            v-model:modelAdditionalValues="additionalValues"
            v-model:modelSearchValue="searchValue"
            v-model:modelRefetch="refetch"
            :treeSelectOptions="treeSelectOptions"
            :onLoadData="onLoadData"
            :fetchTreeDictionary="fetchTreeDictionary"
            :onTreeSelectChange="onTreeSelectChange"
            :treeComponents="treeComponents"
          />
        </template>
      </template>
    </form>
  </div>
</template>

<script>
import { EODButton, EODSeparator } from "@/components/ui";
import { computed, inject, ref, toRefs, watch } from "vue";

import CustomAjv from "./CustomAjv";
import FormBuilderInputs from "./FormBuilderInputs.vue";
import MultiValue from "./MultiValue.vue";
import getDate from "@/helpers/getDate";
import useFormBuilderTreeSelects from "./useFormBuilderTreeSelects";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";

// TODO: TypeScript
export default {
  name: "FormBuilder",
  props: {
    modelValue: { type: Object, default: () => ({}) },
    schema: { type: Object, required: true },
    schemaUI: { type: Object, required: true },
    preview: { type: Boolean, default: false },
    submitFunction: {
      type: Function,
      default: () => {},
    },
    errorsValue: { type: Object, default: () => ({}) },
    loading: { type: Boolean, default: false },
    draft: { type: Boolean, default: false },
    schemaDraft: { type: Object, default: () => ({}) },
  },
  setup(props, { emit }) {
    const $message = inject("$message");
    const store = useStore();
    const { t } = useI18n();

    const DEBUG = !!+process.env.VUE_APP_DEBUG;

    // prettier-ignore
    const {
      draft,
      errorsValue,
      modelValue,
      schema,
      schemaDraft,
      schemaUI,
    } = toRefs(props);

    const ajv = new CustomAjv();
    const defaultValues = ref({});
    const additionalValues = ref({});
    const refetch = ref({});

    const model = computed({
      get() {
        return modelValue.value;
      },
      set(val) {
        emit("update:modelValue", val);
      },
    });

    const errors = computed({
      get() {
        return errorsValue.value;
      },
      set(value) {
        emit("update:errorsValue", value);
      },
    });

    const properties = computed(() =>
      schema.value.allOf
        ? {
            ...schema.value.properties,
            ...Object.fromEntries(
              schema.value.allOf.map(
                (item) => Object.entries(item.then.properties)[0],
              ),
            ),
          }
        : schema.value.properties,
    );

    const required = computed(() => {
      if (draft.value) {
        return [];
      } else {
        const req = [...schema.value.required];
        Object.values(schema.value.properties).forEach((item) => {
          if (item.items?.required) {
            req.push(...item.items.required);
          }
        });

        const allOfRequired = [];
        if (schema.value.allOf) {
          Object.values(schema.value.allOf).forEach((item) => {
            allOfRequired.push(...item.then.required);
            Object.values(item.then.properties).forEach((elem) => {
              if (elem.items?.required) {
                allOfRequired.push(...elem.items.required);
              }
            });
          });

          return [...req, ...allOfRequired];
        }

        return req;
      }
    });

    const { fetchTreeDictionaries, ...treeSelects } = useFormBuilderTreeSelects(
      schemaUI,
      model,
      additionalValues,
    );

    const daterangeComparator = (condition) => {
      if (model.value[condition.property]?.length === 2) {
        const startDate = getDate(model.value[condition.property][0]);
        const endDate = getDate(model.value[condition.property][1]);

        const value = getDate(condition.value);

        switch (condition.operation) {
          case "le": {
            return startDate < value;
          }
          case "ge": {
            return endDate > value;
          }
          case "inc": {
            return startDate <= value && endDate >= value;
          }
          case "dec": {
            return startDate > value || endDate < value;
          }
          default: {
            return false;
          }
        }
      }

      return false;
    };

    const conditionalRender = (key) => {
      const condition = schemaUI.value[key].condition;

      const operations = {
        eq: (a, b) => a == b,
        neq: (a, b) => a != b,
        le: (a, b) => a < b,
        leq: (a, b) => a <= b,
        ge: (a, b) => a > b,
        geq: (a, b) => a >= b,
        inc: (a, b) => a?.includes(b),
        dec: (a, b) => !a?.includes(b),
        some: (a, b) => b?.some((x) => x === a),
        someMulti: (a, b) => b?.some((x) => a?.includes(x)),
      };

      if (condition) {
        switch (schemaUI.value[condition.property].component) {
          case "dueDate":
          case "currentDate":
          case "date": {
            return operations[condition.operation]?.(
              getDate(model.value[condition.property]),
              getDate(condition.value),
            );
          }
          case "daterange": {
            return daterangeComparator(condition);
          }
          case "checkbox": {
            return operations[
              condition.operation === "some" ? "someMulti" : condition.operation
            ]?.(model.value[condition.property], condition.value);
          }
          default: {
            return operations[condition.operation]?.(
              model.value[condition.property],
              condition.value,
            );
          }
        }
      }

      return true;
    };

    const showMultiValueButton = (key) => {
      return Object.keys(properties.value[key].items.properties).every(
        (item) => !schemaUI.value[item].props.disabled,
      );
    };

    const addEntity = (key) => {
      model.value[key].push(
        Object.fromEntries(
          Object.keys(properties.value[key].items.properties).map((item) => [
            item,
            undefined,
          ]),
        ),
      );
    };

    const removeEntity = (key, index) => {
      model.value[key] = model.value[key].filter((_, idx) => idx !== index);
    };

    const parseOutputData = (formData, properties) => {
      for (const [key, value] of Object.entries(properties)) {
        if (conditionalRender(key)) {
          if (
            schemaUI.value[key].props.disabled &&
            !["currentUser", "allUnits"].includes(schemaUI.value[key].component)
          ) {
            // Don't send disabled values because you cannot edit them.
            // currentUser and allUnits are exceptions because they need to be set
            // currentDate and calc will be generated by backend
            formData[key] = undefined;
          } else if (
            required.value.includes(key) &&
            ((!formData[key] && formData[key] !== 0) ||
              (Array.isArray(formData[key]) && formData[key].length === 0))
          ) {
            $message.error(t("forms.gui.fillRequiredField_", [value.title]));
            return;
          } else if (value.type === "number") {
            const parsed = parseFloat(formData[key]);
            formData[key] = isNaN(parsed) ? undefined : parsed;
          } else if (value.type === "integer") {
            const parsed = parseInt(formData[key]);
            formData[key] = isNaN(parsed) ? undefined : parsed;
          } else if (
            (["date", "dueDate"].includes(schemaUI.value[key].component) &&
              formData[key] === null) ||
            (schemaUI.value[key].component === "daterange" &&
              formData[key]?.length !== 2)
          ) {
            formData[key] = undefined;
          } else if (schemaUI.value[key].component === "bankAccount") {
            if (
              formData[key] &&
              value.minLength &&
              formData[key]?.length < value.minLength
            ) {
              $message.error(
                t("forms.gui.minLengthError", [value.title, value.minLength]),
              );
              return;
            }
            if (
              formData[key] &&
              value.maxLength &&
              formData[key]?.length > value.maxLength
            ) {
              $message.error(
                t("forms.gui.maxLengthError", [value.title, value.maxLength]),
              );
              return;
            }
          } else if (schemaUI.value[key].component === "multiValue") {
            formData[key] = formData[key].map((item) =>
              Object.fromEntries(
                Object.entries(item).filter(
                  ([propName]) => !propName.endsWith("_display"),
                ),
              ),
            );

            for (const submodel of formData[key]) {
              if (
                !parseOutputData(submodel, properties[key].items.properties)
              ) {
                return;
              }
            }
          }
        }
      }

      return true;
    };

    const submit = () => {
      emit("update:loading", true);
      const formData = { ...model.value };

      if (DEBUG) {
        // eslint-disable-next-line no-console
        console.log("Model before modification: ", model.value);
      }

      // Don't send conditional properties that are not rendered.
      // It's done in separate loop because parsing may change condition evaluation.
      for (const key of Object.keys(properties.value)) {
        if (!conditionalRender(key)) {
          formData[key] = undefined;
        }
      }

      if (!parseOutputData(formData, properties.value)) {
        emit("update:loading", false);
        return;
      }

      // do not send "_display" and "_additional_value" properties
      for (const key of Object.keys(formData)) {
        if (key.endsWith("_display") || key.endsWith("_additional_value")) {
          delete formData[key];
        }
      }

      if (DEBUG) {
        // eslint-disable-next-line no-console
        console.log("Model after modification: ", formData);
      }

      const valid = ajv.validate(
        draft.value ? schemaDraft.value : schema.value,
        formData,
      );
      if (!valid) {
        $message.error(t("app.error"));
        console.error(ajv.errors);
        emit("update:loading", false);
      } else {
        props.submitFunction(formData);
      }
    };

    const setDefaultValues = (model, properties, multiValueIndex = null) => {
      Object.entries(properties).forEach(([key, value]) => {
        const uniqueKey =
          multiValueIndex !== null ? `${key}-${multiValueIndex}` : null;

        // if model[key] is undefined or null or an empty string set default value
        if (
          model[key] === undefined ||
          model[key] === null ||
          model[key] === ""
        ) {
          switch (schemaUI.value[key].component) {
            case "currentDate": {
              model[key] = new Date().toISOString();
              break;
            }
            case "currentUser": {
              const user = store.getters["user/getUser"];
              model[key] = user.id;
              defaultValues.value[key] = { id: user.id, name: user.full_name };
              break;
            }
            case "allUnits": {
              const units = store.getters["user/getUser"]?.units ?? [];
              if (units.length > 0) {
                model[key] = units[0].id;
                model[`${key}_display`] = units[0].name;
              }
              break;
            }
            case "multiValue": {
              model[key] = [];
              addEntity(key);
              break;
            }
            default: {
              model[key] = value.default;
            }
          }
        } else {
          if (
            [
              "contractors",
              "dictSelect",
              "multiSelect",
              "prioritySelect",
              "userDelegation",
              "groupDelegation",
              "currentUser",
              "allUnits",
              "contacts",
              "systemSelect",
              "usersUnits",
            ].includes(schemaUI.value[key].component)
          ) {
            if (Array.isArray(model[key])) {
              defaultValues.value[uniqueKey ?? key] = model[key].map(
                (item, idx) => ({
                  id: item,
                  name: model[`${key}_display`][idx],
                }),
              );
            } else {
              defaultValues.value[uniqueKey ?? key] = {
                id: model[key],
                name: model[`${key}_display`],
              };
              additionalValues.value[key] = model[`${key}_additional_value`];
            }
          } else if (schemaUI.value[key].component === "multiValue") {
            model[key].forEach((submodel, index) => {
              setDefaultValues(submodel, value.items.properties, index);
            });
          }
        }
      });
    };

    watch(schemaUI, () => {
      setDefaultValues(model.value, properties.value);
      fetchTreeDictionaries(properties.value);
    });

    setDefaultValues(model.value, properties.value);
    fetchTreeDictionaries(properties.value);

    return {
      ...treeSelects,
      addEntity,
      additionalValues,
      conditionalRender,
      defaultValues,
      errors,
      model,
      properties,
      refetch,
      removeEntity,
      required,
      submit,
      showMultiValueButton,
    };
  },
  components: {
    EODButton,
    EODSeparator,
    FormBuilderInputs,
    MultiValue,
  },
  emits: ["update:modelValue", "update:errorsValue", "update:loading"],
};
</script>

<style scoped lang="scss">
.form-container {
  height: auto;
}

.form-builder {
  margin: 1.2rem 0;
}
</style>
