<template>
  <div class="input-container">
    <label :for="id" class="label">
      {{ label }}
      <span
        v-if="required"
        class="color--error"
        :title="$t('app.fieldRequired')"
      >
        &nbsp;*
      </span>
    </label>
    <div class="input-wrap">
      <Textarea
        v-if="type === 'textarea'"
        v-model:value="cValue"
        v-bind="componentProps"
        :autoSize="{ minRows: 3 }"
        @invalid="onInvalid"
      />
      <Input
        v-else
        v-model:value="cValue"
        v-bind="componentProps"
        @invalid="onInvalid"
      />

      <slot />
    </div>
    <span class="color--error" v-if="errors">
      {{ errors[0] }}
    </span>
  </div>
</template>

<script>
import { Input } from "ant-design-vue";

export default {
  name: "EODInput",
  props: {
    value: [String, Number],
    id: String,
    required: Boolean,
    placeholder: String,
    label: String,
    pattern: String,
    min: Number,
    max: Number,
    maxlength: Number,
    minlength: Number,
    step: { type: [Number, String], default: "any" },
    type: { type: String, default: "text" },
    disabled: { type: Boolean, default: false },
    errors: Array,
    props: Object,
    allowClear: { type: Boolean, default: false },
  },
  components: { Input, Textarea: Input.TextArea },
  computed: {
    cValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("update:value", newValue);
      },
    },
    cPlaceholder() {
      return this.placeholder ?? this.label;
    },
    componentProps() {
      return {
        id: this.id,
        required: this.required,
        placeholder: this.cPlaceholder,
        disabled: this.disabled,
        type: this.type,
        allowClear: this.allowClear,
        pattern: this.pattern || undefined,
        min: this.min,
        max: this.max,
        maxlength: this.maxlength,
        minlength: this.minlength,
        step: this.step,
        ...this.props,
      };
    },
  },
  emits: ["update:value", "invalid"],
  methods: {
    onInvalid(event) {
      this.$emit("invalid", event);
    },
  },
};
</script>
