<template>
  <Section :title="$t('numeration.units')">
    <BaseTable
      :columns="columns"
      :service="(params) => getNumerationUnits(id, params)"
    >
      <template #name="{ record }">
        {{ record.unit.name }}
      </template>
      <template #symbol="{ record }">
        {{ record.unit.unit_symbol }}
      </template>
      <template #actions="{ record }">
        <div class="actions">
          <PrintBarcodes
            v-if="record?.actions?.printable"
            :record="record"
            :printOptions="true"
          />
        </div>
      </template>
    </BaseTable>
  </Section>
</template>

<script>
import { BaseTable } from "@/components/table";
import PrintBarcodes from "@/components/numeration/PrintBarcodes";
import { Section } from "@/components/ui";
import { getNumerationUnits } from "@/services/numeration";

export default {
  name: "NumerationUnits",
  props: { id: { type: String, required: true } },
  data() {
    return {
      columns: [
        {
          title: this.$t("app.name"),
          dataIndex: "unit__name",
          slots: { customRender: "name" },
          sorter: true,
        },
        {
          title: this.$t("units.symbol"),
          dataIndex: "unit__unit_symbol",
          slots: { customRender: "symbol" },
          sorter: true,
        },
        {
          title: this.$t("numeration.nextFreeValue"),
          dataIndex: "next_free_value",
          sorter: true,
        },
        {
          title: this.$t("app.actions"),
          dataIndex: "actions",
          slots: { customRender: "actions" },
          fixed: "right",
          width: "70",
        },
      ],
    };
  },
  components: {
    BaseTable,
    PrintBarcodes,
    Section,
  },
  methods: {
    getNumerationUnits,
  },
};
</script>
