<template>
  <div class="sort-panel">
    <p class="paragraph">{{ $t("repository.sort") }}:</p>
    <button
      :class="$t('repository.oldFirst') === sortLabel ? 'sort' : 'sort active'"
      @click="handleSort"
    >
      {{ $t("repository.oldFirst") }}
    </button>
    <button
      :class="$t('repository.newFirst') === sortLabel ? 'sort' : 'sort active'"
      @click="handleSort"
    >
      {{ $t("repository.newFirst") }}
    </button>
  </div>
</template>

<script>
export default {
  name: "SortPanel",
  props: {
    sortLabel: {
      type: String,
      default: "",
    },
    handleSort: {
      type: Function,
      default: () => {},
    },
  },
};
</script>
