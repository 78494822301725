<template>
  <div class="registry-links-panel">
    <div
      v-for="result in results"
      :key="result.id"
      class="registry-links-panel__link"
    >
      <router-link
        :to="{
          name: 'RegistryPreview',
          params: { id: result.id },
        }"
      >
        {{ result.name }}
      </router-link>
    </div>
  </div>
</template>

<script>
import { getRegistryList } from "@/services/registers";

export default {
  name: "RegistryLinks",
  data() {
    return {
      results: [],
    };
  },
  created() {
    getRegistryList().then(({ data: { results } }) => {
      this.results = results;
    });
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/Variables";
.registry-links-panel {
  padding: 24px 24px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: left;
  &__link {
    padding: 0.5rem;
    width: auto;
  }
}
</style>
