<template>
  <div class="center">
    <div class="photo">
      <img :src="require(`@/assets/${type}.svg`)" :alt="`${type} error`" />
    </div>
    <div class="description">
      <h1 class="big-size">{{ type }}</h1>
      <p class="align-left">
        {{ $t(`app.${type}message`) }}
      </p>
      <EODButtonLink
        :to="{ name: 'Home' }"
        :title="$t('app.backToMainPage')"
        icon=""
      />
    </div>
  </div>
</template>

<script>
import { EODButtonLink } from "@/components/ui";

export default {
  name: "ErrorComponent",
  props: { type: { type: String, required: true } },
  components: { EODButtonLink },
};
</script>

<style lang="scss" scoped>
@import "@/scss/Variables";

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  color: $grey;
}

.description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 20%;
  padding-left: 2%;

  .big-size {
    color: $grey;
    font-size: 4rem;
    margin-bottom: 0.2rem;
  }

  .align-left {
    text-align: left;
    margin-bottom: 10%;
  }
}
</style>
