<template>
  <Breadcrumbs
    :path="[{ path: { name: 'Registry' }, title: $t('modules.registry') }]"
    :current="data.name"
    iconComponent="CarryOutOutlined"
  >
    <template #summary>
      <div class="summary">
        <div class="summary-item">
          <p>
            <strong>{{ $t("app.description") }}:</strong>
            {{ data.description }}
          </p>
          <p>
            <strong>{{ $t("registry.format") }}:</strong>
            {{ data.counter_display }}
          </p>
        </div>
      </div>
    </template>

    <EODButtonLink
      v-if="hasPermissionToEdit"
      :to="{ name: 'RegistryEdit', params: { id } }"
      :title="$t('app.edit_', [$t('registry.registry')])"
      icon="EditOutlined"
    />
  </Breadcrumbs>

  <section class="section">
    <BaseTable
      :columns="columns"
      :service="(params) => getRegistryDocuments(params, id)"
      paginationType="LOAD_MORE"
    >
      <template #columnRender="{ record, column }">
        <div v-if="column.dataIndex in record.date_fields">
          {{
            new Date(record.date_fields[column.dataIndex]).toLocaleDateString()
          }}
        </div>
        <div v-else>
          {{ record[column.dataIndex] || "-" }}
        </div>
      </template>

      <template #actions="{ record }">
        <TableActions
          :record="record"
          :previewRoute="{
            name: 'DocumentsPreview',
            params: { id: record.document_id },
          }"
          :showEdit="false"
          :showRemove="false"
        >
          <PrintBarcodes v-if="record.counter_value" :record="record" />
        </TableActions>
      </template>
    </BaseTable>
  </section>
</template>

<script>
import { BaseTable, TableActions } from "@/components/table";
import { Breadcrumbs, EODButtonLink } from "@/components/ui";
import {
  editRegistry,
  getRegistry,
  getRegistryDocuments,
} from "@/services/registers";

import PERMISSIONS from "@/consts/permissions";
import PrintBarcodes from "@/components/numeration/PrintBarcodes";

export default {
  data() {
    return {
      id: +this.$route.params?.id,
      columns: [
        {
          title: this.$t("app.number"),
          dataIndex: "counter_value",
        },
        {
          title: this.$t("app.name"),
          dataIndex: "document_name",
        },
      ],
      data: {
        name: "",
        description: "",
        columns: [],
        counter: "",
        counter_display: "",
      },
    };
  },
  components: {
    BaseTable,
    Breadcrumbs,
    EODButtonLink,
    TableActions,
    PrintBarcodes,
  },
  computed: {
    hasPermissionToEdit() {
      return this.$permissions.includes(PERMISSIONS.REGISTRY_MANAGE);
    },
  },
  created() {
    if (this.id) {
      getRegistry(this.id).then(({ data }) => {
        this.data = data;

        const tableActions = [
          {
            title: this.$t("app.actions"),
            dataIndex: "actions",
            slots: { customRender: "actions" },
            fixed: "right",
            width: "70",
          },
        ];

        const dynamicColumns = data.columns
          .map((el) => {
            return {
              title: el.display_name,
              dataIndex: el.variable_name,
              slots: { customRender: "columnRender" },
            };
          })
          .reverse();

        this.columns = [...this.columns, ...dynamicColumns, ...tableActions];
      });
    }
  },
  methods: {
    editRegistry,
    getRegistryDocuments,
  },
};
</script>
