<template>
  <AccountsModal
    :id="id"
    v-model:activate="VATModal"
    v-model:modelData="contractor"
    v-model:fetchingVAT="fetchingVAT"
  />

  <section class="tax-status" v-if="contractor.tax_status">
    <CheckCircleFilled class="color--success" />
    &nbsp;
    <span>
      {{
        `${$t("contractors.isVATPayer")}. ${$t(
          "contractors.verifiedAt",
        )}: ${new Date(contractor.tax_status_update).toLocaleDateString()}`
      }}
    </span>
  </section>

  <Section id="bank-account" v-if="id" :title="$t('contractors.bankAccounts')">
    <template v-slot:button>
      {{ $t("contractors.setAsDefault") }}
      <EODSwitch
        v-model:checked="account.is_default"
        size="small"
        :title="$t('contractors.setAsDefault')"
      />

      <EODButton
        class="ml-1"
        type="primary"
        :name="$t('contractors.fetchVATInfo')"
        @click="VATModal = true"
        :loading="fetchingVAT"
      />
    </template>
    <div class="form-container">
      <form
        id="account-form"
        @submit.prevent="submitBankAccount()"
        class="two-column-form"
      >
        <div class="column">
          <EODInput
            id="account_identifier"
            v-model:value="account.identifier"
            :label="$t('contractors.accountIdentifier')"
            :maxlength="100"
            :errors="errors.identifier"
            @invalid="onInvalid()"
          />

          <EODSelect
            id="country_id"
            storeID="country"
            v-model:value="account.country_id"
            :label="$t('contractors.country')"
            :service="countriesService"
            :defaultValue="defaultCountry"
            :errors="errors.country_id"
          />

          <EODInput
            id="account_number"
            v-model:value="account.account_number"
            :label="$t('contractors.accountNumber')"
            :maxlength="100"
            :errors="errors.account_number"
            required
            :disabled="bankAccountID"
            @invalid="onInvalid()"
          />
        </div>

        <div class="column">
          <EODSelect
            id="bank_id"
            v-model:value="account.bank_id"
            :label="$t('contractors.bank')"
            :service="fetchAccountBanks"
            :defaultValue="defaultBank"
            :errors="errors.bank_id"
          />

          <EODInput
            id="swift`"
            v-model:value="account.swift"
            :label="$t('contractors.swift')"
            :maxlength="100"
            :errors="errors.swift"
            @invalid="onInvalid()"
          />

          <EODSelect
            id="account_currency"
            storeID="contractors_currency"
            v-model:value="account.currency"
            :label="$t('contractors.currency')"
            :service="currencyService"
            :defaultValue="defaultCurrency"
            :errors="errors.currency"
          />
        </div>

        <div class="buttons">
          <EODButton
            v-if="bankAccountID && hasPermissionToDelete"
            type="danger"
            ghost
            :name="$t('app.delete')"
            @click="confirmDelete"
            icon="DeleteOutlined"
          />

          <EODButton
            type="primary"
            htmlType="submit"
            :name="buttonTitle"
            :disabled="isButtonDisabled"
            :loading="loading$"
          />
        </div>
      </form>
    </div>
  </Section>
</template>

<script>
import { EODButton, Section } from "@/components/ui";
import { EODInput, EODSelect, EODSwitch } from "@/components/inputs";
import {
  createBankAccount,
  deleteBankAccount,
  editBankAccount,
  getAccountBankOption,
  getBankAccount,
} from "@/services/contractors";

import AccountsModal from "./AccountsModal";
import { CheckCircleFilled } from "@ant-design/icons-vue";
import PERMISSIONS from "@/consts/permissions";

export default {
  name: "BankAccountForm",
  props: {
    id: Number,
    isDataEmpty: Function,
    modelData: Object,
    countriesService: Function,
    currencyService: Function,
    showBankAccountForm: Boolean,
  },
  data() {
    return {
      account: {},
      errors: {},
      VATModal: false,
      fetchingVAT: false,
      defaultCurrency: null,
      defaultCountry: null,
      defaultBank: null,
    };
  },
  components: {
    AccountsModal,
    CheckCircleFilled,
    EODButton,
    EODInput,
    EODSwitch,
    Section,
    EODSelect,
  },
  computed: {
    bankAccountID() {
      return this.$store.state.contractors.bankAccountID;
    },
    buttonTitle() {
      return this.bankAccountID
        ? this.$t("app.saveChanges")
        : this.$t("app.add");
    },
    isButtonDisabled() {
      return this.isDataEmpty(this.account);
    },
    accountsCount() {
      return this.$store.state.contractors.accountsCount;
    },
    contractor: {
      get() {
        return this.modelData;
      },
      set(val) {
        this.$emit("update:modelData", val);
      },
    },
    hasPermissionToDelete() {
      return this.$permissions.includes(
        PERMISSIONS.CONTRACTORS_BANK_ACCOUNTS_DELETE,
      );
    },
  },
  emits: ["update:modelData"],
  methods: {
    fetchBankAccount() {
      if (this.bankAccountID) {
        getBankAccount(this.bankAccountID).then(({ data }) => {
          this.account = data;
          this.defaultCurrency = this.$getDefaultValue(data, "currency");
          this.defaultCountry = this.$getDefaultValue(data, "country_id");
          this.defaultBank = this.$getDefaultValue(data, "bank_id");

          this.account.country_id =
            data.country_id !== null ? data.country_id : undefined;
          this.account.bank_id =
            data.bank_id !== null ? data.bank_id : undefined;
          this.account.currency =
            data.currency !== null ? data.currency : undefined;
        });
      } else {
        this.account = {};
        this.account.contractor = this.id;
        this.errors = {};
      }
    },
    submitBankAccount() {
      this.loading$ = true;

      const service = this.account.id ? editBankAccount : createBankAccount;

      service(
        {
          order: this.accountsCount + 1,
          ...this.account,
          contractor: this.id,
        },
        this.account.id,
      )
        .then(() => {
          this.$emit("update:showBankAccountForm", false);
          this.$store.dispatch("setBankAccountID", undefined);
          this.$store.dispatch("closeModal");
          this.$store.dispatch("refreshSort");
          this.$store.dispatch("bankAccounts/refreshTable");
          this.$message.success(this.$t("app.success"));
          this.errors = {};
          this.account = {};
        })
        .finally(() => {
          this.loading$ = false;
        });
    },
    confirmDelete() {
      this.$store.dispatch("setModal", {
        message: this.$t("contractors.doYouWantToDeleteBankAccount"),
        title: this.$t("contractors.deleteBankAccount"),
        modalOk: () => this.removeBankAccount(),
      });
    },
    removeBankAccount() {
      deleteBankAccount(this.account.id).then(() => {
        this.$message.success(this.$t("app.success"));
        this.$store.dispatch("closeModal");
        this.$store.dispatch("bankAccounts/refreshTable");
        this.$store.dispatch("refreshSort");
        this.$store.dispatch("setBankAccountID", undefined);
      });
    },
    onInvalid() {
      this.collapse = false;
    },
    fetchAccountBanks(params) {
      return getAccountBankOption(params).then(({ data }) => data);
    },
  },
  watch: {
    bankAccountID() {
      this.fetchBankAccount();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/Contractors";
@import "@/scss/Variables";

.tax-status {
  border: 2px solid $success-border;
  margin: 1rem;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  background-color: $success-background;
}
</style>
