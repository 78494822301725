<template>
  <section class="section">
    <Modal
      v-model:visible="showNotification"
      :title="notification?.title"
      :closable="false"
      destroyOnClose
      @cancel="handleOK"
    >
      <div class="center">
        <EODEditor
          :preview="notification?.rendered"
          :options="{
            status: false,
            toolbar: false,
          }"
        />
      </div>

      <template #footer>
        <Button
          type="primary"
          htmlType="submit"
          @click="handleOK"
          :title="$t('notifications.read')"
        >
          {{ $t("notifications.read") }}
        </Button>
      </template>
    </Modal>

    <BaseTable
      :columns="columns"
      :rowClassName="rowClassName"
      :service="(params) => getOwnNotificationList({ read, ...params })"
      :customStore="customStore"
    >
      <template #category="{ record }">
        <span :class="record.read ? 'color--grey' : 'color--primary'">
          &#9679;
        </span>
        {{ record?.notification?.notifications_category_display }}
      </template>

      <template #actions="{ record }">
        <button
          class="action"
          @click="handleRead(record)"
          :title="$t('app.preview')"
        >
          <EyeOutlined />
        </button>
      </template>
    </BaseTable>
  </section>
</template>

<script>
import { Button, Modal } from "ant-design-vue";
import {
  getOwnNotification,
  getOwnNotificationList,
} from "@/services/notifications";

import { BaseTable } from "@/components/table";
import { EODEditor } from "@/components/inputs";
import { EyeOutlined } from "@ant-design/icons-vue";
import dynamicTable from "@/store/modules/dynamicTable";

export default {
  name: "OwnNotificationsList",
  props: {
    read: {
      type: Boolean,
      default: null,
    },
    customStore: {
      type: [Boolean, String],
      default: false,
    },
  },
  data() {
    return {
      notification: null,
      showNotification: false,
      columns: [
        {
          title: this.$t("app.category"),
          dataIndex: "notification__notifications_category",
          sorter: true,
          width: "20%",
          slots: { customRender: "category" },
        },
        {
          title: this.$t("app.content"),
          dataIndex: "notification.title",
          ellipsis: true,
        },
        {
          title: this.$t("notifications.receivedDate"),
          dataIndex: "created",
          width: "15%",
          sorter: true,
        },
        {
          title: this.$t("app.actions"),
          dataIndex: "actions",
          slots: { customRender: "actions" },
          fixed: "right",
          width: "70",
        },
      ],
    };
  },
  components: {
    BaseTable,
    Button,
    EyeOutlined,
    EODEditor,
    Modal,
  },
  created() {
    if (this.customStore && !this.$store.hasModule(this.customStore)) {
      this.$store.registerModule(this.customStore, dynamicTable);
    }
  },
  methods: {
    getOwnNotification,
    getOwnNotificationList,
    rowClassName(record) {
      return record.read ? "color--grey'" : "font--bold";
    },
    handleRead(record) {
      getOwnNotification(record.id).then(({ data }) => {
        this.notification = {
          title: record.notification.title,
          rendered: data.rendered,
        };
        this.showNotification = true;
      });
    },
    handleOK() {
      this.notification = null;
      this.showNotification = false;
      this.$store.dispatch("refreshTable");

      for (const store of ["unreadNotifications", "readNotifications"]) {
        if (this.$store.hasModule(store)) {
          this.$store.dispatch(`${store}/refreshTable`);
        }
      }
    },
  },
};
</script>
