import { createI18n } from "vue-i18n";

function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i,
  );
  const messages: Record<string, any> = {};
  locales.keys().forEach((key: string) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

export default createI18n({
  legacy: true,
  locale:
    localStorage.getItem("language") || process.env.VUE_APP_I18N_LOCALE || "pl",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "pl",
  messages: loadLocaleMessages(),
});
