<template>
  <GUSModal
    v-model:visible="visible"
    :initialNIP="contractor.nip"
    v-model:modelContractor="contractor"
    v-model:modelAddress="address"
  />

  <Section
    id="contractor"
    :title="$t('contractors.basicInfo')"
    :collapsable="true"
    v-model:collapsed="collapse"
  >
    <div class="form-container">
      <form
        id="contractor-form"
        @submit.prevent="submitContractor"
        class="form"
      >
        <EODInput
          id="name"
          v-model:value="contractor.name"
          :label="$t('app.name')"
          required
          :maxlength="140"
          :errors="errors.name"
          @invalid="onInvalid"
        />

        <EODInput
          id="short_name"
          v-model:value="contractor.short_name"
          :label="$t('contractors.shortcut')"
          required
          :maxlength="140"
          :errors="errors.short_name"
          @invalid="onInvalid"
        />

        <EODInput
          id="identifier"
          v-model:value="contractor.identifier"
          :label="$t('contractors.identifier')"
          required
          :maxlength="15"
          :errors="errors.identifier"
          @invalid="onInvalid"
        />

        <EODRadio
          id="status"
          v-model:value="contractor.status"
          :label="$t('app.status')"
          required
          :options="statusOptions"
          :errors="errors.status"
          @invalid="onInvalid"
        />

        <EODSelect
          id="types"
          v-model:value="contractor.contractor_class"
          required
          :label="$t('contractors.class')"
          :service="fetchContractorClasses"
          :defaultValue="defaultValues.contractor_class"
          :errors="errors.contractor_class"
        />

        <EODSelect
          id="country_tax"
          storeID="country"
          v-model:value="contractor.country_tax"
          :label="$t('contractors.taxCountry')"
          :service="countriesService"
          :defaultValue="defaultValues.country_tax"
          :errors="errors.country_tax"
        />

        <div class="input-button">
          <EODInput
            id="nip"
            v-model:value="contractor.nip"
            :label="$t('contractors.nip')"
            :maxlength="13"
            :errors="errors.nip"
            @invalid="onInvalid"
          />
          <EODButton
            type="primary"
            @click="visible = true"
            class="mb-050 ml-050"
            :name="$t('contractors.gus')"
          />
        </div>

        <EODSelect
          id="country_nip"
          storeID="country"
          v-model:value="contractor.country_nip"
          :label="$t('contractors.nipCountry')"
          :service="countriesService"
          :defaultValue="defaultValues.country_nip"
          :errors="errors.country_nip"
        />

        <div v-if="!id" class="buttons">
          <Button
            htmlType="submit"
            :title="$t('app.saveAndLeave')"
            :loading="loading$"
          >
            {{ $t("app.saveAndLeave") }}
          </Button>
          <Button
            class="ml-1"
            htmlType="submit"
            type="primary"
            :title="$t('app.continue')"
            @click="setGoToEdit"
            :loading="loading$"
          >
            {{ $t("app.continue") }}
          </Button>
        </div>
      </form>
    </div>
  </Section>
</template>

<script>
import { EODButton, Section } from "@/components/ui";
import { EODInput, EODRadio, EODSelect } from "@/components/inputs";
import {
  createAddress,
  createContractor,
  editContractor,
  getContractorClassOption,
} from "@/services/contractors";

import { Button } from "ant-design-vue";
import GUSModal from "./GUSModal";
import { STATUS } from "@/consts/statuses";

export default {
  name: "ContractorForm",
  props: {
    id: Number,
    modelData: Object,
    modelErrors: Object,
    modelCollapse: Boolean,
    modelAddress: Object,
    modelAttachments: Array,
    promiseArray: Array,
    isDataEmpty: Function,
    countriesService: Function,
    defaultValues: Object,
  },
  data() {
    return {
      goToEdit: false,
      visible: false,
      statusOptions: [
        {
          name: this.$t("status.active"),
          id: STATUS.ACTIVE,
        },
        { name: this.$t("status.inactive"), id: STATUS.INACTIVE },
      ],
      contractorClassOption: [],
      fetching: false,
    };
  },
  components: {
    Button,
    EODButton,
    EODInput,
    EODRadio,
    EODSelect,
    GUSModal,
    Section,
  },
  computed: {
    contractor: {
      get() {
        return this.modelData;
      },
      set(val) {
        this.$emit("update:modelData", val);
      },
    },
    errors: {
      get() {
        return this.modelErrors;
      },
      set(val) {
        this.$emit("update:modelErrors", val);
      },
    },
    collapse: {
      get() {
        return this.modelCollapse;
      },
      set(val) {
        this.$emit("update:modelCollapse", val);
      },
    },
    promises: {
      get() {
        return this.promiseArray;
      },
      set(val) {
        this.$emit("update:promiseArray", val);
      },
    },
    address: {
      get() {
        return this.modelAddress;
      },
      set(val) {
        this.$emit("update:modelAddress", val);
      },
    },
    attachments: {
      get() {
        return this.modelAttachments;
      },
      set(val) {
        this.$emit("update:modelAttachments", val);
      },
    },
    shouldSubmit() {
      return this.$store.state.contractors.submitContractor;
    },
  },
  emits: [
    "update:modelData",
    "update:modelID",
    "update:modelErrors",
    "update:modelCollapse",
    "update:promiseArray",
    "update:modelAddress",
  ],
  methods: {
    submitContractor() {
      this.loading$ = true;

      this.contractor.attachments = this.attachments.map((item) => item.id);

      if (!this.contractor.nip) {
        this.contractor.nip = null;
      }

      const service = this.id ? editContractor : createContractor;

      this.promises.push(
        service(this.contractor, this.id)
          .then(async ({ data }) => {
            if (!this.id) {
              if (!this.isDataEmpty(this.address)) {
                await createAddress({ ...this.address, contractor: data.id });
              }

              if (this.goToEdit) {
                this.$router.replace({
                  name: "ContractorsEdit",
                  params: { id: data.id },
                  hash: "#address",
                });
              } else {
                this.$message.success(this.$t("app.success"));
                this.$router.push({ name: "Contractors" });
              }

              this.loading$ = false;
            }
            this.errors = {};
            return true;
          })
          .catch((err) => {
            if (!this.id) {
              this.loading$ = false;
            }
            this.errors = err.response.data;
            return false;
          }),
      );
    },
    onInvalid() {
      this.collapse = false;
    },
    setGoToEdit() {
      this.goToEdit = true;
    },
    fetchContractorClasses(params) {
      return getContractorClassOption(params).then(({ data }) => data);
    },
  },
  watch: {
    shouldSubmit(val) {
      if (val) {
        this.submitContractor();
        this.$store.dispatch("resetSubmitContractor");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/Contractors";
</style>
