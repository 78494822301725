<template>
  <!-- WARNING: Numeration module has prototype version of translations -->
  <Breadcrumbs
    :current="$t('modules.numeration')"
    iconComponent="OrderedListOutlined"
  >
    <EODButtonLink
      v-if="$permissions.includes('counters.add_counter_custom')"
      :to="{
        name: 'NumerationCreate',
      }"
      :title="$t('app.add_', [$tc('numeration.numeration', 2)])"
    />
  </Breadcrumbs>

  <section class="section">
    <BaseTable :columns="columns" :service="getNumerationList">
      <template v-slot:status="{ record }">
        <StatusDot :status="record.status" />
        {{ record.status_display }}
      </template>
      <template v-slot:text_format="{ record }">
        <span class="pr-050">{{ record.text_format }}</span>
        <QuestionCircleOutlined
          class="help"
          :title="record.counter_text_value"
        />
      </template>
      <template v-slot:in_use="{ record }">
        {{ record.in_use ? $t("app.yes") : $t("app.no") }}
      </template>
      <template #next_free_value="{ record }">
        {{ record.is_unit_counter ? "-" : record.next_free_value }}
      </template>
      <template v-slot:actions="{ record }">
        <TableActions
          :record="record"
          :previewRoute="{
            name: 'NumerationPreview',
            params: { id: record.id },
          }"
          :editRoute="{
            name: 'NumerationEdit',
            params: { id: record.id },
          }"
          :deleteMessage="
            $t('app.doYouWantToDelete_', [
              // eslint-disable-next-line prettier/prettier
              `${$tc('numeration.numeration', 2)} &quot;${record.name}&quot;?`,
            ])
          "
          :modalTitle="$t('app.deletion_', [$tc('numeration.numeration', 1)])"
          :deleteService="deleteNumeration"
        >
          <PrintBarcodes
            v-if="record?.actions?.printable"
            :record="record"
            :printOptions="true"
          />
        </TableActions>
      </template>
    </BaseTable>
  </section>
</template>

<script>
import { BaseTable, StatusDot, TableActions } from "@/components/table";
import { Breadcrumbs, EODButtonLink } from "@/components/ui";
import { deleteNumeration, getNumerationList } from "@/services/numeration";
import PrintBarcodes from "@/components/numeration/PrintBarcodes";
import { QuestionCircleOutlined } from "@ant-design/icons-vue";

export default {
  name: "Numeration",
  data() {
    return {
      columns: [
        { title: this.$t("app.name"), dataIndex: "name", sorter: true },
        {
          title: this.$t("app.status"),
          dataIndex: "status",
          sorter: true,
          slots: { customRender: "status" },
        },
        {
          title: this.$t("numeration.format"),
          dataIndex: "text_format",
          sorter: true,
          slots: { customRender: "text_format" },
        },
        {
          title: this.$t("numeration.inUse"),
          dataIndex: "in_use",
          sorter: true,
          slots: { customRender: "in_use" },
        },
        {
          title: this.$t("numeration.nextFreeValue"),
          dataIndex: "next_free_value",
          sorter: true,
          slots: { customRender: "next_free_value" },
        },
        {
          title: this.$t("app.actions"),
          dataIndex: "actions",
          slots: { customRender: "actions" },
          fixed: "right",
          width: "150",
        },
      ],
    };
  },
  components: {
    BaseTable,
    Breadcrumbs,
    EODButtonLink,
    StatusDot,
    QuestionCircleOutlined,
    TableActions,
    PrintBarcodes,
  },
  methods: {
    deleteNumeration,
    getNumerationList,
  },
};
</script>
