import APPS from "@/consts/apps";
import Numeration from "@/views/Numeration/Numeration.vue";
import NumerationForm from "@/views/Numeration/NumerationForm.vue";
import NumerationPreview from "@/views/Numeration/NumerationPreview.vue";
import i18n from "@/i18n";

const t = i18n.global.t;
const tc = i18n.global.tc;

const numeration = [
  {
    path: "/numeration/",
    name: "Numeration",
    component: Numeration,
    meta: {
      title: `${t("meta.app")} - ${t("modules.numeration")}`,
      requiresAuth: true,
      permission: "counters.view_counter_custom",
      app: APPS.COUNTERS,
    },
  },
  {
    path: "/numeration/create/",
    name: "NumerationCreate",
    component: NumerationForm,
    meta: {
      title: `${t("meta.app")} - ${t("meta.create_", [
        tc("numeration.numeration", 1),
      ])}`,
      requiresAuth: true,
      permission: "counters.add_counter_custom",
      app: APPS.COUNTERS,
    },
  },
  {
    path: "/numeration/:id/edit/",
    name: "NumerationEdit",
    component: NumerationForm,
    meta: {
      title: `${t("meta.app")} - ${t("meta.edit_", [
        tc("numeration.numeration", 1),
      ])}`,
      requiresAuth: true,
      permission: "counters.change_counter_custom",
      app: APPS.COUNTERS,
    },
    props: true,
  },
  {
    path: "/numeration/:id/preview/",
    name: "NumerationPreview",
    component: NumerationPreview,
    meta: {
      title: `${t("meta.app")} - ${t("app.preview_", [
        tc("numeration.numeration", 1),
      ])}`,
      requiresAuth: true,
      permission: "counters.view_counter_custom",
      app: APPS.COUNTERS,
    },
    props: true,
  },
];

export default numeration;
