import axiosSession from "@/config/apiClient";

const login = (data) => {
  return axiosSession.post("/api/token/", data);
};

const refresh = (data) => {
  return axiosSession.post("/api/refresh/", data);
};

export { login, refresh };
