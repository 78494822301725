<template>
  <Breadcrumbs
    :path="[
      { path: { name: 'JRWA' }, title: $t('modules.jrwa') },
      {
        path: { name: 'JRWAEdit', params: { id: data.jrwa_register } },
        title: $t('jrwa.meta.edit'),
      },
    ]"
    :current="title"
    iconComponent="SortAscendingOutlined"
    :what="$tc('jrwa.classificationSymbol', 0)"
  />

  <Section :title="$t('jrwa.info')">
    <div class="form-container">
      <form @submit.prevent="submit" class="form">
        <div v-if="!id">
          {{ $t("jrwa.entriesForm.firstLevelElement") }}
          <EODSwitch
            v-model:checked="data.first_level_element"
            size="small"
            :title="$t('jrwa.entriesForm.firstLevelElement')"
            :onChange="onSwitchChange"
            :disabled="rootElementLength === 10 || rootElementLength === 0"
          />
        </div>

        <EODTreeSelect
          v-if="!id"
          id="parent"
          v-model:value="data.parent"
          :treeData="jrwaList"
          :onLoadData="onLoadData"
          :label="$t('documents.JRWANumber')"
          :placeholder="$t('jrwa.entriesForm.assignSymbolToRow')"
          @search="fetchJRWAEntriesList"
          @select="onNodeSelect"
          @change="onTreeSelectChange"
          v-model:searchValue="searchValue"
          :disabled="data.first_level_element"
          :required="!data.first_level_element"
          :errors="errors.parent"
        />

        <EODInput
          v-if="!id"
          id="number"
          type="number"
          v-model:value="number"
          :label="$t('jrwa.entriesForm.number')"
          disabled
        />

        <EODInput
          v-if="id"
          id="key"
          v-model:value="data.key"
          :label="$t('jrwa.symbol')"
          disabled
        />

        <EODInput
          id="name"
          v-model:value="data.name"
          :label="$t('jrwa.entriesForm.addClassificationName')"
          :errors="errors.name"
        />

        <EODSelect
          id="archive_category"
          v-model:value="data.archive_category"
          :label="$t('jrwa.entriesForm.archiveCategoryMark')"
          :service="fetchArchiveCategoryList"
          :defaultValue="defaultArchiveCategory"
          :errors="errors.archive_category"
        />

        <EODInput
          id="comments"
          type="textarea"
          v-model:value="data.comments"
          :label="$t('jrwa.comments')"
          :maxlength="500"
          :errors="errors.comments"
        />

        <Button
          htmlType="submit"
          type="primary"
          :title="action"
          :loading="loading$"
        >
          {{ action }}
        </Button>
      </form>
    </div>
  </Section>
</template>

<script>
import { Breadcrumbs, Section } from "@/components/ui";
import {
  EODInput,
  EODSelect,
  EODSwitch,
  EODTreeSelect,
} from "@/components/inputs";
import {
  createJRWAEntriesList,
  editJRWAEntriesList,
  getArchiveCategoryList,
  getJRWAEntries,
  getJRWAEntriesKey,
  getJRWAEntriesList,
} from "@/services/jrwa";

import { Button } from "ant-design-vue";

export default {
  name: "JRWAForm",
  data() {
    return {
      rootElementLength: 0,
      selectedElementLength: 0,
      jrwaList: [],
      number: "",
      data: {
        key: "",
        first_level_element: false,
        parent: null,
        name: "",
        archive_category: "",
        comments: "",
        jrwa_register: +this.$route.params?.versionID,
      },
      errors: {},
      id: +this.$route.params?.id,
      defaultArchiveCategory: null,
      searchValue: "",
    };
  },
  components: {
    Breadcrumbs,
    Button,
    EODInput,
    EODSelect,
    EODSwitch,
    EODTreeSelect,
    Section,
  },
  computed: {
    action() {
      return this.id ? this.$t("app.saveChanges") : this.$t("app.saveAndLeave");
    },
    title() {
      return this.id
        ? this.$t("jrwa.meta.editEntry")
        : this.$t("jrwa.meta.addEntry");
    },
  },
  created() {
    this.fetchJRWAEntriesList();
    if (this.id) {
      this.fetchJRWAEntries();
    }
  },
  methods: {
    submit() {
      this.loading$ = true;

      const service = this.id ? editJRWAEntriesList : createJRWAEntriesList;

      if (this.data.first_level_element) {
        this.data.parent = null;
      }

      service(this.data, this.id)
        .then(() => {
          this.$router.go(-1);
          this.$message.success(this.$t("app.success"));
        })
        .catch((err) => {
          this.errors = err.response.data;
        })
        .finally(() => {
          this.loading$ = false;
        });
    },
    fetchJRWAEntries() {
      getJRWAEntries(this.id).then(({ data }) => {
        this.data = data;
        this.defaultArchiveCategory = this.$getDefaultValue(
          data,
          "archive_category",
        );
      });
    },
    fetchJRWAEntriesList() {
      return getJRWAEntriesList({
        jrwa_register: this.data.jrwa_register,
        search_field: this.searchValue,
      }).then(({ data: { results } }) => {
        if (results.length === 0) {
          this.data.first_level_element = true;
          this.number = 0;
        } else {
          this.rootElementLength = results.length;
        }

        this.jrwaList = results.map((item) => {
          return {
            id: item.id,
            pId: item.parent || 0,
            value: item.id,
            title: `${item.key} ${item.name}`,
            isLeaf: !item.children,
            disabled: !item.can_have_more_children,
          };
        });

        if (
          this.data.parent &&
          !this.jrwaList.find((elem) => elem.id === this.data.parent) &&
          !this.searchValue
        ) {
          this.jrwaList.push({
            id: this.data.parent,
            pId: 0,
            value: this.data.parent,
            title: this.data.parent_display,
            isLeaf: true,
          });
        }
      });
    },
    fetchArchiveCategoryList(params) {
      return getArchiveCategoryList(params).then(({ data }) => data);
    },
    onLoadData(treeNode) {
      const { id: nodeId } = treeNode.dataRef;
      return getJRWAEntriesList({
        jrwa_register: this.data.jrwa_register,
        parent: nodeId,
      }).then(({ data: { results } }) => {
        const children = results.map((item) => {
          if (item.key.length < 4) {
            if (item.id === this.data.parent) {
              this.jrwaList = this.jrwaList.filter(
                (elem) => elem.id !== this.data.parent,
              );
            }
            return {
              id: item.id,
              pId: item.parent || 0,
              value: item.id,
              title: `${item.key} ${item.name}`,
              isLeaf: !item.children || item.key.length > 2,
              disabled: !item.can_have_more_children,
            };
          }
        });

        this.jrwaList = this.jrwaList.concat(children);
      });
    },
    onTreeSelectChange(value) {
      this.searchValue = "";
      this.data.parent_display = this.jrwaList.find(
        (elem) => elem.id === value,
      )?.title;
    },
    onNodeSelect(value) {
      if (value) {
        getJRWAEntriesKey(value).then(({ data: { key } }) => {
          this.number = key;
          this.selectedElementLength = key;
        });
      }
    },
    onSwitchChange(checked) {
      if (checked) {
        this.number = this.rootElementLength;
        this.data.parent = null;
      } else {
        this.number = this.selectedElementLength;
      }
    },
  },
};
</script>
