<template>
  <button class="cancel" :title="$t('app.goBack')" @click="handleClick">
    <ArrowLeftOutlined class="color--font-primary" />
  </button>
</template>

<script>
import { ArrowLeftOutlined } from "@ant-design/icons-vue";

export default {
  name: "Cancel",
  props: {
    goTo: { type: Function, required: true },
    bodyContent: { type: String, required: true },
    ask: Boolean,
  },
  components: {
    ArrowLeftOutlined,
  },
  methods: {
    handleClick() {
      if (this.ask) {
        this.$store.dispatch("setModal", {
          message: this.bodyContent,
          title: this.$t("app.exit"),
          modalOk: this.confirmCancel,
          okText: this.$t("app.exit"),
          cancelText: this.$t("app.stayAndContinue"),
        });
      } else {
        this.goTo();
      }
    },
    confirmCancel() {
      this.goTo();
      this.$store.dispatch("closeModal");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/Variables";

.cancel {
  background: transparent;
  color: $primary;
  border: none;
  margin: 0.125rem 1rem;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    @extend .focus;
  }
}
</style>
