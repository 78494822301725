const PERMISSIONS = {
  ARCHIVE: "archives.access_folder_custom",
  CONTACTS: "contacts.view_contact_custom",
  CONTACTS_ADD: "contacts.add_contact_custom",
  CONTACTS_EDIT: "contacts.change_contact_custom",
  CONTRACTORS: "contractors.view_contractor_custom",
  CONTRACTORS_ADD: "contractors.add_change_contractor_custom",
  CONTRACTORS_EDIT: "contractors.add_change_contractor_custom",
  CONTRACTORS_DELETE: "contractors.delete_contractor_custom",
  CONTRACTORS_VERIFICATION: "contractors.change_contractor_verified_custom",
  CONTRACTORS_CONTACT_DATA: "contractors.view_contactdetail_custom",
  CONTRACTORS_CONTACT_DATA_ADD: "contractors.add_contactdetail_custom",
  CONTRACTORS_CONTACT_DATA_DELETE: "contractors.delete_contactdetail_custom",
  CONTRACTORS_BANK_ACCOUNTS: "contractors.view_bankaccount_custom",
  CONTRACTORS_BANK_ACCOUNTS_ADD: "contractors.add_bankaccount_custom",
  CONTRACTORS_BANK_ACCOUNTS_DELETE: "contractors.delete_bankaccount_custom",
  DEPLOYMENTS: "camunda.view_deployment_custom",
  DEPLOYMENTS_ADD: "camunda.add_deployment_custom",
  DEPLOYMENTS_EDIT: "camunda.change_deployment_custom",
  DEPLOYMENTS_START: "camunda.start_deployment_custom",
  DICTIONARIES: "dicts.view_dictionary_custom",
  DICTIONARIES_ADD: "dicts.add_dictionary_custom",
  DICTIONARIES_EDIT: "dicts.change_dictionary_custom",
  DICTIONARIES_DELETE: "dicts.delete_dictionary_custom",
  DICTIONARIES_ENTRY: "dicts.view_dictionary_custom",
  DICTIONARIES_ENTRY_ADD: "dicts.add_entry_custom",
  DICTIONARIES_ENTRY_DELETE: "dicts.delete_entry_custom",
  DOCUMENTS: "documents.view_document_custom",
  DOCUMENTS_IN_UNIT: "documents.view_document_in_unit_custom",
  DOCUMENTS_ADD: "documents.add_document_custom",
  FORMS: "forms.view_form_custom",
  FORMS_ADD: "forms.add_form_custom",
  FORMS_EDIT: "forms.change_form_custom",
  GROUPS: "users.view_group_custom",
  GROUPS_ADD: "users.add_group_custom",
  GROUPS_EDIT: "users.change_group_custom",
  GROUPS_DELETE: "users.delete_group_custom",
  ISSUES: "issues.view_issue_custom",
  ISSUES_ADD: "issues.add_issue_custom",
  JRWA: "jrwa.view_jrwa_custom",
  JRWA_ADD: "jrwa.add_jrwa_custom",
  JRWA_EDIT: "jrwa.change_jrwa_custom",
  JRWA_ENTRY: "jrwa.view_jrwa_custom",
  JRWA_ENTRY_ADD: "jrwa.add_jrwa_symbol_custom",
  JRWA_ENTRY_EDIT: "jrwa.change_jrwa_custom",
  MAILBOX: "mailboxes.view_mailbox_custom",
  MAILBOX_ADD: "mailboxes.add_mailbox_custom",
  MAILBOX_EDIT: "mailboxes.change_mailbox_custom",
  NOTIFICATIONS: "notifications.view_notification_custom",
  NOTIFICATIONS_ADD: "notifications.add_notification_custom",
  NOTIFICATIONS_EDIT: "notifications.change_notification_custom",
  NOTIFICATIONS_DELETE: "notifications.delete_notification_custom",
  NUMERATION: "counters.view_counter_custom",
  NUMERATION_ADD: "counters.add_counter_custom",
  REPOSITORY_MANAGE: "repositories.manage_repositoryfolder_custom",
  REPLACEMENTS_ALL: "replacement.all_replacement_custom",
  REPLACEMENTS_OWN: "replacement.own_replacement_custom",
  REGISTRY_MANAGE: "registers.manage_register_custom",
  REGISTRY_VIEW: "registers.view_register_custom",
  ROLES: "users.view_roles_custom",
  ROLES_ADD: "users.add_roles_custom",
  ROLES_EDIT: "users.change_roles_custom",
  ROLES_DELETE: "users.delete_roles_custom",
  SEARCH: "search.view_search_custom",
  TASKS_OWN: "camunda.view_claim_and_complete_task_custom",
  TASKS_ALL: "camunda.view_camunda_all_task_custom",
  UNITS: "units.view_unit_custom",
  USERS: "users.view_user_custom",
  USERS_EDIT: "users.change_user_custom",
};

export default PERMISSIONS;
