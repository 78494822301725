import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")
  const _component_Skeleton = _resolveComponent("Skeleton")
  const _component_ConfigProvider = _resolveComponent("ConfigProvider")

  return (_openBlock(), _createBlock(_component_ConfigProvider, { locale: _ctx.locale }, {
    default: _withCtx(() => [
      _createVNode(_component_Skeleton, { onSetLanguage: _ctx.setLanguage }, {
        default: _withCtx(() => [
          _createVNode(_component_router_view, {
            key: _ctx.$route.path
          })
        ]),
        _: 1
      }, 8, ["onSetLanguage"])
    ]),
    _: 1
  }, 8, ["locale"]))
}