import { getProfile } from "@/services/user";

const user = {
  namespaced: true,
  state: {
    user: {
      permissions: [],
    },
  },
  mutations: {
    SET(state, user) {
      state.user = user;
    },
    CLEAR(state) {
      state.user = {
        permissions: [],
      };
    },
  },
  actions: {
    add({ commit }) {
      return getProfile()
        .then(({ data: user }) => {
          if (!user.is_staff) {
            localStorage.removeItem("access_token");
            localStorage.removeItem("refresh_token");
            window.location.href = process.env.VUE_APP_FRONT_OFFICE_URL;
          }
          commit("SET", user);
        })
        .catch((err) => {
          // Login requires this catch to work properly
          console.error(err);
        });
    },
    clear({ commit }) {
      commit("CLEAR");
    },
  },
  getters: {
    getUser(state) {
      return state.user;
    },
  },
};

export default user;
