<template>
  <UploadDragger
    v-if="!maxFiles || files.length < maxFiles"
    name="file"
    :multiple="multiple"
    :customRequest="customRequest"
    :showUploadList="false"
    :disabled="disabled"
  >
    <p class="ant-upload-drag-icon">
      <inbox-outlined />
    </p>
    <p class="ant-upload-text">
      {{
        requiredFormat
          ? $t("app.fileUploader.hintJRWA")
          : $t("app.fileUploader.hint")
      }}
    </p>
  </UploadDragger>

  <Table
    v-if="files.length > 0"
    :columns="columns"
    :data-source="files"
    class="fileUploaderTable mt-2"
    :pagination="{
      hideOnSinglePage: parseInt(maxFiles) < 10,
    }"
  >
    <template v-slot:description="{ record }">
      <template v-if="record.editable">
        <input v-model="editedDescription[record.id]" />

        <span>
          <button
            class="icon-button"
            type="button"
            @click="saveDescription(record)"
            :title="$t('app.save')"
          >
            <CheckOutlined />
          </button>

          <button
            class="icon-button"
            type="button"
            @click="descriptionEditMode(record, false)"
            :title="$t('app.cancel')"
          >
            <CloseOutlined />
          </button>
        </span>
      </template>

      <template v-else>
        <span>
          {{ record.description || "-" }}
        </span>

        <span>
          <button
            v-if="!disabled"
            class="icon-button"
            type="button"
            @click="descriptionEditMode(record, true)"
            :title="$t('app.edit')"
          >
            <EditOutlined />
          </button>
        </span>
      </template>
    </template>

    <template v-slot:actions="{ record }">
      <div class="actions">
        <slot
          name="filePreview"
          v-if="record.content_type === 'application/pdf'"
        >
          <button
            class="action"
            type="button"
            @click="handlePreview(record)"
            :title="$t('app.preview')"
          >
            <EyeOutlined />
          </button>
        </slot>
        <a
          v-if="downloadFileURL"
          class="action"
          :href="downloadFile(record)"
          :title="$t('app.downloadFile')"
        >
          <DownloadOutlined />
        </a>
        <button
          v-if="!disabled"
          class="action"
          type="button"
          @click="confirmDelete(record)"
          :title="$t('app.delete')"
        >
          <DeleteOutlined />
        </button>
        <slot
          v-if="record.possible_conversions?.length > 0 && !disabled"
          name="fileConvert"
        >
          <button
            class="action"
            type="button"
            @click="
              openConvertModal(
                record.id,
                record.filename,
                record.possible_conversions,
              )
            "
            :title="$t('documents.convert')"
          >
            <SwapOutlined />
          </button>
        </slot>
      </div>
    </template>
    <template v-slot:creationDate="{ record }">
      {{ new Date(record.created_date).toLocaleDateString() }}
    </template>
  </Table>
  <SelectModal
    v-model:visible="showConvertModal"
    v-model:selectedOption="selectedExtension"
    :title="$t('documents.convertFile')"
    :body="$tc('documents.convertFileInfo_', [fileExtension])"
    :okText="$t('documents.convert')"
    :options="optionsList"
    :onSubmit="handleConvert"
    :onCancel="closeConvertModal"
    selectId="extensionToConvert"
  />
</template>

<script>
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  EyeOutlined,
  InboxOutlined,
  SwapOutlined,
} from "@ant-design/icons-vue";

import { SelectModal } from "@/components/modals";
import { Table } from "@/components/table";
import { Upload } from "ant-design-vue";
import apiClient from "@/config/apiClient";
import { convertDocument } from "@/services/documents";

export default {
  name: "FileUploader",
  props: {
    maxFiles: {
      type: [Boolean, Number],
      default: false,
    },
    fileList: {
      type: Array,
      default: () => [],
    },
    fileURL: {
      type: String,
      required: true,
    },
    customColumns: {
      type: Array,
      default: () => [],
    },
    downloadFileURL: Function,
    fetchData: {
      type: Function,
      default: () => {},
    },
    newAttachmentsWithDesc: Array,
    disabled: { type: Boolean, default: false },
    requiredFormat: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showConvertModal: false,
      upload: null,
      editedDescription: {},
      fileID: null,
      fileExtension: "",
      selectedExtension: null,
      optionsList: [],
      columns: [
        {
          title: this.$t("app.name"),
          dataIndex: "filename",
          width: "35%",
          sorter: (a, b) => a.filename.localeCompare(b.filename),
        },
        {
          title: this.$t("app.description"),
          dataIndex: "description",
          slots: { customRender: "description" },
          width: "25%",
        },
        {
          title: this.$t("app.created"),
          dataIndex: "created_date",
          slots: { customRender: "creationDate" },
          width: "15%",
        },
        {
          title: this.$t("app.actions"),
          dataIndex: "actions",
          slots: { customRender: "actions" },
          width: "10%",
        },
      ],
    };
  },
  components: {
    CloseOutlined,
    CheckOutlined,
    DeleteOutlined,
    DownloadOutlined,
    EyeOutlined,
    EditOutlined,
    InboxOutlined,
    Table,
    UploadDragger: Upload.Dragger,
    SelectModal,
    SwapOutlined,
  },
  computed: {
    files: {
      get() {
        return this.fileList;
      },
      set(value) {
        this.$emit("update:fileList", value);
      },
    },
  },
  emits: ["update:fileList", "previewFile"],
  methods: {
    downloadFile(file) {
      return this.downloadFileURL(file.id);
    },
    confirmDelete(file) {
      this.$store.dispatch("setModal", {
        modalOk: () => this.handleDelete(file.id),
        title: this.$t("app.confirmation"),
        message: `${this.$t("app.doYouWantToDelete")}?`,
      });
    },
    handlePreview(file) {
      this.$emit("previewFile", this.downloadFileURL(file.id));
    },
    handleDelete(fileID) {
      apiClient
        .delete(`${this.fileURL}${fileID}/`, { globalErrorHandler: false })
        .then(() => {
          delete this.editedDescription[fileID];
          this.$message.success(this.$t("app.fileUploader.deleteSuccess"));
          this.$store.dispatch("closeModal");
          this.files = this.files.filter((elem) => elem.id !== fileID);
          // TODO: think about storing info about not saved attachments
          this.fetchData();
        })
        .catch(() => {
          this.$message.error(this.$t("app.fileUploader.deleteError"));
        });
    },
    descriptionEditMode(record, open) {
      this.editedDescription[record.id] = record.description;
      record.editable = open;
    },
    saveDescription(record) {
      if (record.newAdded) {
        record.description = this.editedDescription[record.id];
        record.editable = false;
        this.$emit(
          "update:newAttachmentsWithDesc",
          this.newAttachmentsWithDesc.concat(record),
        );
        return;
      }
      const item = record;
      item.description = this.editedDescription[record.id];

      apiClient.put(`${this.fileURL}${item.id}/`, item).then(() => {
        this.$message.success(this.$t("app.success"));
        item.editable = false;
      });
    },
    customRequest(data) {
      const { file } = data;
      const formData = new FormData();
      formData.append("file", file);

      apiClient
        .post(this.fileURL, formData, { globalErrorHandler: false })
        .then(({ data }) => {
          this.files.push({ ...data, editable: false, newAdded: true });
        })
        .catch(() => {
          this.$message.error(this.$t("app.fileUploader.error"));
        });
    },
    openConvertModal(id, filename, possibleConversions) {
      this.fileID = id;
      this.fileExtension = filename.substr(filename.lastIndexOf(".") + 1);
      this.optionsList = possibleConversions.map((item) => ({
        id: item,
        name: this.$t(`documents.${item}Info`),
      }));
      this.showConvertModal = true;
    },
    closeConvertModal() {
      this.showConvertModal = false;
      this.fileId = null;
      this.fileExtension = "";
      this.selectedExtension = null;
    },
    handleConvert() {
      convertDocument({
        attachment: this.fileID,
        conversion_format: this.selectedExtension,
      }).then(() => {
        this.$message.success(this.$t("app.success"));
        this.closeConvertModal();
      });
    },
  },
  mounted() {
    if (this.customColumns.length > 0) {
      //TODO: check why default prop  not working
      this.columns = this.customColumns;
    }
  },
};
</script>
