<template>
  <Barcodes
    v-if="isModalOpen"
    v-model:visible="isModalOpen"
    :record="record"
    :printOptions="printOptions"
  />
  <button
    :class="[isAction ? 'action' : 'mb-1 ant-btn']"
    :title="$t('numeration.barcodes.print')"
    @click="printBarcode"
  >
    <PrinterOutlined />
    {{ isAction ? null : $t("numeration.barcodes.printNumber") }}
  </button>
</template>

<script>
import Barcodes from "./Barcodes";
import { PrinterOutlined } from "@ant-design/icons-vue";

export default {
  name: "PrintBarcodes",
  props: {
    isAction: { type: Boolean, default: true },
    record: { type: Object, required: true },
    printOptions: { type: Boolean, default: false },
  },
  data() {
    return {
      isModalOpen: false,
    };
  },
  components: {
    PrinterOutlined,
    Barcodes,
  },

  methods: {
    printBarcode() {
      this.isModalOpen = true;
    },
  },
};
</script>
