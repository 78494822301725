<template>
  <Breadcrumbs
    :path="[
      {
        path: { name: 'Archive', query: { tab: 'folders' } },
        title: $t('modules.archive'),
      },
    ]"
    :current="title"
    iconComponent="ContainerOutlined"
    :what="$t('archive.folders.folder')"
  >
    <EODButton
      v-if="id"
      class="ml-1"
      type="danger"
      ghost
      @click="confirmDelete"
      icon="DeleteOutlined"
      :name="`${$t('app.delete')} ${$tc('archive.folders.folder', 0)}`"
    />
    <EODButton
      v-if="id"
      class="ml-1"
      type="primary"
      @click="openCompleteFolderModal"
      :name="$t('archive.folders.completeFolder')"
    />
  </Breadcrumbs>

  <Section :title="$t('archive.folders.info')">
    <div class="form-container">
      <form id="folder_form" @submit.prevent="submit" class="form">
        <EODSelect
          id="unit"
          storeID="folder_unit"
          v-model:value="data.unit"
          :label="$t('issues.unit')"
          :service="fetchUnits"
          required
          :disabled="!!id"
          :errors="errors.unit"
          :defaultValue="defaultUnit"
          @change="onUnitChange"
        />

        <JRWASelect
          id="jrwa"
          v-model:value="data.jrwa"
          v-model:value_display="data.jrwa_display"
          :label="$t('modules.jrwa')"
          required
          :errors="errors.jrwa"
          :disabled="!!id"
          @change="onJRWAChange"
          :runInitialFetch="!id"
          v-model:refetch="refetchJRWASelect"
        />

        <EODInput
          id="archive_category"
          v-model:value="data.archive_category"
          :label="$t('archive.folders.category')"
          :errors="errors.archive_category"
          disabled
        />

        <EODInput
          id="act_symbol"
          v-model:value="data.act_symbol"
          :label="$t('archive.folders.actSymbol')"
          :errors="errors.act_symbol"
          disabled
        />

        <EODInput
          id="title"
          v-model:value="data.title"
          :label="$t('archive.folders.title')"
          :errors="errors.title"
          disabled
        />

        <EODInput
          id="description"
          v-model:value="data.description"
          :label="$t('archive.folders.description')"
          type="textarea"
          required
          :errors="errors.description"
        />

        <EODInput
          v-if="id"
          id="date_from"
          v-model:value="edgeYearFrom"
          :label="$t('archive.folders.yearFrom')"
          disabled
          :errors="errors.egde_date_from"
        />

        <EODInput
          v-if="id"
          id="date_to"
          v-model:value="edgeYearTo"
          :label="$t('archive.folders.yearTo')"
          disabled
          :errors="errors.edge_date_to"
        />

        <EODInput
          v-if="id"
          id="number_of_volumes"
          v-model:value="data.number_of_volumes"
          type="number"
          :label="$t('archive.folders.numberOfVolumes')"
          required
          :errors="errors.number_of_volumes"
        />

        <EODInput
          v-if="id"
          id="archive_signature"
          v-model:value="data.archive_signature"
          :label="$t('archive.folders.archiveSignature')"
          disabled
          :errors="errors.archive_signature"
        >
          <Tooltip :title="$t('archive.folders.archiveSignatureInfo')">
            <InfoCircleOutlined />
          </Tooltip>
        </EODInput>

        <div class="buttons" v-if="!id">
          <EODButton
            htmlType="submit"
            :name="$t('app.saveAndLeave')"
            :loading="loading$"
          />

          <EODButton
            htmlType="submit"
            type="primary"
            :name="$t('archive.folders.completeFolder')"
            :loading="loading$"
            @click="setGoToEdit"
          />
        </div>
      </form>
    </div>
  </Section>

  <FoldersContent v-if="id" :id="id" v-model:activeKey="activeKeyContent" />

  <EODButton
    v-if="id"
    form="folder_form"
    class="mb-2"
    htmlType="submit"
    type="primary"
    :name="$t('archive.folders.save')"
    :loading="loading$"
  />

  <CompleteFolderModal
    v-if="id"
    v-model:visible="showCompleteFolderModal"
    :id="id"
    :unit="defaultUnit?.name"
    :jrwa="data.jrwa_display"
    v-model:activeKeyContent="activeKeyContent"
  />
</template>

<script>
import { Breadcrumbs, EODButton, Section } from "@/components/ui";
import { EODInput, EODSelect, JRWASelect } from "@/components/inputs";
import {
  createFolder,
  deleteFolder,
  editFolder,
  getFolder,
} from "@/services/archive";

import CompleteFolderModal from "@/components/archive/CompleteFolderModal";
import FoldersContent from "@/components/archive/FoldersContent";
import { InfoCircleOutlined } from "@ant-design/icons-vue";
import { Tooltip } from "ant-design-vue";
import { getEmployeeUnits } from "@/services/units";

export default {
  name: "FoldersForm",
  props: {
    id: { type: Number, required: true },
    showCompleteFolderModalProp: { type: Boolean, default: false },
  },
  data() {
    return {
      data: {
        unit: null,
        jrwa: null,
        archive_category: "",
        act_symbol: "",
        title: "",
        description: "",
        number_of_volumes: 1,
        edge_date_from: "",
        edge_date_to: "",
      },
      defaultUnit: null,
      unitSymbol: "",
      jrwaKey: "",
      errors: {},
      goToEdit: false,
      refetchJRWASelect: false,
      showCompleteFolderModal: false,
      activeKeyContent: "issues",
    };
  },
  components: {
    Breadcrumbs,
    CompleteFolderModal,
    EODButton,
    EODInput,
    EODSelect,
    FoldersContent,
    InfoCircleOutlined,
    JRWASelect,
    Section,
    Tooltip,
  },
  beforeRouteEnter(to, _, next) {
    if (to.name === "FoldersCreate") {
      next();
    } else {
      getFolder(to.params.id)
        .then(({ data }) => {
          next((vm) => {
            vm.data = data;
            vm.refetchJRWASelect = true;
            vm.defaultUnit = vm.$getDefaultValue(data, "unit");
            vm.data.archive_category = data.archive_category_display;
            [vm.unitSymbol, vm.jrwaKey] =
              data.act_symbol === null ? ["", ""] : data.act_symbol.split(".");
            vm.showCompleteFolderModal = vm.showCompleteFolderModalProp;
          });
        })
        .catch((err) => {
          if (err.response.status === 404) {
            next({ name: "404" });
          }
        });
    }
  },
  computed: {
    title() {
      return this.id
        ? this.$t("archive.folders.meta.edit")
        : this.$t("archive.folders.add");
    },
    actSymbol() {
      return (
        this.data.act_symbol ||
        (this.unitSymbol && this.jrwaKey
          ? `${this.unitSymbol}.${this.jrwaKey}`
          : "")
      );
    },
    edgeYearFrom() {
      return this.data.edge_date_from === null
        ? ""
        : new Date(this.data.edge_date_from).getFullYear();
    },
    edgeYearTo() {
      return this.data.edge_date_to === null
        ? ""
        : new Date(this.data.edge_date_to).getFullYear();
    },
  },
  methods: {
    submit() {
      this.loading$ = true;
      const service = this.id ? editFolder : createFolder;

      service(this.data, this.id)
        .then(({ data }) => {
          this.$message.success(this.$t("app.success"));
          if (this.goToEdit) {
            this.$router.replace({
              name: "FoldersEdit",
              params: {
                id: data.id,
                showCompleteFolderModalProp: this.showCompleteFolderModal,
              },
            });
          } else {
            this.$router.push({ name: "Archive", query: { tab: "folders" } });
          }
        })
        .catch((err) => {
          this.errors = err.response.data;
        })
        .finally(() => {
          this.goToEdit = false;
          this.loading$ = false;
        });
    },
    fetchUnits(params) {
      return getEmployeeUnits(params).then(({ data }) => data);
    },
    onJRWAChange(value, jrwaList) {
      const item = jrwaList.find((elem) => elem.id === value);

      this.data.archive_category = item.archiveCategory;
      this.data.title = item.name;
      this.jrwaKey = item.key;
    },
    onUnitChange(value) {
      const item = this.$store.state[
        "select_folder_unit"
      ]?.serviceOptions?.find((elem) => elem.id === value);

      this.unitSymbol = item.unit_symbol;
    },
    setGoToEdit() {
      this.goToEdit = true;
      this.showCompleteFolderModal = true;
    },
    confirmDelete() {
      this.$store.dispatch("setModal", {
        title: this.$t("app.delete_", [this.$tc("archive.folders.folder", 0)]),
        message: this.$t("app.doYouWantToDelete_", [
          this.$tc("archive.folders.folder", 0),
        ]),
        modalOk: this.deleteThis,
      });
    },
    deleteThis() {
      deleteFolder(this.id).then(() => {
        this.$message.success(this.$t("app.success"));
        this.$store.dispatch("closeModal");
        this.$router.replace({ name: "Archive", query: { tab: "folders" } });
      });
    },
    openCompleteFolderModal() {
      this.showCompleteFolderModal = true;
    },
  },
  watch: {
    actSymbol(val) {
      this.data.act_symbol = val;
    },
  },
};
</script>
