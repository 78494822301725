<template>
  <Breadcrumbs
    :path="[
      { path: { name: 'Dictionaries' }, title: $t('modules.dictionaries') },
    ]"
    :current="title"
    iconComponent="SortAscendingOutlined"
  >
    <EODButtonLink
      v-if="hasPermissionToEdit"
      :to="{ name: 'DictionariesEdit', params: { id: dictID } }"
      :title="$t('dictionaries.edit')"
      icon="EditOutlined"
    />
  </Breadcrumbs>

  <Preview
    id="dict"
    :oid="dictID"
    :data="previewData"
    :title="$t('dictionaries.info')"
    :noPreviewMsg="$t('dictionaries.noPreview')"
  />

  <DictionaryEntriesPreview v-if="entryID" id="entry" />

  <DictionaryEntries
    v-if="dictID && hasDictEntriesPermission"
    id="entries"
    :dictID="dictID"
    :preview="true"
  />
</template>

<script>
import { Breadcrumbs, EODButtonLink } from "@/components/ui";
import DictionaryEntries from "./DictionaryEntries";
import DictionaryEntriesPreview from "./DictionaryEntriesPreview";
import PERMISSIONS from "@/consts/permissions";
import { Preview } from "@/components/common";
import { getDictionary } from "@/services/dictionaries";

export default {
  name: "DictionariesPreview",
  data() {
    return {
      dictID: +this.$route.params.id,
      previewData: [],
      title: "",
    };
  },
  components: {
    Breadcrumbs,
    EODButtonLink,
    DictionaryEntries,
    DictionaryEntriesPreview,
    Preview,
  },
  computed: {
    entryID() {
      return this.$store.state.dictionaries.entryID;
    },
    hasPermissionToEdit() {
      return this.$permissions.includes(PERMISSIONS.DICTIONARIES_EDIT);
    },
    hasDictEntriesPermission() {
      return this.$permissions.includes(PERMISSIONS.DICTIONARIES_ENTRY);
    },
  },
  mounted() {
    this.$store.dispatch("setEntryID", undefined);

    if (this.dictID) {
      getDictionary(this.dictID).then(({ data }) => {
        this.title = data.name;
        this.previewData = [
          { title: this.$t("app.name"), value: data.name },
          {
            title: this.$t("app.status"),
            value: data.status_display,
          },
          {
            title: this.$t("app.description"),
            value: data.description || "-",
          },
        ];
      });
    }
  },
};
</script>
