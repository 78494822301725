import * as Sentry from "@sentry/browser";

import { Form, message } from "ant-design-vue";
import { key, store } from "./store";

import App from "./App.vue";
import { Vue as VueIntegration } from "@sentry/integrations";
import { createApp } from "vue";
import i18n from "./i18n";
import router from "./router";

const VueApp = createApp(App).use(Form).use(store, key).use(router).use(i18n);

VueApp.mixin({
  data() {
    return {
      loading$: false,
    };
  },
  computed: {
    $permissions() {
      return store.getters["user/getUser"].permissions;
    },
  },
  methods: {
    async $checkPermissions(permission: string) {
      if (this.$permissions.includes(permission)) {
        return true;
      } else if (this.$permissions.length === 0) {
        await this.$store.dispatch("user/add", { root: true });
        return this.$permissions.includes(permission);
      } else {
        return false;
      }
    },
    $getDefaultValue(root: any, key: string) {
      return root[key] ? { id: root[key], name: root[`${key}_display`] } : null;
    },
    $scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
});

VueApp.config.globalProperties.$message = message;

VueApp.config.globalProperties.$message.config({
  duration: 10,
  maxCount: 5,
});

VueApp.provide("$message", VueApp.config.globalProperties.$message);

VueApp.mount("#app");

Sentry.init({
  dsn: process.env.VUE_APP_SENTRY_PUBLIC_DSN,
  environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
  integrations: [
    new VueIntegration({
      // VueApp,
      attachProps: true,
    }),
  ],
});
