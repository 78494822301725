import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EODButtonLink = _resolveComponent("EODButtonLink")
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")
  const _component_Preview = _resolveComponent("Preview")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_Breadcrumbs, {
      path: [
      {
        path: { name: 'Contacts' },
        title: _ctx.$t('modules.contacts'),
      },
    ],
      current: _ctx.$t('contacts.preview'),
      iconComponent: "TeamOutlined"
    }, {
      default: _withCtx(() => [
        (_ctx.editable)
          ? (_openBlock(), _createBlock(_component_EODButtonLink, {
              key: 0,
              to: { name: 'ContactsEdit', params: { id: _ctx.id } },
              title: _ctx.$t('app.edit_', [_ctx.$tc('contacts.contact', 1)]),
              icon: "EditOutlined"
            }, null, 8, ["to", "title"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["path", "current"]),
    _createVNode(_component_Preview, {
      oid: _ctx.id,
      data: _ctx.previewData,
      title: _ctx.$t('contacts.preview'),
      noPreviewMsg: _ctx.$t('app.noPreview_', [_ctx.$tc('contacts.contact', 2)])
    }, null, 8, ["oid", "data", "title", "noPreviewMsg"])
  ], 64))
}