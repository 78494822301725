
import { defineComponent } from "vue";

export default defineComponent({
  name: "Footer",
  setup() {
    const showFooter = !!+process.env.VUE_APP_SHOW_FOOTER;
    return { showFooter };
  },
});
