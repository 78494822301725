import { MessageApi } from "ant-design-vue/lib/message";
import { inject } from "vue";
import { processMailboxMessage } from "@/services/mailboxes";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "@/store";

export default function useMessageProcess() {
  const $message = inject("$message") as MessageApi;
  const router = useRouter();
  const store = useStore();
  const { t } = useI18n();

  const handleStartProcess = (
    mailboxId: string,
    messageId: number,
    relocate: boolean = false,
  ) => {
    processMailboxMessage(mailboxId, messageId)
      .then(() => {
        $message.success(t("app.success"));
        if (relocate) {
          router.go(-1); // TODO: verify this
        }
      })
      .finally(() => {
        store.dispatch("closeModal");
        store.dispatch("refreshTable");
      });
  };

  const handleConfirm = (
    mailboxId: string,
    messageId: number,
    relocate: boolean = false,
  ) => {
    store.dispatch("setModal", {
      title: t("app.confirmation"),
      message: t("mailboxes.messages.doYouWantToProcess"),
      modalOk: () => handleStartProcess(mailboxId, messageId, relocate),
    });
  };

  return { handleConfirm, handleStartProcess };
}
