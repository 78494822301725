import { Params } from "@/interfaces/common";
import axios from "@/config/apiClient";

const getAttachmentsList = (id: string, params: Params) => {
  return axios.get(`/api/proposals/${id}/attachments/`, { params });
};

const getAttachment = (id: number) => {
  return axios.get(`/api/proposals/attachments/${id}/`);
};

const downloadAttachmentURL = (id: number) => {
  const accessToken = localStorage.getItem("access_token");
  return `/api/proposals/attachments/${id}/download/?token=${accessToken}`;
};

export { downloadAttachmentURL, getAttachmentsList, getAttachment };
