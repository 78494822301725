<template>
  <Section
    id="service-tasks"
    :title="$t('deployments.serviceTasks')"
    v-if="serviceTasks.length > 0"
  >
    <div
      v-for="(task, idx) in serviceTasks"
      :key="task.service_task_id"
      class="form-container"
    >
      <form class="form">
        <div class="service-task-title">
          <h2>{{ task.name }}</h2>
        </div>

        <EODSelect
          :id="`notification-type-${idx}`"
          v-model:value="serviceTasks[idx].notification_type"
          :label="$t('notifications.type')"
          required
          :options="servicesList"
          @change="handleTypeChange(idx)"
          :disabled="deploymentStarted"
          :errors="errors[idx]?.notification_type"
        />

        <EODSelect
          :id="`notification-${idx}`"
          :storeID="`notifications-${serviceTasks[idx].notification_type}`"
          v-model:value="serviceTasks[idx].notification"
          :label="$t('deployments.notification')"
          required
          :disabled="!serviceTasks[idx].notification_type || deploymentStarted"
          :service="
            (params) =>
              fetchNotifications(params, serviceTasks[idx].notification_type)
          "
          :defaultValue="defaultNotifications[idx]"
          :errors="errors[idx]?.notification"
        />

        <EODSelect
          :id="`users-${idx}`"
          v-model:value="serviceTasks[idx].users"
          mode="multiple"
          :label="$tc('app.user', 2)"
          :service="fetchUsers"
          :defaultValue="defaultUsers[idx]"
          :disabled="deploymentStarted"
          :errors="errors[idx]?.users"
        />

        <EODSelect
          :id="`groups-${idx}`"
          v-model:value="serviceTasks[idx].groups"
          mode="multiple"
          :label="$t('modules.teams')"
          :service="fetchGroups"
          :defaultValue="defaultGroups[idx]"
          :disabled="deploymentStarted"
          :errors="errors[idx]?.groups"
        />
      </form>
    </div>

    <EODButton
      :disabled="deploymentStarted"
      :name="$t('app.save')"
      type="primary"
      @click="submitServiceTasks"
      :loading="loading$"
    />
  </Section>
</template>

<script>
import { EODButton, Section } from "@/components/ui";
import { editServiceTasks, getServiceTasks } from "@/services/deployments";
import {
  getNotificationsList,
  getNotificationsTypes,
} from "@/services/notifications";

import { EODSelect } from "@/components/inputs";
import { STATUS } from "@/consts/statuses";

export default {
  name: "ServiceTasks",
  props: {
    deploymentID: { type: Number, required: true },
    fetchUsers: { type: Function, required: true },
    fetchGroups: { type: Function, required: true },
    deploymentStarted: { type: Boolean, default: false },
  },
  data() {
    return {
      serviceTasks: [],
      servicesList: [],
      notifications: {},
      errors: {},
      defaultNotifications: [],
      defaultUsers: [],
      defaultGroups: [],
    };
  },
  components: { EODButton, EODSelect, Section },
  created() {
    this.fetchServiceTasks();
  },
  methods: {
    submitServiceTasks() {
      this.loading$ = true;

      editServiceTasks(this.serviceTasks, this.deploymentID)
        .then(() => {
          this.$message.success(this.$t("app.success"));
          this.errors = {};
        })
        .catch((err) => {
          this.errors = Object.fromEntries(err.response.data.entries());
        })
        .finally(() => {
          this.loading$ = false;
        });
    },
    fetchNotifications(params, type) {
      return getNotificationsList({
        status: STATUS.ACTIVE,
        notifications_type: type,
        ...params,
      }).then(({ data }) => ({
        ...data,
        results: data.results.map((item) => ({
          id: item.id,
          name: item.name,
        })),
      }));
    },
    async fetchServiceTasks() {
      if (this.deploymentID) {
        await getNotificationsTypes().then(({ data }) => {
          this.servicesList = Object.entries(data).map(([key, value]) => ({
            id: +key,
            name: value,
          }));
        });

        getServiceTasks(this.deploymentID).then(({ data }) => {
          this.serviceTasks = data;

          for (const [index, task] of data.entries()) {
            this.defaultNotifications[index] = this.$getDefaultValue(
              task,
              "notification",
            );

            this.defaultUsers[index] = [
              { id: -1, name: this.$t("deployments.deploymentCreator") },
            ].concat(
              task.users.map((item) => ({
                ...item,
                name: item.full_name,
              })),
            );
            task.users = task.users.map((item) => item.id);

            this.defaultGroups = task.groups;
            task.groups = task.groups.map((item) => item.id);
          }
        });
      }
    },
    handleTypeChange(idx) {
      this.serviceTasks[idx].notification = null;
      this.defaultNotifications[idx] = null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/Variables";

.service-task-title {
  width: 100%;
  text-align: left;
  border-bottom: 1px solid $menu-dark-bg;
}
</style>
