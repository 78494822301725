
import { BranchesOutlined, DeleteOutlined } from "@ant-design/icons-vue";
import { computed, defineComponent, toRefs } from "vue";

import { EODSwitch } from "@/components/inputs";

export default defineComponent({
  name: "FieldTile",
  props: {
    item: { type: Object, required: true },
    disabled: { type: Boolean, default: false },
    editCondition: { type: Function, default: () => {} },
    removeItem: { type: Function, default: () => {} },
    multiValue: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const { disabled } = toRefs(props);

    const cDisabled = computed({
      get() {
        return disabled.value;
      },
      set(val) {
        emit("update:disabled", val);
      },
    });

    return { cDisabled };
  },
  components: { BranchesOutlined, DeleteOutlined, EODSwitch },
  emits: ["update:disabled"],
});
