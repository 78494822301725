<template>
  <Breadcrumbs
    :current="$t('modules.contractors')"
    iconComponent="TeamOutlined"
  >
    <EODButtonLink
      v-if="hasPermissionToAdd"
      :to="{ name: 'ContractorsCreate' }"
      :title="$t('contractors.add')"
    />
  </Breadcrumbs>

  <Tabs @change="$scrollToTop">
    <TabPane key="1" :tab="$t('contractors.tabs.all')">
      <ContractorsList customStore="contractorsList" />
    </TabPane>
    <TabPane key="2" :tab="$t('contractors.tabs.verified')">
      <ContractorsList
        :params="{ verified: true }"
        customStore="contractorsVerified"
      />
    </TabPane>
    <TabPane key="3" :tab="$t('contractors.tabs.unverified')">
      <ContractorsList
        :params="{ verified: false }"
        customStore="contractorsUnverified"
      />
    </TabPane>
  </Tabs>
</template>

<script>
import { Breadcrumbs, EODButtonLink } from "@/components/ui";

import ContractorsList from "./ContractorsList";
import PERMISSIONS from "@/consts/permissions";
import { Tabs } from "ant-design-vue";

export default {
  name: "Contractors",
  components: {
    Breadcrumbs,
    ContractorsList,
    EODButtonLink,
    Tabs,
    TabPane: Tabs.TabPane,
  },
  computed: {
    hasPermissionToAdd() {
      return this.$permissions.includes(PERMISSIONS.CONTRACTORS_ADD);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/Contractors";
</style>
