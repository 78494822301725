
import { JRWAEntrySimple, JRWATreeNode } from "@/interfaces/jrwa";
import { PropType, computed, defineComponent, ref, toRefs, watch } from "vue";

import { EODTreeSelect } from "@/components/inputs";
import { getJRWAEntriesSelectList } from "@/services/jrwa";

export default defineComponent({
  name: "JRWASelect",
  props: {
    value: Number,
    value_display: String,
    id: String,
    required: Boolean,
    errors: { type: Array },
    onChange: Function as PropType<
      (val: number, jrwaList: JRWATreeNode[]) => any
    >,
    refetch: {
      type: Boolean,
      default: false,
    },
    runInitialFetch: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const { value, value_display, refetch } = toRefs(props);
    const searchJrwa = ref<string>("");
    const jrwaList = ref<JRWATreeNode[]>([]);

    const cValue = computed({
      get: () => value.value,
      set: (val) => emit("update:value", val),
    });
    const cValue_display = computed({
      get: () => value_display.value,
      set: (val) => emit("update:value_display", val),
    });

    const getTreeNode = (item: JRWAEntrySimple): JRWATreeNode => ({
      id: item.id,
      pId: item.parent || 0,
      value: item.id,
      title: `${item.key} ${item.name}`,
      isLeaf: !item.children,
      disabled: !!item.children,
      key: item.key,
      name: item.name,
      archiveCategory: item.archive_category_display,
    });

    const fetchJRWAList = () => {
      return getJRWAEntriesSelectList(
        searchJrwa.value ? { search_field: searchJrwa.value, limit: 100 } : {},
      ).then(({ data: { results } }) => {
        jrwaList.value = results.map(getTreeNode);

        if (
          cValue.value &&
          !jrwaList.value.find((elem) => elem.id === cValue.value) &&
          !searchJrwa.value
        ) {
          jrwaList.value.push({
            id: cValue.value,
            pId: 0,
            value: cValue.value,
            title: cValue_display.value,
            isLeaf: true,
          } as JRWATreeNode);
        }
      });
    };
    const onLoadData = (treeNode: any) => {
      const { id: nodeId } = treeNode.dataRef;
      return getJRWAEntriesSelectList({ parent: nodeId }).then(
        ({ data: { results } }) => {
          const children = results.map((item: JRWAEntrySimple) => {
            if (item.id === cValue.value) {
              jrwaList.value = jrwaList.value.filter(
                (elem) => elem.id !== cValue.value,
              );
            }
            return getTreeNode(item);
          });

          jrwaList.value = jrwaList.value.concat(children);
        },
      );
    };
    const onTreeSelectChange = (val: number) => {
      searchJrwa.value = "";
      cValue_display.value = jrwaList.value.find(
        (elem) => elem.id === val,
      )?.title;

      if (props.onChange) {
        props.onChange(val, jrwaList.value);
      }
    };

    if (props.runInitialFetch) {
      fetchJRWAList();
    }

    watch(refetch, (val) => {
      if (val) {
        fetchJRWAList();
        emit("update:refetch", false);
      }
    });

    return {
      cValue,
      fetchJRWAList,
      onLoadData,
      onTreeSelectChange,
      jrwaList,
      searchJrwa,
    };
  },
  components: { EODTreeSelect },
  emits: ["update:value", "update:value_display", "update:refetch"],
});
