
import { EODButton, Section } from "@/components/ui";
import { EODInput, EODSelect } from "@/components/inputs";
import {
  addComment,
  editComment,
  getCommentTypes,
  getComments,
  getProcessComments,
} from "@/services/tasks";
import { computed, defineComponent, inject, ref, toRefs } from "vue";

import { BaseTable } from "@/components/table";
import { Comment } from "@/interfaces/tasks";
import CommentRow from "./CommentRow.vue";
import { MessageApi } from "ant-design-vue/lib/message";
import { Select } from "ant-design-vue";
import { SelectOption } from "@/interfaces/common";
import TypeTag from "./TypeTag.vue";
import dynamicTable from "@/store/modules/dynamicTable";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";

export default defineComponent({
  name: "Comments",
  props: {
    id: { type: String, required: true },
    customStore: { type: String, required: true },
    preview: { type: Boolean, default: false },
    process: { type: Boolean, default: false },
  },
  setup(props) {
    const $message = inject<MessageApi>("$message");
    const store = useStore();
    const { t } = useI18n();

    const { customStore } = toRefs(props);
    const comment = ref<Comment>({ message: "", comment_type: null });
    const commentTypeFilter = ref<number | null>(null);
    const typeOptions = ref<SelectOption[]>([]);
    const columns = ref([
      { dataIndex: "comment", slots: { customRender: "comment" } },
    ]);
    const errors = ref<any>({});
    const loading = ref(false);

    const saveComment = () => {
      loading.value = true;
      const service = comment.value.id
        ? editComment(comment.value, comment.value.id)
        : addComment(comment.value, props.id);

      service
        .then(() => {
          $message.success(t("app.success"));
          clearForm();
          errors.value = {};
          store.dispatch(`${customStore.value}/refreshTable`);
        })
        .catch((err) => {
          errors.value = err.response.data;
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const handleEdit = (editedComment: Comment) => {
      comment.value = editedComment;
    };

    const clearForm = () => {
      comment.value = {
        message: "",
        comment_type: null,
      };
    };

    const service = computed(() =>
      props.process ? getProcessComments : getComments,
    );

    // TODO: move registration of customStore to BaseTable
    if (!store.hasModule(customStore.value)) {
      store.registerModule(customStore.value, dynamicTable);
    }

    getCommentTypes().then(({ data }: { data: any }) => {
      typeOptions.value = Object.entries(data).map(([key, value]) => ({
        name: value as string,
        id: +key,
      }));
    });

    return {
      clearForm,
      columns,
      comment,
      commentTypeFilter,
      customStore,
      errors,
      handleEdit,
      loading,
      saveComment,
      service,
      typeOptions,
    };
  },
  components: {
    BaseTable,
    CommentRow,
    EODButton,
    EODSelect,
    EODInput,
    Section,
    SelectOption: Select.Option,
    TypeTag,
  },
});
