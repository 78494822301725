<template>
  <span :class="colorsMap[status]"> &#9679; </span>
</template>

<script>
import { STATUS } from "@/consts/statuses";

export default {
  name: "StatusDot",
  props: {
    status: Number,
    colorsMap: {
      type: Object,
      default: () => ({
        [STATUS.ACTIVE]: "color--success",
        [STATUS.INACTIVE]: "color--error",
        [STATUS.ARCHIVE]: "color--grey",
      }),
    },
  },
};
</script>
