
import { BaseTable, TableActions } from "@/components/table";
import { defineComponent, ref } from "vue";
import {
  deleteMailboxMessage,
  processMailboxMessage,
} from "@/services/mailboxes";

import { FileTextOutlined } from "@ant-design/icons-vue";
import { useI18n } from "vue-i18n";
import useMessageProcess from "@/composables/useMessageProcess";

export default defineComponent({
  name: "MessagesList",
  props: {
    service: {
      type: Function,
      required: true,
    },
    id: {
      type: [String, Number],
      required: true,
    },
    editionMode: {
      type: Boolean,
      default: false,
    },
    deletedPreview: { type: Boolean, default: false },
  },
  setup() {
    const { t } = useI18n();
    const columns = ref([
      {
        title: t("mailboxes.messages.subject"),
        dataIndex: "subject",
        width: "35%",
      },
      {
        title: t("app.date"),
        dataIndex: "date",
        slots: { customRender: "date" },
      },
      {
        title: t("mailboxes.messages.from"),
        dataIndex: "from",
      },
      {
        title: t("app.actions"),
        dataIndex: "actions",
        slots: { customRender: "actions" },
        fixed: "right",
        width: "120",
      },
    ]);

    return {
      columns,
      deleteMailboxMessage,
      processMailboxMessage,
      ...useMessageProcess(),
    };
  },
  components: {
    BaseTable,
    FileTextOutlined,
    TableActions,
  },
});
