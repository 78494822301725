import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EODTreeSelect = _resolveComponent("EODTreeSelect")

  return (_openBlock(), _createBlock(_component_EODTreeSelect, {
    id: _ctx.id,
    label: _ctx.$t('documents.JRWANumber'),
    value: _ctx.cValue,
    "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (_ctx.cValue = $event)),
    treeData: _ctx.jrwaList,
    onLoadData: _ctx.onLoadData,
    searchValue: _ctx.searchJrwa,
    "onUpdate:searchValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.searchJrwa = $event)),
    onSearch: _ctx.fetchJRWAList,
    onChange: _ctx.onTreeSelectChange,
    errors: _ctx.errors,
    required: _ctx.required
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["id", "label", "value", "treeData", "onLoadData", "searchValue", "onSearch", "onChange", "errors", "required"]))
}