import { AxiosResponse } from "axios";

export default function formsErrorHandler(
  response: AxiosResponse,
  errorMessage?: string,
) {
  if (errorMessage) {
    return errorMessage;
  }

  // TODO: think about better backend
  if (typeof response.data === "object") {
    const errors = [...Object.entries(response.data)];
    if (errors.length > 0) {
      if (
        !["name", "status", "description"].includes(errors[0][0]) &&
        Array.isArray(errors[0][1]) &&
        typeof errors[0][1][0] === "string"
      ) {
        return errors[0][1];
      }
    }
  }

  return "";
}
