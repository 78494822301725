import apiClient from "@/config/apiClient";

const getNotificationsList = (params) => {
  return apiClient.get("/api/notifications/", { params });
};

const createNotification = (data) => {
  return apiClient.post("/api/notifications/", data);
};

const editNotification = (data, id) => {
  return apiClient.put(`/api/notifications/${id}/`, data);
};

const getNotification = (id) => {
  return apiClient.get(`/api/notifications/${id}/`);
};

const deleteNotification = (id) => {
  return apiClient.delete(`/api/notifications/${id}/`);
};

const getNotificationsTypes = () => {
  return apiClient.get("/api/notifications/types/");
};

const getOwnNotificationList = (params) => {
  return apiClient.get("/api/notifications/my-notifications/", { params });
};
const getOwnNotification = (id) => {
  return apiClient.get(`/api/notifications/my-notifications/${id}/`);
};

export {
  createNotification,
  deleteNotification,
  editNotification,
  getNotification,
  getNotificationsTypes,
  getNotificationsList,
  getOwnNotificationList,
  getOwnNotification,
};
