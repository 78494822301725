import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "section pt-0" }
const _hoisted_2 = { class: "filters" }
const _hoisted_3 = { class: "section" }
const _hoisted_4 = { class: "actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")
  const _component_EODSelect = _resolveComponent("EODSelect")
  const _component_EODDatePicker = _resolveComponent("EODDatePicker")
  const _component_EODButton = _resolveComponent("EODButton")
  const _component_InfoCircleOutlined = _resolveComponent("InfoCircleOutlined")
  const _component_Tooltip = _resolveComponent("Tooltip")
  const _component_EyeOutlined = _resolveComponent("EyeOutlined")
  const _component_router_link = _resolveComponent("router-link")
  const _component_BaseTable = _resolveComponent("BaseTable")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_Breadcrumbs, {
      current: _ctx.$t('proposal.proposals'),
      iconComponent: "FileOutlined"
    }, null, 8, ["current"]),
    _createVNode("section", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode(_component_EODSelect, {
          id: "deployment-category",
          storeID: "proposal-category",
          value: _ctx.filters.deployment__category,
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (_ctx.filters.deployment__category = $event)),
          label: _ctx.$t('proposal.category'),
          service: _ctx.fetchDeploymentCategory
        }, null, 8, ["value", "label", "service"]),
        _createVNode(_component_EODSelect, {
          id: "proposal-status",
          storeID: "proposal-status",
          value: _ctx.filters.proposal__status,
          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => (_ctx.filters.proposal__status = $event)),
          label: _ctx.$t('app.status'),
          service: _ctx.fetchProposalsStatuses
        }, null, 8, ["value", "label", "service"]),
        _createVNode(_component_EODSelect, {
          id: "field-of-study",
          storeID: "field-of-study",
          value: _ctx.filters.proposal__field_of_study,
          "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => (_ctx.filters.proposal__field_of_study = $event)),
          label: _ctx.$t('proposal.fieldOfStudy'),
          service: _ctx.fetchFieldsOfStudyList
        }, null, 8, ["value", "label", "service"]),
        _createVNode(_component_EODSelect, {
          id: "department",
          storeID: "department",
          value: _ctx.filters.proposal__department,
          "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => (_ctx.filters.proposal__department = $event)),
          label: _ctx.$t('proposal.department'),
          service: _ctx.fetchDepartmentsList
        }, null, 8, ["value", "label", "service"]),
        _createVNode(_component_EODSelect, {
          id: "year-of-study",
          storeID: "filter-owner-options",
          value: _ctx.filters.proposal__year_of_study,
          "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => (_ctx.filters.proposal__year_of_study = $event)),
          label: _ctx.$t('proposal.yearOfStudy'),
          service: _ctx.fetchYearsListForProposal
        }, null, 8, ["value", "label", "service"]),
        _createVNode(_component_EODDatePicker, {
          id: "submission-date-from",
          value: _ctx.filters.proposal__submission_date__date__gte,
          "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => (_ctx.filters.proposal__submission_date__date__gte = $event)),
          label: `${_ctx.$t('proposal.submissionDate')} - ${_ctx.$t(
          'search.operators.from',
        )}`,
          dateOnly: ""
        }, null, 8, ["value", "label"]),
        _createVNode(_component_EODDatePicker, {
          id: "submission-date-to",
          value: _ctx.filters.proposal__submission_date__date__lte,
          "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => (_ctx.filters.proposal__submission_date__date__lte = $event)),
          label: `${_ctx.$t('proposal.submissionDate')} - ${_ctx.$t(
          'search.operators.to',
        )}`,
          dateOnly: ""
        }, null, 8, ["value", "label"]),
        _createVNode(_component_EODDatePicker, {
          id: "end-date-from",
          value: _ctx.filters.proposal__end_date__date__gte,
          "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => (_ctx.filters.proposal__end_date__date__gte = $event)),
          label: `${_ctx.$t('issues.endDate')} - ${_ctx.$t('search.operators.from')}`,
          dateOnly: ""
        }, null, 8, ["value", "label"]),
        _createVNode(_component_EODDatePicker, {
          id: "end-date-to",
          value: _ctx.filters.proposal__end_date__date__lte,
          "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => (_ctx.filters.proposal__end_date__date__lte = $event)),
          label: `${_ctx.$t('issues.endDate')} - ${_ctx.$t('search.operators.to')}`,
          dateOnly: ""
        }, null, 8, ["value", "label"]),
        _createVNode(_component_EODButton, {
          name: _ctx.$t('app.reset'),
          onClick: _ctx.handleFilterReset,
          class: "mr-1 mt-1"
        }, null, 8, ["name", "onClick"]),
        _createVNode(_component_EODButton, {
          class: "mt-1",
          type: "primary",
          name: _ctx.$t('app.filter'),
          onClick: _ctx.handleFilter
        }, null, 8, ["name", "onClick"])
      ])
    ]),
    _createVNode("section", _hoisted_3, [
      _createVNode(_component_BaseTable, {
        columns: _ctx.columns,
        service: (params) => _ctx.getProcessesList({ ...params, ..._ctx.filters }),
        customParams: _ctx.customParams,
        customStore: _ctx.customStore
      }, {
        processId: _withCtx(({ record }) => [
          _createVNode(_component_Tooltip, {
            title: record.process_id
          }, {
            default: _withCtx(() => [
              _createVNode(_component_InfoCircleOutlined)
            ]),
            _: 2
          }, 1032, ["title"])
        ]),
        name: _withCtx(({ record }) => [
          _createTextVNode(_toDisplayString(record.proposal?.name || "-"), 1)
        ]),
        applicant: _withCtx(({ record }) => [
          _createTextVNode(_toDisplayString(record.proposal?.applicant || "-"), 1)
        ]),
        submission_date: _withCtx(({ record }) => [
          _createTextVNode(_toDisplayString(record.proposal?.submission_date
            ? new Date(record.proposal.submission_date).toLocaleString()
            : "-"), 1)
        ]),
        end_date: _withCtx(({ record }) => [
          _createTextVNode(_toDisplayString(record.proposal?.end_date || "-"), 1)
        ]),
        status: _withCtx(({ record }) => [
          _createTextVNode(_toDisplayString(record.proposal?.status || "-"), 1)
        ]),
        actions: _withCtx(({ record }) => [
          _createVNode("div", _hoisted_4, [
            _createVNode(_component_router_link, {
              class: "action",
              to: {
              name: 'ProcessPreview',
              params: { id: record.process_id },
            },
              title: _ctx.$t('app.preview')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_EyeOutlined)
              ]),
              _: 2
            }, 1032, ["to", "title"])
          ])
        ]),
        _: 1
      }, 8, ["columns", "service", "customParams", "customStore"])
    ])
  ], 64))
}