<template>
  <Breadcrumbs :current="$t('modules.forms')" iconComponent="FormOutlined">
    <EODButton
      v-if="hasPermissionsToAdd"
      :name="$t('app.createFrom')"
      icon="PlusOutlined"
      @click="openCreateFromModal"
    />
    <EODButtonLink
      v-if="hasPermissionsToAdd"
      :to="{
        name: 'FormsCreate',
      }"
      :title="$t('forms.add')"
      class="ml-1"
    />
  </Breadcrumbs>
  <section class="section">
    <BaseTable :columns="columns" :service="getFormsList">
      <template v-slot:description="{ record }">
        <span :title="record.description">
          {{ record.description || "-" }}
        </span>
      </template>
      <template v-slot:status="{ record }">
        <StatusDot :status="record.status" />
        {{ record.status_display }}
      </template>
      <template v-slot:actions="{ record }">
        <TableActions
          :record="record"
          :previewRoute="{ name: 'FormsPreview', params: { id: record.id } }"
          :editRoute="{ name: 'FormsEdit', params: { id: record.id } }"
          :deleteMessage="`${$t('app.doYouWantToDelete')} ${$tc(
            'forms.form',
            1,
          )} &quot;${record.name}&quot;?`"
          :modalTitle="$t('forms.delete')"
          :deleteService="deleteForm"
        />
      </template>
    </BaseTable>
  </section>
  <SelectModal
    v-model:visible="showCreateFromModal"
    v-model:selectedOption="selectedSourceId"
    :title="$t('app.createFrom')"
    :body="$t('forms.createFromInfo')"
    :service="fetchFormsList"
    :onSubmit="handleCreateFrom"
    :onCancel="closeCreateFromModal"
    selectId="formsToCreate"
  />
</template>

<script>
import { BaseTable, StatusDot, TableActions } from "@/components/table";
import { Breadcrumbs, EODButton, EODButtonLink } from "@/components/ui";
import { deleteForm, getFormsList } from "@/services/forms";
import PERMISSIONS from "@/consts/permissions";
import { SelectModal } from "@/components/modals";

export default {
  name: "Forms",
  data() {
    return {
      showCreateFromModal: false,
      selectedSourceId: null,
      formsList: [],
      columns: [
        { title: this.$t("app.name"), dataIndex: "name", sorter: true },
        {
          title: this.$t("app.description"),
          dataIndex: "description",
          ellipsis: true,
          slots: { customRender: "description" },
          width: "50%",
        },
        {
          title: this.$t("app.status"),
          dataIndex: "status",
          sorter: true,
          slots: { customRender: "status" },
          width: "20%",
        },
        {
          title: this.$t("app.actions"),
          dataIndex: "actions",
          slots: { customRender: "actions" },
          width: "10%",
        },
      ],
    };
  },
  components: {
    BaseTable,
    Breadcrumbs,
    EODButton,
    EODButtonLink,
    StatusDot,
    TableActions,
    SelectModal,
  },
  computed: {
    hasPermissionsToAdd() {
      return this.$permissions.includes(PERMISSIONS.FORMS_ADD);
    },
  },
  methods: {
    deleteForm,
    getFormsList,
    fetchFormsList(params) {
      return getFormsList(params).then(({ data }) => data);
    },
    openCreateFromModal() {
      this.showCreateFromModal = true;
    },
    closeCreateFromModal() {
      this.showCreateFromModal = false;
      this.selectedSourceId = null;
    },
    handleCreateFrom() {
      if (this.selectedSourceId) {
        this.$router.push({
          name: "FormsCreate",
          query: { createFrom: this.selectedSourceId },
        });
      } else {
        return this.$message.error(this.$t("forms.chooseForm"));
      }
    },
  },
};
</script>
