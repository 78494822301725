<template>
  <Modal
    v-model:visible="cVisible"
    :title="null"
    :closable="false"
    :maskClosable="false"
    :footer="null"
    :width="416"
  >
    <div class="modal-content">
      <p class="modal-title">
        <span class="modal-icon">
          <ExclamationCircleOutlined v-if="!icon" class="icon" />
          <component v-else :is="icon" />
        </span>
        {{ title }}
      </p>
      <slot></slot>
    </div>
    <div class="buttons">
      <EODButton
        :name="cancelText || $t('app.cancel')"
        @click="onCancel"
        :loading="isLoading"
      />
      <EODButton
        v-if="!disableConfirm"
        type="primary"
        :name="okText || $t('app.continue')"
        @click="onSubmit"
        class="ml-050"
        :loading="isLoading"
      />
    </div>
  </Modal>
</template>

<script>
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  FolderOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons-vue";
import { EODButton } from "@/components/ui";
import { Modal } from "ant-design-vue";
import { ShareAltOutlined } from "@ant-design/icons-vue";

export default {
  name: "ConfirmModal",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: String,
    okText: String,
    cancelText: String,
    onSubmit: {
      type: Function,
      default: () => {},
    },
    onCancel: {
      type: Function,
      required: true,
    },
    icon: {
      type: [String, Boolean],
      default: false,
    },
    disableConfirm: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DeleteOutlined,
    EODButton,
    ExclamationCircleOutlined,
    FolderOutlined,
    Modal,
    PlayCircleOutlined,
    ShareAltOutlined,
  },
  computed: {
    cVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/Variables";
.modal-content {
  padding-left: 3rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  .modal-title {
    font-size: 1rem;
    position: relative;
    margin-bottom: 0.5rem;
    .modal-icon {
      position: absolute;
      top: 0;
      left: -2.5rem;
      font-size: 1.4rem;
      line-height: 1.8rem;
      .icon {
        color: $warning;
      }
    }
  }
}
.buttons {
  text-align: right;
  padding: 1.5rem 0.5rem 0;
}
</style>
