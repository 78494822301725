<template>
  <Switch
    v-model:checked="value"
    :size="size"
    :title="title"
    :onChange="onChange"
    :disabled="disabled"
  />
</template>

<script>
import { Switch } from "ant-design-vue";

export default {
  name: "EODSwitch",
  props: {
    size: { type: String, default: "default" },
    checked: Boolean,
    invert: { type: Boolean, default: false },
    title: String,
    onChange: Function,
    disabled: { type: Boolean, default: false },
  },
  components: { Switch },
  computed: {
    value: {
      get() {
        return this.invert ? !this.checked : this.checked;
      },
      set(val) {
        this.$emit("update:checked", this.invert ? !val : val);
      },
    },
  },
};
</script>
