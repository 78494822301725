<template>
  <Modal
    v-model:visible="cVisible"
    :title="this.$t('issues.share')"
    :okText="$t('issues.share')"
    :cancelText="$t('app.cancel')"
    :closable="false"
    @ok="submit"
    @cancel="onCancel"
    destroyOnClose
  >
    <div class="form-container">
      <form id="issue-share-form" class="form">
        <p>
          {{ $t("issues.shareInfo") }}
        </p>

        <EODSelect
          id="user"
          v-model:value="data.users"
          mode="multiple"
          :label="$t('app.user')"
          :service="fetchUsers"
          :errors="errors.user"
          v-model:refetch="refetchUsers"
          required
        />

        <EODInput
          id="comment"
          type="textarea"
          v-model:value="data.comment"
          :label="$t('app.comment')"
          :props="{ maxlength: 500 }"
          :errors="errors.comment"
        />
      </form>
    </div>
  </Modal>
</template>

<script>
import { EODInput, EODSelect } from "@/components/inputs";

import { Modal } from "ant-design-vue";
import { getUsersSimpleList } from "@/services/permissions";
import { shareIssue } from "@/services/issues";

export default {
  name: "IssueShare",
  props: {
    issueID: {
      type: Number,
      default: null,
    },
    owner: {
      type: Object,
      default: null,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      data: {
        users: [],
        comment: "",
      },
      errors: {},
      refetchUsers: false,
    };
  },
  components: {
    EODInput,
    EODSelect,
    Modal,
  },
  computed: {
    cVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
  },
  emits: ["update:visible", "update:issueID"],
  methods: {
    submit() {
      shareIssue(this.data, this.issueID)
        .then(() => {
          this.resetData();
          this.$message.success(this.$t("app.success"));

          for (const store of ["issueShared", "issueHistory"]) {
            if (this.$store.hasModule(store)) {
              this.$store.dispatch(`${store}/refreshTable`);
            }
          }

          this.cVisible = false;
        })
        .catch((err) => {
          this.errors = err.response.data;
        });
    },
    onCancel() {
      this.resetData();
      this.cVisible = false;
      this.$emit("update:issueID", null);
    },
    resetData() {
      this.data = {
        users: [],
        comment: "",
      };
      this.errors = {};
    },
    fetchUsers(params) {
      return getUsersSimpleList(params).then(({ data }) => ({
        ...data,
        results: data.results
          .filter((elem) => elem.id !== this.owner?.id)
          .map((elem) => ({
            id: elem.id,
            name: elem.full_name,
            title: elem.email,
          })),
      }));
    },
  },
  watch: {
    owner() {
      this.refetchUsers = true;
    },
    issueID(val) {
      if (val && this.owner?.id) {
        this.refetchUsers = true;
      }
    },
  },
};
</script>
