<template>
  <div class="actions">
    <slot></slot>

    <slot name="preview" v-if="showPreview">
      <router-link
        class="action"
        v-if="record?.actions?.viewable"
        :to="previewRoute"
        :title="$t('app.preview')"
      >
        <EyeOutlined />
      </router-link>
    </slot>

    <slot name="deploymentStart" />

    <slot name="edit" v-if="showEdit">
      <router-link
        class="action"
        v-if="record?.actions?.editable"
        :to="editRoute"
        :title="$t('app.edit')"
      >
        <EditOutlined />
      </router-link>
    </slot>

    <slot name="delete" v-if="showRemove">
      <button
        v-if="record?.actions?.removable"
        class="action"
        @click="confirmDelete"
        :title="$t('app.delete')"
      >
        <DeleteOutlined />
      </button>
    </slot>
  </div>
</template>

<script>
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
} from "@ant-design/icons-vue";

export default {
  name: "TableActions",
  props: {
    record: { type: Object, required: true },
    showEdit: { type: Boolean, default: true },
    showRemove: { type: Boolean, default: true },
    showPreview: { type: Boolean, default: true },
    previewRoute: { type: Object, default: () => ({ path: "#" }) },
    editRoute: { type: Object, default: () => ({ path: "#" }) },
    deleteMessage: { type: String, default: "" },
    modalTitle: { type: String, default: "" },
    deleteService: { type: Function, default: () => {} },
    customStore: {
      type: [Boolean, String],
      default: false,
    },
  },
  components: {
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
  },
  emits: ["delete-success"],
  methods: {
    confirmDelete() {
      this.$store.dispatch("setModal", {
        message: this.deleteMessage,
        title: this.modalTitle,
        modalOk: this.deleteItem,
      });
    },
    deleteItem() {
      this.loading$ = true;
      this.deleteService(this.record.id)
        .then(() => {
          this.$message.success(this.$t("app.success"));
          this.$store.dispatch("closeModal");
          if (this.customStore) {
            this.$store.dispatch(`${this.customStore}/refreshTable`);
          } else {
            this.$store.dispatch("refreshTable");
          }
          this.$emit("delete-success");
        })
        .finally(() => {
          this.loading$ = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/Variables.scss";

.actions {
  display: flex;
}
</style>
