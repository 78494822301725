import APPS from "@/consts/apps";
import Repository from "@/views/Repository/Repository.vue";
import i18n from "@/i18n";

const t = i18n.global.t;

const repository = [
  {
    path: "/repository/",
    name: "Repository",
    component: Repository,
    meta: {
      title: `${t("meta.app")} - ${t("modules.repository")}`,
      requiresAuth: true,
      app: APPS.REPOSITORIES,
    },
  },
];

export default repository;
