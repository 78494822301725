import { Store, useStore as baseUseStore, createStore } from "vuex";

import { InjectionKey } from "vue";
import { State } from "@/interfaces/store";
import archive from "./modules/archive";
import contractors from "./modules/contractors";
import dictionaries from "./modules/dictionaries";
import modal from "./modules/modal";
import notifications from "./modules/notifications";
import repository from "./modules/repository";
import table from "./modules/table";
import user from "./modules/user";

const key: InjectionKey<Store<State>> = Symbol();

const store = createStore<State>({
  modules: {
    archive,
    contractors,
    dictionaries,
    modal,
    notifications,
    repository,
    table,
    user,
  },
});

const useStore = () => {
  return baseUseStore(key);
};

export { key, store, useStore };
