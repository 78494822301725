
import { Breadcrumbs, EODButtonLink } from "@/components/ui";
import { defineComponent, ref } from "vue";

import { Preview } from "@/components/common";
import { PreviewDataNode } from "@/interfaces/common";
import { getContact } from "@/services/contacts";

export default defineComponent({
  name: "ContactsPreview",
  props: { id: { type: Number, required: true } },
  setup() {
    const previewData = ref<PreviewDataNode[]>([]);
    const editable = ref(false);

    return { editable, previewData };
  },
  components: {
    Breadcrumbs,
    EODButtonLink,
    Preview,
  },
  beforeRouteEnter(to, _, next) {
    getContact(+to.params.id)
      .then(({ data }) => {
        // TODO: proper typing
        next((vm: any) => {
          vm.previewData = [
            { title: vm.$t("contractors.firstName"), value: data.name },
            { title: vm.$t("contractors.lastName"), value: data.last_name },
            { title: vm.$t("contractors.phone"), value: data.phone_numbers },
            { title: vm.$t("contractors.email"), value: data.emails },
            { title: vm.$t("contractors.streetName"), value: data.street },
            {
              title: vm.$t("contractors.streetNumber"),
              value: data.street_number,
            },
            {
              title: vm.$t("contractors.streetSuite"),
              value: data.apartment_number,
            },
            { title: vm.$t("contractors.postCode"), value: data.postal_code },
            { title: vm.$t("contractors.city"), value: data.city },
            {
              title: vm.$t("contractors.country"),
              value: data.country_display,
            },
          ];
          vm.editable = data?.actions?.editable;
        });
      })
      .catch((err) => {
        if (err.response.status === 404) {
          next({ name: "404" });
        }
      });
  },
});
