
import { Avatar, BackTop, Badge, Modal } from "ant-design-vue";
import {
  BellOutlined,
  DownOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SearchOutlined,
  UserOutlined,
} from "@ant-design/icons-vue";
import { SSO_URLS, USER_SOURCE } from "@/consts/statuses";
import { computed, defineComponent, ref, watch } from "vue";

import EODMenu from "./EODMenu.vue";
import Footer from "./Footer.vue";
import { HAS_BEFORE_LOGIN_PAGE } from "@/consts/variables";
import PERMISSIONS from "@/consts/permissions";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "@/store";

export default defineComponent({
  name: "Skeleton",
  setup(_, { emit }) {
    const router = useRouter();
    const store = useStore();
    const { t, locale } = useI18n();

    const notificationsSocket = ref<WebSocket>();
    const collapsed = ref(false);
    const selectedLanguage = ref(localStorage.getItem("language") || "pl");
    const showUserInfo = ref(false);
    const DEBUG = !!+process.env.VUE_APP_DEBUG;
    const showSecondaryLogo = !!+process.env.VUE_APP_NAV_SECONDARY_LOGO;

    const user = computed(() => store.getters["user/getUser"]);
    const userId = computed(() => user.value.id);

    const notifications = computed(
      () => store.getters["notifications/getCount"],
    );
    const title = computed(() =>
      collapsed.value ? t("app.uncollapseMenu") : t("app.collapseMenu"),
    );

    const modalMessage = computed(() => store.state.modal.modalMessage);
    const modalTitle = computed(() => store.state.modal.modalTitle);
    const modalOk = computed(() => store.state.modal.modalOk);
    const okText = computed(() => store.state.modal.okText);
    const cancelText = computed(() => store.state.modal.cancelText);
    const isModalOpen = computed({
      get() {
        return store.state.modal.isModalOpen;
      },
      set() {
        store.dispatch("closeModal");
      },
    });

    const connectToWS = () => {
      const scheme = window.location.protocol == "https:" ? "wss" : "ws";
      const accessToken = localStorage.getItem("access_token");
      const socketURL = `${scheme}://${window.location.host}/ws/notifications/?token=${accessToken}`;
      notificationsSocket.value = new WebSocket(socketURL);

      notificationsSocket.value.onmessage = (evt) => {
        const data = JSON.parse(evt.data);
        store.dispatch("notifications/setCount", data.notifications_number);

        if (["IMPORT_JRWA_REGISTER", "IMPORT_ENTRIES"].includes(data.name)) {
          store.dispatch(
            "notifications/setImportStatus",
            data.success ? "success" : "error",
          );
        }

        if (DEBUG) {
          console.log("MSG", evt);
        }
      };

      if (DEBUG) {
        notificationsSocket.value.onopen = (evt) => {
          console.log("OPEN", evt);
        };

        notificationsSocket.value.onclose = (evt) => {
          console.log("CLOSE", evt);
        };

        notificationsSocket.value.onerror = (evt) => {
          console.error("ERROR", evt);
        };
      }
    };

    const clearSession = () => {
      notificationsSocket.value.close();
      notificationsSocket.value = null;
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      store.dispatch("user/clear", { root: true });
    };

    const handleLogout = () => {
      clearSession();
      router.push({
        name: HAS_BEFORE_LOGIN_PAGE ? "BeforeLogin" : "Login",
      });
    };

    const handleLogoutSSO = () => {
      clearSession();
      window.location.href = SSO_URLS.LOGOUT;
    };

    const setLanguage = (lang: string) => {
      localStorage.setItem("language", lang);
      selectedLanguage.value = lang;
      locale.value = lang;
      emit("set-language", lang);
    };

    const setShowUserInfo = () => {
      showUserInfo.value = !showUserInfo.value;
    };

    watch(userId, (val) => {
      if (val && !notificationsSocket.value) {
        connectToWS();
      }
    });

    if (userId.value) {
      connectToWS();
    }

    return {
      PERMISSIONS,
      USER_SOURCE,
      cancelText,
      collapsed,
      handleLogout,
      handleLogoutSSO,
      isModalOpen,
      modalMessage,
      modalOk,
      modalTitle,
      notifications,
      okText,
      selectedLanguage,
      setLanguage,
      setShowUserInfo,
      showSecondaryLogo,
      showUserInfo,
      title,
      user,
    };
  },
  components: {
    Avatar,
    BackTop,
    Badge,
    DownOutlined,
    EODMenu,
    Footer,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    Modal,
    BellOutlined,
    SearchOutlined,
    UserOutlined,
  },
  emits: ["set-language"],
});
