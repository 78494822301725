import { computed, ref, watch } from "vue";

import { State } from "@/interfaces/store";
import { useStore } from "@/store";

export default function useCommentsNumber(customStore: keyof State) {
  const store = useStore();

  const commentsCount = computed<number>(
    () => store.state[customStore]?.data?.count,
  );

  const numberOfComments = ref(commentsCount.value);

  const commentsFilter = computed<number | null>(
    () => store.state[customStore]?.params?.comment_type,
  );

  watch(commentsCount, (val) => {
    if (!commentsFilter.value) {
      numberOfComments.value = val;
    }
  });

  return { numberOfComments };
}
