<template>
  <div class="input-container">
    <label :for="id" class="label">
      {{ label }}
      <span
        v-if="required"
        class="color--error"
        :title="$t('app.fieldRequired')"
      >
        &nbsp;*
      </span>
    </label>
    <div class="input-wrap">
      <Select
        :id="id"
        v-model:value="cValue"
        :required="required"
        :placeholder="cPlaceholder"
        :disabled="disabled"
        :filter-option="filterOption"
        :show-search="showSearch"
        :searchValue="searchValue"
        @search="onSearch"
        :mode="mode"
        showArrow
        :allowClear="!required && allowClear"
        :optionLabelProp="optionLabelProp"
        v-bind="props"
        @change="onChange"
        @popupScroll="onPopupScroll"
      >
        <template v-if="cFetching" v-slot:notFoundContent>
          <Spin size="small" />
        </template>
        <slot>
          <SelectOption
            v-for="option in cOptions"
            :key="option.id"
            :disabled="option.disabled"
            :title="option.title || option.name"
          >
            {{ option.name }}
          </SelectOption>
        </slot>
      </Select>
      <slot name="tooltip" />
    </div>
    <span class="color--error" v-if="errors">
      {{ errors[0] }}
    </span>
  </div>
</template>

<script>
import { Select, Spin } from "ant-design-vue";
import dynamicSelect from "@/store/modules/dynamicSelect";

export default {
  name: "EODSelect",
  props: {
    value: {},
    id: String,
    required: Boolean,
    placeholder: String,
    label: String,
    options: Array,
    disabled: { type: Boolean, default: false },
    errors: { type: Array },
    props: Object,
    search: {
      type: [Function, Boolean],
      default: false,
    },
    fetching: Boolean,
    mode: String,
    optionLabelProp: { type: String, default: "children" },
    onChange: {
      type: Function,
      default: () => {},
    },
    allowClear: {
      type: Boolean,
      default: true,
    },
    service: {
      type: [Function, Boolean],
      default: false,
    },
    refetch: Boolean,
    storeID: String,
    defaultValue: { type: [Object, Array], default: null },
    disableInitialFetch: { type: Boolean, default: false },
  },
  data() {
    return {
      searchValue: "",
      timeout: null,
    };
  },
  components: { Select, SelectOption: Select.Option, Spin },
  computed: {
    cValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("update:value", newValue);
      },
    },
    cPlaceholder() {
      return this.placeholder ?? this.label;
    },
    optionsWithDefaultValue() {
      let options = this.options;
      if (this.defaultValue) {
        options = options
          .concat(this.defaultValue)
          .reduce(
            (acc, item) =>
              acc.find((elem) => elem.id === item.id) ? acc : [...acc, item],
            [],
          );
      }
      return options;
    },
    cOptions() {
      return this.service ? this.serviceOptions : this.optionsWithDefaultValue;
    },
    cFetching() {
      return this.serviceFetching || this.fetching;
    },
    filterOption() {
      return this.service || this.search || this.mode === "multiple"
        ? false
        : true;
    },
    showSearch() {
      return !!this.service || !!this.search;
    },
    onSearch() {
      return (
        (this.service ? this.onServiceSearch : false) ||
        this.search ||
        undefined
      );
    },
    customStore() {
      return `select_${this.storeID || this.id}`;
    },
    serviceOptions() {
      return this.$store.state[this.customStore]?.serviceOptions;
    },
    serviceFetching() {
      return this.$store.state[this.customStore]?.serviceFetching;
    },
    selectsNumber() {
      return this.$store.state[this.customStore]?.selectsNumber;
    },
  },
  created() {
    if (!this.$store.hasModule(this.customStore)) {
      this.$store.registerModule(this.customStore, dynamicSelect);
      this.$store.dispatch(`${this.customStore}/setService`, this.service);
    } else {
      this.$store.dispatch(`${this.customStore}/increamentSelectsNumber`);
    }
    if (this.service) {
      if (
        !this.serviceFetching &&
        this.cOptions?.length === 0 &&
        !this.disableInitialFetch
      ) {
        this.initialFetch();
      } else if (this.defaultValue) {
        this.$store.dispatch(
          `${this.customStore}/addDefaultValue`,
          this.defaultValue,
        );
      }
    }
  },
  emits: ["update:value", "update:refetch"],
  methods: {
    onPopupScroll(event) {
      this.$store.dispatch(`${this.customStore}/onPopupScroll`, event);
    },
    onServiceSearch(value = "") {
      this.searchValue = value;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.$store.dispatch(`${this.customStore}/onServiceSearch`, value);
      }, 250);
    },
    initialFetch() {
      this.$store.dispatch(`${this.customStore}/initialFetch`).then(() => {
        if (this.defaultValue) {
          this.$store.dispatch(
            `${this.customStore}/addDefaultValue`,
            this.defaultValue,
          );
        }
      });
    },
  },
  beforeUnmount() {
    if (this.selectsNumber === 1) {
      this.$store.unregisterModule(this.customStore);
    } else {
      this.$store.dispatch(`${this.customStore}/decreamentSelectsNumber`);
    }
  },
  watch: {
    refetch(val) {
      if (val) {
        this.initialFetch();
        this.$emit("update:refetch", false);
      }
    },
    defaultValue(val) {
      if (val) {
        this.$store.dispatch(`${this.customStore}/addDefaultValue`, val);
      }
    },
    customStore(val) {
      if (!this.$store.hasModule(val)) {
        this.$store.registerModule(val, dynamicSelect);
        this.$store.dispatch(`${val}/setService`, this.service);
        this.initialFetch();
      }
    },
  },
};
</script>
