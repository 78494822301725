<template>
  <Section :title="$t('contractors.bankAccounts')">
    <BaseTable
      :columns="columns"
      :service="getBankAccountsList"
      :customParams="{ contractor: contractorID }"
      customStore="bankAccounts"
    >
      <template v-slot:button v-if="!preview">
        <EODButton
          v-if="hasPermissionToAdd"
          type="primary"
          :name="$t('contractors.addBankAccount')"
          @click="openBankAccountForm(null)"
          icon="PlusOutlined"
        />
      </template>
      <template #verified_at="{ record }">
        {{
          record.verified_at
            ? new Date(record.verified_at).toLocaleDateString()
            : "-"
        }}
      </template>
      <template #is_default="{ record }">
        {{ record.is_default ? $t("app.yes") : "" }}
      </template>
      <template v-slot:actions="{ record }">
        <TableActions
          :record="record"
          :deleteMessage="$t('contractors.doYouWantToDeleteBankAccount')"
          :modalTitle="$t('contractors.deleteBankAccount')"
          :deleteService="deleteBankAccount"
          @delete-success="onDeleteSuccess"
          :showPreview="preview"
          :showEdit="!preview"
          :showRemove="!preview"
          customStore="bankAccounts"
        >
          <template v-slot:preview>
            <button
              v-if="record?.actions?.viewable"
              class="action"
              :title="$t('app.preview')"
              @click="openBankAccountForm(record.id)"
            >
              <EyeOutlined />
            </button>
          </template>
          <template v-slot:edit>
            <button
              v-if="record?.actions?.editable"
              class="action"
              :title="$t('app.edit')"
              @click="openBankAccountForm(record.id)"
            >
              <EditOutlined />
            </button>
          </template>
        </TableActions>
      </template>
    </BaseTable>
  </Section>
</template>

<script>
import { BaseTable, TableActions } from "@/components/table";
import { EODButton, Section } from "@/components/ui";
import { EditOutlined, EyeOutlined } from "@ant-design/icons-vue";
import { deleteBankAccount, getBankAccountsList } from "@/services/contractors";

import PERMISSIONS from "@/consts/permissions";
import dynamicTable from "@/store/modules/dynamicTable";

export default {
  name: "BankAccountsList",
  props: {
    contractorID: Number,
    preview: { type: Boolean, default: false },
    showBankAccountForm: Boolean,
  },
  data() {
    return {
      columns: [
        {
          title: this.$t("contractors.identifier"),
          dataIndex: "identifier",
          sorter: true,
          ellipsis: true,
        },
        {
          title: this.$t("contractors.accountNumber"),
          dataIndex: "account_number",
          sorter: true,
          ellipsis: true,
        },
        {
          title: this.$t("contractors.verifiedAt"),
          dataIndex: "verified_at",
          sorter: true,
          ellipsis: true,
          slots: { customRender: "verified_at" },
        },
        {
          title: this.$t("contractors.bank"),
          dataIndex: "bank_id_display",
          sorter: true,
          ellipsis: true,
        },
        {
          title: this.$t("contractors.country"),
          dataIndex: "country_id_display",
          sorter: true,
          ellipsis: true,
        },
        {
          title: this.$t("contractors.default"),
          dataIndex: "is_default",
          slots: { customRender: "is_default" },
        },
        {
          title: this.$t("app.actions"),
          dataIndex: "actions",
          slots: { customRender: "actions" },
          fixed: "right",
          width: "100",
        },
      ],
    };
  },
  components: {
    BaseTable,
    EODButton,
    EditOutlined,
    EyeOutlined,
    Section,
    TableActions,
  },
  computed: {
    hasPermissionToAdd() {
      return this.$permissions.includes(
        PERMISSIONS.CONTRACTORS_BANK_ACCOUNTS_ADD,
      );
    },
  },
  created() {
    if (!this.$store.hasModule("bankAccounts")) {
      this.$store.registerModule("bankAccounts", dynamicTable);
    }
  },
  methods: {
    deleteBankAccount,
    getBankAccountsList,
    async openBankAccountForm(id) {
      await this.$emit("update:showBankAccountForm", true);
      this.$store.dispatch("setBankAccountID", id);
      await this.$router.replace({ hash: "#bank-account" });
    },
    onDeleteSuccess() {
      this.$store.dispatch("setBankAccountID", undefined);
      this.$store.dispatch("refreshSort");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/Contractors";
</style>
