<template>
  <div :class="['drawer', visible ? 'drawer--opened' : 'drawer--closed']">
    <button class="drawer-button" @click="handle">
      <RightOutlined v-if="visible" />
      <LeftOutlined v-else />
    </button>

    <slot></slot>
  </div>
</template>

<script>
import { LeftOutlined, RightOutlined } from "@ant-design/icons-vue";

export default {
  name: "EODDrawer",
  props: { visible: Boolean, handle: Function },
  components: { LeftOutlined, RightOutlined },
};
</script>

<style lang="scss" scoped>
@import "@/scss/Variables";

.drawer {
  position: sticky;
  top: calc(6rem + 45px);
  background-color: $white;
  height: calc(100vh - 6rem - 45px);
  z-index: 2;

  &--opened {
    transition: flex 0.5s cubic-bezier(0.7, 0.3, 0.1, 1);
    flex: 1;
  }

  &--closed {
    transition: flex 0.5s cubic-bezier(0.7, 0.3, 0.1, 1);
    flex: 0;
  }
}

.drawer-button {
  position: absolute;
  top: 50vh;
  left: -14px;
  background-color: $white;
  border: none;
  width: 14px;
  height: 66px;
  cursor: pointer;
  padding: 0 2px;
  transform: perspective(20px) rotateY(-45deg);

  &:hover,
  &:focus {
    border: 3px solid primaryOpacity(0.6);
    border-right: none;
    outline: none;
  }
}
</style>
