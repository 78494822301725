const notifications = {
  namespaced: true,
  state: {
    count: 0,
    shouldRefresh: false,
    importStatus: "",
  },
  mutations: {
    REFRESH(state, value) {
      state.shouldRefresh = value;
    },
    SET_COUNT(state, payload) {
      state.count = payload;
    },
    SET_IMPORT_STATUS(state, payload) {
      state.importStatus = payload;
    },
  },
  actions: {
    refreshTable({ commit }) {
      commit("REFRESH", true);
    },
    resetRefresh({ commit }) {
      commit("REFRESH", false);
    },
    setCount({ commit }, payload) {
      commit("SET_COUNT", payload);
    },
    setImportStatus({ commit }, payload) {
      commit("SET_IMPORT_STATUS", payload);
    },
  },
  getters: {
    getCount(state) {
      return state.count;
    },
  },
};

export default notifications;
