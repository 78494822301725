
import { Breadcrumbs, EODButton, EODSeparator, Section } from "@/components/ui";
import { EODInput, EODSelect } from "@/components/inputs";
import { Params, SelectOption } from "@/interfaces/common";
import { computed, defineComponent, inject, ref } from "vue";
import {
  createContact,
  deleteContact,
  editContact,
  getContact,
} from "@/services/contacts";

import { Contact } from "@/interfaces/contacts";
import { MessageApi } from "ant-design-vue/lib/message";
import { getAccountCountryOption } from "@/services/contractors";
import getDefaultValue from "@/helpers/getDefaultValue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "@/store";

export default defineComponent({
  name: "ContactsForm",
  props: { id: { type: Number, default: null } },
  setup(props) {
    const $message = inject<MessageApi>("$message");
    const router = useRouter();
    const store = useStore();
    const { t } = useI18n();

    const data = ref<Contact>({
      name: "",
      last_name: "",
      emails: [],
      phone_numbers: [],
      street: "",
      street_number: "",
      apartment_number: "",
      postal_code: "",
      city: "",
      country: null,
    });
    const errors = ref<any>({});
    const loading = ref<boolean>(false);
    const defaultCountry = ref<SelectOption>(null);
    const phoneNumbers = ref<{ value: string }[]>([{ value: "" }]);
    const emails = ref<{ value: string }[]>([{ value: "" }]);

    const title = computed<string>(() =>
      t(`app.${props.id ? "edit" : "add"}_`, [
        t("contacts.contact", props.id ? 2 : 1),
      ]),
    );

    const submit = () => {
      const service = props.id ? editContact : createContact;
      const formData = {
        ...data.value,
        phone_numbers: phoneNumbers.value.reduce(
          (acc, item) => (item.value ? [...acc, item.value] : acc),
          [],
        ),
        emails: emails.value.reduce(
          (acc, item) => (item.value ? [...acc, item.value] : acc),
          [],
        ),
      };

      service(formData, props.id)
        .then(() => {
          $message.success(t("app.success"));
          router.push({ name: "Contacts" });
        })
        .catch((err) => {
          errors.value = err.response.data;
        });
    };

    const fetchCountries = (params: Params) => {
      return getAccountCountryOption(params).then(({ data }) => data);
    };

    const handleDelete = () => {
      deleteContact(props.id)
        .then(() => {
          $message.success(t("app.success"));
          store.dispatch("closeModal");
          store.dispatch("refreshTable");
          router.replace({ name: "Contacts" });
        })
        .catch((err) => {
          errors.value = err.response.data;
        });
    };

    const confirmDelete = () => {
      store.dispatch("setModal", {
        message: t("app.doYouWantToDelete_", [t("contacts.contact")]),
        title: t("app.deletion_"),
        modalOk: handleDelete,
      });
    };

    const addItem = (array: { value: string }[]) => {
      array.push({ value: "" });
    };

    const deleteItem = (array: { value: string }[], idx: number) => {
      array.splice(idx, 1);
    };

    return {
      addItem,
      confirmDelete,
      data,
      defaultCountry,
      deleteItem,
      emails,
      errors,
      fetchCountries,
      loading,
      phoneNumbers,
      submit,
      title,
    };
  },
  components: {
    Breadcrumbs,
    EODButton,
    EODInput,
    EODSelect,
    EODSeparator,
    Section,
  },
  beforeRouteEnter(to, _, next) {
    if (to.name === "ContactsCreate") {
      next();
    } else {
      getContact(+to.params.id)
        .then(({ data }) => {
          // TODO: proper typing
          next((vm: any) => {
            vm.data = data;
            vm.phoneNumbers =
              data.phone_numbers.length > 0
                ? data.phone_numbers.map((value) => ({ value }))
                : [{ value: "" }];
            vm.emails =
              data.emails.length > 0
                ? data.emails.map((value) => ({ value }))
                : [{ value: "" }];
            vm.defaultCountry = getDefaultValue(vm.data, "country");
          });
        })
        .catch((err) => {
          if (err.response.status === 404) {
            next({ name: "404" });
          }
        });
    }
  },
});
