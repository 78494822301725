<template>
  <Breadcrumbs
    :path="[{ path: { name: 'JRWA' }, title: $t('modules.jrwa') }]"
    :current="$t('jrwa.meta.preview')"
    iconComponent="SortAscendingOutlined"
  >
    <Button
      @click="generatePDF"
      :title="$t('jrwa.generatePDF')"
      :loading="loading$"
    >
      {{ $t("jrwa.generatePDF") }}
    </Button>
    <EODButtonLink
      :to="{ name: 'JRWAEdit', params: { id: id } }"
      :title="$t('jrwa.meta.edit')"
      icon="EditOutlined"
      v-if="actions?.editable"
    />
  </Breadcrumbs>
  <Preview
    :oid="id"
    :data="previewData"
    :title="$t('jrwa.meta.preview')"
    :noPreviewMsg="$t('jrwa.noPreview')"
  />
  <section class="section" v-if="id && hasJRWAEntryPermission">
    <JRWAEntries :jrwa_register="id" :showEdit="false" :showRemove="false" />
  </section>
</template>

<script>
import { Breadcrumbs, EODButtonLink } from "@/components/ui";
import { generateJRWAPDF, getJRWA } from "@/services/jrwa";

import { Button } from "ant-design-vue";
import JRWAEntries from "@/views/JRWA/JRWAEntries";
import PERMISSIONS from "@/consts/permissions";
import { Preview } from "@/components/common";

export default {
  name: "JRWAPreview",
  data() {
    return {
      id: +this.$route.params?.id,
      previewData: [],
      actions: null,
    };
  },
  components: {
    Button,
    Breadcrumbs,
    EODButtonLink,
    JRWAEntries,
    Preview,
  },
  computed: {
    hasJRWAEntryPermission() {
      return this.$permissions.includes(PERMISSIONS.JRWA_ENTRY);
    },
  },
  created() {
    if (this.id) {
      this.fetchJRWA();
    }
  },
  methods: {
    generatePDF() {
      this.loading$ = true;
      generateJRWAPDF(this.id)
        .then(({ data }) => {
          window.open(URL.createObjectURL(data));
        })
        .finally(() => {
          this.loading$ = false;
        });
    },
    fetchJRWA() {
      getJRWA(this.id).then(({ data }) => {
        this.actions = data.actions;
        this.previewData = [
          { title: this.$t("app.name"), value: data.name },
          { title: this.$t("app.status"), value: data.status_display },
          { title: this.$t("app.description"), value: data.description },
        ];
      });
    },
  },
};
</script>
