<template>
  <MenuItem
    v-if="$permissions.includes(PERMISSIONS.REGISTRY_MANAGE)"
    key="Registry"
  >
    <router-link
      class="sidebar__link"
      :to="{ name: 'Registry' }"
      :title="$t('modules.registry')"
    >
      <CarryOutOutlined /><span>{{ $t("modules.registry") }}</span>
    </router-link>
  </MenuItem>
  <SubMenu
    v-else-if="$permissions.includes(PERMISSIONS.REGISTRY_VIEW)"
    class="sidebar__submenu"
    :onTitleClick="fetchRegistryList"
  >
    <template v-slot:title>
      <CarryOutOutlined /><span>{{ $t("modules.registry") }}</span>
    </template>
    <MenuItem key="RegistryAll">
      <router-link
        class="sidebar__link"
        :to="{ name: 'Registry' }"
        :title="$t('registry.all')"
      >
        {{ $t("registry.all") }}
      </router-link>
    </MenuItem>
    <template v-if="count <= 5">
      <MenuItem v-for="item in list" :key="item.id">
        <router-link
          class="sidebar__link"
          :to="{ name: 'RegistryPreview', params: { id: item.id } }"
          :title="item.name"
        >
          {{ item.name }}
        </router-link>
      </MenuItem>
    </template>
  </SubMenu>
</template>

<script>
import { CarryOutOutlined } from "@ant-design/icons-vue";
import { Menu } from "ant-design-vue";
import PERMISSIONS from "@/consts/permissions";

import { getRegistryList } from "@/services/registers";

export default {
  name: "RegistryMenu",
  data() {
    return {
      PERMISSIONS,
      count: 0,
      list: [],
    };
  },
  components: {
    CarryOutOutlined,
    MenuItem: Menu.Item,
    SubMenu: Menu.SubMenu,
  },
  created() {
    if (this.$permissions.includes(PERMISSIONS.REGISTRY_VIEW)) {
      this.fetchRegistryList();
    }
  },
  methods: {
    fetchRegistryList() {
      getRegistryList().then(({ data }) => {
        this.count = data.count;
        this.list = data.results;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.sidebar {
  &__submenu {
    text-align: left;
  }

  &__link {
    text-align: left;
  }
}
</style>
