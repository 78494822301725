<template>
  <AdvancedSearchModal
    v-model:visible="isAdvancedSearchModalVisible"
    v-model:searchId="searchId"
    :advancedQuery="advancedQuery"
  />

  <Breadcrumbs :current="$t('search.searching')" />

  <form id="search-form" @submit.prevent="search" class="search-container">
    <div class="search-container__section">
      <Input
        id="search"
        v-model:value="searchParams.query"
        :placeholder="$t('search.inputInfo')"
        required
        :maxlength="250"
        class="search-input"
      />
      <EODButton
        icon="SearchOutlined"
        :name="$t('search.search')"
        htmlType="submit"
        type="primary"
        class="search-button"
      />
    </div>
    <div class="search-container__section">
      <Checkbox v-model:checked="searchParams.ocr">
        {{ $t("search.searchOCR") }}
      </Checkbox>
      <EODButton
        @click="openAdvancedSearchModal"
        class="search-text-button"
        type="button"
        :name="$t('search.advancedSearch')"
      >
        {{ $t("search.advancedSearch") }}
      </EODButton>
    </div>
  </form>

  <Tabs :tabBarStyle="{ top: '12rem' }" @change="$scrollToTop">
    <TabPane key="1" :tab="$t('app.all')">
      <SearchResults :searchId="searchId" filter="all" />
    </TabPane>
    <TabPane key="2">
      <template v-slot:tab>
        <SnippetsOutlined />
        {{ $t("modules.documents") }}
      </template>
      <SearchResults :searchId="searchId" filter="documents" />
    </TabPane>
    <TabPane key="3">
      <template v-slot:tab>
        <PaperClipOutlined />
        {{ $t("app.attachments") }}
      </template>
      <SearchResults :searchId="searchId" filter="attachments" />
    </TabPane>
    <TabPane key="4">
      <template v-slot:tab>
        <FolderOutlined />
        {{ $t("modules.issues") }}
      </template>
      <SearchResults :searchId="searchId" filter="issues" />
    </TabPane>
    <TabPane key="5">
      <template v-slot:tab>
        <PartitionOutlined />
        {{ $t("modules.processes") }}
      </template>
      <SearchResults :searchId="searchId" filter="processes" />
    </TabPane>
  </Tabs>
</template>

<script>
import { Breadcrumbs, EODButton } from "@/components/ui";
import { Checkbox, Input, Tabs } from "ant-design-vue";
import {
  FolderOutlined,
  PaperClipOutlined,
  PartitionOutlined,
  SnippetsOutlined,
} from "@ant-design/icons-vue";

import AdvancedSearchModal from "./AdvancedSearchModal";
import SearchResults from "@/views/Search/SearchResults";
import { searchSimple } from "@/services/search";

export default {
  name: "Search",
  data() {
    return {
      searchParams: {
        query: null,
        ocr: false,
      },
      searchId: +this.$route.params?.searchId,
      errors: {},
      isAdvancedSearchModalVisible: false,
      advancedQuery: false,
    };
  },
  components: {
    AdvancedSearchModal,
    Breadcrumbs,
    Checkbox,
    EODButton,
    FolderOutlined,
    Input,
    PaperClipOutlined,
    PartitionOutlined,
    SearchResults,
    SnippetsOutlined,
    Tabs,
    TabPane: Tabs.TabPane,
  },
  methods: {
    openAdvancedSearchModal() {
      this.isAdvancedSearchModalVisible = true;
    },
    search() {
      searchSimple(this.searchParams).then(({ data }) => {
        this.$router.replace({
          name: "SearchResult",
          params: { searchId: data },
        });
      });
    },
    getQueryFromStore(query) {
      if (this.searchId) {
        if (query.query) {
          this.searchParams = query || {
            query: null,
            ocr: false,
          };
        } else if (query.blocks) {
          this.advancedQuery = query;
        }
      }
    },
  },
  mounted() {
    this.watchDataStore = this.$store.watch(
      () => {
        return this.$store.getters["searchall/getData"];
      },
      (val) => {
        this.getQueryFromStore(val.query);
      },
      {
        deep: true,
      },
    );
  },
  beforeUnmount() {
    this.watchDataStore();
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/Variables";
@import "@/scss/Search";
</style>
