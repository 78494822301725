import SYSTEM_DICT from "@/config/systemDict";
import apiClient from "@/config/apiClient";

const getContractorsList = (params) => {
  return apiClient.get("/api/contractors/", { params });
};

const getContractorsSelect = (params) => {
  return apiClient.get("/api/contractors/select/", { params });
};

const createContractor = (data) => {
  return apiClient.post("/api/contractors/", data);
};

const editContractor = (data, id) => {
  return apiClient.put(`/api/contractors/${id}/`, data, {
    globalErrorHandler: false,
  });
};

const getContractor = (id) => {
  return apiClient.get(`/api/contractors/${id}/`);
};

const deleteContractor = (id) => {
  return apiClient.delete(`/api/contractors/${id}/`);
};

const createAddress = (data) => {
  return apiClient.post("/api/contractors/address/", data, {
    globalErrorHandler: false,
  });
};

const editAddress = (data, id) => {
  return apiClient.put(`/api/contractors/address/${id}/`, data, {
    globalErrorHandler: false,
  });
};

const createSupply = (data) => {
  return apiClient.post("/api/contractors/supply/", data, {
    globalErrorHandler: false,
  });
};

const editSupply = (data, id) => {
  return apiClient.put(`/api/contractors/supply/${id}/`, data, {
    globalErrorHandler: false,
  });
};

const getContactsList = (params) => {
  return apiClient.get(`/api/contractors/contact/`, { params });
};

const getContact = (id) => {
  return apiClient.get(`/api/contractors/contact/${id}/`);
};

const createContact = (data) => {
  return apiClient.post("/api/contractors/contact/", data);
};

const editContact = (data, id) => {
  return apiClient.put(`/api/contractors/contact/${id}/`, data);
};

const deleteContact = (id) => {
  return apiClient.delete(`/api/contractors/contact/${id}/`);
};

const createFeature = (data) => {
  return apiClient.post("/api/contractors/features/", data, {
    globalErrorHandler: false,
  });
};

const editFeature = (data, id) => {
  return apiClient.put(`/api/contractors/features/${id}/`, data, {
    globalErrorHandler: false,
  });
};

const getBankAccountsList = (params) => {
  return apiClient.get(`/api/contractors/bank/`, { params });
};

const getBankAccount = (id) => {
  return apiClient.get(`/api/contractors/bank/${id}/`);
};

const createBankAccount = (data) => {
  return apiClient.post("/api/contractors/bank/", data);
};

const editBankAccount = (data, id) => {
  return apiClient.put(`/api/contractors/bank/${id}/`, data);
};

const deleteBankAccount = (id) => {
  return apiClient.delete(`/api/contractors/bank/${id}/`);
};

const deleteAttribute = (id) => {
  return apiClient.delete(`/api/contractors/features/attribute/${id}/`);
};

const getDataFromGUS = (nip) => {
  return apiClient.get(`/api/contractors/gus/${nip}/`);
};

const sortAccounts = (data) => {
  return apiClient.post("/api/contractors/bank/order/", data);
};

const getVATAccounts = (id) => {
  return apiClient.get(`/api/contractors/vat/${id}/`);
};

const addVATAccounts = (data, id) => {
  return apiClient.post(`/api/contractors/vat/${id}/`, data);
};

//todo if more usage of system dict extract to some file
const getContractorClassOption = (params) => {
  return apiClient.get(
    `/api/dicts/entry/keyword/${SYSTEM_DICT.CONTRACTOR_CLASS}/`,
    { params },
  );
};

const getAccountBankOption = (params) => {
  return apiClient.get(`/api/dicts/entry/keyword/${SYSTEM_DICT.BANK}/`, {
    params,
  });
};

const getAccountCountryOption = (params) => {
  return apiClient.get(`/api/dicts/entry/keyword/${SYSTEM_DICT.COUNTRY}/`, {
    params,
  });
};

const getAddressStateOption = (params) => {
  return apiClient.get(`/api/dicts/entry/keyword/${SYSTEM_DICT.STATE}/`, {
    params,
  });
};

const getPaymentConditionsOption = (params) => {
  return apiClient.get(
    `/api/dicts/entry/keyword/${SYSTEM_DICT.PAYMENT_CONDITIONS}/`,
    {
      params,
    },
  );
};

const getTradeCreditTypeOption = (params) => {
  return apiClient.get(`/api/dicts/entry/keyword/${SYSTEM_DICT.CREDIT_TYPE}/`, {
    params,
  });
};

const getCurrencyOption = (params) => {
  return apiClient.get(`/api/dicts/entry/keyword/${SYSTEM_DICT.CURRENCY}/`, {
    params,
  });
};

const downloadContractorFileURL = (id) => {
  const accessToken = localStorage.getItem("access_token");
  return `/api/contractors/downloads/${id}/?token=${accessToken}`;
};

export {
  addVATAccounts,
  createAddress,
  createBankAccount,
  createContact,
  createContractor,
  createFeature,
  createSupply,
  deleteAttribute,
  deleteBankAccount,
  deleteContact,
  deleteContractor,
  downloadContractorFileURL,
  editAddress,
  editBankAccount,
  editContact,
  editContractor,
  editFeature,
  editSupply,
  getBankAccount,
  getBankAccountsList,
  getContact,
  getContactsList,
  getContractor,
  getContractorsList,
  getContractorsSelect,
  getDataFromGUS,
  getVATAccounts,
  sortAccounts,
  getContractorClassOption,
  getAccountCountryOption,
  getAccountBankOption,
  getAddressStateOption,
  getPaymentConditionsOption,
  getTradeCreditTypeOption,
  getCurrencyOption,
};
