<template>
  <Modal
    v-model:visible="cVisible"
    :title="$t('issues.doYouWantToClose')"
    :okText="$t('issues.close')"
    :cancelText="$t('app.cancel')"
    :closable="false"
    @ok="submit"
    @cancel="onCancel"
  >
    <div class="form-container">
      <form id="issue-close-form" class="form">
        <EODDatePicker
          id="endDate"
          v-model:value="date"
          :label="$t('issues.endDate')"
          dateOnly
          disabled
        />

        <EODInput
          id="realization"
          type="textarea"
          v-model:value="realization"
          :label="$t('issues.realization')"
          :props="{ maxlength: 500 }"
          :errors="errors.realization"
          required
        />
      </form>
    </div>
  </Modal>
</template>

<script>
import { EODDatePicker, EODInput } from "@/components/inputs";

import { Modal } from "ant-design-vue";
import { closeIssue } from "@/services/issues";

export default {
  name: "IssueClose",
  props: {
    issueID: {
      type: Number,
      default: null,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    handleRefresh: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      realization: null,
      date: new Date().toISOString().substring(0, 10),
      errors: {},
    };
  },
  components: {
    EODDatePicker,
    EODInput,
    Modal,
  },
  computed: {
    cVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
  },
  emits: ["update:visible"],
  methods: {
    submit() {
      closeIssue({ realization: this.realization }, this.issueID)
        .then(() => {
          this.resetData();
          this.$message.success(this.$t("app.success"));

          this.cVisible = false;
          this.handleRefresh();

          if (this.$store.hasModule("issueHistory")) {
            this.$store.dispatch("issueHistory/refreshTable");
          }
        })
        .catch((err) => {
          this.errors = err.response.data;
        });
    },
    onCancel() {
      this.resetData();
      this.cVisible = false;
    },
    resetData() {
      this.realization = null;
      this.errors = {};
    },
  },
};
</script>
