const dynamicSelect = {
  namespaced: true,
  state: () => {
    return {
      serviceOptions: [],
      pages: 1,
      nextPage: 2,
      searchValue: "",
      serviceFetching: false,
      service: () => {},
      defaultValues: [],
      selectsNumber: 1,
    };
  },
  mutations: {
    SET_DATA(state, values) {
      state.pages = values.pages;
      state.nextPage = values.nextPage;
      state.serviceOptions = values.serviceOptions;
    },
    SET_SEARCH_VALUE(state, value) {
      state.searchValue = value;
    },
    SET_SERVICE_FETCHING(state, value) {
      state.serviceFetching = value;
    },
    SET_SERVICE(state, value) {
      state.service = value;
    },
    SET_DEFAULT_VALUES(state, value) {
      state.defaultValues = value;
    },
    SET_SELECTS_NUMBER(state, value) {
      state.selectsNumber = value;
    },
  },
  actions: {
    onPopupScroll({ commit, state }, event) {
      const target = event.target;

      if (
        state.service &&
        state.nextPage <= state.pages &&
        !state.serviceFetching &&
        target.scrollTop + target.offsetHeight >= 0.8 * target.scrollHeight
      ) {
        commit("SET_SERVICE_FETCHING", true);
        state
          .service({
            page: state.nextPage,
            search_field: state.searchValue || undefined,
          })
          .then((data) => {
            let options = data.results;

            for (const value of state.defaultValues) {
              options = options.filter((item) => item.id !== value.id);
            }

            commit("SET_DATA", {
              pages: data.pages_number,
              nextPage: state.nextPage + 1,
              serviceOptions: state.serviceOptions.concat(options),
            });
          })
          .finally(() => {
            commit("SET_SERVICE_FETCHING", false);
          });
      }
    },
    onServiceSearch({ commit, state }, value) {
      commit("SET_SEARCH_VALUE", value);

      commit("SET_SERVICE_FETCHING", true);
      state
        .service({ search_field: value })
        .then((data) => {
          commit("SET_DATA", {
            pages: data.pages_number,
            nextPage: 2,
            serviceOptions: data.results,
          });
        })
        .finally(() => {
          commit("SET_SERVICE_FETCHING", false);
        });
    },
    async initialFetch({ commit, state }) {
      if (state.service) {
        commit("SET_SERVICE_FETCHING", true);
        await state
          .service()
          .then((data) => {
            commit("SET_DATA", {
              pages: data.pages_number,
              nextPage: 2,
              serviceOptions: data.results,
            });
          })
          .finally(() => {
            commit("SET_SERVICE_FETCHING", false);
          });
      }
    },
    addDefaultValue({ commit, state }, defaultValue) {
      const options = [];

      commit(
        "SET_DEFAULT_VALUES",
        state.defaultValues
          .concat(defaultValue)
          .reduce(
            (acc, item) =>
              acc.find((elem) => elem.id === item.id) ? acc : [...acc, item],
            [],
          ),
      );

      for (const value of state.defaultValues) {
        if (!state.serviceOptions.find((item) => item.id === value.id)) {
          options.push(value);
        }
      }

      if (options.length > 0) {
        commit("SET_DATA", {
          pages: state.pages,
          nextPage: state.nextPage,
          serviceOptions: options.concat(state.serviceOptions),
        });
      }
    },
    setService({ commit }, payload) {
      commit("SET_SERVICE", payload);
    },
    increamentSelectsNumber({ commit, state }) {
      commit("SET_SELECTS_NUMBER", state.selectsNumber + 1);
    },
    decreamentSelectsNumber({ commit, state }) {
      commit("SET_SELECTS_NUMBER", state.selectsNumber - 1);
    },
  },
};

export default dynamicSelect;
