function generateCondition(component, value) {
  const condition = value.ui.condition;

  switch (condition.operation) {
    case "eq": {
      switch (component) {
        case "date":
        case "dueDate":
        case "currentDate": {
          return { dateEq: condition.value };
        }
        default: {
          return { const: condition.value };
        }
      }
    }
    case "neq": {
      switch (component) {
        case "date":
        case "dueDate":
        case "currentDate": {
          return { dateNeq: condition.value };
        }
        default: {
          return { not: { const: condition.value } };
        }
      }
    }
    case "le": {
      switch (component) {
        case "calc":
        case "number": {
          return { exclusiveMaximum: condition.value };
        }
        case "numeration": {
          return { stringLe: condition.value };
        }
        case "daterange": {
          return { rangeLe: condition.value };
        }
        default: {
          return { dateLe: condition.value };
        }
      }
    }
    case "leq": {
      switch (component) {
        case "calc":
        case "number": {
          return { maximum: condition.value };
        }
        case "numeration": {
          return { stringLeq: condition.value };
        }
        default: {
          return { dateLeq: condition.value };
        }
      }
    }
    case "ge": {
      switch (component) {
        case "calc":
        case "number": {
          return { exclusiveMinimum: condition.value };
        }
        case "numeration": {
          return { stringGe: condition.value };
        }
        case "daterange": {
          return { rangeGe: condition.value };
        }
        default: {
          return { dateGe: condition.value };
        }
      }
    }
    case "geq": {
      switch (component) {
        case "calc":
        case "number": {
          return { minimum: condition.value };
        }
        case "numeration": {
          return { stringGeq: condition.value };
        }
        default: {
          return { dateGeq: condition.value };
        }
      }
    }
    case "inc": {
      switch (component) {
        case "input":
        case "textarea":
        case "numeration": {
          return { stringInc: condition.value };
        }
        case "daterange": {
          return { rangeInc: condition.value };
        }
        default: {
          return { contains: { const: condition.value } };
        }
      }
    }
    case "dec": {
      switch (component) {
        case "input":
        case "textarea":
        case "numeration": {
          return { stringDec: condition.value };
        }
        case "daterange": {
          return { rangeDec: condition.value };
        }
        default: {
          return { not: { contains: { const: condition.value } } };
        }
      }
    }
    case "some": {
      return { enum: condition.value };
    }
    default: {
      return {};
    }
  }
}

export default generateCondition;
