import APPS from "@/consts/apps";
import MailboxForm from "@/views/Mailboxes/MailboxForm.vue";
import MailboxPreview from "@/views/Mailboxes/MailboxPreview.vue";
import Mailboxes from "@/views/Mailboxes/Mailboxes.vue";
import MessagePreview from "@/views/Mailboxes/MessagePreview.vue";
import PERMISSIONS from "@/consts/permissions";
import { RouteLocation } from "vue-router";
import i18n from "@/i18n";

const t = i18n.global.t;

const mailboxes = [
  {
    path: "/mailboxes/",
    name: "Mailboxes",
    component: Mailboxes,
    meta: {
      title: `${t("meta.app")} - ${t("mailboxes.meta.list")}`,
      requiresAuth: true,
      permission: PERMISSIONS.MAILBOX,
      app: APPS.MAILBOXES,
    },
  },
  {
    path: "/mailboxes/create/",
    name: "MailboxCreate",
    component: MailboxForm,
    meta: {
      title: `${t("meta.app")} - ${t("mailboxes.meta.add")}`,
      requiresAuth: true,
      permission: PERMISSIONS.MAILBOX_ADD,
      app: APPS.MAILBOXES,
    },
  },
  {
    path: "/mailboxes/:id/edit/",
    name: "MailboxEdit",
    component: MailboxForm,
    meta: {
      title: `${t("meta.app")} - ${t("mailboxes.meta.edit")}`,
      requiresAuth: true,
      permission: PERMISSIONS.MAILBOX_EDIT,
      app: APPS.MAILBOXES,
    },
    props: true,
  },
  {
    path: "/mailboxes/:id/preview/",
    name: "MailboxPreview",
    component: MailboxPreview,
    meta: {
      title: `${t("meta.app")} - ${t("mailboxes.meta.preview")}`,
      requiresAuth: true,
      permission: PERMISSIONS.MAILBOX,
      app: APPS.MAILBOXES,
    },
  },
  {
    path: "/mailboxes/:name/message/:id/preview/",
    name: "MessagePreview",
    component: MessagePreview,
    meta: {
      title: `${t("meta.app")} - ${t("mailboxes.meta.messagePreview")}`,
      requiresAuth: true,
      permission: PERMISSIONS.MAILBOX,
      app: APPS.MAILBOXES,
    },
    props: (route: RouteLocation) => ({
      mailboxId: route.params.name,
      id: +route.params.id,
    }),
  },
  {
    path: "/mailboxes/:name/message/:id/deleted/",
    name: "DeletedMessagePreview",
    component: MessagePreview,
    meta: {
      title: `${t("meta.app")} - ${t("mailboxes.meta.messagePreview")}`,
      requiresAuth: true,
      permission: PERMISSIONS.MAILBOX,
      app: APPS.MAILBOXES,
    },
    props: (route: RouteLocation) => ({
      mailboxId: route.params.name,
      id: +route.params.id,
      deleted: true,
    }),
  },
];

export default mailboxes;
