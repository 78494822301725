<template>
  <Breadcrumbs
    :path="[{ path: { name: 'Teams' }, title: $t('modules.teams') }]"
    :current="id ? $t('permissions.teams.edit') : $t('permissions.teams.add')"
    iconComponent="UserOutlined"
    :what="$tc('permissions.teams.team', 2)"
  >
    <EODButton
      v-if="id && hasPermissionsToDelete"
      class="ml-1"
      type="danger"
      ghost
      @click="confirmDelete"
      icon="DeleteOutlined"
      :name="`${$t('app.delete')} ${$tc('permissions.teams.team', 1)}`"
    />
  </Breadcrumbs>

  <section class="section">
    <div class="form-container">
      <form class="form">
        <EODInput
          id="team-name"
          v-model:value="data.name"
          :label="$t('permissions.teams.teamName')"
          required
          :maxlength="200"
          :errors="errors.name"
        />
      </form>
    </div>
  </section>

  <section class="section">
    <BaseTable
      :columns="columns"
      :service="getUsersList"
      :rowSelection="rowSelection"
    >
      <template v-slot:groups="{ record }">
        {{ record.groups.map((e) => e.name).join(", ") }}
      </template>
      <template #last_name="{ record }">
        {{ record.full_name }}
      </template>
    </BaseTable>
  </section>

  <div class="buttons">
    <Button
      type="primary"
      :title="$t('permissions.teams.save')"
      :loading="loading$"
      @click="submit"
    >
      {{ $t("permissions.teams.save") }}
    </Button>
  </div>
</template>

<script>
import { Breadcrumbs, EODButton } from "@/components/ui";
import {
  createGroup,
  deleteGroup,
  editGroup,
  getGroup,
  getUsersList,
} from "@/services/permissions";

import { BaseTable } from "@/components/table";
import { Button } from "ant-design-vue";
import { EODInput } from "@/components/inputs";
import PERMISSIONS from "@/consts/permissions";

export default {
  name: "TeamsForm",
  data() {
    return {
      id: +this.$route.params?.id,
      rowSelection: {
        selectedRowKeys: [],
        onChange: this.onChange,
      },
      data: {
        name: "",
        user_set: null,
      },
      columns: [
        {
          title: this.$t("permissions.users.fullName"),
          dataIndex: "last_name",
          slots: { customRender: "last_name" },
          sorter: true,
        },
        { title: "Email", dataIndex: "email", sorter: true },
        {
          title: this.$t("modules.teams"),
          dataIndex: "groups",
          slots: { customRender: "groups" },
          width: "20%",
          sorter: true,
        },
      ],
      errors: {},
    };
  },
  components: {
    Breadcrumbs,
    Button,
    EODButton,
    EODInput,
    BaseTable,
  },
  computed: {
    hasPermissionsToDelete() {
      return this.$permissions.includes(PERMISSIONS.GROUPS_DELETE);
    },
  },
  created() {
    if (this.id) {
      getGroup(this.id).then(({ data }) => {
        this.rowSelection.selectedRowKeys = data.user_set;
        this.data.name = data.name;
      });
    }
  },
  methods: {
    onChange(selectedRowKeys) {
      this.rowSelection.selectedRowKeys = selectedRowKeys;
    },
    getUsersList,
    submit() {
      this.data.user_set = this.rowSelection.selectedRowKeys;
      const service = this.id ? editGroup : createGroup;

      service(this.data, this.id)
        .then(() => {
          this.$router.push({ name: "Teams" });
          this.$message.success(this.$t("app.success"));
        })
        .catch((err) => {
          this.errors = err.response.data;
        });
    },
    confirmDelete() {
      this.$store.dispatch("setModal", {
        title: this.$t("app.delete_", [this.$tc("permissions.teams.team", 1)]),
        message: this.$t("app.doYouWantToDelete_", [
          `${this.$tc("permissions.teams.team", 1)} ${this.data.name}`,
        ]),
        modalOk: this.deleteThis,
      });
    },
    deleteThis() {
      deleteGroup(this.id).then(() => {
        this.$message.success(this.$t("app.success"));
        this.$store.dispatch("closeModal");
        this.$router.replace({ name: "Teams" });
      });
    },
  },
};
</script>
