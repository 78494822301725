<template>
  <Button
    @click="onClick"
    :title="buttonTitle"
    :type="type"
    :htmlType="htmlType"
  >
    <slot>
      <component v-if="icon" :is="icon" />
      {{ name }}
      <component v-if="rightIcon" :is="rightIcon" />
    </slot>
  </Button>
</template>

<script>
import {
  CloseOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  EyeOutlined,
  FileTextOutlined,
  FolderOpenOutlined,
  FolderOutlined,
  PlayCircleOutlined,
  PlusOutlined,
  ReloadOutlined,
  SearchOutlined,
} from "@ant-design/icons-vue";
import { Button } from "ant-design-vue";

export default {
  name: "EODButton",
  props: {
    icon: String,
    rightIcon: String,
    title: String,
    onClick: Function,
    type: { type: String, default: "default" },
    htmlType: { type: String, default: "button" },
    name: String,
  },
  components: {
    Button,
    CloseOutlined,
    DeleteOutlined,
    DownOutlined,
    EditOutlined,
    EyeOutlined,
    FileTextOutlined,
    PlusOutlined,
    ReloadOutlined,
    SearchOutlined,
    FolderOutlined,
    FolderOpenOutlined,
    PlayCircleOutlined,
  },
  computed: {
    buttonTitle() {
      return this.title ? this.title : this.name;
    },
  },
};
</script>
