<template>
  <ConfirmModal
    v-model:visible="cVisible"
    :title="$t('documents.addToCase')"
    :okText="$t('documents.addToCase')"
    :onSubmit="handleAddToIssue"
    :onCancel="closeAddToIssueModal"
    icon="FolderOutlined"
    :isLoading="isLoading"
  >
    <p>{{ modalText }}</p>
    <EODSelect
      id="issues"
      v-model:value="selectedIssue"
      :service="fetchIssues"
      :errors="noSelectedIssueError"
      :onChange="onIssueSelect"
    />
    <EODInput
      id="comment"
      type="textarea"
      v-model:value="addToIssueComment"
      :label="$t('app.comment')"
      :props="{ maxlength: 500 }"
    />
  </ConfirmModal>
</template>

<script>
import { EODInput, EODSelect } from "@/components/inputs";
import { addDocument, getIssuesList } from "@/services/issues";
import { ConfirmModal } from "@/components/modals";
import { ISSUE_STATUS } from "@/consts/statuses";

export default {
  name: "AddToIssueModal",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    documents: Array,
    cleanDocumentsList: {
      type: Boolean,
      default: false,
    },
    customStore: {
      type: [Boolean, String],
      default: false,
    },
    refreshDocument: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      selectedIssue: null,
      addToIssueComment: "",
      noSelectedIssueError: [],
      isLoading: false,
    };
  },
  components: {
    ConfirmModal,
    EODInput,
    EODSelect,
  },
  computed: {
    cVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
    modalText() {
      if (this.documents.length === 1) {
        return this.$tc("documents.pickIssueToJoin", [this.documents[0].name]);
      } else {
        return this.$t("documents.pickIssueToJoinSelected");
      }
    },
  },
  methods: {
    fetchIssues(params) {
      return getIssuesList({
        ...params,
        status: ISSUE_STATUS.IN_PROGRESS,
      }).then(({ data }) => {
        const results = data.results.map((item) => ({
          id: item.id,
          name: item.signature,
        }));
        return { ...data, results };
      });
    },
    handleAddToIssue() {
      if (!this.selectedIssue) {
        this.noSelectedIssueError.push(
          this.$t("documents.pickIssueToJoinSelected"),
        );
        return;
      }
      this.isLoading = true;
      addDocument(
        {
          document: this.documents.map((item) => item.id),
          comment: this.addToIssueComment,
        },
        this.selectedIssue,
      )
        .then(() => {
          this.$message.success(this.$t("app.success"));
          this.closeAddToIssueModal();
          if (this.customStore) {
            this.$store.dispatch(`${this.customStore}/refreshTable`);
          }
          this.refreshDocument();
        })
        .catch((err) => {
          this.errors = err.response.data;
        })
        .finally(() => (this.isLoading = false));
    },
    closeAddToIssueModal() {
      this.cVisible = false;
      this.selectedIssue = null;
      this.addToIssueComment = "";
      if (this.cleanDocumentsList) {
        this.$emit("update:documents", []);
      }
    },
    onIssueSelect() {
      this.noSelectedIssueError = [];
    },
  },
};
</script>
