<!--TODO: break the component into smaller parts-->
<template>
  <Drawer
    :width="1022"
    :visible="drawerVisible"
    :body-style="{ paddingBottom: '80px' }"
    @close="toggleDrawer(false)"
    :closable="false"
    :maskClosable="false"
    :keyboard="false"
  >
    <template v-slot:title>
      <div class="drawer-options">
        {{ $t("tasks.meta.task") }}
        <EODSelect
          id="task"
          v-model:value="selectedTask"
          :placeholder="$t('deployments.saveToConfigure')"
          :options="userTasks"
          class="select-task"
          :loading="loading$"
        />
        <Button
          v-if="selectedTask"
          @click="openPreview"
          :title="$t('app.preview')"
          :loading="loading$"
        >
          <EyeOutlined />
          {{ $t("app.preview") }}
        </Button>
        <Button
          v-if="selectedTask"
          @click="openDuplicateModal"
          :title="$t('deployments.duplicateTask.duplicateTask')"
          :loading="loading$"
        >
          <CopyOutlined />
          {{ $t("deployments.duplicateTask.duplicateTask") }}
        </Button>
        <Button
          v-if="selectedTask"
          @click="openPermissionModal"
          :title="$t('app.grantPermissions')"
          type="primary"
          :loading="loading$"
        >
          <LockOutlined />
          {{ $t("app.grantPermissions") }}
        </Button>
      </div>
    </template>
    <div v-if="selectedTask" class="form-fields-container">
      <Draggable
        id="form-fields-container"
        class="form-fields-container__field"
        :list="fieldsList"
        :sort="false"
        :group="{ name: 'tiles', pull: 'clone', put: false }"
        :clone="(original) => JSON.parse(JSON.stringify(original))"
      >
        <div
          class="tile"
          v-for="elem in fieldsList"
          :key="elem.name"
          :fieldId="elem.id"
        >
          <div class="tile__icon"><component :is="elem.icon" /></div>
          <div class="tile__title">
            <div>
              <strong>{{ elem.name }}: </strong><span>{{ elem.title }}</span>
              <span
                v-if="elem.required"
                class="color--error"
                :title="$t('app.fieldRequired')"
              >
                *
              </span>
            </div>
          </div>
        </div>
      </Draggable>
      <Draggable
        v-if="selectedTask"
        :list="fields[selectedTask]"
        :group="{ name: 'tiles', pull: false }"
        class="form-blueprint form-fields-container__field"
        @change="onFormFieldChange"
      >
        <div v-for="(elem, index) in fields[selectedTask]" :key="elem.id">
          <FieldTile
            :item="elem"
            v-model:disabled="elem.ui.props.disabled"
            :editCondition="editCondition"
            :removeItem="() => removeItem(index)"
          />
          <Draggable
            v-if="elem.ui.component === 'multiValue'"
            :list="elem.subfields"
            :group="{ name: 'mutli-value-tiles', pull: false, put: false }"
            class="subtiles"
          >
            <template v-for="subfield in elem.subfields" :key="subfield.id">
              <FieldTile
                :item="subfield"
                v-model:disabled="subfield.ui.props.disabled"
                multiValue
              />
            </template>
          </Draggable>
        </div>
      </Draggable>
    </div>
    <div class="drawer-footer">
      <Button
        @click="toggleDrawer(false)"
        :title="$t('app.cancel')"
        class="mr-1"
        :loading="loading$"
      >
        {{ $t("app.cancel") }}
      </Button>
      <Button
        @click="submitForms"
        :title="$t('app.confirm')"
        type="primary"
        :loading="loading$"
        :disabled="deployment.deployment_started"
      >
        {{ $t("app.confirm") }}
      </Button>
    </div>
  </Drawer>

  <button
    :class="`drawer-button ${drawerVisible ? 'drawer-button--extended' : ''}`"
    @click="toggleDrawer(!drawerVisible, selectedTask)"
  >
    <RightOutlined v-if="drawerVisible" />
    <LeftOutlined v-else />
  </button>

  <section class="section" v-if="id">
    <div class="forms-builder">
      <div class="tasks">
        <div v-if="userTasks.length === 0">
          {{ $t("deployments.noUserTasks") }}
        </div>
        <div v-else v-for="task in userTasks" :key="task.id">
          <div class="title-row">
            <strong>{{ task.name }}</strong>
            <div>
              <CheckCircleOutlined
                v-if="
                  isSaved(task.id) &&
                  Object.keys(taskData[task.id].schema_ui).length > 0
                "
                class="color--success mr-1"
                :title="$t('forms.saved')"
              />
              <CloseCircleOutlined
                v-else
                class="color--grey mr-1"
                :title="$t('forms.notSaved')"
              />
              <Button
                @click="toggleDrawer(true, task.id)"
                :title="$t('app.configure')"
                class="mr-050"
              >
                {{ $t("app.configure") }}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <ServiceTasks
    v-if="id"
    :deploymentID="id"
    :fetchUsers="fetchUsers"
    :fetchGroups="fetchGroups"
    :deploymentStarted="deployment.deployment_started"
  />

  <Section :title="$t('deployments.permsLabel')" v-if="id">
    <template #breadcrumbs>
      <Tooltip :title="$t('deployments.rememberAboutPermissions')">
        <InfoCircleOutlined />
      </Tooltip>
    </template>
    <Share
      :id="id"
      :editService="editDeploymentPerms"
      :getService="getDeploymentPerms"
      :viewPerms="false"
      :editPerms="false"
      :startPerms="true"
    />
  </Section>

  <Modal
    v-model:visible="showConditionalModal"
    :title="$t('forms.conditionalForm.title')"
    :z-index="modalZIndex"
    :footer="null"
  >
    <ConditionalRenderingForm
      v-if="selectedField"
      :fieldsList="selectedList"
      :selectedField="selectedField"
      @formChanged="onConditionChange"
    />
  </Modal>

  <Modal
    v-model:visible="preview.show"
    :title="$t('forms.preview')"
    :z-index="modalZIndex"
  >
    <template v-slot:footer>
      <Button @click="closePreview">
        {{ $t("app.cancel") }}
      </Button>
    </template>
    <FormBuilder
      v-model:modelValue="preview.model"
      :schema="preview.schema"
      :schemaUI="preview.schemaUI"
      :preview="true"
    />
  </Modal>

  <Modal
    v-model:visible="duplicateModalVisible"
    :title="`${$t('deployments.duplicateTask.duplicateTask')}`"
    @ok="confirmDuplicateModal"
    @cancel="closeDuplicateModal"
    :okText="`${$t('deployments.duplicateTask.duplicate')}`"
    :z-index="modalZIndex"
  >
    <p>{{ $t("deployments.duplicateTask.duplicateInfo") }}</p>
    <EODSelect
      id="tasks"
      v-model:value="taskListForDuplicated"
      :label="$t('modules.tasks')"
      required
      :options="duplicateSelectTasks"
      :errors="errors.tasks"
      mode="multiple"
    />
  </Modal>

  <Modal
    v-model:visible="grantPermissionsModalVisible"
    :title="$t('app.grantPermissions')"
    @ok="submitPerms"
    @cancel="closePermissionModal"
    :z-index="modalZIndex"
    :okButtonProps="{ disabled: deployment.deployment_started }"
  >
    <form id="perms-form" class="form">
      <div v-if="!selectedTask">
        {{ $t("deployments.noUserTasks") }}
      </div>
      <div v-else>
        <EODSelect
          :id="`perms-users-${selectedTask}`"
          storeID="perms-users"
          v-model:value="userTasksPerms[selectedTask].users"
          mode="multiple"
          :label="$tc('app.user', 2)"
          :service="fetchUsers"
          :defaultValue="defaultUsers[selectedTask]"
          :errors="errors.users"
        />

        <EODSelect
          :id="`perms-groups-${selectedTask}`"
          storeID="perms-groups"
          v-model:value="userTasksPerms[selectedTask].groups"
          mode="multiple"
          :label="$tc('modules.teams', 0)"
          :service="fetchGroups"
          :defaultValue="defaultGroups[selectedTask]"
          :errors="errors.groups"
        />
      </div>
    </form>
  </Modal>
</template>

<script>
import { Breadcrumbs, Section } from "@/components/ui";
import { Button, Drawer, Modal, Tooltip, Upload } from "ant-design-vue";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  CopyOutlined,
  EyeOutlined,
  InboxOutlined,
  LeftOutlined,
  LockOutlined,
  RightOutlined,
} from "@ant-design/icons-vue";
import {
  ConditionalRenderingForm,
  CustomAjv,
  FieldTile,
  FormBuilder,
  formIcons,
  formsErrorHandler,
  generateCondition,
  generateSchemaDraft,
} from "@/components/forms";
import {
  EODInput,
  EODRadio,
  EODSelect,
  EODSwitch,
  FileUploader,
} from "@/components/inputs";
import { createOrUpdateFormsBulk, getForm } from "@/services/forms";
import {
  createUserTasksPerms,
  editDeploymentPerms,
  getDeploymentPerms,
  getUserTasks,
  getUserTasksPerms,
} from "@/services/deployments";
import { getGroupsList, getUsersList } from "@/services/permissions";
import { iconsMap, namesMap } from "@/components/forms/formInputsConfig";

import { STATUS } from "@/consts/statuses";
import SchemaUISchema from "@/schemas/schemaUI.schema.json";
import Selecto from "selecto";
import ServiceTasks from "./ServiceTasks";
import { Share } from "@/components/common";
import { Tabs } from "ant-design-vue";
import { UploadOutlined } from "@ant-design/icons-vue";
import { VueDraggableNext } from "vue-draggable-next";
import { nextTick } from "vue";

export default {
  name: "ConfigureDeployment",
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
    deployment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      drawerVisible: false,
      fields: {},
      taskData: {},
      errors: {},
      fieldsList: [],
      userTasks: [],
      selectedTask: null,
      userTasksPerms: {},
      defaultUsers: {},
      defaultGroups: {},
      ajv: new CustomAjv(),
      preview: {
        schema: { properties: {} },
        schemaUI: {},
        title: "",
        model: {},
        show: false,
      },
      duplicateModalVisible: false,
      taskListForDuplicated: [],
      grantPermissionsModalVisible: false,
      modalZIndex: 1002,
      selectedList: [],
      selectedField: null,
      showConditionalModal: false,
      errorMsg: null,
      mainSchemaUI: {},
      selectedForDragFields: [],
      selecto: null,
      formFieldsContainer: null,
    };
  },
  components: {
    Breadcrumbs,
    Button,
    CheckCircleOutlined,
    CloseCircleOutlined,
    ConditionalRenderingForm,
    CopyOutlined,
    Draggable: VueDraggableNext,
    Drawer,
    EODInput,
    EODRadio,
    EODSelect,
    EODSwitch,
    EyeOutlined,
    FieldTile,
    FileUploader,
    FormBuilder,
    InboxOutlined,
    LeftOutlined,
    LockOutlined,
    Modal,
    RightOutlined,
    Section,
    ServiceTasks,
    Share,
    Tabs,
    TabPane: Tabs.TabPane,
    Tooltip,
    Upload,
    UploadDragger: Upload.Dragger,
    UploadOutlined,
    ...formIcons,
  },
  computed: {
    duplicateSelectTasks() {
      return this.userTasks.filter((item) => item.id !== this.selectedTask);
    },
  },
  created() {
    if (this.id) {
      this.fetchData();
    }
  },
  methods: {
    editDeploymentPerms,
    getDeploymentPerms,
    setSelectHandlerConfig() {
      this.formFieldsContainer = document.getElementById(
        "form-fields-container",
      );
      this.selecto = new Selecto({
        container: this.formFieldsContainer,
        selectableTargets: [".tile", document.querySelector(".tile")],
        selectByClick: false,
        selectFromInside: false,
        continueSelect: false,
        toggleContinueSelect: "shift",
        keyContainer: window,
        hitRate: 0,
      });
      this.selecto.on("select", (e) => {
        e.added.forEach((el) => {
          el.classList.add("tile--selected");
          if (
            !this.selectedForDragFields.some(
              (item) => item === el.getAttribute("fieldId"),
            )
          ) {
            this.selectedForDragFields.push(el.getAttribute("fieldId"));
          }
        });
        e.removed.forEach((el) => {
          el.classList.remove("tile--selected");
          this.selectedForDragFields.splice(
            this.selectedForDragFields.findIndex(
              (item) => item === el.getAttribute("fieldId"),
            ),
            1,
          );
        });
      });
    },
    toggleDrawer(open, taskId = null) {
      this.selectedTask = taskId;
      this.drawerVisible = open;
      if (!open) {
        this.fetchData();
      } else {
        nextTick(async () => {
          this.setSelectHandlerConfig();
        });
      }
    },
    async fetchData() {
      await getUserTasks(this.id).then(({ data }) => {
        data.forEach((task) => {
          this.fields[task.id] = [];
          this.taskData[task.id] = {};
          this.userTasksPerms[task.id] = {};
        });
        this.userTasks = data;
      });

      await getUserTasksPerms(this.id).then(({ data }) => {
        this.userTasksPerms = data;

        for (const [userTask, perms] of Object.entries(data)) {
          this.defaultUsers[userTask] = perms.users.map((item) => ({
            ...item,
            name: item.full_name,
          }));
          perms.users = perms.users.map((item) => item.id);

          this.defaultGroups[userTask] = perms.groups;
          perms.groups = perms.groups.map((item) => item.id);
        }
      });

      for (const task of this.userTasks) {
        if (task.form) {
          this.taskData[task.id] = task.form;
          this.fields[task.id] = this.schemaToList(task.form);
        }
      }

      getForm(this.deployment.form).then(({ data }) => {
        this.fieldsList = this.schemaToList(data);
        this.mainSchemaUI = data.schema_ui;
      });
    },
    fetchGroups(params) {
      return getGroupsList(params).then(({ data }) => ({
        ...data,
        results: data.results.map((item) => ({
          id: item.id,
          name: item.name,
        })),
      }));
    },
    fetchUsers(params) {
      return getUsersList(params).then(({ data }) => ({
        ...data,
        results: data.results.map((item) => ({
          id: item.id,
          name: item.full_name,
        })),
      }));
    },
    removeItem(index) {
      this.fields[this.selectedTask].splice(index, 1);
    },
    generateForm(key) {
      const list = this.fields[key];
      const schema = {
        $schema: "http://json-schema.org/draft-07/schema#",
        title: `${this.deployment.name} - ${this.deployment.form_display} - ${key}`,
        description: "User-task subform",
        type: "object",
        properties: {},
        allOf: [],
        required: [],
      };
      const schemaUI = {};

      for (const [index, elem] of list.entries()) {
        // prettier-ignore
        /* eslint-disable no-unused-vars */
        const {
          id,
          ui,
          condition,
          required,
          name,
          icon,
          subfields,
          ...rest
        } = elem;
        /* eslint-enable no-unused-vars */

        if (
          schema.properties[id] ||
          schema.allOf.find(
            (item) => Object.keys(item.then.properties)[0] === id,
          )
        ) {
          this.$message.error(
            `${this.$t("deployments.cantDuplicate")} "${
              this.userTasks.find((item) => item.id === key)?.name
            }"`,
          );
          return { schema: null, schemaUI: null };
        }

        ui.ordering = index;

        if (
          ui.component === "numeration" &&
          ui.isUnitCounter &&
          !list.some((item) =>
            ["usersUnits", "allUnits"].includes(item.ui.component),
          )
        ) {
          this.$message.error(this.$t("forms.gui.unitRequiredError"));
          return { schema: null, schemaUI: null };
        }

        if (ui.parent && !list.find((item) => item.id === ui.parent)) {
          this.$message.error(this.$t("forms.gui.parentError_", [rest.title]));
          return { schema: null, schemaUI: null };
        }

        if (
          ui.children &&
          !ui.children.every((item) => list.find((elem) => elem.id === item))
        ) {
          this.$message.error(this.$t("forms.gui.childError_", [rest.title]));
          return { schema: null, schemaUI: null };
        }

        if (ui.component === "multiValue") {
          for (const [idx, subfield] of subfields.entries()) {
            schemaUI[subfield.id] = JSON.parse(
              JSON.stringify(this.mainSchemaUI[subfield.id]),
            );
            schemaUI[subfield.id].props.disabled = !!subfields?.find(
              (item) => item.id === subfield.id,
            )?.ui?.props?.disabled;

            schemaUI[subfield.id].ordering = idx;

            if (schemaUI[subfield.id].props.disabled) {
              rest.items.required = rest.items.required.filter(
                (item) => item !== subfield.id,
              );
            }
          }
        }

        if (ui.calc) {
          for (const variable of ui.calc.variables) {
            if (!list.find((item) => item.id === variable)) {
              this.$message.error(
                this.$t("deployments.calcError", [
                  this.userTasks.find((item) => item.id === key)?.name,
                  this.fieldsList.find((item) => item.id === variable)?.title,
                ]),
              );
              return { schema: null, schemaUI: null };
            }
          }
        }

        if (condition) {
          if (
            !list.find(
              (item) => item.id === Object.keys(condition.if.properties)[0],
            )
          ) {
            this.$message.error(
              this.$t("deployments.conditionError", [
                this.userTasks.find((item) => item.id === key)?.name,
                this.fieldsList.find(
                  (item) => item.id === Object.keys(condition.if.properties)[0],
                )?.title,
              ]),
            );
            return { schema: null, schemaUI: null };
          }

          if (required && ui.props.disabled) {
            condition.then.required = [];
          }

          schema.allOf.push(condition);
        } else {
          schema.properties[id] = rest;

          if (required && !ui.props.disabled) {
            schema.required.push(id.toString());
          }
        }

        schemaUI[id] = ui;
      }

      if (schema.allOf.length === 0) {
        schema.allOf = undefined;
      }

      // this validation is unnecessary (?) in production, leave it for tests
      const schemaIsValid = this.ajv.validateSchema(schema);

      if (!schemaIsValid) {
        console.error(this.ajv.errors);
        return { schema: null, schemaUI: null };
      }

      const schemaUIIsValid = this.ajv.validate(SchemaUISchema, schemaUI);

      if (!schemaUIIsValid) {
        console.error(this.ajv.errors);
        return { schema: null, schemaUI: null };
      }

      return { schema, schemaUI };
    },
    isSaved(taskId) {
      return Object.keys(this.taskData[taskId]).length > 0;
    },
    async submitForms() {
      const formsArray = [];
      this.loading$ = true;

      for (const task of this.userTasks) {
        if (this.fields[task.id].length > 0 || this.isSaved(task.id)) {
          const { schema, schemaUI } = this.generateForm(task.id);

          if (!schema || !schemaUI) {
            this.loading$ = false;
            return;
          }

          const schemaDraft = generateSchemaDraft(schema);

          let data = {};

          if (this.isSaved(task.id)) {
            data = this.taskData[task.id];
            data.schema = schema;
            data.schema_ui = schemaUI;
            data.schema_draft = schemaDraft;
          } else {
            data = {
              name: `${this.deployment.name} - ${this.deployment.form_display} - ${task.id}`,
              status: STATUS.ACTIVE,
              description: `Formularz dla kroku "${task.name}" (${task.id}) w obiegu "${this.deployment.name}"`,
              schema,
              schema_ui: schemaUI,
              schema_draft: schemaDraft,
              parent: this.deployment.form,
              task_definition_key: task.id,
            };
          }

          formsArray.push(data);
        }
      }

      if (formsArray.length > 0) {
        createOrUpdateFormsBulk(formsArray, formsErrorHandler)
          .then(() => {
            this.$message.success(this.$t("app.success"));
            this.toggleDrawer(false);
          })
          .finally(() => {
            this.loading$ = false;
          });
      } else {
        this.loading$ = false;
      }
    },
    //TODO: POC perms, cleanup
    submitPerms() {
      const perms = { user_tasks: [] };

      Object.keys(this.userTasksPerms).forEach((key) => {
        perms.user_tasks.push({
          user_task_id: key,
          entity: this.userTasksPerms[key],
        });
      });

      createUserTasksPerms(perms, this.id)
        .then(() => {
          this.closePermissionModal();
          this.$message.success(this.$t("app.success"));
        })
        .catch((err) => {
          this.errors = err.response.data;
        });
    },
    schemaToList(data) {
      const properties = data.schema.allOf
        ? {
            ...data.schema.properties,
            ...Object.fromEntries(
              data.schema.allOf.map((item) => {
                return Object.entries(item.then.properties)[0];
              }),
            ),
          }
        : data.schema.properties;

      const required = data.schema.allOf
        ? [
            ...data.schema.required,
            ...data.schema.allOf
              .map((item) => item.then.required)
              .reduce((acc, val) => [...acc, ...val], []),
          ]
        : data.schema.required;

      return this.convertInputData(
        properties,
        required,
        data.schema_ui,
        data.schema,
      );
    },
    convertInputData(properties, required, schema_ui, schema) {
      return Object.entries(properties)
        .map(([key, value]) => {
          let stringRepresentation;

          if (["radio", "simpleSelect"].includes(schema_ui[key].component)) {
            stringRepresentation = value.enum.join(";");
          }

          if (schema_ui[key].component === "checkbox") {
            stringRepresentation = value.items.enum.join(";");
          }

          let condition = null;

          // secure case when there is condition in schema_ui, but not in schema
          if (schema_ui[key].condition) {
            condition = schema?.allOf?.find(
              (item) => Object.keys(item.then.properties)[0] === key,
            );

            if (!condition) {
              schema_ui[key].condition = undefined;
            }
          }

          let subfields;

          if (schema_ui[key].component === "multiValue") {
            subfields = this.convertInputData(
              value.items.properties,
              value.items.required,
              schema_ui,
            );
          }

          return {
            ...value,
            id: key,
            ui: schema_ui[key],
            condition,
            required: required.includes(key),
            // prettier-ignore
            name:
              namesMap[
                schema_ui[key].multiValueType ?? schema_ui[key].component
              ],
            // prettier-ignore
            icon:
              iconsMap[
                schema_ui[key].multiValueType ?? schema_ui[key].component
              ],
            stringRepresentation,
            subfields,
          };
        })
        .sort((a, b) => {
          return a.ui.ordering - b.ui.ordering;
        });
    },
    openPermissionModal() {
      this.grantPermissionsModalVisible = true;
    },
    closePermissionModal() {
      this.grantPermissionsModalVisible = false;
    },
    openDuplicateModal() {
      this.duplicateModalVisible = true;
    },
    confirmDuplicateModal() {
      if (this.taskListForDuplicated.length === 0) {
        return (this.errors.tasks = [
          this.$t("deployments.duplicateTask.noSelectedTasks"),
        ]);
      }
      this.taskListForDuplicated.forEach((item) => {
        this.fields[item] = JSON.parse(
          JSON.stringify(this.fields[this.selectedTask]),
        );
      });
      this.closeDuplicateModal();
    },
    closeDuplicateModal() {
      this.duplicateModalVisible = false;
      this.taskListForDuplicated = [];
      this.errors.tasks = [];
    },
    openPreview() {
      const { schema, schemaUI } = this.generateForm(this.selectedTask);
      if (!schema || !schemaUI) {
        return;
      }
      this.preview.schema = schema;
      this.preview.schemaUI = schemaUI;
      this.preview.show = true;
    },
    closePreview() {
      this.preview.show = false;
      this.preview.model = {};
    },
    onConditionChange() {
      // prettier-ignore
      /* eslint-disable no-unused-vars */
      const {
        id,
        ui,
        condition,
        required,
        name,
        icon,
        ...rest
      } = this.selectedField;
      /* eslint-enable no-unused-vars */

      if (!ui.condition) {
        this.selectedField.condition = undefined;
        this.showConditionalModal = false;
        this.$message.success(this.$t("forms.conditionalForm.deleteSuccess"));
        return;
      }

      const component = this.selectedList.find(
        (item) => item.id === ui.condition.property,
      )?.ui?.component;

      this.selectedField.condition = {
        if: {
          properties: {
            [ui.condition.property]: generateCondition(
              component,
              this.selectedField,
            ),
          },
          required: [ui.condition.property],
        },
        then: {
          properties: {
            [id]: rest,
          },
          required: required ? [id] : [],
        },
      };

      this.showConditionalModal = false;
    },
    editCondition(item) {
      this.selectedList = this.fields[this.selectedTask];
      this.selectedField = item;
      this.showConditionalModal = true;
    },
    onFormFieldChange(e) {
      if (!e.added) {
        return;
      }
      if (
        this.selectedForDragFields.some(
          (item) => item === e.added.element.id && !!e.added,
        )
      ) {
        const sortedSelectedItems = this.fieldsList.filter((item) =>
          this.selectedForDragFields.includes(item.id),
        );
        const newArray = this.fields[this.selectedTask];
        newArray.splice(e.added.newIndex, 1);
        const beginOfArray = newArray.splice(0, e.added.newIndex);
        this.fields[this.selectedTask] = [
          ...beginOfArray,
          ...sortedSelectedItems,
          ...newArray,
        ];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/Variables";
@import "@/scss/Forms";

// has to be smaller than modalZIndex
$drawer-button-z-index: 1001;

.drawer-button {
  z-index: $drawer-button-z-index;
  position: fixed;
  top: 50vh;
  right: 0;
  background-color: $white;
  border: none;
  width: 14px;
  height: 66px;
  cursor: pointer;
  padding: 0 2px;
  transform: perspective(20px) rotateY(-45deg);
  transition-duration: 0.27s;

  &:hover,
  &:focus {
    border: primaryOpacity(0.6);
    border-right: none;
    outline: none;
  }

  &--extended {
    right: 1021px;
  }
}

.form-fields-container {
  display: flex;
  width: 100%;
  &__field {
    flex-grow: 1;
    border: 1px dashed $primary;
    padding: 1rem 0;
  }
}

.drawer-options {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.drawer-footer {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: $white;
  text-align: right;
  z-index: 1;
}

.select-task {
  width: 25rem;
}

.subtiles {
  margin: 0 0.5rem 0.5rem 4rem;
}

.field-tile {
  display: flex;
  justify-content: space-between;
  border: 1px solid $menu-dark-bg;
  border-radius: 4px;
  padding: 0.3rem;
  margin: 0.4rem;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}
</style>
