<template>
  <Breadcrumbs
    :path="[{ path: { name: 'Mailboxes' }, title: $t('modules.mailboxes') }]"
    :current="title"
    iconComponent="MailOutlined"
    :what="$t('mailboxes.mailbox')"
  />

  <Section
    :title="$t('mailboxes.info')"
    :collapsable="true"
    v-model:collapsed="collapse.info"
  >
    <div class="form-container">
      <form @submit.prevent="submit" class="form">
        <EODInput
          id="name"
          required
          v-model:value="data.name"
          :label="$t('app.name')"
          :errors="errors.name"
        />

        <EODInput
          id="host"
          required
          v-model:value="data.host"
          :label="$t('mailboxes.host')"
          :errors="errors.host"
        />

        <EODInput
          id="username"
          required
          v-model:value="data.username"
          :label="$t('app.username')"
          :errors="errors.username"
        />

        <EODInput
          id="password"
          type="password"
          required
          v-model:value="data.password"
          :label="$t('app.password')"
          :errors="errors.password"
        />

        <EODInput
          id="port"
          type="number"
          required
          v-model:value="data.port"
          :label="$t('mailboxes.port')"
          :errors="errors.port"
        />

        <div class="input-container">
          <label for="ssl" class="label">SSL</label>
          <div>
            <EODSwitch
              id="ssl"
              v-model:checked="data.ssl"
              size="small"
              title="SSL"
            />
          </div>
        </div>
        <div class="input-container">
          <label for="starttls" class="label">STARTTLS</label>
          <div>
            <EODSwitch
              id="starttls"
              v-model:checked="data.starttls"
              size="small"
              title="starttls"
            />
          </div>
        </div>
        <div class="input-container">
          <label for="active" class="label">{{ $t("mailboxes.active") }}</label>
          <div>
            <EODSwitch
              id="active"
              v-model:checked="data.active"
              size="small"
              title="active"
            />
          </div>
        </div>

        <div class="buttons">
          <Button
            htmlType="submit"
            type="primary"
            :title="$t('app.save')"
            :loading="loading$"
          >
            {{ $t("app.save") }}
          </Button>
        </div>
      </form>
    </div>
  </Section>

  <Section
    id="mailbox-share"
    :title="$t('app.share')"
    v-if="id"
    :collapsable="true"
    v-model:collapsed="collapse.share"
  >
    <Share
      :id="id"
      :editService="editMailboxPerms"
      :getService="getMailboxPerms"
      :viewPerms="false"
    />
  </Section>

  <Section :title="$tc('mailboxes.messages.message', 2)" v-if="id">
    <Tabs>
      <TabPane key="1" :tab="$t('mailboxes.messages.inbox')" class="mt-1">
        <MessagesList :service="getMailboxMessageList" :id="id" editionMode />
      </TabPane>
      <TabPane
        key="2"
        :tab="$t('mailboxes.messages.deletedMessages')"
        class="mt-1"
      >
        <MessagesList
          :service="getDeletedMailboxMessageList"
          :id="id"
          editionMode
          deletedPreview
        />
      </TabPane>
    </Tabs>
  </Section>
</template>

<script>
import { Breadcrumbs, Section } from "@/components/ui";
import { Button, Tabs } from "ant-design-vue";
import { EODInput, EODSwitch } from "@/components/inputs";
import {
  createMailbox,
  editMailbox,
  editMailboxPerms,
  getDeletedMailboxMessageList,
  getMailbox,
  getMailboxMessageList,
  getMailboxPerms,
} from "@/services/mailboxes";

import MessagesList from "./MessagesList";
import { Share } from "@/components/common";

export default {
  name: "MailboxForm",
  props: { id: String },
  data() {
    return {
      collapse: {
        info: !!this.id,
        share: true,
      },
      messageId: null,
      data: {
        name: "",
        port: "993",
        host: "",
        password: "",
        username: "",
        ssl: true,
        starttls: false,
        last_sync: "",
        active: true,
        messages: [],
      },
      fetching: false,
      errors: {},
    };
  },
  components: {
    Breadcrumbs,
    Button,
    Section,
    EODInput,
    EODSwitch,
    MessagesList,
    Share,
    Tabs,
    TabPane: Tabs.TabPane,
  },
  computed: {
    action() {
      return this.id ? this.$t("app.editAction") : this.$t("app.addAction");
    },
    title() {
      return this.id
        ? this.$t("mailboxes.meta.edit")
        : this.$t("mailboxes.meta.add");
    },
  },
  created() {
    if (this.id) {
      getMailbox(this.id).then(({ data }) => {
        this.data = data;
      });
    }
  },
  methods: {
    getDeletedMailboxMessageList,
    editMailboxPerms,
    getMailbox,
    getMailboxMessageList,
    getMailboxPerms,
    submit() {
      this.loading$ = true;

      const service = this.id ? editMailbox : createMailbox;

      service(this.data, this.id)
        .then(() => {
          this.$router.push({ name: "Mailboxes" });

          this.$message.success(this.$t("app.success"));
        })
        .catch((err) => {
          this.errors = err.response.data;
        })
        .finally(() => {
          this.loading$ = false;
        });
    },
  },
};
</script>
