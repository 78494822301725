<template>
  <Breadcrumbs
    :path="[
      {
        path: { name: 'Archive', query: { tab: 'handovers' } },
        title: $t('modules.archive'),
      },
    ]"
    :current="$tc('archive.handovers.handoverLists', 1)"
    iconComponent="ContainerOutlined"
  >
    <EODButton
      v-if="preview"
      :name="$t('deployments.start')"
      icon="PlayCircleOutlined"
      @click="openSelectDeploymentModal"
    />
    <EODButton
      v-if="preview"
      @click="generateHandover"
      :title="$t('archive.handovers.generateHandoverLists')"
      :loading="loading$"
      class="ml-1"
    >
      {{ $t("archive.handovers.generateHandoverLists") }}
    </EODButton>
    <EODButtonLink
      v-if="preview"
      :to="{ name: 'HandoversEdit', params: { id: id } }"
      :title="$t('app.edit_', [$tc('archive.handovers.handoverLists', 0)])"
      icon="EditOutlined"
    />
    <EODButton
      v-if="!preview && handover?.actions?.removable"
      class="ml-1"
      type="danger"
      ghost
      @click="confirmDelete"
      icon="DeleteOutlined"
      :name="`${$t('app.delete')} ${$tc('archive.handovers.handoverLists', 0)}`"
    />
    <EODButton
      v-if="!preview"
      class="ml-1"
      type="primary"
      @click="openCompleteFolderModal"
      :name="$t('archive.handovers.completeHandoverList')"
    />
  </Breadcrumbs>

  <Preview
    :oid="id"
    :data="previewData"
    :title="$t('archive.handovers.handoversInfo')"
    :noPreviewMsg="$t('app.noPreview_', [$tc('archive.folders.folder', 1)])"
  />

  <HandoversContent
    :id="id"
    :preview="preview"
    :handleRefresh="fetchHandover"
  />
  <CompleteHandoveListsModal
    v-if="showHandoverListsModal"
    v-model:visible="showHandoverListsModal"
    :handover="handover"
    :id="id"
    :handleRefresh="fetchHandover"
    customStore="handoverFolders"
  />
</template>

<script>
import { Breadcrumbs, EODButton, EODButtonLink } from "@/components/ui";
import {
  deleteHandover,
  generateHandoverLists,
  getHandover,
} from "@/services/handovers";

import CompleteHandoveListsModal from "@/components/archive/CompleteHandoverListsModal.vue";
import HandoversContent from "@/components/archive/HandoversContent";
import { Preview } from "@/components/common";

export default {
  name: "HandoverPreview",
  props: {
    id: { type: String, required: true },
    preview: { type: Boolean, default: true },
  },
  data() {
    return {
      previewData: [],
      activeKey: "handovers",
      showSelectDeploymentModal: false,
      showHandoverListsModal: false,
      handover: {},
    };
  },
  components: {
    Breadcrumbs,
    CompleteHandoveListsModal,
    EODButton,
    EODButtonLink,
    HandoversContent,
    Preview,
  },
  beforeRouteEnter(to, _, next) {
    getHandover(to.params.id)
      .then(({ data }) => {
        next((vm) => {
          vm.fetchHandoverCallback(vm, data);
        });
      })
      .catch((err) => {
        if (err.response.status === 404) {
          next({ name: "404" });
        }
      });
  },
  methods: {
    fetchHandoverCallback(vm, data) {
      vm.handover = data;
      vm.previewData = [
        {
          title: vm.$t("archive.handovers.handoverCreationDate"),
          value: data.filing_date,
        },
        {
          title: vm.$t("archive.handovers.handoverCreationPerson"),
          value: data.user_display,
        },
        {
          title: vm.$t("archive.handovers.handoverNumber"),
          value: data.protocol_number,
          info: vm.$t("archive.folders.archiveSignatureInfo"),
        },
        { title: vm.$t("app.status"), value: data.status_display },
        {
          title: vm.$t("issues.unit"),
          value: data.unit_display,
        },
        {
          title: vm.$t("archive.folders.category"),
          value: data.archive_category_type,
        },
        {
          title: vm.$t("archive.handovers.handoverType"),
          value: data.protocol_type_display,
        },
      ];
    },
    fetchHandover() {
      getHandover(this.id).then(({ data }) => {
        this.fetchHandoverCallback(this, data);
      });
    },
    confirmDelete() {
      this.$store.dispatch("setModal", {
        title: this.$t("app.delete_", [
          this.$tc("archive.handovers.handoverLists", 0),
        ]),
        message: this.$t("app.doYouWantToDelete_", [
          this.$tc("archive.handovers.handoverLists", 0),
        ]),
        modalOk: this.deleteThis,
      });
    },
    deleteThis() {
      deleteHandover(this.id).then(() => {
        this.$message.success(this.$t("app.success"));
        this.$store.dispatch("closeModal");
        this.$router.replace({ name: "Archive", query: { tab: "handovers" } });
      });
    },
    updateActiveKey(value) {
      this.activeKey = value;
    },
    openSelectDeploymentModal() {
      this.showSelectDeploymentModal = true;
    },
    openCompleteFolderModal() {
      this.showHandoverListsModal = true;
    },
    generateHandover() {
      generateHandoverLists(this.id).then(({ data }) =>
        window.open(URL.createObjectURL(data)),
      );
    },
  },
};
</script>
