export default function generateSchemaDraft(schema: any) {
  const schemaDraft = JSON.parse(JSON.stringify(schema));
  schemaDraft.required = [];
  schemaDraft.additionalProperties = false;

  if (schemaDraft.allOf) {
    schemaDraft.properties = {
      ...schemaDraft.properties,
      ...Object.fromEntries(
        schemaDraft.allOf.map(
          (item: any) => Object.entries(item.then.properties)[0],
        ),
      ),
    };

    schemaDraft.allOf = undefined;
  }

  Object.values(schemaDraft.properties).forEach((item: any) => {
    if (item.items?.required?.length > 0) {
      item.items.required = [];
    }
  });

  return schemaDraft;
}
