const contractors = {
  state: {
    contactID: undefined,
    bankAccountID: undefined,
    submitContractor: false,
    submitAddress: false,
    submitSupply: false,
    submitFeatures: false,
    refreshSort: false,
    accountsCount: 0,
  },
  mutations: {
    SET_CONTACT_ID(state, payload) {
      state.contactID = payload;
    },
    SET_BANK_ACCOUNT_ID(state, payload) {
      state.bankAccountID = payload;
    },
    SET_SUBMIT_CONTRACTOR(state, payload) {
      state.submitContractor = payload;
    },
    SET_SUBMIT_ADDRESS(state, payload) {
      state.submitAddress = payload;
    },
    SET_SUBMIT_SUPPLY(state, payload) {
      state.submitSupply = payload;
    },
    SET_SUBMIT_FEATURES(state, payload) {
      state.submitFeatures = payload;
    },
    SET_REFRESH_SORT(state, payload) {
      state.refreshSort = payload;
    },
    SET_ACCOUNTS_COUNT(state, payload) {
      state.accountsCount = payload;
    },
  },
  actions: {
    setContactID({ commit }, payload) {
      commit("SET_CONTACT_ID", payload);
    },
    setBankAccountID({ commit }, payload) {
      commit("SET_BANK_ACCOUNT_ID", payload);
    },
    submitContractor({ commit }) {
      commit("SET_SUBMIT_CONTRACTOR", true);
      commit("SET_SUBMIT_ADDRESS", true);
      commit("SET_SUBMIT_SUPPLY", true);
      commit("SET_SUBMIT_FEATURES", true);
    },
    resetSubmitContractor({ commit }) {
      commit("SET_SUBMIT_CONTRACTOR", false);
    },
    resetSubmitAddress({ commit }) {
      commit("SET_SUBMIT_ADDRESS", false);
    },
    resetSubmitSupply({ commit }) {
      commit("SET_SUBMIT_SUPPLY", false);
    },
    resetSubmitFeatures({ commit }) {
      commit("SET_SUBMIT_FEATURES", false);
    },
    submitVerification({ commit }) {
      commit("SET_SUBMIT_CONTRACTOR", true);
    },
    refreshSort({ commit }) {
      commit("SET_REFRESH_SORT", true);
    },
    resetRefreshSort({ commit }) {
      commit("SET_REFRESH_SORT", false);
    },
    setAccountsCount({ commit }, payload) {
      commit("SET_ACCOUNTS_COUNT", payload);
    },
  },
};

export default contractors;
