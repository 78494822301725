
import InputMask from "./InputMask.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "EODInputMask",
  props: {
    value: { type: String, default: "" },
    id: { type: String, required: true },
    required: { type: Boolean, default: false },
    placeholder: { type: String, default: "" },
    label: { type: String, default: "" },
    mask: { type: String, required: true },
    disabled: { type: Boolean, default: false },
    errors: { type: Array, default: null },
  },
  components: { InputMask },
  computed: {
    cValue: {
      get() {
        return this.value;
      },
      set(newValue: string) {
        this.$emit("update:value", newValue);
      },
    },
    cPlaceholder() {
      return this.placeholder ?? this.label;
    },
  },
  emits: ["update:value", "invalid"],
  methods: {
    onInvalid(event: Event) {
      this.$emit("invalid", event);
    },
  },
});
