<template>
  <Breadcrumbs
    :path="[
      { path: { name: 'Deployments' }, title: $t('modules.deployments') },
    ]"
    :current="$t('deployments.preview')"
    iconComponent="SyncOutlined"
  >
    <EODButton
      v-if="id && hasExportPermission"
      :name="$t('deployments.export')"
      @click="exportDeployment([id])"
    />
    <EODButtonLink
      v-if="hasPermissionToEdit"
      :to="{ name: 'DeploymentEdit', params: { id } }"
      :title="$t('deployments.meta.edit')"
      icon="EditOutlined"
    />
  </Breadcrumbs>

  <Preview
    :oid="id"
    :data="previewData"
    :title="$t('deployments.preview')"
    :noPreviewMsg="$t('deployments.noPreview')"
  />
</template>

<script>
import { Breadcrumbs, EODButton, EODButtonLink } from "@/components/ui";

import PERMISSIONS from "@/consts/permissions";
import { Preview } from "@/components/common";
import { getDeployment } from "@/services/deployments";
import { ref } from "vue";
import useExportDeployments from "@/composables/useExportDeployments";

export default {
  name: "DeploymentsPreview",
  props: { id: { type: Number, required: true } },
  setup() {
    const previewData = ref([]);

    return { previewData, ...useExportDeployments() };
  },
  components: { Breadcrumbs, EODButton, EODButtonLink, Preview },
  beforeRouteEnter(to, _, next) {
    getDeployment(to.params.id)
      .then(({ data }) => {
        next((vm) => {
          vm.previewData = [
            { title: vm.$t("app.name"), value: data.name },
            {
              title: vm.$t("app.status"),
              value: data.status_display,
            },
            {
              title: vm.$t("app.version"),
              value: data.version,
            },
            {
              title: vm.$t("app.key"),
              value: data.camunda_key,
            },
            {
              title: vm.$t("app.description"),
              value: data.description,
            },
          ];
        });
      })
      .catch((err) => {
        if (err.response.status === 404) {
          next({ name: "404" });
        }
      });
  },
  computed: {
    hasPermissionToEdit() {
      return this.$permissions.includes(PERMISSIONS.DEPLOYMENTS_EDIT);
    },
  },
};
</script>
