import APPS from "@/consts/apps";
import PERMISSIONS from "@/consts/permissions";
import { RouteLocation } from "vue-router";
import TaskForm from "@/views/Tasks/TaskForm.vue";
import Tasks from "@/views/Tasks/Tasks.vue";
import i18n from "@/i18n";

const t = i18n.global.t;

const tasks = [
  {
    path: "/my-tasks/",
    name: "MyTasks",
    component: Tasks,
    meta: {
      title: `${t("meta.app")} - ${t("tasks.myTasks")}`,
      requiresAuth: true,
      permission: [PERMISSIONS.TASKS_OWN, PERMISSIONS.TASKS_ALL],
      app: APPS.WORKFLOWS,
    },
    props: { myTasks: true },
  },
  {
    path: "/tasks/",
    name: "Tasks",
    component: Tasks,
    meta: {
      title: `${t("meta.app")} - ${t("tasks.meta.list")}`,
      requiresAuth: true,
      permission: [PERMISSIONS.TASKS_OWN, PERMISSIONS.TASKS_ALL],
      app: APPS.WORKFLOWS,
    },
    props: { myTasks: false },
  },
  {
    path: "/tasks/:id/form/",
    name: "TaskForm",
    component: TaskForm,
    meta: {
      title: `${t("meta.app")} - ${t("tasks.meta.task")}`,
      requiresAuth: true,
      app: APPS.WORKFLOWS,
    },
    props: (route: RouteLocation) => ({ id: route.params.id }),
  },
  {
    path: "/tasks/:id/preview/",
    name: "TaskPreview",
    component: TaskForm,
    meta: {
      title: `${t("meta.app")} - ${t("tasks.meta.task")}`,
      requiresAuth: true,
      app: APPS.WORKFLOWS,
    },
    props: (route: RouteLocation) => ({
      preview: true,
      id: route.params.id,
    }),
  },
];

export default tasks;
