<template>
  <Breadcrumbs :current="$t('modules.tasks')" iconComponent="ProfileOutlined" />

  <Tabs @change="$scrollToTop">
    <TabPane key="1" :tab="$t('tasks.current')">
      <CurrentTasks :deploymentsService="fetchDeployments" :myTasks="myTasks" />
    </TabPane>
    <TabPane key="2" :tab="$t('tasks.completed')">
      <CompletedTasks
        :deploymentsService="fetchDeployments"
        :myTasks="myTasks"
      />
    </TabPane>
  </Tabs>
</template>

<script>
import { CompletedTasks, CurrentTasks } from "@/components/tasks";

import { Breadcrumbs } from "@/components/ui";
import { Tabs } from "ant-design-vue";
import { getDeploymentsSelect } from "@/services/deployments";

export default {
  name: "Tasks",
  props: {
    myTasks: { type: Boolean, default: null },
  },
  data() {
    return {
      deployments: [],
    };
  },
  components: {
    Breadcrumbs,
    CompletedTasks,
    CurrentTasks,
    Tabs,
    TabPane: Tabs.TabPane,
  },
  methods: {
    fetchDeployments(params) {
      return getDeploymentsSelect({ all_versions: true, ...params }).then(
        ({ data }) => ({
          ...data,
          results: data.results.map((item) => ({
            id: item.id,
            name:
              item.version !== 1
                ? `${item.name} - ${this.$t("app.version")} ${item.version}`
                : item.name,
          })),
        }),
      );
    },
  },
};
</script>
