const archive = {
  state: () => ({
    shouldRefetch: false,
  }),
  mutations: {
    SET_SHOULD_REFETCH(state, payload) {
      state.shouldRefetch = payload;
    },
  },
  actions: {
    refetchEntries({ commit }) {
      commit("SET_SHOULD_REFETCH", true);
    },
    resetRefetchEntries({ commit }) {
      commit("SET_SHOULD_REFETCH", false);
    },
  },
};

export default archive;
