<template>
  <Draggable :list="columns" ghost-class="columns-ghost">
    <div class="columns" v-for="(column, index) in columns" :key="index">
      <MenuOutlined class="mr-1 mt-1" />
      <EODInput
        class="mr-1"
        :id="`variable_name_${column.variable_name}`"
        v-model:value="column.variable_name"
        :placeholder="$t('registry.variables.placeholder')"
      />

      <EODInput
        class="mr-1"
        :id="`variable_display_${column.display_name}`"
        v-model:value="column.display_name"
        :placeholder="$t('registry.variables.label')"
      />

      <button
        type="button"
        class="icon-button"
        @click="removeColumn(index)"
        :title="$t('app.delete')"
      >
        <DeleteOutlined />
      </button>
    </div>
  </Draggable>

  <div class="add-columns" v-if="columns.length < 5">
    <EODInput
      class="mr-1"
      id="column_name"
      v-model:value="name"
      :placeholder="$t('registry.variables.placeholder')"
    />

    <EODInput
      class="mr-1"
      id="column_display"
      v-model:value="display"
      :placeholder="$t('registry.variables.label')"
    />

    <Button
      class="action-button"
      htmlType="button"
      type="primary"
      :title="$t('app.add')"
      @click="addColumn"
      :disabled="!enableAdd"
    >
      {{ $t("registry.variables.add") }}
    </Button>
  </div>
</template>

<script>
import { DeleteOutlined, MenuOutlined } from "@ant-design/icons-vue";

import { Button } from "ant-design-vue";
import { EODInput } from "@/components/inputs";
import { VueDraggableNext } from "vue-draggable-next";

export default {
  name: "ColumnsSection",
  props: {
    columnsModel: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      name: "",
      display: "",
    };
  },
  components: {
    Button,
    EODInput,
    DeleteOutlined,
    Draggable: VueDraggableNext,
    MenuOutlined,
  },
  computed: {
    enableAdd() {
      return this.name.length > 0 && this.display.length > 0;
    },
    columns: {
      get() {
        return this.columnsModel;
      },
      set(val) {
        this.$emit("update:columnsModel", val);
      },
    },
  },
  emits: ["update:columnsModel"],
  methods: {
    addColumn() {
      this.columns.push({
        variable_name: this.name,
        display_name: this.display,
      });

      this.name = "";
      this.display = "";
    },
    removeColumn(index) {
      this.columns.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/Variables";

.columns,
.add-columns {
  display: flex;
  width: 70%;
  margin: 1em auto;
}
.columns {
  &:hover {
    cursor: pointer;
  }
  &-ghost {
    padding: 0.5em;
    background-color: primaryOpacity(0.05);
    border: 1px dashed $primary;
  }
}

.action-button {
  margin-top: 0.4rem;
}

.icon-button {
  padding: 0.5em 0;
  border: none;
  background-color: transparent;
  color: $error;
  outline: none;
  cursor: pointer;
}
</style>
