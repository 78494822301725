import { DictionaryEntriesParams } from "@/interfaces/dictionaries";
import { Params } from "@/interfaces/common";
import axiosSession from "@/config/apiClient";

const dicts = "/api/dicts/entry/keyword/";

//TODO: fix types

const getDictionariesList = (params?: Params) => {
  return axiosSession.get("/api/dicts/", { params });
};
const getDictionariesSelect = (params?: DictionaryEntriesParams) => {
  return axiosSession.get("/api/dicts/select/", { params });
};

const createDictionary = (data: any) => {
  return axiosSession.post("/api/dicts/", data);
};

const getDictionary = (id: any) => {
  return axiosSession.get(`/api/dicts/${id}/`);
};

const editDictionary = (data: any, id: any) => {
  return axiosSession.put(`/api/dicts/${id}/`, data);
};

const deleteDictionary = (id: any) => {
  return axiosSession.delete(`/api/dicts/${id}/`);
};

const getDictionaryEntries = (params?: DictionaryEntriesParams) => {
  return axiosSession.get("/api/dicts/entry/", { params });
};

const getDictionaryEntriesSelect = (params?: DictionaryEntriesParams) => {
  return axiosSession.get("/api/dicts/entry/select/", { params });
};

const getDictionaryEntry = (id: any) => {
  return axiosSession.get(`/api/dicts/entry/${id}/`);
};

const createDictionaryEntry = (data: any) => {
  return axiosSession.post(`/api/dicts/entry/`, data);
};

const editDictionaryEntry = (data: any, id: any) => {
  return axiosSession.put(`/api/dicts/entry/${id}/`, data);
};

const getDictionaryStatuses = () => {
  return axiosSession.get("/api/dicts/status/");
};

const getDictionaryEntryStatuses = () => {
  return axiosSession.get("/api/dicts/entry/status/");
};

const deleteDictionaryEntry = (id: any) => {
  return axiosSession.delete(`/api/dicts/entry/${id}/`);
};

const getDictionaryEntryTypes = () => {
  return axiosSession.get("/api/dicts/entry/types/");
};

const getDictionaryEntriesByKeyword = (
  keyword: string,
  params?: DictionaryEntriesParams,
) => {
  return axiosSession.get(`${dicts}${keyword}/`, { params });
};

const getDeploymentCategory = (params?: Params) => {
  return axiosSession.get(`${dicts}proposal_category/`, { params });
};

const getProposalsStatuses = (params?: Params) => {
  return axiosSession.get(`${dicts}proposal_status/`, { params });
};

const getFieldsOfStudyList = (params?: DictionaryEntriesParams) => {
  return axiosSession.get(`${dicts}proposal_field_of_study/`, { params });
};

const getDepartmentsList = (params?: DictionaryEntriesParams) => {
  return axiosSession.get(`${dicts}proposal_department/`, { params });
};

const getYearsListForProposal = (params?: DictionaryEntriesParams) => {
  return axiosSession.get(`${dicts}proposal_year_of_study/`, { params });
};

export {
  createDictionary,
  createDictionaryEntry,
  editDictionary,
  editDictionaryEntry,
  deleteDictionary,
  deleteDictionaryEntry,
  getDictionary,
  getDictionaryEntries,
  getDictionaryEntriesByKeyword,
  getDictionaryEntry,
  getDictionaryEntryStatuses,
  getDictionaryEntryTypes,
  getDictionaryStatuses,
  getDictionariesList,
  getDictionariesSelect,
  getDictionaryEntriesSelect,
  getDeploymentCategory,
  getProposalsStatuses,
  getFieldsOfStudyList,
  getDepartmentsList,
  getYearsListForProposal,
};
