<template>
  <Breadcrumbs
    :current="`${$tc('issues.issues', 1)} ${signature}`"
    :path="[{ path: { name: 'Issues' }, title: $t('modules.issues') }]"
    iconComponent="FolderOutlined"
  >
    <EODButton
      v-if="optionsPerms && status == ISSUE_STATUS.IN_PROGRESS"
      :name="$t('issues.close')"
      @click="handleCloseIssue"
    />

    <EODButton
      v-if="optionsPerms && status == ISSUE_STATUS.IN_PROGRESS"
      :name="$t('issues.generate')"
    />

    <EODButton
      v-if="optionsPerms"
      :name="$t('issues.share')"
      @click="handleShareIssue"
    />

    <EODButton
      v-if="optionsPerms && status == ISSUE_STATUS.IN_PROGRESS"
      type="primary"
      :name="$t('issues.edit')"
      icon="EditOutlined"
      @click="handleEdit"
    />
    <EODButton
      v-if="optionsPerms && status == ISSUE_STATUS.CLOSED"
      :name="$t('issues.reopen')"
      @click="handleReopen"
    />
  </Breadcrumbs>

  <Modal
    v-model:visible="shareModalVisible"
    :title="$t('issues.shareDocument')"
    :okText="$t('issues.shareDocument')"
    :cancelText="$t('app.cancel')"
    :closable="false"
    :okButtonProps="{
      htmlType: 'submit',
      form: 'perms-form',
    }"
    @cancel="closeShareModal"
  >
    <ShareForm
      v-model:perms="documentSharePerms"
      :defaultValues="permsDefaultValues"
      :errors="documentSharePermsErrors"
      :submitFunction="submitPerms"
    />
  </Modal>

  <IssueForm
    v-model:visible="showIssueForm"
    :issueID="id"
    :handleRefresh="fetchIssue"
  />

  <IssueAddDocument
    v-model:visible="showAddDocument"
    :issueID="id"
    :signature="signature"
    :selectStoreID="selectStoreID"
  />

  <IssueShare v-model:visible="showShareForm" :issueID="id" :owner="owner" />

  <IssueClose
    v-model:visible="showCloseIssue"
    :issueID="id"
    :handleRefresh="fetchIssue"
  />

  <Tabs @change="$scrollToTop">
    <TabPane key="1" :tab="$t('issues.infoTab')" class="mt-1">
      <Preview
        :oid="id"
        :data="previewData"
        :title="$t('issues.infoTab')"
        :noPreviewMsg="$t('issues.noPreview')"
      />

      <Section :title="$t('modules.documents')">
        <BaseTable
          :columns="documentTableColumns"
          :service="(params) => getIssueDocuments(id, params)"
          customStore="IssueDocumentsTable"
        >
          <template v-slot:button>
            <EODButton
              v-if="
                status == ISSUE_STATUS.IN_PROGRESS && hasAddDocumentPermission
              "
              type="primary"
              :name="$t('issues.addDocument')"
              @click="handleAddDocument"
              icon="PlusOutlined"
            />
          </template>
          <template v-slot:document_date="{ record }">
            {{ new Date(record.document_date).toLocaleDateString() }}
          </template>
          <template v-slot:actions="{ record }">
            <TableActions
              :record="record"
              :previewRoute="{
                name: 'DocumentsPreview',
                params: { id: record.id },
              }"
              :showEdit="false"
              :showRemove="false"
            >
              <button
                v-if="record?.actions?.removable"
                class="action"
                @click="openRemoveDocumentModal(record.id)"
                :title="$t('issues.removeDocument')"
              >
                <DeleteOutlined />
              </button>
              <button
                v-if="record?.actions?.start_deployment"
                class="action"
                @click="openStartDeploymentModal(record.id, record.name)"
                :title="$t('deployments.start')"
              >
                <PlayCircleOutlined />
              </button>
              <button
                v-if="record?.actions?.editable"
                class="action"
                @click="handleShareDocument(record.id)"
                :title="$t('issues.shareDocument')"
              >
                <ShareAltOutlined />
              </button>
            </TableActions>
          </template>
        </BaseTable>
      </Section>
    </TabPane>

    <TabPane key="2" :tab="$t('issues.shareTab')" class="mt-1">
      <Section :title="$t('issues.shareTab')">
        <BaseTable
          :columns="sharedTableColumns"
          :service="(params) => getSharedList(id, params)"
          customStore="issueShared"
        >
          <template v-slot:unit="{ record }">
            {{ record.unit || "-" }}
          </template>
          <template v-slot:actions="{ record }">
            <button
              class="action"
              v-if="record?.actions?.unshare"
              @click="openUnshareIssueModal(record.user)"
              :title="$t('issues.unshare')"
            >
              <DisconnectOutlined />
            </button>
          </template>
        </BaseTable>
      </Section>
    </TabPane>
    <TabPane key="3" :tab="$t('issues.historyTab')" class="mt-1">
      <IssueHistory />
    </TabPane>
  </Tabs>
  <StartDeploymentForDocModal
    v-model:visible="showStartDeploymentModal"
    :documentName="documentName"
    selectId="deploymentsToStartIssuePreview"
    :documentsId="[selectedDocumentId]"
  />
  <RemoveDocument
    v-model:visible="showRemoveDocumentModal"
    v-model:commentValue="comment"
    v-model:documentID="selectedDocumentId"
    :issueNumber="signature"
    :issueId="id"
    :selectStoreID="selectStoreID"
  />
  <ConfirmModal
    v-model:visible="showUnshareIssueModal"
    :title="$t('app.confirmation')"
    :okText="$t('issues.unshare')"
    :onSubmit="handleUnshareIssue"
    :onCancel="closeUnshareIssueModal"
  >
    {{ `${$t("issues.doYouWantToRemoveShare")}: ${signature}?` }}
  </ConfirmModal>

  <ConfirmModal
    v-model:visible="showReopenIssueModal"
    :title="$t('issues.reopen')"
    :okText="$t('issues.reopen')"
    :onCancel="closeReopenIssueModal"
    :onSubmit="submitReopenIssue"
  >
    <form id="issue-close-form" class="form">
      <EODInput
        id="lastComment"
        type="textarea"
        v-model:value="lastComment"
        :label="$t('app.comment')"
        :props="{ maxlength: 500 }"
        required
        :errors="errors.last_comment"
      />
    </form>
  </ConfirmModal>
</template>

<script>
import { BaseTable, TableActions } from "@/components/table";
import { Breadcrumbs, EODButton, Section } from "@/components/ui";
import {
  DeleteOutlined,
  DisconnectOutlined,
  PlayCircleOutlined,
  ShareAltOutlined,
} from "@ant-design/icons-vue";
import { Preview, ShareForm } from "@/components/common";
import {
  closeIssue,
  getIssue,
  getIssueDocuments,
  getSharedList,
  issueReopen,
  unshareIssue,
} from "@/services/issues";
import { editDocumentPerms, getDocumentPerms } from "@/services/documents";

import { ConfirmModal } from "@/components/modals";
import { EODInput } from "@/components/inputs";
import { ISSUE_STATUS } from "@/consts/statuses";
import IssueAddDocument from "./IssueAddDocument";
import IssueClose from "./IssueClose";
import IssueForm from "./IssueForm";
import IssueHistory from "./IssuesHistory";
import IssueShare from "./IssueShare";
import { Modal } from "ant-design-vue";
import PERMISSIONS from "@/consts/permissions";
import RemoveDocument from "@/views/Issues/RemoveDocument";
import StartDeploymentForDocModal from "@/views/Documents/StartDeploymentForDocModal";
import { Tabs } from "ant-design-vue";
import dynamicTable from "@/store/modules/dynamicTable";

export default {
  name: "IssuePreview",
  data() {
    return {
      showStartDeploymentModal: false,
      showRemoveDocumentModal: false,
      showUnshareIssueModal: false,
      selectedUserID: null,
      ISSUE_STATUS,
      id: +this.$route.params?.id,
      isOwner: false,
      optionsPerms: false,
      owner: null,
      previewData: [],
      share: {},
      errors: {},
      comment: "",
      lastComment: "",
      data: {},
      documentName: "",
      status: false,
      signature: "",
      inArchive: null,
      showAddDocument: false,
      showIssueForm: false,
      showShareForm: false,
      showCloseIssue: false,
      showReopenIssueModal: false,
      shareModalVisible: false,
      selectedDocumentId: null,
      documentSharePerms: {
        users_can_change: [],
        groups_can_change: [],
        users_can_view: [],
        groups_can_view: [],
      },
      permsDefaultValues: {},
      documentSharePermsErrors: {},
      selectStoreID: "issues-add-document",
      sharedTableColumns: [
        {
          title: this.$t("permissions.users.fullName"),
          dataIndex: "full_name",
          sorter: true,
        },
        {
          title: this.$t("issues.unit"),
          slots: { customRender: "unit" },
          dataIndex: "unit",
          sorter: true,
        },
        {
          title: this.$t("app.date"),
          dataIndex: "sharing_date",
          sorter: true,
        },
        {
          title: this.$t("app.actions"),
          dataIndex: "actions",
          slots: { customRender: "actions" },
          fixed: "right",
          width: "70",
        },
      ],
      documentTableColumns: [
        {
          title: this.$t("app.name"),
          dataIndex: "name",
          sorter: true,
        },
        {
          title: this.$t("documents.date"),
          dataIndex: "document_date",
          slots: { customRender: "document_date" },
          sorter: true,
        },
        {
          title: this.$t("app.actions"),
          dataIndex: "actions",
          slots: { customRender: "actions" },
          fixed: "right",
          width: "150",
        },
      ],
    };
  },
  components: {
    ConfirmModal,
    RemoveDocument,
    Breadcrumbs,
    BaseTable,
    DeleteOutlined,
    DisconnectOutlined,
    TableActions,
    EODButton,
    EODInput,
    IssueAddDocument,
    IssueClose,
    IssueForm,
    IssueHistory,
    IssueShare,
    Modal,
    Preview,
    PlayCircleOutlined,
    Section,
    ShareForm,
    ShareAltOutlined,
    StartDeploymentForDocModal,
    Tabs,
    TabPane: Tabs.TabPane,
  },
  computed: {
    user() {
      return this.$store.getters["user/getUser"];
    },
    hasAddDocumentPermission() {
      return this.$permissions.includes(PERMISSIONS.DOCUMENTS_ADD);
    },
  },
  created() {
    if (this.id) {
      this.fetchIssue();

      if (!this.$store.hasModule("issueShared")) {
        this.$store.registerModule("issueShared", dynamicTable);
      }
      if (!this.$store.hasModule("IssueDocumentsTable")) {
        this.$store.registerModule("IssueDocumentsTable", dynamicTable);
      }
    }
  },
  methods: {
    getIssue,
    getIssueDocuments,
    getSharedList,
    closeIssue,
    issueReopen,
    fetchIssue() {
      getIssue(this.id).then(({ data }) => {
        this.isOwner = this.user.id === data.owner;
        this.owner = { id: data.owner };
        this.optionsPerms = data.options_perms;
        this.status = data.status;
        this.signature = data.signature;
        this.inArchive = data.in_archive;
        this.previewData = [
          {
            title: this.$t("app.status"),
            value: data.status_display,
          },
          {
            title: this.$t("issues.number"),
            value: data.signature,
          },
          {
            title: this.$t("issues.creationDate"),
            value: data.initiation_date
              ? new Date(data.initiation_date).toLocaleDateString()
              : "-",
          },
          {
            title: this.$t("issues.endDate"),
            value: data.close_date
              ? new Date(data.close_date).toLocaleDateString()
              : "-",
          },
          {
            title: this.$t("issues.content"),
            value: data.content,
          },
          {
            title: this.$t("issues.sender"),
            value: data.sender,
          },
          {
            title: this.$t("issues.senderSignature"),
            value: data.sender_signature,
          },
          {
            title: this.$t("issues.senderDate"),
            value: data.sender_date
              ? new Date(data.sender_date).toLocaleDateString()
              : "-",
          },
          {
            title: this.$t("documents.JRWANumber"),
            value: data.jrwa_display,
          },
          {
            title: this.$t("issues.creator"),
            value: data.created_by_display,
          },
          {
            title: this.$t("issues.handler"),
            value: data.owner_display,
          },
          {
            title: this.$t("issues.unit"),
            value: data.unit_display,
          },
          {
            title: this.$t("issues.realization"),
            value: data.realization,
          },
        ];
      });
    },
    handleEdit() {
      this.showIssueForm = true;
    },
    handleReopen() {
      this.showReopenIssueModal = true;
    },
    handleAddDocument() {
      this.showAddDocument = true;
    },
    closeShareModal() {
      this.shareModalVisible = false;
      this.selectedDocumentId = null;
      this.documentSharePerms = {
        users_can_change: [],
        groups_can_change: [],
        users_can_view: [],
        groups_can_view: [],
      };
      this.permsDefaultValues = {};
    },
    handleShareDocument(documentID) {
      getDocumentPerms(documentID).then(({ data }) => {
        this.selectedDocumentId = documentID;
        this.permsDefaultValues = data;
        this.documentSharePerms = Object.entries(data).reduce(
          (acc, [key, val]) => ({
            ...acc,
            [key]: val.map((item) => item.id),
          }),
          {},
        );
        this.shareModalVisible = true;
      });
    },
    submitPerms() {
      editDocumentPerms(this.documentSharePerms, this.selectedDocumentId)
        .then(({ data: msg }) => {
          this.$message.success(msg);
        })
        .catch((err) => {
          this.documentSharePermsErrors = err.response.data;
        })
        .finally(() => {
          this.closeShareModal();
        });
    },
    submitReopenIssue() {
      issueReopen({ last_comment: this.lastComment }, this.id)
        .then(() => {
          this.resetData();
          this.fetchIssue();
          this.$message.success(this.$t("app.success"));

          this.closeReopenIssueModal();

          if (this.$store.hasModule("issuePreview")) {
            this.$store.dispatch("issuePreview/refreshTable");
          }
        })
        .catch((err) => {
          this.errors = err.response.data;
        });
    },
    resetData() {
      this.lastComment = null;
      this.errors = {};
    },
    handleCloseIssue() {
      this.showCloseIssue = true;
    },
    handleShareIssue() {
      this.showShareForm = true;
    },
    openUnshareIssueModal(userID) {
      this.selectedUserID = userID;
      this.showUnshareIssueModal = true;
    },
    closeUnshareIssueModal() {
      this.showUnshareIssueModal = false;
      this.selectedUserID = null;
    },
    closeReopenIssueModal() {
      this.showReopenIssueModal = false;
    },
    handleUnshareIssue() {
      unshareIssue(this.id, this.selectedUserID).then(() => {
        this.closeUnshareIssueModal();
        this.$message.success(this.$t("app.success"));

        for (const store of ["issueShared", "issueHistory"]) {
          if (this.$store.hasModule(store)) {
            this.$store.dispatch(`${store}/refreshTable`);
          }
        }
      });
    },
    openRemoveDocumentModal(id) {
      this.selectedDocumentId = id;
      this.showRemoveDocumentModal = true;
    },
    openStartDeploymentModal(id, name) {
      this.selectedDocumentId = id;
      this.documentName = name;
      this.showStartDeploymentModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.breadcrumbs {
  .button-row {
    button {
      margin: 0 0.25rem;
    }
  }
}
.actions {
  justify-content: flex-end;
  flex-direction: row-reverse;
}
</style>
