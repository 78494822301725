import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "form-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EODRadio = _resolveComponent("EODRadio")
  const _component_EODSelect = _resolveComponent("EODSelect")
  const _component_Modal = _resolveComponent("Modal")
  const _component_EODButton = _resolveComponent("EODButton")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_Modal, {
      visible: _ctx.visible,
      "onUpdate:visible": _cache[8] || (_cache[8] = ($event: any) => (_ctx.visible = $event)),
      title: _ctx.$t('ldap.addLDAPUser'),
      okButtonProps: { htmlType: 'submit', form: 'ldap-user-form' }
    }, {
      default: _withCtx(() => [
        _createVNode("div", _hoisted_1, [
          _createVNode("form", {
            id: "ldap-user-form",
            onSubmit: _cache[7] || (_cache[7] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"])),
            class: "form"
          }, [
            _createVNode(_component_EODRadio, {
              id: "ou",
              value: _ctx.ou,
              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (_ctx.ou = $event)),
              required: "",
              label: _ctx.$t('permissions.users.source'),
              options: _ctx.ouOptions,
              onChange: _ctx.onOUChange
            }, null, 8, ["value", "label", "options", "onChange"]),
            _createVNode(_component_EODSelect, {
              id: "ldap-user",
              value: _ctx.data.username,
              "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => (_ctx.data.username = $event)),
              label: _ctx.$tc('app.user', 1),
              required: "",
              refetch: _ctx.refetchUsers,
              "onUpdate:refetch": _cache[3] || (_cache[3] = ($event: any) => (_ctx.refetchUsers = $event)),
              disableInitialFetch: true,
              service: _ctx.searchUsers,
              errors: _ctx.errors.username
            }, null, 8, ["value", "label", "refetch", "service", "errors"]),
            _createVNode(_component_EODSelect, {
              id: "roles",
              value: _ctx.data.roles,
              "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => (_ctx.data.roles = $event)),
              mode: "multiple",
              label: _ctx.$tc('permissions.roles.roles', 0),
              service: _ctx.fetchRoles,
              errors: _ctx.errors.roles
            }, null, 8, ["value", "label", "service", "errors"]),
            _createVNode(_component_EODSelect, {
              id: "groups",
              value: _ctx.data.groups,
              "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => (_ctx.data.groups = $event)),
              mode: "multiple",
              label: _ctx.$t('modules.teams'),
              service: _ctx.fetchGroups,
              errors: _ctx.errors.groups
            }, null, 8, ["value", "label", "service", "errors"]),
            (_ctx.APPS.UNITS)
              ? (_openBlock(), _createBlock(_component_EODSelect, {
                  key: 0,
                  id: "units",
                  value: _ctx.data.units,
                  "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => (_ctx.data.units = $event)),
                  label: _ctx.$t('permissions.users.unit', 0),
                  mode: "multiple",
                  required: "",
                  service: _ctx.fetchUnits,
                  errors: _ctx.errors.unit
                }, null, 8, ["value", "label", "service", "errors"]))
              : _createCommentVNode("", true)
          ], 32)
        ])
      ]),
      _: 1
    }, 8, ["visible", "title"]),
    _createVNode(_component_EODButton, {
      name: _ctx.$t('ldap.addLDAPUser'),
      type: "primary",
      onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.visible = true))
    }, null, 8, ["name"])
  ], 64))
}