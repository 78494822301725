import Ajv from "ajv";
import getDate from "@/helpers/getDate";

/**
 * CustomAjv extends Ajv and adds some new keywords for JSON Schema.
 * They are used to compare dates, dates range and check string inclusion or lexical order.
 */
class CustomAjv extends Ajv {
  constructor(options) {
    super({ multipleOfPrecision: 3, ...options });

    const basicOperations = {
      0: (a, b) => a < b,
      1: (a, b) => a <= b,
      2: (a, b) => a > b,
      3: (a, b) => a >= b,
      4: (a, b) => a === b,
      5: (a, b) => a !== b,
      6: (a, b) => a?.includes(b),
      7: (a, b) => !a?.includes(b),
    };

    const rangeOperations = {
      rangeLe: (data, schema) => getDate(data[0]) < getDate(schema),
      rangeGe: (data, schema) => getDate(data[1]) > getDate(schema),
      rangeInc: (data, schema) =>
        getDate(data[0]) <= getDate(schema) &&
        getDate(data[1]) >= getDate(schema),
      rangeDec: (data, schema) =>
        getDate(data[0]) > getDate(schema) &&
        getDate(data[1]) < getDate(schema),
    };

    for (const [index, keyword] of [
      "dateLe",
      "dateLeq",
      "dateGe",
      "dateGeq",
      "dateEq",
      "dateNeq",
    ].entries()) {
      this.addKeyword(keyword, {
        type: "string",
        compile: function (schema) {
          return function (data) {
            return basicOperations[index](getDate(data), getDate(schema));
          };
        },
        errors: false,
        metaSchema: {
          type: "string",
          format: "date-time",
        },
      });
    }

    for (const [index, keyword] of [
      [0, "stringLe"],
      [1, "stringLeq"],
      [2, "stringGe"],
      [3, "stringGeq"],
      [6, "stringInc"],
      [7, "stringDec"],
    ]) {
      this.addKeyword(keyword, {
        type: "string",
        compile: function (schema) {
          return function (data) {
            return basicOperations[index](data, schema);
          };
        },
        errors: false,
        metaSchema: {
          type: "string",
        },
      });
    }

    for (const keyword of ["rangeLe", "rangeGe", "rangeInc", "rangeDec"]) {
      this.addKeyword(keyword, {
        type: "array",
        compile: function (schema) {
          return function (data) {
            return data.length === 2
              ? rangeOperations[keyword](data, schema)
              : false;
          };
        },
        errors: false,
        metaSchema: {
          type: "string",
          format: "date-time",
        },
      });
    }
  }
}

export default CustomAjv;
