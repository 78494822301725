const modal = {
  state: {
    isModalOpen: false,
    modalMessage: "",
    modalTitle: "",
    okText: "",
    cancelText: "",
    modalOk: () => {},
  },
  mutations: {
    OPEN_MODAL(state) {
      state.isModalOpen = true;
    },
    CLOSE_MODAL(state) {
      state.isModalOpen = false;
    },
    SET_MODAL(state, payload) {
      state.modalMessage = payload.message;
      state.modalTitle = payload.title;
      state.modalOk = payload.modalOk;
      state.okText = payload.okText;
      state.cancelText = payload.cancelText;
    },
  },
  actions: {
    closeModal({ commit }) {
      commit("CLOSE_MODAL");
    },
    setModal({ commit }, payload) {
      commit("SET_MODAL", payload);
      commit("OPEN_MODAL");
    },
  },
};

export default modal;
