<template>
  <ConfirmModal
    v-model:visible="cVisible"
    :title="$t('documents.blockActionTitle')"
    :onSubmit="confirmBlockActionModal"
    :onCancel="closeBlockActionModal"
  >
    <p>{{ allowInfo }}</p>
    <ul>
      <li v-for="item in allowItems" v-bind:key="item.id">
        {{ item.name }}
      </li>
    </ul>
    <p>{{ blockedInfo }}</p>
    <ul>
      <li v-for="item in blockedItems" v-bind:key="item.id">
        {{ item.name }}
      </li>
    </ul>
  </ConfirmModal>
</template>
<script>
import { ConfirmModal } from "@/components/modals";

export default {
  name: "BlockOperationModal",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    openOperationModal: Function,
    allowItems: Array,
    blockedItems: Array,
    allowInfo: String,
    blockedInfo: String,
  },
  components: {
    ConfirmModal,
  },
  computed: {
    cVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
  },
  methods: {
    closeBlockActionModal() {
      this.cVisible = false;
      this.$emit("update:allowItems", []);
      this.$emit("update:blockedItems", []);
    },
    confirmBlockActionModal() {
      this.cVisible = false;
      this.$emit("update:blockedItems", []);
      this.openOperationModal();
    },
  },
};
</script>
