<template>
  <Breadcrumbs
    :current="$t('modules.registry')"
    iconComponent="CarryOutOutlined"
  >
    <EODButtonLink
      v-if="hasPermissionToAdd"
      :to="{
        name: 'RegistryCreate',
      }"
      :title="$t('app.add_', [$t('registry.registry')])"
    />
  </Breadcrumbs>

  <section class="section">
    <BaseTable :columns="columns" :service="getRegistryList">
      <template #numeration="{ record }">
        {{ record.text_format }}
      </template>
      <template #actions="{ record }">
        <TableActions
          :record="record"
          :previewRoute="{
            name: 'RegistryPreview',
            params: { id: record.id },
          }"
          :editRoute="{
            name: 'RegistryEdit',
            params: { id: record.id },
          }"
          :deleteMessage="`${$t('app.doYouWantToDelete')} ${$t(
            'registry.registry',
          )} &quot;${record.name}&quot;?`"
          :modalTitle="$t('app.delete')"
          :deleteService="deleteRegistry"
        />
      </template>
    </BaseTable>
  </section>
</template>

<script>
import { BaseTable, TableActions } from "@/components/table";
import { Breadcrumbs, EODButtonLink } from "@/components/ui";
import {
  createRegistry,
  deleteRegistry,
  getRegistryList,
} from "@/services/registers";

import PERMISSIONS from "@/consts/permissions";

export default {
  name: "Registers",
  data() {
    return {
      columns: [
        {
          title: this.$t("registry.name"),
          dataIndex: "name",
          sorter: true,
        },
        {
          title: this.$t("registry.format"),
          dataIndex: "counter__text_format",
          slots: { customRender: "numeration" },
          sorter: true,
        },
        {
          title: this.$t("app.actions"),
          dataIndex: "actions",
          slots: { customRender: "actions" },
          fixed: "right",
          width: "120",
        },
      ],
    };
  },
  components: {
    BaseTable,
    Breadcrumbs,
    EODButtonLink,
    TableActions,
  },
  computed: {
    hasPermissionToAdd() {
      return this.$permissions.includes(PERMISSIONS.REGISTRY_MANAGE);
    },
  },
  methods: {
    createRegistry,
    deleteRegistry,
    getRegistryList,
  },
};
</script>
