import SYSTEM_DICT from "@/config/systemDict";
import apiClient from "@/config/apiClient";

const createDocument = (data) => {
  return apiClient.post("/api/documents/", data);
};

const createDocumentAndStartDeployment = (data, id) => {
  return apiClient.post(
    `/api/documents/create-and-run-deployment/${id}/`,
    data,
  );
};

const startDeploymentFromDashboard = (id) => {
  return apiClient.post(
    `/api/documents/create-and-run-deployment/${id}/?auto_document=True`,
  );
};

const createDocumentAttachment = (data) => {
  return apiClient.post("/api/documents/attachments/", data);
};

const deleteDocument = (id) => {
  return deleteMultipleDocuments([id]);
};

const deleteMultipleDocuments = (ids) => {
  return apiClient.post(`/api/documents/bulk-delete/`, { ids });
};

const editDocument = (data, id) => {
  return apiClient.put(`/api/documents/${id}/`, data);
};

const editDocumentPerms = (data, id) => {
  return apiClient.post(`/api/documents/${id}/assignee-perm/`, data);
};

const getDocument = (id) => {
  return apiClient.get(`/api/documents/${id}/`);
};

const getDocumentsList = (params) => {
  return apiClient.get("/api/documents/", { params });
};

const getDocumentsSelectList = (params) => {
  return apiClient.get("/api/documents/select/", { params });
};

const getDocumentTypes = (params) => {
  return apiClient.get(
    `/api/dicts/entry/keyword/${SYSTEM_DICT.DOCUMENT_TYPES}/`,
    { params },
  );
};

const getDocumentPerms = (id) => {
  return apiClient.get(`/api/documents/${id}/assignee-perm/`);
};

const convertDocument = (data) => {
  return apiClient.post("/api/documents/attachments/convert/", data);
};

const getConvertedDocuments = (parentId) => {
  return apiClient.get(`/api/documents/attachments/converted/${parentId}/`);
};

const edward = () => {
  return apiClient.get("/api/documents/scan/");
};

const copyDocument = (id) => {
  return apiClient.post(`/api/documents/${id}/copy/`);
};

const getRelatedDocument = (params, id) => {
  return apiClient.get(`/api/documents/${id}/related-documents/`, { params });
};

const downloadDocumentURL = (id) => {
  const accessToken = localStorage.getItem("access_token");
  return `/api/documents/downloads/${id}/?token=${accessToken}`;
};

export {
  copyDocument,
  createDocument,
  createDocumentAndStartDeployment,
  createDocumentAttachment,
  deleteDocument,
  deleteMultipleDocuments,
  downloadDocumentURL,
  editDocument,
  editDocumentPerms,
  edward,
  getDocument,
  getDocumentsList,
  getDocumentTypes,
  getDocumentPerms,
  convertDocument,
  getConvertedDocuments,
  getDocumentsSelectList,
  getRelatedDocument,
  startDeploymentFromDashboard,
};
