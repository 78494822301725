<template>
  <Breadcrumbs
    :current="$t('modules.deployments')"
    iconComponent="SyncOutlined"
  >
    <EODButton
      v-if="hasExportPermission"
      :name="$t('deployments.import')"
      @click="importDeployment"
    />
    <EODButtonLink
      v-if="hasPermissionToAdd"
      :to="{
        name: 'DeploymentCreate',
      }"
      :title="$t('deployments.add')"
    />
  </Breadcrumbs>

  <section class="section">
    <Modal
      :title="$t('app.confirmation')"
      v-model:visible="startProcessModal"
      @ok="handleStartProcess"
      :okButtonProps="{ loading }"
    >
      <p>{{ $t("deployments.doYouWantToStart") }}</p>
    </Modal>

    <BaseTable
      :columns="columns"
      :service="getDeploymentsList"
      :rowSelection="hasExportPermission ? rowSelection : null"
    >
      <template #button>
        <EODButton
          v-if="hasExportPermission"
          :disabled="rowSelection.selectedRowKeys.length === 0"
          :name="$tc('deployments.export', 2)"
          @click="exportDeployment(rowSelection.selectedRowKeys)"
        />
      </template>
      <template #name="{ record }">
        <CheckCircleOutlined
          v-if="record.is_configured"
          class="color--success"
          :title="$t('deployments.configured')"
        />
        <CloseCircleOutlined
          v-else
          class="color--grey"
          :title="$t('deployments.notConfigured')"
        />
        &nbsp;
        {{ record.name }}
      </template>
      <template v-slot:status="{ record }">
        <StatusDot :status="record.status" />
        {{ record.status_display }}
      </template>
      <template v-slot:actions="{ record }">
        <TableActions
          :record="{
            id: record.id,
            name: record.name,
            actions: record.actions,
          }"
          :previewRoute="{
            name: 'DeploymentPreview',
            params: { id: record.id },
          }"
          :editRoute="{ name: 'DeploymentEdit', params: { id: record.id } }"
          :deleteMessage="`${$t('app.doYouWantToDelete')} ${$tc(
            'deployments.deployment',
            2,
          )} &quot;${record.name}&quot;?`"
          :modalTitle="$t('deployments.delete')"
          :deleteService="deleteDeployment"
        >
          <button
            v-if="record?.actions?.start_deployment"
            class="action"
            @click="handleConfirm(record.id)"
            :title="$t('deployments.start')"
          >
            <PlayCircleOutlined />
          </button>
        </TableActions>
      </template>
    </BaseTable>
  </section>
</template>

<script>
import { BaseTable, StatusDot, TableActions } from "@/components/table";
import { Breadcrumbs, EODButton, EODButtonLink } from "@/components/ui";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons-vue";
import { computed, inject, ref } from "vue";
import {
  deleteDeployment,
  getDeploymentsList,
  importDeployments,
  startDeploymentWithProposal,
} from "@/services/deployments";

import { Modal } from "ant-design-vue";
import PERMISSIONS from "@/consts/permissions";
import { PlayCircleOutlined } from "@ant-design/icons-vue";
import useExportDeployments from "@/composables/useExportDeployments";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";

export default {
  name: "Deployments",
  setup() {
    const { t } = useI18n();
    const $message = inject("$message");
    const loading = ref(false);
    const store = useStore();

    const rowSelection = ref({
      selectedRowKeys: [],
      onChange(selectedRowKeys) {
        rowSelection.value.selectedRowKeys = selectedRowKeys;
      },
    });

    const columns = ref([
      {
        title: t("app.name"),
        dataIndex: "name",
        sorter: true,
        slots: { customRender: "name" },
      },
      {
        title: t("app.version"),
        dataIndex: "version",
        sorter: true,
      },
      {
        title: t("app.key"),
        dataIndex: "camunda_key",
        sorter: true,
      },
      {
        title: t("app.status"),
        dataIndex: "status",
        sorter: true,
        slots: { customRender: "status" },
      },
      //TODO: refactor backend to return proper date
      {
        title: t("app.createdDate"),
        dataIndex: "created",
        sorter: true,
      },
      {
        title: t("app.createdBy"),
        dataIndex: "created_by",
      },
      {
        title: t("app.actions"),
        dataIndex: "actions",
        slots: { customRender: "actions" },
        fixed: "right",
        width: "150",
      },
    ]);

    const startProcessModal = ref(false);
    const processId = ref(null);

    const handleStartProcess = () => {
      loading.value = true;

      // TODO: revert it to original startDeployment when it's not longer needed
      startDeploymentWithProposal(processId.value)
        .then(() => {
          $message.success(t("app.success"));
        })
        .finally(() => {
          startProcessModal.value = false;
          processId.value = null;
          loading.value = false;
        });
    };

    const handleConfirm = (id) => {
      processId.value = id;
      startProcessModal.value = true;
    };

    const importDeployment = () => {
      importDeployments().then(({ data }) => {
        $message.success(data);
      });
    };

    const hasPermissionToAdd = computed(() =>
      store.getters["user/getUser"].permissions.includes(
        PERMISSIONS.DEPLOYMENTS_ADD,
      ),
    );

    return {
      ...useExportDeployments(),
      columns,
      deleteDeployment,
      getDeploymentsList,
      handleConfirm,
      handleStartProcess,
      hasPermissionToAdd,
      importDeployment,
      processId,
      rowSelection,
      startProcessModal,
      loading,
    };
  },
  components: {
    BaseTable,
    Breadcrumbs,
    CheckCircleOutlined,
    CloseCircleOutlined,
    EODButton,
    EODButtonLink,
    StatusDot,
    TableActions,
    PlayCircleOutlined,
    Modal,
  },
};
</script>
