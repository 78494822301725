import APPS from "@/consts/apps";
import Deployments from "@/views/Deployments/Deployments.vue";
import DeploymentsForm from "@/views/Deployments/DeploymentsForm.vue";
import DeploymentsPreview from "@/views/Deployments/DeploymentsPreview.vue";
import PERMISSIONS from "@/consts/permissions";
import ProcessPreview from "@/views/Processes/ProcessPreview.vue";
import Processes from "@/views/Processes/Processes.vue";
import Proposals from "@/views/Proposals/Proposals.vue";
import { RouteLocation } from "vue-router";
import i18n from "@/i18n";

const t = i18n.global.t;

const deployments = [
  {
    path: "/deployments/",
    name: "Deployments",
    component: Deployments,
    meta: {
      title: `${t("meta.app")} - ${t("deployments.meta.list")}`,
      requiresAuth: true,
      permission: PERMISSIONS.DEPLOYMENTS,
      app: APPS.WORKFLOWS,
    },
  },
  {
    path: "/deployments/:id/preview/",
    name: "DeploymentPreview",
    component: DeploymentsPreview,
    meta: {
      title: `${t("meta.app")} - ${t("deployments.preview")}`,
      requiresAuth: true,
      permission: PERMISSIONS.DEPLOYMENTS,
      app: APPS.WORKFLOWS,
    },
    props: (route: RouteLocation) => ({
      id: +route.params.id,
    }),
  },
  {
    path: "/deployments/create/",
    name: "DeploymentCreate",
    component: DeploymentsForm,
    meta: {
      title: `${t("meta.app")} - ${t("deployments.meta.add")}`,
      requiresAuth: true,
      permission: PERMISSIONS.DEPLOYMENTS_ADD,
      app: APPS.WORKFLOWS,
    },
  },
  {
    path: "/deployments/:id/edit/",
    name: "DeploymentEdit",
    component: DeploymentsForm,
    meta: {
      title: `${t("meta.app")} - ${t("deployments.meta.edit")}`,
      requiresAuth: true,
      permission: PERMISSIONS.DEPLOYMENTS_EDIT,
      app: APPS.WORKFLOWS,
    },
    props: (route: RouteLocation) => ({
      id: +route.params.id,
    }),
  },
  {
    path: "/processes/",
    name: "Processes",
    component: Processes,
    meta: {
      title: `${t("meta.app")} - ${t("processes.meta.list")}`,
      requiresAuth: true,
      permission: [PERMISSIONS.TASKS_OWN, PERMISSIONS.TASKS_ALL],
      app: APPS.WORKFLOWS,
    },
  },
  {
    path: "/processes/:id/preview/",
    name: "ProcessPreview",
    component: ProcessPreview,
    meta: {
      title: `${t("meta.app")} - ${t("processes.meta.preview")}`,
      requiresAuth: true,
      permission: [PERMISSIONS.TASKS_OWN, PERMISSIONS.TASKS_ALL],
      app: APPS.WORKFLOWS,
    },
    props: true,
  },
  {
    path: "/proposals/",
    name: "Proposals",
    component: Proposals,
    meta: {
      title: `${t("meta.app")} - ${t("proposal.proposals")}`,
      requiresAuth: true,
      permission: [PERMISSIONS.TASKS_OWN, PERMISSIONS.TASKS_ALL],
      app: APPS.PROPOSALS,
    },
  },
];

export default deployments;
