import APPS from "@/consts/apps";
import Contacts from "@/views/Contacts/Contacts.vue";
import ContactsForm from "@/views/Contacts/ContactsForm.vue";
import ContactsPreview from "@/views/Contacts/ContactsPreview.vue";
import PERMISSIONS from "@/consts/permissions";
import { RouteLocation } from "vue-router";
import i18n from "@/i18n";

const t = i18n.global.t;
const tc = i18n.global.tc;

const contacts = [
  {
    path: "/contacts/",
    name: "Contacts",
    component: Contacts,
    meta: {
      title: `${t("meta.app")} - ${t("modules.contacts")}`,
      requiresAuth: true,
      permission: PERMISSIONS.CONTACTS,
      app: APPS.CONTACTS,
    },
  },
  {
    path: "/contacts/create/",
    name: "ContactsCreate",
    component: ContactsForm,
    meta: {
      title: `${t("meta.app")} - ${t("app.add_", [tc("contacts.contact", 1)])}`,
      requiresAuth: true,
      permission: PERMISSIONS.CONTACTS_ADD,
      app: APPS.CONTACTS,
    },
  },
  {
    path: "/contacts/:id/edit/",
    name: "ContactsEdit",
    component: ContactsForm,
    meta: {
      title: `${t("meta.app")} - ${t("app.edit_", [
        tc("contacts.contact", 1),
      ])}`,
      requiresAuth: true,
      permission: PERMISSIONS.CONTACTS_EDIT,
      app: APPS.CONTACTS,
    },
    props: (route: RouteLocation) => ({ id: +route.params.id }),
  },
  {
    path: "/contacts/:id/preview/",
    name: "ContactsPreview",
    component: ContactsPreview,
    meta: {
      title: `${t("meta.app")} - ${t("contacts.preview")}`,
      requiresAuth: true,
      permission: PERMISSIONS.CONTACTS,
      app: APPS.CONTACTS,
    },
    props: (route: RouteLocation) => ({ id: +route.params.id }),
  },
];

export default contacts;
