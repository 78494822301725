<template>
  <div class="preview__row">
    <div class="title" v-if="item?.info">
      <Tooltip :title="item.info">
        <InfoCircleOutlined />
      </Tooltip>
      {{ title }}
    </div>
    <div class="title" v-else>{{ title }}</div>
    <div class="value">
      <ul class="list" v-if="Array.isArray(item.value)">
        <li v-for="value in item.value" :key="value">
          <router-link v-if="value.to" :to="value.to">
            {{ value.value }}
          </router-link>
          <template v-else>
            {{ value }}
          </template>
        </li>
      </ul>
      <template v-else>
        <router-link v-if="item.to" :to="item.to">
          {{ item.value }}
        </router-link>
        <template v-else>
          {{ item.value || "-" }}
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import { InfoCircleOutlined } from "@ant-design/icons-vue";
import { Tooltip } from "ant-design-vue";

export default {
  name: "PreviewSegment",
  props: { item: Object },
  components: { InfoCircleOutlined, Tooltip },
  computed: {
    title() {
      return `${this.item.title}${this.item.title ? ":" : ""}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.preview__row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0.5rem;
}

.title {
  font-weight: bold;
  text-align: right;
  flex: 1;
  padding-right: 1rem;
}

.value {
  text-align: left;
  width: 50%;
}

.list {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}

@media only screen and (max-width: 768px) {
  .preview__row {
    flex-direction: column;
    margin: 0.25rem;
  }

  .title {
    text-align: center;
    padding-right: 0;
  }

  .value {
    text-align: center;
    width: 100%;
  }
}
</style>
