<template>
  <Breadcrumbs>
    <template #summary>
      <div class="summary">
        <h3>{{ $t("modules.home") }}</h3>
        <h1>
          {{ $t("app.welcome", [user.full_name]) }}
        </h1>
        <form id="search-form" @submit.prevent="search">
          <div class="search-container__section">
            <Input
              id="search"
              v-model:value="searchParams.query"
              :placeholder="$t('search.inputInfo')"
              required
              :maxlength="250"
              class="search-input--home"
            />
            <EODButton
              icon="SearchOutlined"
              :name="$t('search.search')"
              htmlType="submit"
              type="primary"
              class="search-button"
            />
          </div>
        </form>
      </div>
    </template>
  </Breadcrumbs>

  <div v-if="APPS.WORKFLOWS" class="main-section">
    <template v-if="$permissions.includes(PERMISSIONS.TASKS_OWN)">
      <div class="tables-wrapper-mobile" v-if="isMobile">
        <QuickStartProcesses :isMobile="true" v-if="showQuickStartProcess" />
        <OwnTaskListMobile
          v-if="isTasksOverDue"
          :service="() => getTasksList({ limit: 3, overdue: true })"
          :title="$t('tasks.pastDue')"
          :customStore="homeTodoStore"
          :successfulClaim="successfulClaim"
        />
        <OwnTaskListMobile
          :service="
            () =>
              getTasksList({ limit: 3, take_assigned: true, my_tasks: true })
          "
          :title="$t('tasks.myTasks')"
          :customStore="homeOwnTasksStore"
          :successfulClaim="successfulClaim"
        />
        <OwnTaskListMobile
          :service="() => getTasksList({ limit: 3, take_assigned: false })"
          :title="$t('tasks.tasksToDo')"
          :customStore="homeTodoStore"
          :successfulClaim="successfulClaim"
        />
      </div>

      <div class="tables-wrapper" v-else>
        <QuickStartProcesses v-if="showQuickStartProcess" />
        <div v-if="isTasksOverDue" class="section">
          <OwnTaskList
            :service="() => getTasksList({ limit: 3, overdue: true })"
            :customStore="homeTasksOverDueStore"
            :successfulClaim="successfulClaim"
            :title="$t('tasks.pastDue')"
            :link="{
              route: { name: 'Tasks' },
              name: $t('tasks.allTasks'),
              icon: '',
            }"
          />
        </div>
        <div class="section">
          <OwnTaskList
            :service="
              () =>
                getTasksList({ limit: 3, take_assigned: true, my_tasks: true })
            "
            :customStore="homeOwnTasksStore"
            :successfulClaim="successfulClaim"
            :title="$t('tasks.myTasks')"
            :link="{
              route: { name: 'MyTasks' },
              name: $t('tasks.allTasks'),
              icon: '',
            }"
          />
        </div>
        <div class="section">
          <OwnTaskList
            :service="() => getTasksList({ limit: 3, take_assigned: false })"
            :customStore="homeTodoStore"
            :successfulClaim="successfulClaim"
            :title="$t('tasks.tasksToDo')"
            :link="{
              route: path,
              name: $t('tasks.allTasks'),
              icon: '',
            }"
          />
        </div>
      </div>
    </template>

    <div class="section-wrapper">
      <Section :title="$t('tasks.quickStart')" class="quick-start-section">
        <QuickStartMenu />
      </Section>
      <Section
        v-if="
          $permissions.includes(PERMISSIONS.REGISTRY_VIEW) && APPS.REGISTERS
        "
        :title="$tc('registry.registry', 3)"
        class="registries-section"
      >
        <RegistryLinks />
      </Section>
      <Section
        v-if="APPS.NOTIFICATIONS"
        :title="$t('app.notifications')"
        class="notification-section"
      >
        <OwnNotification />
      </Section>
    </div>
  </div>
</template>
<script>
import { getTasksList, getTasksOverDueCount } from "@/services/tasks";
import APPS from "@/consts/apps";
import Breadcrumbs from "@/components/ui/Breadcrumbs";
import { EODButton } from "@/components/ui";
import { Input } from "ant-design-vue";
import OwnNotification from "./OwnNotification.vue";
import OwnTaskList from "@/views/Tasks/OwnTaskList.vue";
import OwnTaskListMobile from "@/views/Tasks/OwnTaskListMobile";
import PERMISSIONS from "@/consts/permissions";
import QuickStartMenu from "./QuickStartMenu.vue";
import QuickStartProcesses from "@/views/Home/QuickStartProcesses.vue";
import RegistryLinks from "./RegistryLinks.vue";
import Section from "@/components/ui/Section.vue";
import dynamicTable from "@/store/modules/dynamicTable";
import { searchSimple } from "@/services/search";

export default {
  name: "Home",
  data() {
    return {
      APPS,
      searchParams: {
        query: null,
        ocr: false,
      },
      searchId: +this.$route.params?.searchId,
      PERMISSIONS,
      homeOwnTasksStore: "homeOwnTasks",
      homeTodoStore: "homeTodo",
      homeTasksOverDueStore: "homeTasksOverDue",
      isMobile: screen.width <= 450,
      isTasksOverDue: false,
      showQuickStartProcess: process.env.VUE_APP_QUICK_START_PROCESS,
    };
  },
  components: {
    Breadcrumbs,
    EODButton,
    Input,
    QuickStartMenu,
    RegistryLinks,
    Section,
    OwnNotification,
    OwnTaskList,
    OwnTaskListMobile,
    QuickStartProcesses,
  },
  computed: {
    user() {
      return this.$store.getters["user/getUser"];
    },
    notifications() {
      return this.$store.getters["notifications/getCount"];
    },
    path() {
      return this.user.is_boss || this.user.is_superior
        ? { name: "Tasks" }
        : { name: "MyTasks" };
    },
  },
  created() {
    if (!this.$store.hasModule(this.homeOwnTasksStore)) {
      this.$store.registerModule(this.homeOwnTasksStore, dynamicTable);
    }
    if (!this.$store.hasModule(this.homeTodoStore)) {
      this.$store.registerModule(this.homeTodoStore, dynamicTable);
    }
    if (!this.$store.hasModule(this.homeTasksOverDueStore)) {
      this.$store.registerModule(this.homeTasksOverDueStore, dynamicTable);
    }
    this.getCountTaskListOverDue();
  },
  methods: {
    getTasksList,
    successfulClaim(messageText) {
      this.$message.success(messageText);
      this.$store.dispatch(`${this.homeOwnTasksStore}/refreshTable`);
      this.$store.dispatch(`${this.homeTodoStore}/refreshTable`);
      this.$store.dispatch(`${this.homeTasksOverDueStore}/refreshTable`);
    },
    search() {
      searchSimple(this.searchParams).then(({ data }) => {
        this.$router.push({
          name: "SearchResult",
          params: { searchId: data },
        });
      });
    },
    getQueryFromStore(query) {
      if (this.searchId) {
        if (query.query) {
          this.searchParams = query || {
            query: null,
            ocr: false,
          };
        } else if (query.blocks) {
          this.advancedQuery = query;
        }
      }
    },
    getCountTaskListOverDue() {
      getTasksOverDueCount()
        .then(({ data }) => {
          if (data.count > 0) {
            this.isTasksOverDue = true;
          } else {
            this.isTasksOverDue = false;
          }
        })
        .catch(() => {
          this.isTasksOverDue = false;
        });
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/scss/Variables";
@import "@/scss/Search";
.summary {
  flex-direction: column;
  text-align: left;
}
.tables-wrapper-mobile {
  display: flex;
  flex-direction: column;
}
.own-task-list {
  padding: 24px;
}
.main-section {
  display: flex;
  justify-content: space-between;
}
.tables-wrapper {
  flex-direction: column;
  display: flex;
  width: 70%;
}
.section-wrapper {
  flex-direction: column;
  display: flex;
  width: 30%;
}
.search-container {
  &__section {
    width: 100%;
    max-width: 500px;
    margin: 0;
  }
}
.search {
  &-input {
    width: 50%;
  }
  &-button {
    width: 30%;
  }
}
@media (max-width: 1024px) {
  .tables-wrapper {
    width: 60%;
  }
  .section-wrapper {
    flex-direction: column;
    display: flex;
    width: 40%;
  }
}

@media (max-width: 768px) {
  .main-section {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .section-wrapper {
    flex-direction: column;
    display: flex;
    width: 100%;
  }
  .tables-wrapper {
    flex-direction: column;
    display: flex;
    width: 100%;
  }
  .notification-section {
    display: none;
  }
}
@media (max-width: 450px) {
  .section-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .quick-start-section {
    width: 90%;
  }
  .registries-section {
    width: 90%;
  }
  .search {
    &-input {
      width: 50%;
    }
    &-button {
      width: 40%;
    }
  }
}
</style>
