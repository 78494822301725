import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tag = _resolveComponent("Tag")

  return (_openBlock(), _createBlock(_component_Tag, {
    color: _ctx.colorsMap[_ctx.commentType]
  }, {
    icon: _withCtx(() => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.iconsMap[_ctx.commentType])))
    ]),
    default: _withCtx(() => [
      _createTextVNode(" " + _toDisplayString(_ctx.commentTypeDisplay), 1)
    ]),
    _: 1
  }, 8, ["color"]))
}