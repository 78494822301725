import APPS from "@/consts/apps";
import Replacements from "@/views/Replacements/Replacements.vue";
import ReplacementsForm from "@/views/Replacements/ReplacementsForm.vue";
import ReplacementsPreview from "@/views/Replacements/ReplacementsPreview.vue";
import i18n from "@/i18n";

const t = i18n.global.t;
const tc = i18n.global.tc;

const replacement = tc("replacements.replacement", 2);

const replacements = [
  {
    path: "/replacements/",
    name: "Replacements",
    component: Replacements,
    meta: {
      title: `${t("meta.app")} - ${t("modules.replacements")}`,
      requiresAuth: true,
      permission: "replacement.own_replacement_custom",
      app: APPS.REPLACEMENTS,
    },
  },
  {
    path: "/replacements/create/",
    name: "ReplacementsCreate",
    component: ReplacementsForm,
    meta: {
      title: `${t("meta.app")} - ${t("app.add_", [replacement])}`,
      requiresAuth: true,
      permission: "replacement.own_replacement_custom",
      app: APPS.REPLACEMENTS,
    },
  },
  {
    path: "/replacements/:id/edit/",
    name: "ReplacementsEdit",
    component: ReplacementsForm,
    meta: {
      title: `${t("meta.app")} - ${t("app.edit_", [replacement])}`,
      requiresAuth: true,
      permission: "replacement.own_replacement_custom",
      app: APPS.REPLACEMENTS,
    },
  },
  {
    path: "/replacements/:id/preview/",
    name: "ReplacementsPreview",
    component: ReplacementsPreview,
    meta: {
      title: `${t("meta.app")} - ${t("app.preview_", [replacement])}`,
      requiresAuth: true,
      permission: "replacement.own_replacement_custom",
      app: APPS.REPLACEMENTS,
    },
  },
  {
    path: "/replacements-all/",
    name: "ReplacementsAll",
    component: Replacements,
    props: { all: true },
    meta: {
      title: `${t("meta.app")} - ${t("modules.replacementsAll")}`,
      requiresAuth: true,
      permission: "replacement.all_replacement_custom",
      app: APPS.REPLACEMENTS,
    },
  },
  {
    path: "/replacements-all/create/",
    name: "ReplacementsAllCreate",
    component: ReplacementsForm,
    props: { all: true },
    meta: {
      title: `${t("meta.app")} - ${t("app.add_", [replacement])}`,
      requiresAuth: true,
      permission: "replacement.all_replacement_custom",
      app: APPS.REPLACEMENTS,
    },
  },
  {
    path: "/replacements-all/:id/edit/",
    name: "ReplacementsAllEdit",
    component: ReplacementsForm,
    props: { all: true },
    meta: {
      title: `${t("meta.app")} - ${t("app.edit_", [replacement])}`,
      requiresAuth: true,
      permission: "replacement.all_replacement_custom",
      app: APPS.REPLACEMENTS,
    },
  },
  {
    path: "/replacements-all/:id/preview/",
    name: "ReplacementsAllPreview",
    component: ReplacementsPreview,
    props: { all: true },
    meta: {
      title: `${t("meta.app")} - ${t("app.preview_", [replacement])}`,
      requiresAuth: true,
      permission: "replacement.all_replacement_custom",
      app: APPS.REPLACEMENTS,
    },
  },
];

export default replacements;
