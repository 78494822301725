<template>
  <Modal
    v-model:visible="cVisible"
    :title="issueID ? $t('issues.edit') : $t('issues.add')"
    :okText="$t('app.save')"
    :cancelText="$t('app.cancel')"
    :closable="false"
    :okButtonProps="{ htmlType: 'submit', form: 'issue-form' }"
    @cancel="onCancel"
  >
    <div class="form-container">
      <form id="issue-form" @submit.prevent="submit" class="form">
        <EODSelect
          required
          v-if="!issueID"
          id="documents"
          :storeID="documentsStoreID"
          v-model:value="issue.documents"
          v-model:refetch="refetchDocuments"
          :label="$t('issues.document')"
          :placeholder="$t('issues.documentsPlaceholder')"
          :service="fetchDocuments"
          :onChange="handleSelectDocument"
          :errors="errors.documents"
          :disabled="!!document"
        >
          <template #tooltip>
            <Tooltip :title="this.$t('issues.documentInfo')">
              <InfoCircleOutlined />
            </Tooltip>
          </template>
        </EODSelect>

        <JRWASelect
          v-if="!issueID && !jrwa"
          id="jrwa"
          v-model:value="issue.jrwa"
          v-model:value_display="issue.jrwa_display"
          :errors="errors.jrwa"
          required
        >
          <Tooltip :title="this.$t('issues.JRWAInfo')">
            <InfoCircleOutlined />
          </Tooltip>
        </JRWASelect>

        <EODInput
          v-if="jrwa"
          id="jrwa"
          type="text"
          v-model:value="jrwa.display"
          :label="$t('documents.JRWANumber')"
          disabled
        />

        <span v-if="!issueID && (issue.jrwa || jrwa)" class="color--warning">
          <ExclamationCircleOutlined /> {{ $t("issues.JRWAWarning") }}
        </span>

        <EODSelect
          v-if="!issueID"
          id="unit"
          v-model:value="issue.unit"
          :label="$t('issues.unit')"
          :service="fetchUnits"
          :defaultValue="defaultUnit"
          :errors="errors.unit"
          required
        />

        <EODInput
          id="content"
          type="textarea"
          v-model:value="issue.content"
          :label="$t('issues.content')"
          :props="{ maxlength: 500 }"
          :errors="errors.content"
          required
        />

        <EODInput
          id="sender"
          v-model:value="issue.sender"
          :label="$t('issues.sender')"
          :props="{ maxlength: 250 }"
          :errors="errors.sender"
        />

        <EODInput
          v-if="!issueID"
          id="sender_signature"
          v-model:value="issue.sender_signature"
          :label="$t('issues.senderSignature')"
          :props="{ maxlength: 250 }"
          :errors="errors.sender_signature"
        />

        <EODDatePicker
          v-if="!issueID"
          id="sender_date"
          v-model:value="issue.sender_date"
          :label="$t('issues.senderDate')"
          :errors="errors.sender_date"
          :disabledDate="futureDate"
          dateOnly
        />

        <EODInput
          v-if="!issueID"
          id="creator"
          v-model:value="user.full_name"
          :label="$t('issues.creator')"
          :props="{ maxlength: 250 }"
          disabled
        />

        <EODSelect
          required
          id="owner"
          :label="$t('issues.handler')"
          :service="fetchOwnerList"
          v-model:value="issue.owner"
          :defaultValue="defaultOwner"
          :errors="errors.owner"
        />

        <EODDatePicker
          :disabled="!!issueID"
          id="initiation_date"
          v-model:value="issue.initiation_date"
          :label="$t('issues.creationDate')"
          :errors="errors.initiation_date"
          :disabledDate="futureDate"
          required
          dateOnly
        />

        <EODInput
          id="comment"
          type="textarea"
          v-model:value="issue.comment"
          :label="$t('app.comment')"
          :props="{ maxlength: 500 }"
          :errors="errors.comment"
        />
      </form>
    </div>
  </Modal>
</template>

<script>
import {
  EODDatePicker,
  EODInput,
  EODSelect,
  JRWASelect,
} from "@/components/inputs";
import {
  ExclamationCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons-vue";
import { Modal, Tooltip } from "ant-design-vue";
import { createIssue, editIssue, getIssue } from "@/services/issues";

import { ISSUE_MODEL } from "@/model/issueModel";
import { getDocumentsSelectList } from "@/services/documents";
import { getUnitsFlatSelect } from "@/services/units";
import { getUsersDescendantUnitsList } from "@/services/permissions";

export default {
  name: "IssueForm",
  props: {
    issueID: {
      type: [Number, String],
      default: null,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    handleRefresh: {
      type: Function,
      default: () => {},
    },
    document: {
      type: [Number, String],
      default: null,
    },
    refreshDocument: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      issue: { ...ISSUE_MODEL },
      errors: {},
      jrwa: null,
      documentsStoreID: "issues-add-document",
      refetchDocuments: false,
      defaultUnit: null,
      defaultOwner: null,
    };
  },
  components: {
    EODDatePicker,
    EODInput,
    EODSelect,
    ExclamationCircleOutlined,
    InfoCircleOutlined,
    JRWASelect,
    Modal,
    Tooltip,
  },
  computed: {
    user() {
      return this.$store.getters["user/getUser"];
    },
    cVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
    documentsList() {
      return this.$store.state[`select_${this.documentsStoreID}`]
        .serviceOptions;
    },
  },
  created() {
    this.issue.owner = this.user.id;
    this.defaultOwner = { id: this.user.id, name: this.user.full_name };
    this.fetchIssue();
    if (this.document) {
      this.issue.documents = this.document;
    }
  },
  emits: ["update:visible", "update:issueID"],
  methods: {
    createIssue,
    editIssue,
    futureDate(current) {
      return current > new Date();
    },
    resetIssue() {
      this.issue = { ...ISSUE_MODEL };
      if (this.document) {
        this.issue.documents = this.document;
      }
      this.jrwa = null;
      this.errors = {};
    },
    fetchIssue() {
      if (this.issueID) {
        getIssue(this.issueID).then(({ data }) => {
          this.issue = data;
          this.issue.comment = null;
          this.defaultUnit = this.$getDefaultValue(data, "unit");
          this.defaultOwner = this.$getDefaultValue(data, "owner");
        });
      }
    },
    fetchOwnerList(params) {
      return getUsersDescendantUnitsList(params).then(({ data }) => ({
        ...data,
        results: data.results
          .filter((elem) => elem.id !== this.issue?.owner)
          .map((elem) => ({
            id: elem.id,
            name: elem.full_name,
          })),
      }));
    },
    fetchDocuments(params) {
      return getDocumentsSelectList({ unassigned: true, ...params }).then(
        ({ data }) => data,
      );
    },
    fetchUnits(params) {
      return getUnitsFlatSelect(params).then(({ data }) => data);
    },
    handleSelectDocument(id) {
      const selectedDocument = this.documentsList.find((el) => el.id === id);
      if (selectedDocument?.jrwa) {
        this.jrwa = {
          id: selectedDocument.jrwa,
          display: selectedDocument.jrwa_display,
        };
      } else {
        this.jrwa = null;
      }
    },
    onCancel() {
      this.resetIssue();
      this.$emit("update:issueID", null);
      this.$emit("update:visible", false);
    },
    submit() {
      if (this.issue) {
        const issueData = this.issue;

        if (!this.issueID) {
          if (!Array.isArray(issueData.documents) && issueData.documents) {
            issueData.documents = [issueData.documents];
          }

          if (this.jrwa) {
            issueData.jrwa = this.jrwa.id;
          }
        }

        const service = this.issueID ? editIssue : createIssue;
        service(issueData, this.issueID)
          .then(({ data }) => {
            for (const store of [
              "issues",
              "issuesInProgress",
              "issueHistory",
            ]) {
              if (this.$store.hasModule(store)) {
                this.$store.dispatch(`${store}/refreshTable`);
              }
            }

            // TODO: make it work with router-link
            this.$message.success(
              this.issueID ? (
                <>{this.$t("app.success")}</>
              ) : (
                <>
                  {this.$t("issues.created_", [data.signature])}{" "}
                  <a href={`/issues/${data.id}/preview/`}>
                    {this.$t("issues.goToIssue")}
                  </a>
                </>
              ),
            );

            if (!this.issueID) {
              this.resetIssue();
            }
            this.$emit("update:visible", false);
            this.handleRefresh();
            if (!this.document) {
              this.refetchDocuments = true;
            }
            this.refreshDocument();
          })
          .catch((err) => {
            this.errors = err.response.data;
          });
      } else {
        this.$message.error(this.$t("app.error"));
      }
    },
  },
  watch: {
    issueID() {
      this.fetchIssue();
    },
  },
};
</script>
