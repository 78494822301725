<template>
  <Breadcrumbs
    :path="[
      { path: { name: 'Notifications' }, title: $t('modules.notifications') },
    ]"
    :current="title"
    iconComponent="MessageOutlined"
    :what="$tc('notifications.notification', 2)"
  >
    <EODButton
      v-if="id && hasPermissionsToDelete"
      ghost
      type="danger"
      icon="DeleteOutlined"
      :name="`${$t('app.delete')} ${$tc('notifications.notification', 1)}`"
      @click="confirmDelete"
    />
  </Breadcrumbs>

  <Section :title="title">
    <div class="form-container">
      <form id="notification-form" @submit.prevent="submit" class="form">
        <EODInput
          id="name"
          v-model:value="data.name"
          required
          :label="$t('app.name')"
          :errors="errors.name"
        />

        <EODSelect
          required
          :label="$t('app.type')"
          v-model:value="data.notifications_type"
          :options="notificationTypes"
          :errors="errors.notifications_type"
        />

        <EODRadio
          required
          :label="$t('app.status')"
          :options="[
            { name: $t('status.active'), id: STATUS.ACTIVE },
            { name: $t('status.inactive'), id: STATUS.INACTIVE },
          ]"
          v-model:value="data.status"
          :errors="errors.status"
        />

        <EODInput
          id="title"
          v-model:value="data.title"
          :disabled="data.notifications_type === NOTIFICATION_TYPE.SMS"
          :required="data.notifications_type !== NOTIFICATION_TYPE.SMS"
          :label="$t('app.title')"
          :errors="errors.title"
        />
      </form>

      <EODEditor
        v-if="!loading"
        required
        :label="$t('app.content')"
        v-model:value="data.template"
        form="notification-form"
        :options="{ initialValue: data.template }"
        :errors="errors.template"
      />

      <div class="legend">
        <p>{{ $t("notifications.legend.info") }}</p>

        <ul>
          <li>
            <strong>&lcub;&lcub;numeration&rcub;&rcub;</strong>
            &nbsp;-&nbsp;{{ $t("notifications.legend.processNumber") }}
          </li>
          <li>
            <strong>&lcub;&lcub;document_name&rcub;&rcub;</strong>
            &nbsp;-&nbsp;{{ $t("notifications.legend.documentName") }}
          </li>
          <li>
            <strong>&lcub;&lcub;deployment_name&rcub;&rcub;</strong>
            &nbsp;-&nbsp;{{ $t("notifications.legend.deploymentName") }}
          </li>
          <li>
            <strong>
              &lcub;&lcub;{{
                $t("notifications.legend.propertyName")
              }}&rcub;&rcub;
            </strong>
            &nbsp;-&nbsp;{{ $t("notifications.legend.propertyNameInfo") }}
          </li>
          <li>
            <strong>&lcub;&lcub;link&rcub;&rcub;</strong>
            &nbsp;-&nbsp;{{ $t("notifications.legend.link") }}
          </li>
          <li>
            <strong>&lcub;&lcub;proposal_link&rcub;&rcub;</strong>
            &nbsp;-&nbsp;{{ $t("notifications.legend.proposal_link") }}
          </li>
        </ul>

        <p>{{ $t("notifications.legend.template") }}:</p>
        <ul>
          <li>
            {{ $t("notifications.legend.newTaskForDeployment") }}:
            <strong>&lcub;&lcub;deployment_name&rcub;&rcub;</strong>
          </li>
          <li>
            {{ $t("notifications.legend.documentInDeployment") }}:
            <strong>&lcub;&lcub;document_name&rcub;&rcub;</strong>
          </li>
        </ul>
      </div>

      <EODButton
        type="primary"
        htmlType="submit"
        :name="$t('app.save')"
        form="notification-form"
        :loading="loading$"
      />
    </div>
  </Section>
</template>

<script>
import { Breadcrumbs, EODButton, Section } from "@/components/ui";
import { EODEditor, EODInput, EODRadio, EODSelect } from "@/components/inputs";
import { NOTIFICATION_TYPE, STATUS } from "@/consts/statuses";
import {
  createNotification,
  deleteNotification,
  editNotification,
  getNotification,
  getNotificationsTypes,
} from "@/services/notifications";
import PERMISSIONS from "@/consts/permissions";

export default {
  name: "NotificationsForm",
  data() {
    return {
      id: +this.$route.params.id,
      data: {
        name: "",
        title: "",
        notifications_type: NOTIFICATION_TYPE.EMAIL,
        template: "",
        status: STATUS.ACTIVE,
      },
      STATUS,
      NOTIFICATION_TYPE,
      notificationTypes: [],
      errors: {},
      originalName: "",
      loading: true,
    };
  },
  components: {
    Breadcrumbs,
    EODButton,
    EODEditor,
    EODInput,
    EODRadio,
    EODSelect,
    Section,
  },
  computed: {
    title() {
      return this.id
        ? this.$t("notifications.edit")
        : this.$t("notifications.add");
    },
    hasPermissionsToDelete() {
      return this.$permissions.includes(PERMISSIONS.NOTIFICATIONS_DELETE);
    },
  },
  methods: {
    submit() {
      this.loading$ = true;

      const service = this.id ? editNotification : createNotification;

      service(this.data, this.id)
        .then(() => {
          this.$router.push({ name: "Notifications" });
          this.$message.success(this.$t("app.success"));
        })
        .catch((err) => {
          this.errors = err.response.data;
        })
        .finally(() => {
          this.loading$ = false;
        });
    },
    confirmDelete() {
      this.$store.dispatch("setModal", {
        modalOk: this.deleteNotification,
        title: this.$t("notifications.delete"),
        message: `${this.$t("app.doYouWantToDelete")} ${this.$tc(
          "notifications.notification",
          1,
        )} "${this.originalName}"?`,
      });
    },
    deleteNotification() {
      deleteNotification(this.id).then(() => {
        this.$message.success(this.$t("app.success"));
        this.$store.dispatch("closeModal");
        this.$router.replace({ name: "Notifications" });
      });
    },
  },
  mounted() {
    if (this.id) {
      getNotification(this.id).then(({ data }) => {
        this.data = data;
        this.originalName = data.name;
        this.loading = false;
      });
    } else {
      this.loading = false;
    }

    getNotificationsTypes().then(({ data }) => {
      this.notificationTypes = Object.entries(data).map(([key, value]) => ({
        name: value,
        id: +key,
      }));
    });
  },
};
</script>

<style lang="scss" scoped>
.legend {
  width: 25rem;
  max-width: 100%;
  text-align: left;
  margin-bottom: 1rem;
}
</style>
