<template>
  <Section :title="$t('issues.historyTab')">
    <BaseTable
      rowKey="history_id"
      :customExpand="handleGetDetail"
      :columns="historyTableColumns"
      :service="(params) => getHistory(id, params)"
      customStore="issueHistory"
      :expandIcon="expandIcon"
    >
      <template #expandedRowRender="{ record }">
        <div v-if="diff[record.history_id]?.items.length">
          <p v-for="d in diff[record.history_id]?.items" :key="d.field_name">
            <strong>{{ d.field_name }}:</strong> {{ d.old_value || "-" }}
            <ArrowRightOutlined /> {{ d.new_value || "-" }}
          </p>
        </div>
        <div v-else>
          {{ $t("issues.noEntries") }}
        </div>
      </template>
    </BaseTable>
  </Section>
</template>

<script>
import { getHistory, getHistoryItem } from "@/services/issues";

import { ArrowRightOutlined } from "@ant-design/icons-vue";
import { BaseTable } from "@/components/table";
import { Section } from "@/components/ui";

import dynamicTable from "@/store/modules/dynamicTable";

export default {
  name: "IssueHistory",
  data() {
    return {
      id: +this.$route.params?.id,
      diff: {},
      historyTableColumns: [
        {
          title: this.$t("app.date"),
          dataIndex: "history_date",
          sorter: true,
        },
        {
          title: this.$t("issues.action"),
          dataIndex: "activity",
        },
        {
          title: this.$t("app.comment"),
          dataIndex: "comment",
        },
        {
          title: this.$t("app.person"),
          dataIndex: "full_name",
          sorter: true,
        },
      ],
    };
  },
  components: {
    ArrowRightOutlined,
    BaseTable,
    Section,
  },
  created() {
    if (!this.$store.hasModule("issueHistory")) {
      this.$store.registerModule("issueHistory", dynamicTable);
    }
  },
  methods: {
    getHistory,
    getHistoryItem,
    expandIcon(props) {
      const { expandable, onExpand, expanded, record } = props;

      if (expandable && record?.actions?.details) {
        // recreated antd expand icon
        return (
          <div
            onClick={() => onExpand(record)}
            role="button"
            tabindex="0"
            class={`ant-table-row-expand-icon ant-table-row-${
              expanded ? "expanded" : "collapsed"
            }`}></div>
        );
      }
    },
    handleGetDetail(record) {
      this.getHistoryItem(this.id, record.history_id).then(
        ({ data: { diff_data: items } }) => {
          this.diff[record.history_id] = { items };
        },
      );
    },
  },
};
</script>
