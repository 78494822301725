<template>
  <ConfirmModal
    v-model:visible="cVisible"
    :title="title"
    :okText="okText"
    :cancelText="cancelText"
    :onSubmit="confirmModal"
    :onCancel="closeModal"
    :icon="icon"
    :isLoading="isLoading"
  >
    <p>{{ body }}</p>
    <EODSelect
      v-if="!resetDataAfterClose || cVisible"
      :id="selectId"
      v-model:value="cSelectedOption"
      :service="service"
      :options="service ? undefined : options"
      :errors="selectError"
      :onChange="onSelect"
      :label="selectLabel"
      :mode="mode"
      :defaultValue="defaultValue"
    />
  </ConfirmModal>
</template>

<script>
import { ConfirmModal } from "@/components/modals";
import { EODSelect } from "@/components/inputs";

export default {
  name: "SelectModal",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    selectedOption: {
      type: [String, Number],
      default: null,
    },
    title: String,
    body: String,
    okText: String,
    cancelText: String,
    service: Function,
    options: Array,
    onSubmit: {
      type: Function,
      required: true,
    },
    onCancel: {
      type: Function,
      required: true,
    },
    resetDataAfterClose: {
      type: Boolean,
      default: false,
    },
    selectId: {
      type: String,
      default: "options",
    },
    selectLabel: String,
    icon: String,
    isLoading: {
      type: Boolean,
      default: false,
    },
    mode: String,
    defaultValue: { type: [Object, Array], default: null },
  },
  data() {
    return {
      selectOptions: [],
      selectError: [],
    };
  },
  components: {
    ConfirmModal,
    EODSelect,
  },
  computed: {
    cVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
    cSelectedOption: {
      get() {
        return this.selectedOption;
      },
      set(val) {
        this.$emit("update:selectedOption", val);
      },
    },
  },
  methods: {
    confirmModal() {
      if (!this.cSelectedOption) {
        this.selectError.push(this.$t("app.selectToContinue"));
      } else {
        this.onSubmit();
      }
    },
    closeModal() {
      this.selectError = [];
      this.onCancel();
    },
    onSelect() {
      this.selectError = [];
    },
  },
};
</script>
