import APPS from "@/consts/apps";
import Forms from "@/views/Forms/Forms.vue";
import FormsForm from "@/views/Forms/FormsForm.vue";
import FormsPreview from "@/views/Forms/FormsPreview.vue";
import PERMISSIONS from "@/consts/permissions";

const forms = [
  {
    path: "/forms/",
    name: "Forms",
    component: Forms,
    meta: {
      title: "EOD - Lista formularzy",
      requiresAuth: true,
      permission: PERMISSIONS.FORMS,
      app: APPS.FORMS,
    },
  },
  {
    path: "/forms/create/",
    name: "FormsCreate",
    component: FormsForm,
    meta: {
      title: "EOD - Dodawanie formularza",
      requiresAuth: true,
      permission: PERMISSIONS.FORMS_ADD,
      app: APPS.FORMS,
    },
  },
  {
    path: "/forms/:id/edit/",
    name: "FormsEdit",
    component: FormsForm,
    meta: {
      title: "EOD - Edycja formularza",
      requiresAuth: true,
      permission: PERMISSIONS.FORMS_EDIT,
      app: APPS.FORMS,
    },
  },
  {
    path: "/forms/:id/preview/",
    name: "FormsPreview",
    component: FormsPreview,
    meta: {
      title: "EOD - Podgląd formularza",
      requiresAuth: true,
      permission: PERMISSIONS.FORMS,
      app: APPS.FORMS,
    },
  },
];

export default forms;
