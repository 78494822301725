<template>
  <div class="filter" ref="filter" @scroll="handleScroll">
    <div class="filter-search">
      <InputSearch
        v-model:value="search"
        :placeholder="$t('app.search')"
        @search="handleSearch"
        :enterButton="true"
      />
    </div>

    <div
      :class="[
        'folder-item',
        folderID === folder.id ? 'folder-item-selected' : '',
      ]"
      v-for="folder in folders"
      :key="folder.id"
      @click="handleSelectFolder(folder)"
      @keyup.enter="handleSelectFolder(folder)"
      tabindex="0"
    >
      {{ folder.name }}
      <span v-if="Number(folder.document_count)">
        <FileTextOutlined /> {{ folder.document_count }}
      </span>
    </div>
  </div>
</template>

<script>
import { FileTextOutlined } from "@ant-design/icons-vue";
import { Input } from "ant-design-vue";
import { getFolders } from "@/services/repository";

export default {
  name: "FilterFolders",
  props: {
    folderFilters: Object,
  },
  data() {
    return {
      folders: [],
      search: null,
      limit: 20,
      nextPage: null,
    };
  },
  components: {
    FileTextOutlined,
    InputSearch: Input.Search,
  },
  computed: {
    shouldRefetch() {
      return this.$store.state.repository.shouldRefetch;
    },
    folderID() {
      return this.$store.getters["repository/folderID"];
    },
  },
  created() {
    this.fetchFolders();
  },
  methods: {
    getFolders,
    handleSearch() {
      this.fetchFolders();
      this.$store.dispatch("repository/resetFolder");
    },
    handleScroll(e) {
      if (
        this.nextPage &&
        !this.loading$ &&
        e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight + 100
      ) {
        this.fetchFolders(this.nextPage);
      }
    },
    adjustFilterList() {
      if (
        this.nextPage &&
        this.$refs.filter.scrollHeight === this.$refs.filter.clientHeight
      ) {
        this.fetchFolders(this.nextPage);
      }
    },
    fetchFolders(page = 1) {
      this.loading$ = true;
      this.getFolders({ limit: this.limit, page, name: this.search })
        .then(({ data }) => {
          this.nextPage = new URLSearchParams(data.next).get("page");
          if (this.search) {
            this.folders = data.results;
          } else {
            this.folders = [
              ...new Map(
                [...this.folders, ...data.results].map((folder) => [
                  folder["id"],
                  folder,
                ]),
              ).values(),
            ]; //get uniquefolders
          }
        })
        .finally(() => {
          this.loading$ = false;
          // TODO: move to mounted
          this.adjustFilterList();
        });
    },
    handleSelectFolder(folder) {
      const { id, name } = folder;
      this.$store.dispatch("repository/selectFolder", { id, name });
    },
  },
  mounted() {
    window.addEventListener("resizeFilterFolder", () =>
      this.adjustFilterList(),
    );
  },
  unmounted() {
    this.$store.dispatch("repository/resetFolder");
    window.removeEventListener("resizeFilterFolder", () =>
      this.adjustFilterList(),
    );
  },
  watch: {
    shouldRefetch(val) {
      if (val) {
        this.folders = [];
        this.fetchFolders();
        this.$store.dispatch("repository/resetRefetch");
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/RepositoryFilter.scss";
.folder-item {
  text-align: left;
  padding: 0 0.25rem 0.35rem 1.5rem;
  display: flex;
  justify-content: space-between;
  margin-left: 0.375rem;

  &:hover,
  &:focus {
    cursor: pointer;
    @extend .focus;
  }
  &-selected {
    color: $primary;
  }
}
</style>
