<template>
  <div class="quick-start-panel">
    <Menu mode="inline" class="quick-start-panel__menu">
      <template v-for="item in items" :key="item.id">
        <MenuItem
          v-if="$permissions.includes(item.permission)"
          :icon="item.icon"
        >
          <router-link :to="item.route" class="quick-start-panel__link">
            <component :is="item.icon" />{{ item.title }}
          </router-link>
        </MenuItem>
      </template>
    </Menu>
  </div>
</template>

<script>
import {
  FormOutlined,
  HomeOutlined,
  MessageOutlined,
  OrderedListOutlined,
  SnippetsOutlined,
  SortAscendingOutlined,
  SyncOutlined,
  TeamOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons-vue";

import { Menu } from "ant-design-vue";
import { config } from "./configQuickStartMenu";

export default {
  name: "QuickStart",
  data() {
    return {
      items: config,
    };
  },
  components: {
    Menu,
    MenuItem: Menu.Item,
    FormOutlined,
    HomeOutlined,
    MessageOutlined,
    OrderedListOutlined,
    SortAscendingOutlined,
    SyncOutlined,
    TeamOutlined,
    SnippetsOutlined,
    UserSwitchOutlined,
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/Variables";
.quick-start-panel {
  &__menu {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-start;
    width: 49%;
    max-height: 250px;
  }
  &__link {
    color: $primary;
    text-align: left;
    padding-top: 0;

    &:focus {
      background-size: 1px 1em;
      box-shadow: inset 0 -0.075em $primary, inset 0 -0.1em $primary;
      display: block;
    }
  }
}

@media (min-width: 768px) and (max-width: 1440px) {
  .quick-start-panel {
    &__menu {
      flex-direction: row;
      width: 100%;
      max-height: 70vh;
    }
  }
}
@media (max-width: 450px) {
  .quick-start-panel {
    &__menu {
      flex-direction: row;
      width: 100%;
      max-height: 50vh;
    }
  }
}
</style>
