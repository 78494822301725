<template>
  <Modal
    :visible="visible"
    :title="$t('search.advancedSearch')"
    :width="992"
    @cancel="onClose"
  >
    <form id="advanced-search" @submit.prevent="handleSearch">
      <div class="search-section">
        <p class="section-name">{{ $t("issues.document") }}:</p>
        <div class="section-content">
          <AdvancedSearchSection
            v-for="(document, index) in data.documents"
            :key="`document_${document.id}`"
            :idName="`document_${document.id}`"
            :fieldList="DOCUMENTS_FIELDS_LIST"
            v-model:operator="document.operator"
            v-model:field="document.field"
            v-model:contain="document.operation"
            v-model:phrase="document.value"
            v-model:date="document.date"
            :addNewRule="index === 0 ? () => addNewRule('documents') : false"
            :removeRule="
              index !== 0 ? () => removeRule('documents', document.id) : false
            "
            v-model:errors="errors.documents[document.id]"
            :disabledAddBtn="data.documents.length >= maxRulesInSection"
            :maxRulesInSection="maxRulesInSection"
          />
        </div>
      </div>
      <div class="search-section">
        <p class="section-name">{{ $t("app.attachments") }}:</p>
        <div class="section-content">
          <AdvancedSearchSection
            v-for="(attachment, index) in data.attachments"
            :key="`attachments_${attachment.id}`"
            :idName="`attachments_${attachment.id}`"
            :fieldList="ATTACHMENTS_FIELDS_LIST"
            v-model:operator="attachment.operator"
            v-model:field="attachment.field"
            v-model:contain="attachment.operation"
            v-model:phrase="attachment.value"
            v-model:date="attachment.date"
            :addNewRule="index === 0 ? () => addNewRule('attachments') : false"
            :removeRule="
              index !== 0
                ? () => removeRule('attachments', attachment.id)
                : false
            "
            v-model:errors="errors.attachments[attachment.id]"
            :disabledAddBtn="data.attachments.length >= maxRulesInSection"
            :maxRulesInSection="maxRulesInSection"
          />
        </div>
      </div>
      <div class="search-section">
        <p class="section-name">{{ $t("tasks.deployment") }}:</p>
        <div class="custom-section-content">
          <div class="deployment-select">
            <EODSelect
              id="deployment_deployment"
              v-model:value="data.deployment.value"
              :service="fetchDeploymentList"
              :placeholder="$t('processes.selectDeployment')"
            />
          </div>
          <div class="status-radio">
            <p class="radio-label">
              {{ $t("search.processesStatusInDeployment") }}:
            </p>
            <EODRadio
              id="deployment_status"
              v-model:value="data.processesStatus.value"
              :options="PROCESSES_STATUS_LIST"
              :disabled="!data.deployment.value"
            />
          </div>
        </div>
      </div>
      <div class="search-section">
        <p class="section-name">{{ $t("forms.formField") }}:</p>
        <div class="section-content">
          <AdvancedSearchSection
            v-for="(formField, index) in data.formFields"
            :key="`formFields_${formField.id}`"
            :idName="`formFields_${formField.id}`"
            :fieldList="formFieldList"
            v-model:operator="formField.operator"
            v-model:field="formField.field"
            v-model:contain="formField.operation"
            v-model:phrase="formField.value"
            v-model:date="formField.date"
            :addNewRule="index === 0 ? () => addNewRule('formFields') : false"
            :removeRule="
              index !== 0 ? () => removeRule('formFields', formField.id) : false
            "
            :disabled="!data.deployment.value"
            v-model:errors="errors.formFields[formField.id]"
            :disabledAddBtn="data.formFields.length >= maxRulesInSection"
            :maxRulesInSection="maxRulesInSection"
          >
            <template v-if="index === 0" v-slot:sectionInfo>
              <Tooltip :title="$t('search.formFieldInfo')">
                <InfoCircleOutlined />
              </Tooltip>
            </template>
          </AdvancedSearchSection>
        </div>
      </div>
      <div class="search-section">
        <p class="section-name">{{ $t("search.otherOptions") }}:</p>
        <div class="custom-section-content">
          <div class="search-element">
            <p>{{ $t("search.lastActualisation") }}:</p>
            <EODSelect
              id="otherOptions_modified"
              v-model:value="data.modified"
              :options="UPDATE_TYPES_LIST"
              :allowClear="false"
            />
          </div>
          <div class="search-element">
            <p class="element-name">{{ $t("search.fileType") }}:</p>
            <EODSelect
              id="otherOptions_attachment-extension"
              v-model:value="data.attachmentType.value"
              :options="FILE_TYPES_LIST"
              :allowClear="false"
            />
          </div>
        </div>
      </div>
    </form>
    <template v-slot:footer>
      <button class="clear-button" type="button" @click="handleClear">
        <RedoOutlined class="mr-050" />
        {{ $t("search.clearCriteria") }}
      </button>
      <EODButton
        type="primary"
        form="advanced-search"
        :name="$t('search.search')"
        htmlType="submit"
        icon="SearchOutlined"
      />
    </template>
  </Modal>
</template>

<script>
import {
  ATTACHMENTS_FIELDS_LIST,
  DOCUMENTS_FIELDS_LIST,
  EMPTY_ERRORS,
  FILE_TYPES_LIST,
  GET_FILE_TYPE_FROM_EXTENSION_LIST,
  GET_NEW_FORM,
  IS_RULE_COMPLETE,
  IS_VALUE_DISABLED,
  PROCESSES_STATUS_LIST,
  SEARCH_FIELD_TYPE,
  UPDATE_TYPES_LIST,
} from "./searchConst";
import { EODRadio, EODSelect } from "@/components/inputs";
import { InfoCircleOutlined, RedoOutlined } from "@ant-design/icons-vue";
import { Modal, Tooltip } from "ant-design-vue";
import { getFilterFieldList, searchAdvanced } from "@/services/search";

import AdvancedSearchSection from "./AdvancedSearchSection";
import { EODButton } from "@/components/ui";
import { getDeploymentsSelect } from "@/services/deployments";

export default {
  name: "AdvancedSearchModal",
  props: {
    visible: Boolean,
    advancedQuery: {
      type: [Boolean, Object],
      default: false,
    },
  },
  data() {
    return {
      SEARCH_FIELD_TYPE,
      DOCUMENTS_FIELDS_LIST,
      ATTACHMENTS_FIELDS_LIST,
      PROCESSES_STATUS_LIST,
      UPDATE_TYPES_LIST,
      FILE_TYPES_LIST,
      data: this.GET_NEW_FORM(),
      formFieldList: [],
      errors: this.EMPTY_ERRORS(),
      maxRulesInSection: 7,
    };
  },
  components: {
    AdvancedSearchSection,
    EODButton,
    EODRadio,
    EODSelect,
    InfoCircleOutlined,
    Modal,
    RedoOutlined,
    Tooltip,
  },
  computed: {
    selectedDeployment() {
      return this.data.deployment.value;
    },
  },
  created() {
    this.fetchDeploymentList();
  },
  methods: {
    GET_NEW_FORM,
    EMPTY_ERRORS,
    fetchDeploymentList(params) {
      return getDeploymentsSelect({ all_versions: true, ...params }).then(
        ({ data }) => {
          const results = data.results.map((item) =>
            item.version > 1
              ? {
                  ...item,
                  name: `${item.name} - ${this.$t("app.version")} ${
                    item.version
                  }`,
                }
              : item,
          );
          return { ...data, results };
        },
      );
    },
    fetchFilterFieldList() {
      return getFilterFieldList(this.selectedDeployment).then(({ data }) => {
        this.formFieldList = data;
      });
    },
    validateSection(section) {
      this.data[section].forEach((item) => {
        if (
          item.field &&
          !IS_VALUE_DISABLED(item.operation) &&
          item.value === ""
        ) {
          this.errors[section][item.id] = this.errors[section][item.id] || {};
          this.errors[section][item.id].phrase =
            this.errors[section][item.id].phrase || [];
          this.errors[section][item.id].phrase.push(
            this.$t("search.addValueError"),
          );
        }
        if (item.value && !item.field) {
          this.errors[section][item.id] = this.errors[section][item.id] || {};
          this.errors[section][item.id].field =
            this.errors[section][item.id].field || [];
          this.errors[section][item.id].field.push(
            this.$t("search.selectValueError"),
          );
        }
      });
    },
    handleSearch() {
      this.validateSection("documents");
      this.validateSection("attachments");
      if (this.data.deployment.value) {
        this.validateSection("formFields");
      }
      const filterErrors = (section) => {
        return section.filter((item) => Object.keys(item).length > 0);
      };
      const documentsErrors = filterErrors(this.errors.documents);
      const attachmentsErrors = filterErrors(this.errors.attachments);
      const formFieldsErrors = filterErrors(this.errors.formFields);
      if (
        documentsErrors.length !== 0 ||
        attachmentsErrors.length !== 0 ||
        formFieldsErrors.length !== 0
      ) {
        return;
      }
      const convertOperatorsForApi = (section) => {
        section.forEach((item, index) => {
          if (index === section.length - 1) {
            item.operator = null;
          } else {
            item.operator = section[index + 1].operator;
          }
        });
      };
      const filterCompleteRules = (section) => {
        return section.filter(IS_RULE_COMPLETE).map((item) => ({ ...item }));
      };
      const documents = filterCompleteRules(this.data.documents);
      const attachments = filterCompleteRules(this.data.attachments);
      const formFields = filterCompleteRules(this.data.formFields);
      convertOperatorsForApi(documents);
      convertOperatorsForApi(attachments);
      if (this.data.attachmentType.value) {
        attachments.unshift(this.data.attachmentType);
      }
      convertOperatorsForApi(formFields);
      const searchParams = [];
      if (documents.length > 0) {
        searchParams.push({
          key: SEARCH_FIELD_TYPE.DOCUMENTS,
          query: documents,
        });
      }
      if (attachments.length > 0) {
        searchParams.push({
          key: SEARCH_FIELD_TYPE.ATTACHMENT,
          query: attachments,
        });
      }
      if (this.selectedDeployment) {
        searchParams.push({
          key: SEARCH_FIELD_TYPE.DEPLOYMENT,
          query:
            this.data.processesStatus.value === null
              ? [this.data.deployment]
              : [this.data.processesStatus, this.data.deployment],
        });
      }
      if (formFields.length > 0 && this.selectedDeployment) {
        searchParams.push({
          key: SEARCH_FIELD_TYPE.FORM_FIELD,
          query: formFields,
        });
      }
      searchAdvanced({
        blocks: searchParams,
        modified: this.data.modified,
      }).then(({ data }) => {
        this.$router.replace({
          name: "SearchResult",
          params: { searchId: data },
        });
        this.onClose();
      });
    },
    handleClear() {
      this.data = this.GET_NEW_FORM();
      this.errors = this.EMPTY_ERRORS();
    },
    addNewRule(section, isFirst = false) {
      this.data[section].push({
        id: isFirst
          ? 0
          : this.data[section][this.data[section].length - 1].id + 1,
        operator: "or",
        field: null,
        operation: "contain",
        value: "",
        date: false,
      });
    },
    removeRule(section, id) {
      this.data[section].splice(
        this.data[section].findIndex((item) => item.id === id),
        1,
      );
      this.errors[section][id] = {};
    },
    onClose() {
      this.$emit("update:visible", false);
    },
    fillInQuery(query) {
      this.data.modified = query.modified;
      query.blocks.forEach((item) => {
        switch (item.key) {
          case SEARCH_FIELD_TYPE.DOCUMENTS: {
            this.data.documents = item.query.map((item, index) => ({
              ...item,
              id: index,
            }));
            break;
          }
          case SEARCH_FIELD_TYPE.ATTACHMENT: {
            this.data.attachments = [];
            item.query.forEach((item) => {
              if (item.field === "attachment_extension") {
                // prettier-ignore
                this.data.attachmentType.value =
                  GET_FILE_TYPE_FROM_EXTENSION_LIST(JSON.parse(item.value));
              } else {
                this.data.attachments.push(item);
              }
            });
            this.data.attachments.forEach((item, index) => (item.id = index));
            if (this.data.attachments.length === 0) {
              this.addNewRule("attachments", true);
            }
            break;
          }
          case SEARCH_FIELD_TYPE.DEPLOYMENT: {
            if (item.query.length === 1) {
              this.data.deployment = {
                ...item.query[0],
                value: parseInt(item.query[0].value),
              };
            } else {
              this.data.processesStatus = item.query[0];
              this.data.deployment = {
                ...item.query[1],
                value: parseInt(item.query[1].value),
              };
            }
            break;
          }
          case SEARCH_FIELD_TYPE.FORM_FIELD: {
            this.data.formFields = item.query.map((item, index) => ({
              ...item,
              id: index,
            }));
            break;
          }
        }
      });
      const convertOperatorsFromApi = (section) => {
        const operators = section.map((item) => item.operator);
        operators.unshift(null);
        operators.pop();
        section.forEach((item, index) => (item.operator = operators[index]));
      };
      convertOperatorsFromApi(this.data.documents);
      convertOperatorsFromApi(this.data.attachments);
      convertOperatorsFromApi(this.data.formFields);
    },
  },
  watch: {
    selectedDeployment(val, prevVal) {
      if (prevVal) {
        this.data.formFields = [
          {
            id: 0,
            field: null,
            value: "",
            operation: "contain",
            operator: "or",
          },
        ];
        this.errors.formFields = [];
      }
      if (val) {
        this.fetchFilterFieldList();
      }
    },
    advancedQuery(val) {
      this.fillInQuery(val);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/Variables";

.search-section {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $background-color;
  padding: 1rem 0;
  &:last-child {
    border-bottom: none;
  }

  .section-name {
    width: 10%;
    margin-bottom: 0;
    align-self: flex-start;
    padding-top: 0.6rem;
  }

  .section-content {
    flex-grow: 1;
  }

  .custom-section-content {
    flex-grow: 1;
    display: flex;
  }

  .deployment-select {
    width: 30%;
    margin-right: 2rem;
  }

  .status-radio {
    display: flex;
    align-items: center;

    .radio-label {
      white-space: nowrap;
      margin: 0 1rem 0 0;
    }
  }

  .search-element {
    display: flex;
    width: 40%;
    align-items: center;

    &:first-child {
      margin-right: 2rem;
    }

    .element-name {
      width: 50%;
      margin: 0;
    }
  }
}

.clear-button {
  padding: 0;
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  margin-right: 2rem;
}
@media (max-width: 1024px) {
  .search-section {
    flex-direction: column;
    align-items: flex-start;

    .section-name {
      width: 100%;
    }
  }
}
@media (max-width: 450px) {
  .search-section {
    .section-content {
      width: 100%;
    }
    .custom-section-content {
      flex-direction: column;
    }

    .search-element {
      flex-direction: column;
      width: 100%;

      &:first-child {
        margin-right: 0;
      }

      .element-name {
        width: 100%;
      }
      p {
        margin-bottom: 0;
      }
    }
  }
}
</style>
