<template>
  <Breadcrumbs :current="$t('modules.issues')" iconComponent="FolderOutlined">
    <EODButton
      v-if="hasPermissionToAdd"
      type="primary"
      :name="$t('issues.add')"
      icon="FolderOutlined"
      @click="openIssueModal"
    />
  </Breadcrumbs>

  <IssueForm v-model:visible="showIssueForm" v-model:issueID="issueID" />

  <IssueAddDocument
    v-if="issueID"
    v-model:visible="showAddDocument"
    v-model:issueID="issueID"
    :signature="signature"
  />

  <IssueShare
    v-model:visible="showShareForm"
    v-model:issueID="issueID"
    :owner="owner"
  />

  <Tabs @change="$scrollToTop">
    <TabPane key="1" :tab="$t('app.all')">
      <IssuesList customStore="issues" :handlers="handlers" />
    </TabPane>
    <TabPane key="2" :tab="$t('issues.inProgress')">
      <IssuesList
        :status="ISSUE_STATUS.IN_PROGRESS"
        customStore="issuesInProgress"
        :handlers="handlers"
      />
    </TabPane>
    <TabPane key="3" :tab="$t('issues.closed')">
      <IssuesList
        :status="ISSUE_STATUS.CLOSED"
        customStore="issuesClosed"
        :handlers="handlers"
      />
    </TabPane>
  </Tabs>
</template>

<script>
import { Breadcrumbs, EODButton } from "@/components/ui";

import { ISSUE_STATUS } from "@/consts/statuses";
import IssueAddDocument from "./IssueAddDocument";
import IssueForm from "./IssueForm";
import IssueShare from "./IssueShare";
import IssuesList from "./IssuesList";
import PERMISSIONS from "@/consts/permissions";
import { Tabs } from "ant-design-vue";
import { getIssuesList } from "@/services/issues";

export default {
  name: "Issues",
  data() {
    return {
      ISSUE_STATUS,
      showIssueForm: false,
      showAddDocument: false,
      showShareForm: false,
      issueID: null,
      owner: null,
      signature: null,
      handlers: {
        edit: this.handleEdit,
        addDocument: this.handleAddDocument,
        share: this.handleShare,
      },
    };
  },
  components: {
    Breadcrumbs,
    EODButton,
    IssuesList,
    IssueAddDocument,
    IssueForm,
    IssueShare,
    Tabs,
    TabPane: Tabs.TabPane,
  },
  computed: {
    hasPermissionToAdd() {
      return this.$permissions.includes(PERMISSIONS.ISSUES_ADD);
    },
  },
  methods: {
    getIssuesList,
    openIssueModal() {
      this.showIssueForm = true;
    },
    handleEdit(record) {
      this.owner = record.owner;
      this.issueID = record.id;
      this.showIssueForm = true;
    },
    handleAddDocument(record) {
      this.issueID = record.id;
      this.signature = record.signature;
      this.showAddDocument = true;
    },
    handleShare(record) {
      this.owner = record.owner;
      this.issueID = record.id;
      this.showShareForm = true;
    },
  },
};
</script>
