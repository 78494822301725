<template>
  <div class="base-table__row">
    <TableSearch v-if="hasSearch" @table-search-change="handleQuery" />
    <h3 v-else-if="title" class="mb-0 mr-1">
      <span class="mr-2">{{ title }}</span>
    </h3>
    <div v-else />
    <div class="buttons">
      <slot name="button">
        <EODButtonLink
          v-if="link"
          :to="link.route"
          :title="link.name"
          :icon="link.icon"
        />
      </slot>
      <EODButton
        v-if="!disableRefresh"
        class="ml-1"
        @click="refreshData"
        icon="ReloadOutlined"
        :name="$t('app.refresh')"
      />
    </div>
  </div>
  <Table
    :columns="columns"
    :rowSelection="rowSelection"
    :data="data"
    :pagination="cPagination"
    :loading="loading"
    :rowClassName="rowClassName"
    :scroll="scroll"
    :showHeader="showHeader"
    :rowKey="rowKey"
    :expandIcon="expandIcon"
    @table-change="handleTableChange"
    @table-expand="handleExpand"
    v-model:expandedRowKeys="cExpandedRowKeys"
  >
    <template v-for="column in Object.keys($slots)" v-slot:[column]="scope">
      <slot :name="column" v-bind="scope" />
    </template>
  </Table>
  <EODButton
    v-if="paginationType === PAGINATION_TYPE.LOAD_MORE && !isLastPage"
    @click="loadMore"
    type="primary"
    ghost
    class="mt-1"
  >
    {{ $t("app.loadMore") }}
  </EODButton>
</template>

<script>
import { EODButton, EODButtonLink } from "@/components/ui";

import Table from "./Table";
import TableSearch from "./TableSearch";

export default {
  name: "BaseTable",
  props: {
    service: Function,
    rowSelection: {
      type: Object,
      default: null,
    },
    title: String,
    columns: Array,
    expandedRowKeys: Array,
    hasSearch: { type: Boolean, default: true },
    customParams: {
      type: Object,
      default() {
        return {};
      },
    },
    // TODO: move customStore registration to BaseTable
    customStore: {
      type: [Boolean, String],
      default: false,
    },
    expandIcon: {
      type: Function,
      default: null,
    },
    customExpand: {
      type: [Boolean, Function],
      default: false,
    },
    link: {
      type: Object,
      default: null,
    },
    disableRefresh: {
      type: Boolean,
      default: false,
    },
    rowKey: {
      type: String,
      default: "id",
    },
    rowClassName: Function,
    scroll: {
      type: Object,
      default() {
        return { x: 768 };
      },
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    emitData: {
      type: Boolean,
      default: false,
    },
    paginationType: {
      type: String,
      default: "PAGES",
    },
  },
  data() {
    return {
      data: [],
      loading: false,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      query: "",
      PAGINATION_TYPE: {
        PAGES: "PAGES",
        LOAD_MORE: "LOAD_MORE",
        NONE: "NONE",
      },
      isLastPage: true,
      INVALID_PAGE: "Niepoprawna strona.",
    };
  },
  components: {
    EODButton,
    EODButtonLink,
    Table,
    TableSearch,
  },
  computed: {
    shouldRefresh() {
      return this.customStore
        ? this.$store.getters[`${this.customStore}/shouldRefresh`]
        : this.$store.state.table.shouldRefresh;
    },
    cExpandedRowKeys: {
      get() {
        return this.expandedRowKeys;
      },
      set(newValue) {
        this.$emit("update:expandedRowKeys", newValue);
      },
    },
    cPagination() {
      if (
        this.paginationType === this.PAGINATION_TYPE.LOAD_MORE ||
        this.paginationType === this.PAGINATION_TYPE.NONE
      ) {
        return false;
      } else {
        return this.pagination;
      }
    },
  },
  methods: {
    fetchData(params = {}) {
      this.loading = true;

      this.service({
        limit: this.pagination.pageSize,
        page: this.pagination.current,
        ...params,
        ...this.customParams,
      })
        .then(({ data }) => {
          if (this.paginationType === this.PAGINATION_TYPE.PAGES) {
            const pagination = { ...this.pagination };
            pagination.total = data.count;
            this.data = data.results;
            this.pagination = pagination;
          } else if (this.paginationType === this.PAGINATION_TYPE.LOAD_MORE) {
            this.isLastPage = data.last_page;
            if (this.pagination.current === 1) {
              this.data = data.results;
            } else {
              this.data = this.data.concat(data.results);
            }
          } else {
            this.data = data.results;
          }
          if (this.emitData) {
            this.$store.dispatch(`${this.customStore}/setData`, {
              data,
              params: { ...params, ...this.customParams },
            });
          }

          if (this.customStore) {
            this.$store.dispatch(`${this.customStore}/setIsFetch`);
          }
        })
        .catch((err) => {
          if (
            err.response.data?.detail === this.INVALID_PAGE &&
            this.pagination.current !== 1
          ) {
            this.pagination.current = this.pagination.current - 1;
            this.fetchData();
          }
        })
        .finally(() => (this.loading = false));
    },
    handleTableChange({ pagination, filters, sorter }) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      pager.pageSize = pagination.pageSize;
      this.pagination = pager;

      this.fetchData({
        limit: pagination.pageSize,
        page: pagination.current,
        ordering: `${sorter.order === "descend" ? "-" : ""}${
          sorter.order ? sorter.field : ""
        }`,
        search_field: this.query,
        ...filters,
      });
    },
    handleQuery(query) {
      if (this.query !== query) {
        this.query = query;
        this.pagination.current = 1;
        this.fetchData({ search_field: query, page: 1 });
      }
    },
    handleExpand({ expanded, record }) {
      if (!expanded && this.cExpandedRowKeys) {
        this.cExpandedRowKeys.splice(
          this.cExpandedRowKeys.findIndex((item) => item === record.id),
          1,
        );
      }

      if (expanded && this.customExpand) {
        this.customExpand(record);
      }

      if (expanded && record.children) {
        this.service({
          parent: record.id,
          ...this.customParams,
          // todo fix me (pagination)
          limit: 1000,
        }).then(({ data }) => {
          if (record.children.length !== data.results.length) {
            record.children = data.results;
            this.data = [...this.data];
          }
          if (this.cExpandedRowKeys) {
            this.cExpandedRowKeys.push(record.id);
          }
        });
      }
    },
    loadMore() {
      this.pagination.current++;
    },
    refreshData() {
      if (this.customStore) {
        this.$store.dispatch(`${this.customStore}/refreshTable`);
      } else {
        this.$store.dispatch("refreshTable");
      }
    },
  },
  mounted() {
    this.fetchData();
    const columns = document.querySelectorAll(".ant-table-column-has-sorters");
    columns.forEach((element) => {
      element.setAttribute("tabindex", "0");
      element.addEventListener("keydown", (evt) => {
        if (evt.code === "Enter") {
          element.click();
        }
      });
    });
  },
  watch: {
    shouldRefresh(val) {
      if (val) {
        if (this.expandedRowKeys) {
          this.$emit("update:expandedRowKeys", []);
        }
        this.fetchData({ search_field: this.query });
        if (this.customStore) {
          this.$store.dispatch(`${this.customStore}/resetRefreshTable`);
        } else {
          this.$store.dispatch("resetRefreshTable");
        }
      }
    },
    customParams() {
      this.pagination.current = 1;
      this.fetchData();
    },
    "pagination.current": function () {
      if (
        this.paginationType === this.PAGINATION_TYPE.LOAD_MORE &&
        this.pagination.current !== 1
      ) {
        this.fetchData();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.base-table__row {
  display: flex;
  justify-content: space-between;
  padding: 0 1rem 0.5rem;
}
</style>
