<template>
  <Breadcrumbs
    :path="[{ path: { name: 'Teams' }, title: $t('modules.teams') }]"
    :current="$t('permissions.teams.preview')"
  >
    <EODButtonLink
      v-if="hasPermissionToEdit"
      :to="{ name: 'TeamsEdit', params: { id: id } }"
      :title="$t('permissions.teams.edit')"
      icon="EditOutlined"
    />
  </Breadcrumbs>

  <section class="section">
    <BaseTable
      :columns="columns"
      :service="getUsersList"
      :customParams="{ group: id }"
    >
      <template v-slot:full_name="{ record }">
        {{ record.full_name }}
      </template>
      <template v-slot:groups="{ record }">
        {{ record.groups.map((e) => e.name).join(", ") }}
      </template>
    </BaseTable>
  </section>
</template>

<script>
import { Breadcrumbs, EODButtonLink } from "@/components/ui";

import { BaseTable } from "@/components/table";
import PERMISSIONS from "@/consts/permissions";
import { getUsersList } from "@/services/permissions";

export default {
  name: "TeamsPreview",
  data() {
    return {
      id: +this.$route.params?.id,
      columns: [
        {
          title: this.$t("permissions.users.fullName"),
          dataIndex: "last_name,first_name",
          slots: { customRender: "full_name" },
          sorter: true,
        },
        { title: "Email", dataIndex: "email", sorter: true },
        {
          title: this.$t("modules.teams"),
          dataIndex: "groups",
          slots: { customRender: "groups" },
          width: "20%",
        },
      ],
    };
  },
  components: {
    BaseTable,
    Breadcrumbs,
    EODButtonLink,
  },
  computed: {
    hasPermissionToEdit() {
      return this.$permissions.includes(PERMISSIONS.GROUPS_EDIT);
    },
  },
  methods: {
    getUsersList,
  },
};
</script>
