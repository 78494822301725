const repository = {
  namespaced: true,
  state: () => ({
    shouldRefresh: false,
    folder: { id: null, name: "" },
  }),
  getters: {
    folderID(state) {
      return state.folder.id;
    },
    folderName(state) {
      return state.folder.name;
    },
  },
  mutations: {
    SET_SHOULD_REFETCH(state, payload) {
      state.shouldRefetch = payload;
    },
    SET_FOLDER(state, payload) {
      state.folder = payload;
    },
  },
  actions: {
    refetch({ commit }) {
      commit("SET_SHOULD_REFETCH", true);
    },
    resetRefetch({ commit }) {
      commit("SET_SHOULD_REFETCH", false);
    },
    selectFolder({ commit }, folder) {
      commit("SET_FOLDER", folder);
    },
    resetFolder({ commit }) {
      commit("SET_FOLDER", { id: null, name: "" });
    },
  },
};

export default repository;
