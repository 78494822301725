<template>
  <Section>
    <template v-slot:button>
      <router-link :to="{ name: 'Tasks' }">
        {{ $t("tasks.allTasks") }}
      </router-link>
    </template>
    <div class="tasks-list-mobile-panel">
      <div
        v-for="task in tasks"
        :key="task.id"
        class="tasks-list-mobile-panel__item"
      >
        <div class="tasks-list-mobile-panel__task">
          <h3>{{ task.name }}</h3>
          <p class="tasks-list-mobile-panel__created">
            {{ task.created ? new Date(task.created).toLocaleString() : "-" }}
            <ExclamationCircleOutlined
              v-if="task.due ? new Date(task.due) < tomorrow : false"
              :class="
                new Date(task.due).getTime() === today.getTime()
                  ? 'color--warning'
                  : 'color--error'
              "
              :title="
                new Date(task.due).getTime() === today.getTime()
                  ? $t('tasks.dueComing')
                  : $t('tasks.pastDue')
              "
            />
            <ArrowUpOutlined
              v-if="task.priority >= 50"
              :class="task.priority === 50 ? 'color--warning' : 'color--error'"
              :title="
                task.priority === 50 ? $t('tasks.medium') : $t('tasks.high')
              "
              :style="{ fontSize: '1 rem' }"
            />
            <ArrowDownOutlined
              v-else
              class="color--success"
              :title="$t('tasks.low')"
              :style="{ fontSize: '1 rem' }"
            />
          </p>
        </div>
        <div class="tasks-list-mobile-panel__action">
          <Dropdown :trigger="['click', 'hover']">
            <EODButton class="ml-1">
              <MoreOutlined />
            </EODButton>
            <template v-slot:overlay>
              <Menu selectable>
                <MenuItem key="1">
                  <router-link
                    :to="{
                      name: 'TaskPreview',
                      params: { id: task.id },
                    }"
                    :title="$t('app.preview')"
                    >{{ $t("app.preview") }}</router-link
                  >
                </MenuItem>
                <MenuItem key="2">
                  <router-link
                    v-if="task?.actions?.complete"
                    :to="{
                      name: 'TaskForm',
                      params: { id: task.id },
                    }"
                    :title="$t('tasks.start')"
                    >{{ $t("tasks.start") }}</router-link
                  >
                </MenuItem>
                <MenuItem
                  v-if="task?.actions?.unclaim"
                  @click="handleUnclaim(task)"
                  :title="$t('tasks.unclaim')"
                  key="3"
                >
                  {{ $t("tasks.unclaim") }}
                </MenuItem>
                <MenuItem
                  v-if="task?.actions?.claim"
                  @click="handleClaim(task)"
                  :title="$t('tasks.claim')"
                  key="4"
                >
                  {{ $t("tasks.claim") }}
                </MenuItem>
              </Menu>
            </template>
          </Dropdown>
        </div>
      </div>
    </div>
  </Section>
</template>

<script>
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  ExclamationCircleOutlined,
  MoreOutlined,
} from "@ant-design/icons-vue";
import { Dropdown, Menu } from "ant-design-vue";
import { claimTask, unclaimTask } from "@/services/tasks";
import EODButton from "@/components/ui/EODButton.vue";
import { Section } from "@/components/ui";

export default {
  name: "OwnTaskListMobile",
  props: {
    service: Function,
    customStore: {
      type: String,
      default: "",
    },
    successfulClaim: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      tasks: [],
      errors: {},
      columns: [
        {
          title: this.$t("app.name"),
          dataIndex: "name",
          slots: { customRender: "name" },
          sorter: true,
        },
        {
          title: this.$t("app.actions"),
          dataIndex: "actions",
          slots: { customRender: "actions" },
          width: "30%",
        },
      ],
    };
  },
  components: {
    Section,
    Dropdown,
    Menu,
    MenuItem: Menu.Item,
    MoreOutlined,
    EODButton,
    ArrowDownOutlined,
    ArrowUpOutlined,
    ExclamationCircleOutlined,
  },
  computed: {
    shouldRefresh() {
      return this.$store.getters[`${this.customStore}/shouldRefresh`];
    },
  },
  created() {
    this.fetchTasksList();
  },
  methods: {
    fetchTasksList() {
      this.service().then(({ data: { results } }) => {
        this.tasks = results;
      });
    },
    handleClaim(task) {
      claimTask(task, task.id).then(({ data }) => {
        this.successfulClaim(data);
      });
    },

    handleUnclaim(task) {
      unclaimTask(task, task.id).then(({ data }) => {
        this.successfulClaim(data);
      });
    },
  },
  watch: {
    shouldRefresh(val) {
      if (val) {
        this.fetchTasksList();
        this.$store.dispatch(`${this.customStore}/resetRefreshTable`);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/Variables";

.tasks-list-mobile-panel {
  padding: 1rem;
  &__item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &__created {
    color: $grey;
  }
  &__task {
    text-align: left;
  }
}
</style>
