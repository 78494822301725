import APPS from "@/consts/apps";
import IssuePreview from "@/views/Issues/IssuePreview.vue";
import Issues from "@/views/Issues/Issues.vue";
import PERMISSIONS from "@/consts/permissions";
import i18n from "@/i18n";

const t = i18n.global.t;

const issues = [
  {
    path: "/issues/",
    name: "Issues",
    component: Issues,
    meta: {
      title: `${t("meta.app")} - ${t("issues.meta.list")}`,
      requiresAuth: true,
      permission: PERMISSIONS.ISSUES,
      app: APPS.ISSUES,
    },
  },
  {
    path: "/issues/:id/preview/",
    name: "IssuePreview",
    component: IssuePreview,
    meta: {
      title: `${t("meta.app")} - ${t("issues.meta.preview")}`,
      requiresAuth: true,
      permission: PERMISSIONS.ISSUES,
      app: APPS.ISSUES,
    },
  },
];

export default issues;
