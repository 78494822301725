<template>
  <Section
    id="address"
    v-if="id"
    :title="$t('contractors.address')"
    :collapsable="true"
    v-model:collapsed="collapse"
  >
    <div class="form-container">
      <form
        id="address-form"
        @submit.prevent="submitAddress"
        class="two-column-form"
      >
        <div class="column">
          <EODSelect
            id="address_country"
            storeID="country"
            v-model:value="address.address_country"
            required
            :label="$t('contractors.country')"
            :service="countriesService"
            :defaultValue="defaultValues.address_country"
            :errors="errors.address_country"
          />

          <EODInput
            id="address_district"
            v-model:value="address.address_district"
            :label="$t('contractors.district')"
            :maxlength="20"
            :errors="errors.address_district"
            @invalid="onInvalid()"
          />

          <EODInput
            id="address_city"
            v-model:value="address.address_city"
            :label="$t('contractors.city')"
            required
            :maxlength="30"
            :errors="errors.address_city"
            @invalid="onInvalid()"
          />

          <EODInput
            id="address_street_name"
            v-model:value="address.address_street_name"
            :label="$t('contractors.streetName')"
            required
            :maxlength="40"
            :errors="errors.address_street_name"
            @invalid="onInvalid()"
          />

          <EODInput
            id="address_street_suite"
            v-model:value="address.address_street_suite"
            :label="$t('contractors.streetSuite')"
            :maxlength="6"
            :errors="errors.address_street_suite"
            @invalid="onInvalid()"
          />
        </div>

        <div class="column">
          <EODSelect
            id="address_state"
            v-model:value="address.address_state"
            :label="$t('contractors.voivodeship')"
            :service="fetchAddressStates"
            :defaultValue="defaultValues.address_state"
            :errors="errors.address_state"
          />

          <EODInput
            id="address_municipality"
            v-model:value="address.address_municipality"
            :label="$t('contractors.municipality')"
            :maxlength="20"
            :errors="errors.address_municipality"
            @invalid="onInvalid()"
          />

          <EODInput
            id="address_post_code"
            v-model:value="address.address_post_code"
            :label="$t('contractors.postCode')"
            required
            :maxlength="10"
            :errors="errors.address_post_code"
            @invalid="onInvalid()"
          />

          <EODInput
            id="address_street_number"
            v-model:value="address.address_street_number"
            :label="$t('contractors.streetNumber')"
            required
            :maxlength="10"
            :errors="errors.address_street_number"
            @invalid="onInvalid()"
          />

          <EODInput
            id="post_office"
            v-model:value="address.post_office"
            :label="$t('contractors.postOffice')"
            required
            :maxlength="30"
            :errors="errors.post_office"
            @invalid="onInvalid()"
          />
        </div>
      </form>
    </div>
  </Section>
</template>

<script>
import { EODInput, EODSelect } from "@/components/inputs";
import {
  createAddress,
  editAddress,
  getAddressStateOption,
} from "@/services/contractors";

import { Section } from "@/components/ui";

export default {
  name: "AddressForm",
  props: {
    id: Number,
    modelData: Object,
    modelErrors: Object,
    modelCollapse: Boolean,
    promiseArray: Array,
    countriesService: Function,
    defaultValues: Object,
  },
  components: { EODInput, EODSelect, Section },
  computed: {
    address: {
      get() {
        return this.modelData;
      },
      set(val) {
        this.$emit("update:modelData", val);
      },
    },
    errors: {
      get() {
        return this.modelErrors;
      },
      set(val) {
        this.$emit("update:modelErrors", val);
      },
    },
    collapse: {
      get() {
        return this.modelCollapse;
      },
      set(val) {
        this.$emit("update:modelCollapse", val);
      },
    },
    promises: {
      get() {
        return this.promiseArray;
      },
      set(val) {
        this.$emit("update:promiseArray", val);
      },
    },
    shouldSubmit() {
      return this.$store.state.contractors.submitAddress;
    },
  },
  emits: ["update:modelCollapse", "update:modelErrors"],
  methods: {
    submitAddress() {
      const service = this.address.id ? editAddress : createAddress;

      this.promises.push(
        service({ ...this.address, contractor: this.id }, this.address.id)
          .then(({ data }) => {
            this.address.id = data.id;
            this.errors = {};
            return true;
          })
          .catch((err) => {
            this.errors = err.response.data;
            return false;
          }),
      );
    },
    onInvalid() {
      this.collapse = false;
    },
    fetchAddressStates(params) {
      return getAddressStateOption(params).then(({ data }) => data);
    },
  },
  watch: {
    shouldSubmit(val) {
      if (val) {
        this.submitAddress();
        this.$store.dispatch("resetSubmitAddress");
      }
    },
  },
};
</script>
