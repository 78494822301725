<template>
  <router-link class="pl-1" :to="to" :title="title" tabindex="-1">
    <Button type="primary">
      <slot><component v-if="icon" :is="icon" />{{ title }}</slot>
    </Button>
  </router-link>
</template>

<script>
import { EditOutlined, PlusOutlined } from "@ant-design/icons-vue";

import { Button } from "ant-design-vue";

export default {
  name: "EODButtonLink",
  props: {
    to: Object,
    title: String,
    icon: { type: String, default: "PlusOutlined" },
  },
  components: { Button, EditOutlined, PlusOutlined },
};
</script>
