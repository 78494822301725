<template>
  <Modal
    v-model:visible="isOpen"
    :title="$t('contractors.gusTitle')"
    @ok="fill"
    :okText="$t('contractors.completeData')"
    :okButtonProps="{ loading: loading$ }"
  >
    <form @submit.prevent="fetchData" class="input-button">
      <EODInput
        v-model:value="nip"
        pattern="^\d{10}$"
        :label="$t('contractors.nip')"
      />
      <EODButton
        type="primary"
        htmlType="submit"
        class="mb-050 ml-050"
        :name="$t('contractors.downloadData')"
      />
    </form>

    <div class="overflow">
      <Preview
        v-if="previewContractor.length > 0"
        :oid="1"
        :data="previewContractor"
        :title="$t('contractors.basicInfo')"
      />

      <Preview
        v-if="previewAddress.length > 0"
        :oid="1"
        :data="previewAddress"
        :title="$t('contractors.address')"
      />
    </div>
  </Modal>
</template>

<script>
import { EODButton } from "@/components/ui";
import { EODInput } from "@/components/inputs";
import { Modal } from "ant-design-vue";
import { Preview } from "@/components/common";
import { getDataFromGUS } from "@/services/contractors";

export default {
  name: "GUSModal",
  props: {
    visible: Boolean,
    initialNIP: [String, Number],
    modelContractor: Object,
    modelAddress: Object,
  },
  data() {
    return {
      data: {},
      previewAddress: [],
      previewContractor: [],
      nip: "",
    };
  },
  components: { EODButton, EODInput, Modal, Preview },
  computed: {
    isOpen: {
      get() {
        return this.visible;
      },
      set(value) {
        this.$emit("update:visible", value);
      },
    },
    contractor: {
      get() {
        return this.modelContractor;
      },
      set(val) {
        this.$emit("update:modelContractor", val);
      },
    },
    address: {
      get() {
        return this.modelAddress;
      },
      set(val) {
        val["address_country"] = val["address_country"]?.id;
        val["address_state"] = val["address_state"]?.id;
        this.$emit("update:modelAddress", val);
      },
    },
  },
  emits: ["update:visible", "update:modelContractor", "update:modelAddress"],
  methods: {
    fetchData() {
      getDataFromGUS(this.nip).then(({ data }) => {
        this.data = data;

        this.previewContractor = [
          { title: this.$t("app.name"), value: data.name },
          {
            title: this.$t("contractors.shortcut"),
            value: data.short_name,
          },
          { title: this.$t("contractors.nip"), value: data.nip },
        ];

        this.previewAddress = [
          {
            title: this.$t("contractors.country"),
            value: data.address_country?.name || "-",
          },
          {
            title: this.$t("contractors.voivodeship"),
            value: data.address_state?.name || "-",
          },
          {
            title: this.$t("contractors.district"),
            value: data.address_district,
          },
          {
            title: this.$t("contractors.municipality"),
            value: data.address_municipality,
          },
          {
            title: this.$t("contractors.city"),
            value: data.address_city,
          },
          {
            title: this.$t("contractors.postCode"),
            value: data.address_post_code,
          },
          {
            title: this.$t("contractors.streetName"),
            value: data.address_street_name,
          },
          {
            title: this.$t("contractors.streetNumber"),
            value: data.address_street_number,
          },
          {
            title: this.$t("contractors.streetSuite"),
            value: data.address_street_suite || "-",
          },
          {
            title: this.$t("contractors.postOffice"),
            value: data.post_office,
          },
        ];
      });
    },
    returnNonEmptyFields(object) {
      return Object.fromEntries(
        Object.entries(object)
          .map(([key, value]) => {
            if (value) {
              return [key, value];
            }
          })
          .filter((item) => item),
      );
    },
    fill() {
      this.loading$ = true;
      // eslint-disable-next-line no-unused-vars
      const { name, short_name, nip, url, ...rest } = this.data;

      this.contractor = {
        ...this.contractor,
        ...this.returnNonEmptyFields({
          name,
          short_name,
          nip,
        }),
      };
      this.address = { ...this.address, ...this.returnNonEmptyFields(rest) };
      this.isOpen = false;
      this.loading$ = false;
    },
  },
  watch: {
    visible(val) {
      if (val) {
        this.nip = this.initialNIP;
        if (/^\d{10}$/.test(this.nip)) {
          this.fetchData();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/Contractors";

.overflow {
  max-height: 400px;
  overflow-y: auto;
}
</style>
