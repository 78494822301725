<template>
  <Section id="contact" v-if="id" :title="$t('contractors.contactData')">
    <div class="form-container">
      <form
        id="contact-form"
        @submit.prevent="submitContact(idx)"
        class="two-column-form"
      >
        <div class="column">
          <EODInput
            id="first_name"
            v-model:value="contact.first_name"
            :label="$t('contractors.firstName')"
            :maxlength="15"
            :errors="errors.first_name"
            @invalid="onInvalid()"
          />

          <EODInput
            id="phone"
            v-model:value="contact.phone"
            :label="$t('contractors.phone')"
            type="tel"
            :maxlength="60"
            :errors="errors.phone"
            @invalid="onInvalid()"
          />

          <EODInput
            id="fax"
            v-model:value="contact.fax"
            :label="$t('contractors.fax')"
            :maxlength="30"
            :errors="errors.fax"
            @invalid="onInvalid()"
          />
        </div>

        <div class="column">
          <EODInput
            id="last_name"
            v-model:value="contact.last_name"
            :label="$t('contractors.lastName')"
            :maxlength="35"
            :errors="errors.last_name"
            @invalid="onInvalid()"
          />

          <EODInput
            id="email"
            v-model:value="contact.email"
            :label="$t('contractors.email')"
            type="email"
            :maxlength="50"
            :errors="errors.email"
            @invalid="onInvalid()"
          />
        </div>

        <div class="buttons">
          <EODButton
            v-if="contactID && hasPermissionToDelete"
            type="danger"
            ghost
            :name="$t('app.delete')"
            @click="confirmDelete"
            icon="DeleteOutlined"
          />

          <EODButton
            type="primary"
            htmlType="submit"
            :name="buttonTitle"
            :disabled="isButtonDisabled"
            :loading="loading$"
          />
        </div>
      </form>
    </div>
  </Section>
</template>

<script>
import { EODButton, Section } from "@/components/ui";
import {
  createContact,
  deleteContact,
  editContact,
  getContact,
} from "@/services/contractors";

import { EODInput } from "@/components/inputs";
import PERMISSIONS from "@/consts/permissions";

export default {
  name: "ContactForm",
  props: {
    id: Number,
    isDataEmpty: Function,
    showContactForm: Boolean,
  },
  data() {
    return {
      contact: {},
      errors: {},
    };
  },
  components: {
    EODButton,
    EODInput,
    Section,
  },
  computed: {
    contactID() {
      return this.$store.state.contractors.contactID;
    },
    buttonTitle() {
      return this.contactID ? this.$t("app.saveChanges") : this.$t("app.add");
    },
    isButtonDisabled() {
      return this.isDataEmpty(this.contact);
    },
    hasPermissionToDelete() {
      return this.$permissions.includes(
        PERMISSIONS.CONTRACTORS_CONTACT_DATA_DELETE,
      );
    },
  },
  methods: {
    fetchContact() {
      if (this.contactID) {
        getContact(this.contactID).then(({ data }) => {
          this.contact = data;
        });
      } else {
        this.contact = {};
        this.contact.contractor = this.id;
        this.errors = {};
      }
    },
    submitContact() {
      this.loading$ = true;
      const service = this.contact.id ? editContact : createContact;

      service({ ...this.contact, contractor: this.id }, this.contact.id)
        .then(() => {
          this.$emit("update:showContactForm", false);
          this.$store.dispatch("setContactID", undefined);
          this.$store.dispatch("refreshTable");
          this.$store.dispatch("setEntryID", undefined);
          this.$store.dispatch("closeModal");
          this.$message.success(this.$t("app.success"));
          this.contact = {};
          this.contact.contractor = this.contractorID;
          this.errors = {};
        })
        .catch((err) => {
          this.errors = err.response.data;
        })
        .finally(() => {
          this.loading$ = false;
        });
    },
    confirmDelete() {
      this.$store.dispatch("setModal", {
        message: this.$t("contractors.doYouWantToDeleteContact"),
        title: this.$t("contractors.deleteContact"),
        modalOk: () => this.removeContact(),
      });
    },
    removeContact() {
      deleteContact(this.contact.id).then(() => {
        this.$message.success(this.$t("app.success"));
        this.$store.dispatch("closeModal");
        this.$store.dispatch("refreshTable");
        this.$store.dispatch("setContactID", undefined);
      });
    },
  },
  watch: {
    contactID() {
      this.fetchContact();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/Contractors";
</style>
