<template>
  <Section
    id="attachments"
    v-if="id"
    :title="$t('app.attachments')"
    :collapsable="true"
    v-model:collapsed="collapse"
  >
    <FileUploader
      :fileURL="attachmentURL"
      v-model:fileList="attachments"
      :downloadFileURL="downloadContractorFileURL"
      v-model:newAttachmentsWithDesc="cNewAttachmentsWithDesc"
      @preview-file="handlePreview"
      multiple
    >
      <template v-slot:filePreview />
    </FileUploader>
  </Section>
</template>

<script>
import { FileUploader } from "@/components/inputs";
import { Section } from "@/components/ui";
import { downloadContractorFileURL } from "@/services/contractors";

export default {
  name: "AttachmentsForm",
  props: {
    id: Number,
    modelData: Object,
    modelCollapse: Boolean,
    attachmentURL: String,
    newAttachmentsWithDesc: Array,
    handlePreview: {
      type: Function,
      default: () => {},
    },
  },
  components: { FileUploader, Section },
  computed: {
    attachments: {
      get() {
        return this.modelData;
      },
      set(val) {
        this.$emit("update:modelData", val);
      },
    },

    collapse: {
      get() {
        return this.modelCollapse;
      },
      set(val) {
        this.$emit("update:modelCollapse", val);
      },
    },
    cNewAttachmentsWithDesc: {
      get() {
        return this.newAttachmentsWithDesc;
      },
      set(val) {
        this.$emit("update:newAttachmentsWithDesc", val);
      },
    },
  },
  emits: ["update:modelCollapse"],
  methods: {
    downloadContractorFileURL,
  },
};
</script>
