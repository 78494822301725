<template>
  <Breadcrumbs
    :path="[{ path: { name: 'Mailboxes' }, title: $t('modules.mailboxes') }]"
    :current="$t('mailboxes.meta.preview')"
    iconComponent="MailOutlined"
  >
    <EODButtonLink
      v-if="hasPermissionToEdit"
      :to="{ name: 'MailboxEdit', params: { id } }"
      :title="$t('mailboxes.meta.edit')"
      icon="EditOutlined"
    />
  </Breadcrumbs>

  <Preview
    :oid="id"
    :data="previewData"
    :title="$t('mailboxes.info')"
    :noPreviewMsg="$t('mailboxes.noPreview')"
    :collapsable="true"
    v-model:collapsed="collapse.info"
  />

  <Section :title="$tc('mailboxes.messages.message', 2)" v-if="id">
    <Tabs>
      <TabPane key="1" :tab="$t('mailboxes.messages.inbox')" class="mt-1">
        <MessagesList :service="getMailboxMessageList" :id="id" />
      </TabPane>
      <TabPane
        key="2"
        :tab="$t('mailboxes.messages.deletedMessages')"
        class="mt-1"
      >
        <MessagesList
          :service="getDeletedMailboxMessageList"
          :id="id"
          deletedPreview
        />
      </TabPane>
    </Tabs>
  </Section>
</template>

<script>
import { Breadcrumbs, EODButtonLink, Section } from "@/components/ui";
import {
  getDeletedMailboxMessageList,
  getMailbox,
  getMailboxMessageList,
} from "@/services/mailboxes";

import MessagesList from "./MessagesList";
import { Preview } from "@/components/common";
import { Tabs } from "ant-design-vue";

export default {
  name: "MailboxPreview",
  data() {
    return {
      collapse: {
        info: true,
      },
      id: this.$route.params?.id,
      previewData: [],
      messages: [],
      hasPermissionToEdit: false,
    };
  },
  components: {
    Breadcrumbs,
    EODButtonLink,
    Preview,
    Section,
    MessagesList,
    Tabs,
    TabPane: Tabs.TabPane,
  },
  created() {
    if (this.id) {
      getMailbox(this.id).then(({ data }) => {
        this.previewData = [
          { title: this.$t("app.name"), value: data.name },
          { title: this.$t("mailboxes.host"), value: data.host },
          { title: this.$t("mailboxes.username"), value: data.username },
          { title: this.$t("mailboxes.port"), value: data.port },
        ];
        this.hasPermissionToEdit = data.actions.editable;
      });
    }
  },
  methods: {
    getMailbox,
    getMailboxMessageList,
    getDeletedMailboxMessageList,
  },
};
</script>
