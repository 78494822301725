<template>
  <Breadcrumbs
    :path="[{ path: { name: 'Users' }, title: $t('modules.users') }]"
    :current="`${$t('app.editActivity')} ${$tc('permissions.users.users', 2)}`"
    iconComponent="UserOutlined"
    :what="$tc('permissions.users.users', 0)"
  />

  <Section
    :title="`${$t('app.editActivity')} ${$tc('permissions.users.users', 2)}`"
  >
    <div class="form-container">
      <form @submit.prevent="submit" class="form">
        <EODInput
          id="full_name"
          v-model:value="data.full_name"
          :label="$t('permissions.users.fullName')"
          required
          disabled
          :maxlength="250"
          :errors="errors.full_name"
        />

        <EODInput
          id="email"
          v-model:value="data.email"
          label="Email"
          required
          disabled
          :maxlength="250"
          :errors="errors.email"
        />

        <EODInput
          id="registered_at"
          v-model:value="data.registered_at"
          :label="$t('permissions.users.registered')"
          required
          disabled
          :maxlength="250"
          :errors="errors.registered_at"
        />

        <EODSelect
          id="superior"
          v-model:value="data.superior_of_units"
          :label="$t('permissions.users.superior')"
          disabled
          mode="multiple"
          :options="defaultUnitNames"
          :errors="errors.superior"
        />

        <EODSelect
          id="roles"
          v-model:value="data.roles"
          mode="multiple"
          :label="$tc('permissions.roles.roles', 0)"
          :service="fetchRoles"
          :defaultValue="defaultRoles"
          :errors="errors.roles"
        />

        <EODSelect
          v-if="APPS.UNITS"
          id="unit"
          v-model:value="data.units"
          :label="$t('permissions.users.unit', 0)"
          mode="multiple"
          :service="fetchUnits"
          :defaultValue="defaultUnitNames"
          :errors="errors.unit"
          :disabled="!checkUserSource"
        />

        <EODInput
          id="source"
          v-model:value="data.source"
          :label="$t('permissions.users.source')"
          disabled
          :maxlength="250"
          :errors="errors.source"
        />

        <EODSelect
          id="groups"
          v-model:value="data.groups"
          mode="multiple"
          :label="$t('modules.teams')"
          :service="fetchGroups"
          :defaultValue="defaultGroups"
          :errors="errors.groups"
        />

        <Button
          class="mt-1"
          type="primary"
          htmlType="submit"
          :title="$t('app.save')"
          :loading="loading$"
        >
          {{ $t("app.save") }}
        </Button>
      </form>
    </div>
  </Section>
</template>

<script>
import { Breadcrumbs, Section } from "@/components/ui";
import { EODInput, EODSelect } from "@/components/inputs";
import {
  editUserForm,
  getGroupsSimpleList,
  getUser,
} from "@/services/permissions";

import APPS from "@/consts/apps";
import { Button } from "ant-design-vue";
import { getUnitsFlatSelect } from "@/services/units";

export default {
  name: "UsersForm",
  data() {
    return {
      id: +this.$route.params?.id,
      data: {
        full_name: "",
        email: "",
        registered_at: "",
        roles: [],
        groups: [],
        units: [],
        source: "",
      },
      defaultRoles: null,
      defaultUnitNames: null,
      defaultGroups: null,
      errors: {},
      APPS,
    };
  },
  components: {
    Breadcrumbs,
    Button,
    EODInput,
    EODSelect,
    Section,
  },
  computed: {
    checkUserSource() {
      return (
        this.data.source === "cbu - student" ||
        this.data.source === "cbu - guest"
      );
    },
  },
  methods: {
    submit() {
      this.loading$ = true;
      editUserForm(this.data, this.id)
        .then(() => {
          this.$router.push({ name: "Users" });
          this.$message.success(this.$t("app.success"));
        })
        .catch((err) => {
          this.errors = err.response.data;
        })
        .finally(() => {
          this.loading$ = false;
        });
    },
    fetchRoles(params) {
      return getGroupsSimpleList({ roles: true, ...params }).then(
        ({ data }) => data,
      );
    },
    fetchUnits(params) {
      return getUnitsFlatSelect(params).then(({ data }) => data);
    },
    fetchGroups(params) {
      return getGroupsSimpleList(params).then(({ data }) => data);
    },
  },
  mounted() {
    if (this.id) {
      getUser(this.id).then(({ data }) => {
        this.data = data;
        this.defaultRoles = data.roles;
        this.data.roles = data.roles.map((item) => item.id);
        this.defaultGroups = data.groups;
        this.data.groups = data.groups.map((item) => item.id);
        this.defaultUnitNames = data.units;
        this.data.units = data.units.map((item) => item.id);
      });
    }
  },
};
</script>
