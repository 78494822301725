const SYSTEM_DICT = {
  DOCUMENT_TYPES: "document_types",
  ABSENCE_TYPES: "absence_types",
  CREDIT_TYPE: "trade_credit_type",
  CONTRACTOR_CLASS: "contractor_class",
  COUNTRY: "address_country",
  STATE: "address_state",
  PAYMENT_CONDITIONS: "payment_conditions",
  CURRENCY: "payment_currency",
  BANK: "bank",
  TASK_PRIORITY: "task_priority",
  JRWA_ARCHIVE_CATEGORY: "jrwa_archive_category",
};

export default SYSTEM_DICT;
