<template>
  <Breadcrumbs :current="$t('modules.roles')" iconComponent="UserOutlined">
    <EODButton
      v-if="hasPermissionToAdd"
      class="ml-1"
      @click="openCreateFromModal"
      icon="PlusOutlined"
      :name="$t('app.createFrom')"
    />
    <EODButtonLink
      v-if="hasPermissionToAdd"
      :to="{
        name: 'RolesCreate',
      }"
      :title="$t('permissions.roles.add')"
    />
  </Breadcrumbs>

  <section class="section">
    <BaseTable :columns="columns" :service="getRolesList">
      <template v-slot:actions="{ record }">
        <TableActions
          :record="record"
          :previewRoute="{
            name: 'RolesPreview',
            params: { id: record.id },
          }"
          :editRoute="{
            name: 'RolesEdit',
            params: { id: record.id },
          }"
          :deleteMessage="`${$t('app.doYouWantToDelete')} ${$tc(
            'permissions.roles.roles',
            2,
          )} &quot;${record.name}&quot;? ${$tc('permissions.roles.deleteInfo', [
            record.user_count,
          ])}`"
          :modalTitle="$t('permissions.roles.delete')"
          :deleteService="deleteRole"
        />
      </template>
    </BaseTable>
  </section>
  <SelectModal
    v-model:visible="showCreateFromModal"
    v-model:selectedOption="selectedSourceId"
    :title="$t('app.createFrom')"
    :body="$t('permissions.roles.createFromInfo')"
    :service="fetchRolesList"
    :onSubmit="handleCreateFrom"
    :onCancel="closeCreateFromModal"
    selectId="rolesToCreate"
  />
</template>

<script>
import { BaseTable, TableActions } from "@/components/table";
import { Breadcrumbs, EODButton, EODButtonLink } from "@/components/ui";
import { deleteRole, getRolesList } from "@/services/permissions";
import PERMISSIONS from "@/consts/permissions";
import { SelectModal } from "@/components/modals";

export default {
  name: "Roles",
  data() {
    return {
      showCreateFromModal: false,
      selectedSourceId: null,
      columns: [
        { title: this.$t("app.name"), dataIndex: "name", sorter: true },
        {
          title: this.$t("permissions.roles.numberOfUsers"),
          dataIndex: "user_count",
        },
        {
          title: this.$t("app.actions"),
          dataIndex: "actions",
          slots: { customRender: "actions" },
          fixed: "right",
          width: "120",
        },
      ],
    };
  },
  components: {
    BaseTable,
    Breadcrumbs,
    EODButton,
    EODButtonLink,
    SelectModal,
    TableActions,
  },
  computed: {
    hasPermissionToAdd() {
      return this.$permissions.includes(PERMISSIONS.ROLES_ADD);
    },
  },
  methods: {
    deleteRole,
    getRolesList,
    fetchRolesList(params) {
      return getRolesList(params).then(({ data }) => data);
    },
    openCreateFromModal() {
      this.showCreateFromModal = true;
    },
    closeCreateFromModal() {
      this.showCreateFromModal = false;
      this.selectedSourceId = null;
    },
    handleCreateFrom() {
      if (this.selectedSourceId) {
        this.$router.replace({
          name: "RolesCreateFrom",
          params: { sourceId: this.selectedSourceId },
        });
      } else {
        this.$message.error(this.$t("app.error"));
      }
    },
  },
};
</script>
