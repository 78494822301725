
import { Breadcrumbs, EODButton } from "@/components/ui";
import { EODDatePicker, EODSelect } from "@/components/inputs";
import { EyeOutlined, InfoCircleOutlined } from "@ant-design/icons-vue";
import { Params, SelectOption } from "@/interfaces/common";
import { defineComponent, ref } from "vue";
import {
  getDepartmentsList,
  getDeploymentCategory,
  getFieldsOfStudyList,
  getProposalsStatuses,
  getYearsListForProposal,
} from "@/services/dictionaries";

import { BaseTable } from "@/components/table";
import { DRAFT_STATUS_NAME } from "@/consts/statuses";
import { DictionaryEntriesParams } from "@/interfaces/dictionaries";
import { Tooltip } from "ant-design-vue";
import dynamicTable from "@/store/modules/dynamicTable";
import { getProcessesList } from "@/services/deployments";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";

export default defineComponent({
  name: "Proposals",
  setup() {
    const customParams = { with_proposal: true };

    const { t } = useI18n();
    const store = useStore();
    const customStore = "proposals-list";

    if (!store.hasModule(customStore)) {
      store.registerModule(customStore, dynamicTable);
    }

    const filters = ref<Record<string, string>>({
      deployment__category: null,
      proposal__status: null,
      proposal__field_of_study: null,
      proposal__department: null,
      proposal__year_of_study: null,
      proposal__submission_date__date__gte: null,
      proposal__submission_date__date__lte: null,
      proposal__end_date__date__gte: null,
      proposal__end_date__date__lte: null,
    });

    const columns = [
      {
        dataIndex: "processId",
        slots: { customRender: "processId" },
        width: "3%",
      },
      {
        title: t("proposal.title"),
        dataIndex: "proposal__name",
        slots: { customRender: "name" },
        sorter: true,
      },
      {
        title: t("proposal.applicant"),
        dataIndex: "proposal__applicant",
        slots: { customRender: "applicant" },
        sorter: true,
      },
      {
        title: t("proposal.submissionDate"),
        dataIndex: "proposal__submission_date",
        slots: { customRender: "submission_date" },
        sorter: true,
      },
      {
        title: t("issues.endDate"),
        dataIndex: "proposal__end_date",
        slots: { customRender: "end_date" },
        sorter: true,
      },
      {
        title: t("app.status"),
        dataIndex: "proposal__status__name",
        slots: { customRender: "status" },
        sorter: true,
      },
      {
        title: t("app.actions"),
        dataIndex: "actions",
        slots: { customRender: "actions" },
        fixed: "right",
        width: "70",
      },
    ];

    const fetchDeploymentCategory = (params: Params) => {
      return getDeploymentCategory(params).then(
        ({ data }: { data: any }) => data,
      );
    };
    const fetchProposalsStatuses = (params: Params) => {
      //TODO: ET3U-380
      return getProposalsStatuses(params).then(({ data }: { data: any }) => ({
        ...data,
        results: data.results.filter(
          (item: SelectOption) => item.name !== DRAFT_STATUS_NAME,
        ),
      }));
    };
    const fetchFieldsOfStudyList = (params: DictionaryEntriesParams) => {
      return getFieldsOfStudyList(params).then(
        ({ data }: { data: any }) => data,
      );
    };
    const fetchDepartmentsList = (params: DictionaryEntriesParams) => {
      return getDepartmentsList(params).then(({ data }: { data: any }) => data);
    };
    const fetchYearsListForProposal = (params: DictionaryEntriesParams) => {
      return getYearsListForProposal(params).then(
        ({ data }: { data: any }) => data,
      );
    };

    const refreshProposalsList = () => {
      if (store.hasModule(customStore)) {
        store.dispatch(`${customStore}/refreshTable`);
      }
    };

    const handleFilter = () => {
      refreshProposalsList();
    };

    const handleFilterReset = () => {
      filters.value = {
        deployment__category: null,
        proposal__status: null,
        proposal__field_of_study: null,
        proposal__department: null,
        proposal__year_of_study: null,
        proposal__submission_date__date__gte: null,
        proposal__submission_date__date__lte: null,
        proposal__end_date__date__gte: null,
        proposal__end_date__date__lte: null,
      };
      refreshProposalsList();
    };

    return {
      columns,
      getProcessesList,
      customStore,
      filters,
      fetchDeploymentCategory,
      fetchProposalsStatuses,
      fetchFieldsOfStudyList,
      fetchDepartmentsList,
      fetchYearsListForProposal,
      handleFilter,
      handleFilterReset,
      customParams,
    };
  },
  components: {
    BaseTable,
    Breadcrumbs,
    EODDatePicker,
    EODSelect,
    EyeOutlined,
    EODButton,
    InfoCircleOutlined,
    Tooltip,
  },
});
