<template>
  <Breadcrumbs
    :path="[
      { path: { name: 'Dictionaries' }, title: $t('modules.dictionaries') },
    ]"
    :current="title"
    iconComponent="SortAscendingOutlined"
    :what="$tc('dictionaries.dictionary', 0)"
  >
    <EODButton
      v-if="id && hasPermissionToDelete"
      type="danger"
      ghost
      :name="`${$t('app.delete')} ${$tc('dictionaries.dictionary')}`"
      @click="confirmDelete"
      icon="DeleteOutlined"
    />
  </Breadcrumbs>

  <DictionariesFormComponent
    id="dict"
    :dictID="id"
    @dict-name="setDictName"
    @dict-id="setDictID"
  />
  <DictionaryEntriesForm
    id="entry"
    v-if="id && showEntry"
    :dictID="id"
    :customStore="customEntriesStore"
  />
  <DictionaryEntries
    id="entries"
    v-if="id && hasDictEntriesPermission"
    :dictID="id"
    :customStore="customEntriesStore"
  />
</template>

<script>
import { Breadcrumbs, EODButton } from "@/components/ui";
import DictionariesFormComponent from "./DictionariesFormComponent";
import DictionaryEntries from "./DictionaryEntries";
import DictionaryEntriesForm from "./DictionaryEntriesForm";
import PERMISSIONS from "@/consts/permissions";
import { deleteDictionary } from "@/services/dictionaries";

export default {
  name: "DictionariesForm",
  data() {
    return {
      id: +this.$route.params?.id,
      dictName: "",
      customEntriesStore: "dictionaryEntries",
    };
  },
  components: {
    Breadcrumbs,
    DictionariesFormComponent,
    DictionaryEntries,
    DictionaryEntriesForm,
    EODButton,
  },
  computed: {
    title() {
      return this.id
        ? `${this.$t("app.edit")} ${this.dictName}`
        : this.$t("dictionaries.add");
    },
    showEntry() {
      return this.$store.state.dictionaries.showEntry;
    },
    hasPermissionToDelete() {
      return this.$permissions.includes(PERMISSIONS.DICTIONARIES_DELETE);
    },
    hasDictEntriesPermission() {
      return this.$permissions.includes(PERMISSIONS.DICTIONARIES_ENTRY);
    },
  },
  created() {
    this.$store.dispatch("setEntryID", undefined);
    this.$store.dispatch("resetShowEntry");
  },
  methods: {
    setDictName(name) {
      this.dictName = name;
    },
    setDictID(id) {
      this.id = id;
    },
    confirmDelete() {
      this.$store.dispatch("setModal", {
        modalOk: this.deleteDict,
        title: this.$t("dictionaries.delete"),
        message: `${this.$t("app.doYouWantToDelete")} ${this.$tc(
          "dictionaries.dictionary",
          1,
        )} "${this.dictName}"?`,
      });
    },
    deleteDict() {
      deleteDictionary(this.id).then(() => {
        this.$message.success(this.$t("app.success"));
        this.$store.dispatch("closeModal");
        this.$router.replace({ name: "Dictionaries" });
      });
    },
  },
};
</script>
