<template>
  <Section
    id="features"
    v-if="id"
    :title="$t('contractors.features')"
    :collapsable="true"
    v-model:collapsed="collapse"
  >
    <div class="form-container">
      <form id="features-form" @submit.prevent="submitFeatures" class="form">
        <EODRadio
          id="is_auto"
          v-model:value="features.is_auto"
          :options="[
            { name: $t('app.yes'), id: true },
            { name: $t('app.no'), id: false },
          ]"
          :label="$t('contractors.auto')"
          :errors="errors.is_auto"
        />

        <EODSelect
          id="features_payment_contitions"
          v-model:value="features.payment_conditions"
          :label="$t('contractors.paymentConditions')"
          :service="paymentConditionsService"
          :defaultValue="defaultValues.payment_conditions"
          :errors="errors.payment_conditions"
        />

        <EODInput
          id="url"
          v-model:value="features.url"
          :label="$t('contractors.url')"
          :maxlength="100"
          type="url"
          :errors="errors.url"
          @invalid="onInvalid()"
        />

        <div
          v-for="(attribute, idx) in features.attributes"
          :key="idx"
          class="input-button"
        >
          <EODInput
            :id="`argument-${idx + 1}`"
            v-model:value="attribute.value"
            :label="`${$tc('contractors.attribute', 1)} ${idx + 1}`"
            :maxlength="20"
            :errors="attributesErrors[idx].value"
            @invalid="onInvalid()"
          />

          <EODButton
            v-if="idx === 0"
            type="primary"
            class="mb-050 ml-050"
            @click="addAttribute"
            :title="$t('contractors.addAttribute')"
            icon="PlusOutlined"
          />
          <EODButton
            v-else
            type="danger"
            ghost
            class="mb-050 ml-050"
            @click="confirmAttributeDelete(idx)"
            :title="$t('contractors.deleteAttribute')"
            icon="CloseOutlined"
          />
        </div>
      </form>
    </div>
  </Section>
</template>

<script>
import { EODButton, Section } from "@/components/ui";
import { EODInput, EODRadio, EODSelect } from "@/components/inputs";
import {
  createFeature,
  deleteAttribute,
  editFeature,
} from "@/services/contractors";

export default {
  name: "FeaturesForm",
  props: {
    id: Number,
    modelData: Object,
    modelErrors: Object,
    modelCollapse: Boolean,
    modelAttributesErrors: Array,
    promiseArray: Array,
    paymentConditionsService: Function,
    defaultValues: Object,
  },
  components: { EODButton, EODInput, EODRadio, EODSelect, Section },
  computed: {
    features: {
      get() {
        return this.modelData;
      },
      set(val) {
        this.$emit("update:modelData", val);
      },
    },
    errors: {
      get() {
        return this.modelErrors;
      },
      set(val) {
        this.$emit("update:modelErrors", val);
      },
    },
    collapse: {
      get() {
        return this.modelCollapse;
      },
      set(val) {
        this.$emit("update:modelCollapse", val);
      },
    },
    attributesErrors: {
      get() {
        return this.modelAttributesErrors;
      },
      set(val) {
        this.$emit("update:modelAttributesErrors", val);
      },
    },
    promises: {
      get() {
        return this.promiseArray;
      },
      set(val) {
        this.$emit("update:promiseArray", val);
      },
    },
    shouldSubmit() {
      return this.$store.state.contractors.submitFeatures;
    },
  },
  emits: [
    "update:modelCollapse",
    "update:modelErrors",
    "update:modelAttributesErrors",
  ],
  methods: {
    submitFeatures() {
      const service = this.features.id ? editFeature : createFeature;

      const data = {
        ...this.features,
        contractor: this.id,
        attributes:
          this.features.attributes.length === 1 &&
          this.features.attributes[0].value === ""
            ? []
            : this.features.attributes,
      };

      this.promises.push(
        service(data, this.features.id)
          .then(({ data }) => {
            this.features.id = data.id;
            this.errors = {};
            this.attributesErrors = Array(this.features.attributes.length).fill(
              {},
            );
            return true;
          })
          .catch((err) => {
            this.errors = err.response.data;
            this.attributesErrors =
              err.response.data.attributes ||
              Array(this.features.attributes.length).fill({});
            return false;
          }),
      );
    },
    onInvalid() {
      this.collapse = false;
    },
    addAttribute() {
      this.features.attributes.push({ value: "" });
      this.attributesErrors.push({});
    },
    confirmAttributeDelete(idx) {
      this.$store.dispatch("setModal", {
        message: this.$t("contractors.doYouWantToDeleteAttribute"),
        title: this.$t("contractors.deleteAttribute"),
        modalOk: () => this.removeAttribute(idx),
      });
    },
    removeAttribute(idx) {
      if (this.features.attributes[idx].id) {
        deleteAttribute(this.features.attributes[idx].id).then(() => {
          this.getRidOfAttribute(idx);
          this.$store.dispatch("closeModal");
        });
      } else {
        this.getRidOfAttribute(idx);
        this.$store.dispatch("closeModal");
      }
    },
    getRidOfAttribute(idx) {
      this.features.attributes = this.features.attributes.filter(
        (_, index) => index !== idx,
      );
      this.attributesErrors = this.attributesErrors.filter(
        (_, index) => index !== idx,
      );
    },
  },
  watch: {
    shouldSubmit(val) {
      if (val) {
        this.submitFeatures();
        this.$store.dispatch("resetSubmitFeatures");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/Contractors";
</style>
