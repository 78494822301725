<template>
  <ConfirmModal
    v-model:visible="cVisible"
    :title="$t('issues.removeDocument')"
    :okText="$t('issues.removeDocument')"
    :onSubmit="confirmModal"
    :onCancel="closeModal"
    icon="DeleteOutlined"
  >
    <p>{{ $tc("issues.doYouWantToRemoveDocument", [issueNumber]) }}</p>
    <EODInput
      id="comment"
      type="textarea"
      v-model:value="cCommentValue"
      :label="$t('app.comment')"
      :props="{ maxlength: 500 }"
      :errors="errors?.comment"
    />
  </ConfirmModal>
</template>

<script>
import { ConfirmModal } from "@/components/modals";
import { EODInput } from "@/components/inputs";
import { deleteDocument } from "@/services/issues";

export default {
  name: "RemoveDocument",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    commentValue: {
      type: String,
      default: "",
    },
    issueId: [String, Number],
    selectStoreID: String,
    issueNumber: String,
    documentID: [Number, String],
  },
  data() {
    return {
      errors: [],
    };
  },
  components: {
    ConfirmModal,
    EODInput,
  },
  computed: {
    cVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
    cDocumentID: {
      get() {
        return this.documentID;
      },
      set(val) {
        this.$emit("update:documentID", val);
      },
    },
    cCommentValue: {
      get() {
        return this.commentValue;
      },
      set(val) {
        this.$emit("update:commentValue", val);
      },
    },
  },
  methods: {
    confirmModal() {
      deleteDocument(this.issueId, this.cDocumentID, {
        comment: this.cCommentValue,
      }).then(() => {
        this.closeModal();
        this.$message.success(this.$t("app.success"));
        this.refreshTable();
        if (this.$store.hasModule(`select_${this.selectStoreID}`)) {
          this.$store.dispatch(`select_${this.selectStoreID}/initialFetch`);
        }
      });
    },
    refreshTable() {
      for (const store of ["issueHistory", "IssueDocumentsTable"]) {
        if (this.$store.hasModule(store)) {
          this.$store.dispatch(`${store}/refreshTable`);
        }
      }

      this.$store.dispatch("refreshTable");
    },
    closeModal() {
      this.cVisible = false;
      this.cDocumentID = null;
      this.cCommentValue = "";
    },
  },
};
</script>
