<template>
  <div class="container">
    <Card :title="$t('app.login')" class="login">
      <a-form layout="vertical" :model="form" @submit.prevent="handleSubmit">
        <a-form-item>
          <Input
            id="username"
            v-model:value="form.username"
            :placeholder="$t('app.username')"
          >
            <template v-slot:prefix>
              <UserOutlined style="color: rgba(0, 0, 0, 0.25)" />
            </template>
          </Input>
        </a-form-item>
        <a-form-item>
          <InputPassword
            id="password"
            v-model:value="form.password"
            :placeholder="$t('app.password')"
          >
            <template v-slot:prefix>
              <LockOutlined style="color: rgba(0, 0, 0, 0.25)" />
            </template>
          </InputPassword>
        </a-form-item>
        <a-form-item>
          <Button
            id="submit"
            data-testid="submit"
            type="primary"
            html-type="submit"
            :disabled="isDisabled"
          >
            {{ $t("app.logIn") }}
          </Button>
          <Button id="sso" @click="sso">
            {{ $t("app.sso") }}
          </Button>
        </a-form-item>
      </a-form>
    </Card>
  </div>
</template>

<script>
import { Button, Card, Input } from "ant-design-vue";
import { LockOutlined, UserOutlined } from "@ant-design/icons-vue";
import { SSO_URLS } from "@/consts/statuses";
import { login } from "@/services/login";

export default {
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
    };
  },
  components: {
    Button,
    Card,
    Input,
    InputPassword: Input.Password,
    LockOutlined,
    UserOutlined,
  },
  computed: {
    isDisabled() {
      return this.form.username === "" || this.form.password === "";
    },
  },
  methods: {
    sso() {
      window.location = SSO_URLS.LOGIN;
    },
    handleSubmit() {
      login(this.form)
        .then(({ data }) => {
          localStorage.setItem("access_token", data.access);
          localStorage.setItem("refresh_token", data.refresh);

          this.$store.dispatch("user/add", { root: true });

          this.redirect();
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.$message.error(
              err.response.data.detail || this.$t("app.error"),
            );
          }
        });
    },
    redirect() {
      const redirectTo = this.$route.query.redirect || { name: "Home" };
      this.$router.push(redirectTo);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/Variables";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
}

.login {
  min-height: 300px;
  min-width: 300px;
  max-width: 500px;
  width: 80%;
  margin: 3.5em;
  box-shadow: 0px 10px 10px 0px $box-shadow-color;
}
</style>
