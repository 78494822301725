import { getDeploymentsSelect, startDeployment } from "@/services/deployments";

import i18n from "@/i18n";
import { message } from "ant-design-vue";
import router from "@/router";
import { startDeploymentAndClaim } from "@/services/deployments";

const t = i18n.global.t;

const FETCH_DEPLOYMENTS_SELECT = function (params) {
  return getDeploymentsSelect(params).then(({ data }) => {
    const results = data.results.map((item) => {
      return { ...item, disabled: item.start_deployment === false };
    });
    return { ...data, results };
  });
};

const HANDLE_START_DEPLOYMENT = async function (
  documentIds,
  deploymentId,
  afterStartDeploymentFunction,
) {
  const isOneDocument = documentIds.length === 1;
  const service = isOneDocument ? startDeploymentAndClaim : startDeployment;

  await service(
    { document: isOneDocument ? documentIds[0] : documentIds },
    deploymentId,
  ).then(({ data }) => {
    if (isOneDocument) {
      if (data.task_id) {
        router.replace({
          name: "TaskForm",
          params: { id: data.task_id },
          query: { "back-to": "Documents" },
        });
      } else {
        message.success(data.message);
      }
    } else {
      message.success(t("app.success"));
    }
    afterStartDeploymentFunction();
  });
};

export { FETCH_DEPLOYMENTS_SELECT, HANDLE_START_DEPLOYMENT };
