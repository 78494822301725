<template>
  <Breadcrumbs
    :path="[{ path: { name: 'Forms' }, title: $t('modules.forms') }]"
    :current="$t('forms.preview')"
    iconComponent="FormOutlined"
  >
    <EODButtonLink
      v-if="isEditable"
      :to="{ name: 'FormsEdit', params: { id } }"
      :title="$t('forms.edit')"
      icon="EditOutlined"
    />
  </Breadcrumbs>

  <Preview
    :oid="id"
    :data="previewData"
    :title="$t('forms.preview')"
    :noPreviewMsg="$t('forms.noPreview')"
  />

  <section class="section">
    <FormBuilder
      v-model:modelValue="model"
      :schema="schema"
      :schemaUI="schemaUI"
      :preview="true"
    />
  </section>
</template>

<script>
import { Breadcrumbs, EODButtonLink } from "@/components/ui";
import { FormBuilder } from "@/components/forms";
import { Preview } from "@/components/common";
import { getForm } from "@/services/forms";

export default {
  name: "FormsPreview",
  data() {
    return {
      id: +this.$route.params?.id,
      previewData: [],
      schema: { properties: {} },
      schemaUI: {},
      model: {},
      isEditable: false,
    };
  },
  components: { Breadcrumbs, EODButtonLink, FormBuilder, Preview },
  mounted() {
    if (this.id) {
      getForm(this.id).then(({ data }) => {
        this.previewData = [
          { title: this.$t("app.name"), value: data.name },
          {
            title: this.$t("app.status"),
            value: data.status_display,
          },
          { title: this.$t("app.description"), value: data.description },
        ];

        this.schema = data.schema;
        this.schemaUI = data.schema_ui;
        this.isEditable = data.actions.editable;
      });
    }
  },
};
</script>
