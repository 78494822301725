<template>
  <div class="filter" ref="filter" @scroll="handleScroll">
    <div class="filter-search">
      <InputSearch
        v-model:value="search"
        :placeholder="$t('app.search')"
        @search="handleSearch"
        :enterButton="true"
      />
    </div>
    <Tree
      :expandedKeys="cExpandedKeys"
      v-model:selectedKeys="selectedKeys"
      :treeData="treeData"
      @expand="onExpand"
      @select="onSelect"
    />
  </div>
</template>

<script>
import { Input, Tree } from "ant-design-vue";
import {
  getJRWAList,
  getRepositoryUnits,
  getYearsList,
} from "@/services/repository";

import { RESOURCE_TYPES } from "@/consts/statuses";
import { nextTick } from "vue";

export default {
  name: "FilterJRWA",
  props: {
    selectedFilter: Object,
    expandedKeys: Array,
    greyOutIssue: { type: Boolean, default: false },
  },
  data() {
    return {
      treeData: [],
      search: null,
      selectedKeys: [],
      filterType: {
        UNIT: "UNIT",
        YEAR: "YEAR",
        JRWA: "JRWA",
      },
      nextPageParams: null,
      dataIsLoading: false,
    };
  },
  components: {
    InputSearch: Input.Search,
    Tree,
  },
  computed: {
    cGreyOutIssue: {
      get() {
        return this.greyOutIssue;
      },
      set(val) {
        this.$emit("update:greyOutIssue", val);
      },
    },
    cSelectedFilter: {
      get() {
        return this.selectedFilter;
      },
      set(newValue) {
        this.$emit("update:selectedFilter", newValue);
      },
    },
    cExpandedKeys: {
      get() {
        return this.expandedKeys;
      },
      set(newValue) {
        this.$emit("update:expandedKeys", newValue);
      },
    },
  },
  created() {
    this.fetchUnitsList();
  },
  emits: [
    "update:expandedKeys",
    "update:selectedFilter",
    "update:greyOutIssue",
  ],
  methods: {
    getRepositoryUnits,
    handleSearch() {
      if (!this.dataIsLoading) {
        this.treeData = [];
        this.fetchUnitsList();
      }
    },
    handleScroll(e) {
      if (
        this.nextPageParams &&
        !this.dataIsLoading &&
        e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight + 100
      ) {
        this.fetchUnitsList(this.nextPageParams);
      }
    },
    onExpand(expandedKeys, node) {
      if (node.node.$props.dataRef.type === this.filterType.UNIT) {
        this.fetchYearsList(node.node.$props.dataRef);
      } else if (node.node.$props.dataRef.type === this.filterType.JRWA) {
        this.fetchJRWAList(node.node.$props.dataRef);
      }
      this.cExpandedKeys = expandedKeys;
      this.findTreeElement(
        ".ant-tree-child-tree .ant-tree-switcher-icon",
        ".ant-tree-child-tree .ant-tree-node-content-wrapper",
      );
    },
    onSelect(_, node) {
      this.cSelectedFilter = node.node.$props.dataRef.filter;
      // prettier-ignore
      this.cGreyOutIssue = node.node.$props.dataRef.key.includes(
        "noIssueDocuments",
      );
    },
    adjustFilterList() {
      if (
        this.nextPageParams &&
        this.$refs.filter.scrollHeight === this.$refs.filter.clientHeight
      ) {
        this.fetchUnitsList(this.nextPageParams);
      }
    },
    fetchUnitsList(params) {
      this.dataIsLoading = true;

      getRepositoryUnits({ ...params, search_field: this.search })
        .then(({ data }) => {
          this.nextPageParams = data.next
            ? Object.fromEntries([...new URL(data.next).searchParams])
            : null;
          this.treeData = this.treeData.concat(
            data.results.map((item) => {
              return {
                title: item.name,
                key: item.unit_symbol,
                type: this.filterType.UNIT,
                isLeaf: !item.has_resources,
                disabled: !item.has_resources,
                children: [],
                id: item.id,
                filter: {
                  unit: {
                    unitId: item.id,
                    unitName: item.name,
                    keyToExpand: [item.unit_symbol],
                  },
                  year: null,
                  jrwa: null,
                  jrwaName: null,
                  issues: null,
                },
              };
            }),
          );
          this.findTreeElement(
            ".ant-tree-switcher-icon",
            ".ant-tree-treenode-switcher-close .ant-tree-node-content-wrapper",
          );
        })
        .finally(() => {
          this.dataIsLoading = false;
          // TODO: move to mounted
          this.adjustFilterList();
        });
    },
    fetchYearsList(unit) {
      if (unit.children.length === 0) {
        getYearsList({ unit: unit.id }).then(({ data }) => {
          unit.children = data.map((item) => {
            return {
              title: item,
              key: `${unit.key}_${item}`,
              filter: {
                unit: {
                  unitId: unit.id,
                  unitName: unit.title,
                  keyToExpand: [unit.key],
                },
                year: {
                  year: item,
                  keyToExpand: [unit.key, `${unit.key}_${item}`],
                },
                jrwa: null,
                jrwaName: null,
                issues: null,
              },
              children: [
                {
                  title: this.$t("repository.documentsWithoutJRWA"),
                  key: `${unit.key}_${item}_noJRWA`,
                  children: [],
                  filter: {
                    unit: {
                      unitId: unit.id,
                      unitName: unit.title,
                      keyToExpand: [unit.key],
                    },
                    year: {
                      year: item,
                      keyToExpand: [unit.key, `${unit.key}_${item}`],
                    },
                    jrwa: {
                      jrwa: false,
                      keyToExpand: [
                        unit.key,
                        `${unit.key}_${item}`,
                        `${unit.key}_${item}_noJRWA`,
                      ],
                    },
                    jrwaName: null,
                    issues: null,
                  },
                },
                {
                  title: this.$t("repository.resourcesWithJRWA"),
                  key: `${unit.key}_${item}_JRWA`,
                  children: [],
                  type: this.filterType.JRWA,
                  isLeaf: false,
                  filter: {
                    unit: {
                      unitId: unit.id,
                      unitName: unit.title,
                      keyToExpand: [unit.key],
                    },
                    year: {
                      year: item,
                      keyToExpand: [unit.key, `${unit.key}_${item}`],
                    },
                    jrwa: {
                      jrwa: true,
                      keyToExpand: [
                        unit.key,
                        `${unit.key}_${item}`,
                        `${unit.key}_${item}_JRWA`,
                      ],
                    },
                    jrwaName: null,
                    issues: null,
                  },
                },
              ],
              type: this.filterType.YEAR,
            };
          });
          this.findTreeElement(
            ".ant-tree-child-tree .ant-tree-switcher-icon",
            ".ant-tree-child-tree .ant-tree-treenode-switcher-close .ant-tree-node-content-wrapper",
          );
        });
      }
    },
    fetchJRWAList(jrwaList) {
      if (jrwaList.children.length === 0) {
        getJRWAList({
          unit: jrwaList.filter.unit.unitId,
          year: jrwaList.filter.year.year,
        }).then(({ data: { results } }) => {
          jrwaList.children = results.map((item) => {
            return {
              key: item.jrwa,
              title: (
                <span title={item.jrwa_display}>
                  {item.jrwa_display.split(" ")[0]}
                </span>
              ),
              filter: {
                unit: {
                  unitId: jrwaList.filter.unit.unitId,
                  unitName: jrwaList.filter.unit.unitName,
                  keyToExpand: jrwaList.filter.unit.keyToExpand,
                },
                year: {
                  year: jrwaList.filter.year.year,
                  keyToExpand: jrwaList.filter.year.keyToExpand,
                },
                jrwa: {
                  jrwa: jrwaList.filter.jrwa.jrwa,
                  keyToExpand: jrwaList.filter.jrwa.keyToExpand,
                },
                jrwaName: {
                  jrwaId: item.jrwa,
                  jrwaName: item.jrwa_display,
                  keyToExpand: [...jrwaList.filter.jrwa.keyToExpand, item.jrwa],
                },
                issues: null,
              },
              children: [
                {
                  title: this.$t("repository.noIssueDocuments"),
                  key: `${jrwaList.key}_noIssueDocuments`,
                  children: [],
                  filter: {
                    unit: {
                      unitId: jrwaList.filter.unit.unitId,
                      unitName: jrwaList.filter.unit.unitName,
                      keyToExpand: jrwaList.filter.unit.keyToExpand,
                    },
                    year: {
                      year: jrwaList.filter.year.year,
                      keyToExpand: jrwaList.filter.year.keyToExpand,
                    },
                    jrwa: {
                      jrwa: jrwaList.filter.jrwa.jrwa,
                      keyToExpand: jrwaList.filter.jrwa.keyToExpand,
                    },
                    jrwaName: {
                      jrwaId: item.jrwa,
                      jrwaName: item.jrwa_display,
                      keyToExpand: [
                        ...jrwaList.filter.jrwa.keyToExpand,
                        item.jrwa,
                      ],
                    },
                    issues: {
                      issues: false,
                      keyToExpand: [
                        ...jrwaList.filter.jrwa.keyToExpand,
                        item.jrwa,
                        `${jrwaList.key}_noIssueDocuments`,
                      ],
                    },
                    type: RESOURCE_TYPES.DOCUMENT,
                  },
                },
                {
                  title: this.$t("modules.issues"),
                  key: `${jrwaList.key}_issues`,
                  children: [],
                  filter: {
                    unit: {
                      unitId: jrwaList.filter.unit.unitId,
                      unitName: jrwaList.filter.unit.unitName,
                      keyToExpand: jrwaList.filter.unit.keyToExpand,
                    },
                    year: {
                      year: jrwaList.filter.year.year,
                      keyToExpand: jrwaList.filter.year.keyToExpand,
                    },
                    jrwa: {
                      jrwa: jrwaList.filter.jrwa.jrwa,
                      keyToExpand: jrwaList.filter.jrwa.keyToExpand,
                    },
                    jrwaName: {
                      jrwaId: item.jrwa,
                      jrwaName: item.jrwa_display,
                      keyToExpand: [
                        ...jrwaList.filter.jrwa.keyToExpand,
                        item.jrwa,
                      ],
                    },
                    issues: {
                      issues: true,
                      keyToExpand: [
                        ...jrwaList.filter.jrwa.keyToExpand,
                        item.jrwa,
                        `${jrwaList.key}_issues`,
                      ],
                    },
                    type: RESOURCE_TYPES.ISSUE,
                  },
                },
              ],
            };
          });
          this.findTreeElement(
            ".ant-tree-child-tree .ant-tree-switcher-icon",
            ".ant-tree-child-tree .ant-tree-treenode-switcher-close .ant-tree-node-content-wrapper",
          );
        });
      }
    },
    setTabindex(className) {
      className.forEach((e) => {
        e.setAttribute("tabindex", "0");
        e.addEventListener("keypress", (evt) => {
          if (evt.code === "Enter") {
            e.click();
          }
        });
      });
    },
    findTreeElement(iconQuerySelector, titleQuerySelector) {
      nextTick(() => {
        const icon = document.querySelectorAll(iconQuerySelector);
        const title = document.querySelectorAll(titleQuerySelector);
        this.setTabindex(icon);
        this.setTabindex(title);
      });
    },
  },
  mounted() {
    window.addEventListener("resize", () => this.adjustFilterList());
  },
  unmounted() {
    window.removeEventListener("resize", () => this.adjustFilterList());
  },
};
</script>

<style lang="scss">
@import "@/scss/RepositoryFilter.scss";
</style>
