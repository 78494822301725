import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FileTextOutlined = _resolveComponent("FileTextOutlined")
  const _component_TableActions = _resolveComponent("TableActions")
  const _component_BaseTable = _resolveComponent("BaseTable")

  return (_openBlock(), _createBlock(_component_BaseTable, {
    columns: _ctx.columns,
    hasSearch: false,
    service: (params) => this.service(_ctx.id, params),
    rowKey: "pk"
  }, {
    date: _withCtx(({ record }) => [
      _createTextVNode(_toDisplayString(new Date(record.date).toString() !== "Invalid Date"
          ? new Date(record.date).toLocaleString()
          : "-"), 1)
    ]),
    actions: _withCtx(({ record }) => [
      _createVNode(_component_TableActions, {
        record: {
          id: record.pk,
          subject: record.subject,
          actions: record.actions,
        },
        previewRoute: {
          name: _ctx.deletedPreview ? 'DeletedMessagePreview' : 'MessagePreview',
          params: { name: _ctx.id, id: record.pk },
        },
        showEdit: false,
        showRemove: _ctx.editionMode,
        deleteMessage: `${_ctx.$t('app.doYouWantToDelete')} ${_ctx.$tc(
          'mailboxes.messages.message',
          1,
        )} "${record.subject}"?`,
        modalTitle: _ctx.$t('mailboxes.messages.delete'),
        deleteService: () => _ctx.deleteMailboxMessage(_ctx.id, record.pk)
      }, {
        default: _withCtx(() => [
          (record?.actions?.processable && _ctx.editionMode)
            ? (_openBlock(), _createBlock("button", {
                key: 0,
                class: "action",
                onClick: ($event: any) => (_ctx.handleConfirm(_ctx.id, record.pk)),
                title: _ctx.$t('mailboxes.messages.createDocument')
              }, [
                _createVNode(_component_FileTextOutlined)
              ], 8, ["onClick", "title"]))
            : _createCommentVNode("", true)
        ]),
        _: 2
      }, 1032, ["record", "previewRoute", "showRemove", "deleteMessage", "modalTitle", "deleteService"])
    ]),
    _: 1
  }, 8, ["columns", "service"]))
}