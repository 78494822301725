<template>
  <section class="section">
    <BaseTable
      :columns="columns"
      :service="getDictionariesList"
      :customParams="customParams"
      :customStore="customStore"
    >
      <template v-slot:description="{ record }">
        <span :title="record.description">
          {{ record.description || "-" }}
        </span>
      </template>
      <template v-slot:status="{ record }">
        <StatusDot :status="record.status" />
        {{ record.status_display }}
      </template>
      <template v-slot:actions="{ record }">
        <TableActions
          :record="record"
          :previewRoute="{
            name: 'DictionariesPreview',
            params: { id: record.id },
          }"
          :editRoute="{
            name: 'DictionariesEdit',
            params: { id: record.id },
          }"
          :deleteMessage="`${$t('app.doYouWantToDelete')} ${$tc(
            'dictionaries.dictionary',
            1,
          )} &quot;${record.name}&quot;?`"
          :modalTitle="$t('dictionaries.delete')"
          :deleteService="deleteDictionary"
          :customStore="customStore"
        />
      </template>
    </BaseTable>
  </section>
</template>

<script>
import { BaseTable, StatusDot, TableActions } from "@/components/table";
import { deleteDictionary, getDictionariesList } from "@/services/dictionaries";
import dynamicTable from "@/store/modules/dynamicTable";

export default {
  name: "DictionariesList",
  props: {
    customParams: {
      type: Object,
      default: () => ({}),
    },
    customStore: {
      type: [Boolean, String],
      default: false,
    },
  },
  data() {
    return {
      columns: [
        {
          title: this.$t("app.name"),
          dataIndex: "name",
          sorter: true,
          ellipsis: true,
          width: "20%",
        },
        {
          title: this.$t("app.description"),
          dataIndex: "description",
          ellipsis: true,
          slots: { customRender: "description" },
        },
        {
          title: this.$t("app.status"),
          dataIndex: "status",
          sorter: true,
          slots: { customRender: "status" },
          width: "15%",
        },
        {
          title: this.$t("app.actions"),
          dataIndex: "actions",
          slots: { customRender: "actions" },
          fixed: "right",
          width: "120",
        },
      ],
    };
  },
  components: {
    BaseTable,
    StatusDot,
    TableActions,
  },
  created() {
    if (!this.$store.hasModule(this.customStore)) {
      this.$store.registerModule(this.customStore, dynamicTable);
    }
  },
  methods: {
    deleteDictionary,
    getDictionariesList,
  },
};
</script>
