
import { EODButton } from "@/components/ui";
import { SSO_URLS } from "@/consts/statuses";
import { defineComponent } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "BeforeLogin",
  setup() {
    const route = useRoute();
    const redirectTo = route.query.redirect || { name: "Home" };

    const gotoLoginSSO = () => {
      window.location.href = SSO_URLS.LOGIN;
    };

    return { gotoLoginSSO, redirectTo };
  },
  components: { EODButton },
});
