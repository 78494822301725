<template>
  <form @submit.prevent="setQuery">
    <InputSearch
      v-model:value="query"
      :placeholder="`${$t('app.search')}...`"
      @search="setQuery"
    />
  </form>
</template>

<script>
import { Input } from "ant-design-vue";

export default {
  name: "TableSearch",
  data() {
    return {
      query: "",
    };
  },
  components: { InputSearch: Input.Search },
  emits: ["table-search-change"],
  methods: {
    setQuery() {
      this.$emit("table-search-change", this.query);
    },
  },
};
</script>
