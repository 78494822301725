import apiClient from "@/config/apiClient";

const addDocument = (data, id) => {
  return apiClient.put(`/api/issues/${id}/document/`, data);
};

const getIssuesList = (params) => {
  return apiClient.get(`/api/issues/`, { params });
};

const createIssue = (data) => {
  return apiClient.post("/api/issues/", data);
};

const closeIssue = (data, id) => {
  return apiClient.put(`/api/issues/${id}/close/`, data);
};

const editIssue = (data, id) => {
  return apiClient.patch(`/api/issues/${id}/`, data);
};

const editIssueDocumentAdd = (data, id) => {
  return apiClient.patch(`/api/issues/${id}/document/`, data);
};

const getIssue = (id) => {
  return apiClient.get(`/api/issues/${id}/`);
};

const getIssues = () => {
  return apiClient.get(`/api/issues/select/`);
};

const getIssueDocuments = (id, params) => {
  return apiClient.get(`/api/issues/${id}/documents/`, { params });
};

const getSharedList = (id, params) => {
  return apiClient.get(`/api/issues/${id}/sharing/`, { params });
};

const getHistory = (id, params) => {
  return apiClient.get(`/api/issues/${id}/history/`, { params });
};

const getHistoryItem = (id, historyID) => {
  return apiClient.get(`/api/issues/${id}/history/${historyID}/`);
};

const shareIssue = (data, id) => {
  return apiClient.post(`/api/issues/${id}/sharing/`, data);
};

const unshareIssue = (id, userID) => {
  return apiClient.delete(`/api/issues/${id}/sharing/${userID}/`);
};
const issueReopen = (data, id) => {
  return apiClient.put(`/api/issues/${id}/reopen/`, data);
};
const deleteIssue = (id) => {
  return apiClient.delete(`/api/issues/${id}/`);
};

const deleteDocument = (id, documentID, params) => {
  return apiClient.delete(`/api/issues/${id}/document/${documentID}/`, {
    params,
  });
};

const filterJRWA = (params) => {
  return apiClient.get(`/api/jrwa/entry/select-in-issues/`, { params });
};

const filterOwners = (params) => {
  return apiClient.get(`/api/group/owner-select-in-issues/`, { params });
};

const filterYears = (params) => {
  return apiClient.get(`/api/issues/select/year/`, { params });
};

export {
  addDocument,
  createIssue,
  closeIssue,
  issueReopen,
  deleteDocument,
  deleteIssue,
  editIssue,
  editIssueDocumentAdd,
  getIssue,
  getIssueDocuments,
  getSharedList,
  getIssuesList,
  getIssues,
  getHistory,
  getHistoryItem,
  filterJRWA,
  filterOwners,
  filterYears,
  shareIssue,
  unshareIssue,
};
