<template>
  <Breadcrumbs
    :current="$t('notifications.myNotifications')"
    iconComponent="MessageOutlined"
  />

  <Tabs @change="$scrollToTop">
    <TabPane key="1" :tab="$t('app.all')">
      <OwnNotificationsList />
    </TabPane>
    <TabPane key="2" :tab="$t('notifications.read')">
      <OwnNotificationsList :read="true" customStore="readNotifications" />
    </TabPane>
    <TabPane key="3" :tab="$t('notifications.unread')">
      <OwnNotificationsList :read="false" customStore="unreadNotifications" />
    </TabPane>
  </Tabs>
</template>

<script>
import { Breadcrumbs } from "@/components/ui";
import OwnNotificationsList from "./OwnNotificationsList";
import { Tabs } from "ant-design-vue";

export default {
  name: "OwnNotifications",
  components: {
    Breadcrumbs,
    OwnNotificationsList,
    Tabs,
    TabPane: Tabs.TabPane,
  },
};
</script>
