import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FolderViewOutlined = _resolveComponent("FolderViewOutlined")
  const _component_UnorderedListOutlined = _resolveComponent("UnorderedListOutlined")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("button", {
      type: "button",
      class: [_ctx.preview ? 'ant-btn mr-1' : 'action'],
      title: _ctx.$t('archive.folders.generateFolderDescription'),
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.generateFolderDescription && _ctx.generateFolderDescription(...args)))
    }, [
      _createVNode(_component_FolderViewOutlined),
      (_ctx.preview)
        ? (_openBlock(), _createBlock("span", _hoisted_1, _toDisplayString(_ctx.$t("archive.folders.generateFolderDescription")), 1))
        : _createCommentVNode("", true)
    ], 10, ["title"]),
    _createVNode("button", {
      type: "button",
      class: [_ctx.preview ? 'ant-btn' : 'action'],
      title: _ctx.$t('archive.folders.generateListFolderIssues'),
      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.generateListFolderIssues && _ctx.generateListFolderIssues(...args)))
    }, [
      _createVNode(_component_UnorderedListOutlined),
      (_ctx.preview)
        ? (_openBlock(), _createBlock("span", _hoisted_2, _toDisplayString(_ctx.$t("archive.folders.generateListFolderIssues")), 1))
        : _createCommentVNode("", true)
    ], 10, ["title"])
  ], 64))
}