import APPS from "@/consts/apps";
import PERMISSIONS from "@/consts/permissions";
import Teams from "@/views/Permissions/Teams.vue";
import TeamsForm from "@/views/Permissions/TeamsForm.vue";
import TeamsPreview from "@/views/Permissions/TeamsPreview.vue";
import i18n from "@/i18n";

const t = i18n.global.t;

const teams = [
  {
    path: "/teams/",
    name: "Teams",
    component: Teams,
    meta: {
      title: `${t("meta.app")} - ${t("modules.teams")}`,
      requiresAuth: true,
      permission: PERMISSIONS.GROUPS,
      app: APPS.GROUPS,
    },
  },
  {
    path: "/teams/create/",
    name: "TeamsCreate",
    component: TeamsForm,
    meta: {
      title: `${t("meta.app")} - ${t("permissions.teams.add")}`,
      requiresAuth: true,
      permission: PERMISSIONS.GROUPS_ADD,
      app: APPS.GROUPS,
    },
  },
  {
    path: "/teams/:id/edit/",
    name: "TeamsEdit",
    component: TeamsForm,
    meta: {
      title: `${t("meta.app")} - ${t("permissions.teams.edit")}`,
      requiresAuth: true,
      permission: PERMISSIONS.GROUPS_EDIT,
      app: APPS.GROUPS,
    },
  },
  {
    path: "/teams/:id/preview/",
    name: "TeamsPreview",
    component: TeamsPreview,
    meta: {
      title: `${t("meta.app")} - ${t("permissions.teams.preview")}`,
      requiresAuth: true,
      permission: PERMISSIONS.GROUPS,
      app: APPS.GROUPS,
    },
  },
];
export default teams;
