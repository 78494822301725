import { computed, inject } from "vue";

import { MessageApi } from "ant-design-vue/lib/message";
import { exportDeployments } from "@/services/deployments";
import { useStore } from "@/store";

export default function useExportDeployments() {
  const store = useStore();
  const $message = inject("$message") as MessageApi;

  const exportDeployment = (deploymentIds: number[]) => {
    exportDeployments({ deployments: deploymentIds }).then(
      ({ data }: { data: string }) => {
        $message.success(data);
      },
    );
  };

  const hasExportPermission = computed(() => {
    return store.getters["user/getUser"].permissions.includes(
      "camunda.add_deployment_custom",
    );
  });

  return {
    exportDeployment,
    hasExportPermission,
  };
}
