<template>
  <Preview
    v-if="bankAccountID"
    :oid="bankAccountID"
    :data="bankAccountData"
    :title="$t('contractors.bankAccounts')"
    :noPreviewMsg="$t('contractors.noPreview')"
    :twoColumn="true"
  />
</template>

<script>
import { Preview } from "@/components/common";
import { getBankAccount } from "@/services/contractors";

export default {
  name: "BankAccountPreview",
  data() {
    return {
      bankAccountData: [],
    };
  },
  components: { Preview },
  computed: {
    bankAccountID() {
      return this.$store.state.contractors.bankAccountID;
    },
  },
  methods: {
    fetchBankAccount() {
      getBankAccount(this.bankAccountID).then(({ data }) => {
        this.bankAccountData = [
          [
            {
              title: this.$t("contractors.accountIdentifier"),
              value: data.identifier || "-",
            },
            {
              title: this.$t("contractors.country"),
              value: data.country_id_display || "-",
            },

            {
              title: this.$t("contractors.swift"),
              value: data.swift || "-",
            },

            {
              title: this.$t("contractors.verifiedAt"),
              value: data.verified_at
                ? new Date(data.verified_at).toLocaleDateString()
                : "-",
            },
          ],
          [
            {
              title: this.$t("contractors.bank"),
              value: data.bank_id_display || "-",
            },
            {
              title: this.$t("contractors.accountNumber"),
              value: data.account_number || "-",
            },
            {
              title: this.$t("contractors.currency"),
              value: data.currency_display || "-",
            },
          ],
        ];
      });
    },
  },
  mounted() {
    if (this.bankAccountID) {
      this.fetchBankAccount();
    }
  },
  watch: {
    bankAccountID() {
      this.fetchBankAccount();
    },
  },
};
</script>
