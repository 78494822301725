<template>
  <div class="input-container">
    <label :for="id" class="label">
      {{ label }}
      <span
        v-if="required"
        class="color--error"
        :title="$t('app.fieldRequired')"
      >
        &nbsp;*
      </span>
    </label>
    <div class="input-wrap">
      <component
        :is="component"
        :id="id"
        v-model:value="cValue"
        @invalid="onInvalid"
        :required="required"
        :placeholder="cPlaceholder"
        :disabled="disabled"
        :onChange="onChange"
        :format="showTime ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY'"
        :valueFormat="valueFormat"
        :disabledDate="disabledDate"
        :allowClear="allowClear"
        :showTime="showTime"
      />
      <slot name="tooltip" />
    </div>
    <span class="color--error" v-if="errors">
      {{ errors[0] }}
    </span>
  </div>
</template>

<script>
import { DatePicker } from "ant-design-vue";

export default {
  name: "EODDatePicker",
  props: {
    value: [String, Array],
    id: String,
    required: Boolean,
    placeholder: String,
    label: String,
    disabled: { type: Boolean, default: false },
    errors: Array,
    onChange: Function,
    // if dateOnly is true value will be returned in "YYYY-MM-DD" format
    dateOnly: { type: Boolean, default: false },
    // if defaultMidnight is true the time always will be set to midnight in current timezone
    defaultMidnight: { type: Boolean, default: true },
    range: { type: Boolean, default: false },
    disabledDate: Function,
    allowClear: {
      type: Boolean,
      default: true,
    },
    showTime: { type: Boolean, default: false },
  },
  components: { DatePicker, RangePicker: DatePicker.RangePicker },
  computed: {
    cValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("update:value", newValue);
      },
    },
    cPlaceholder() {
      return this.placeholder ?? this.label;
    },
    valueFormat() {
      return this.dateOnly
        ? "YYYY-MM-DD"
        : this.defaultMidnight
        ? "YYYY-MM-DDT00:00:00.000ZZ"
        : "YYYY-MM-DDTHH:mm:ss.SSSZZ";
    },
    component() {
      return this.range ? "RangePicker" : "DatePicker";
    },
  },
  emits: ["update:value", "invalid"],
  methods: {
    onInvalid(event) {
      this.$emit("invalid", event);
    },
  },
};
</script>
