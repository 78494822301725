import SYSTEM_DICT from "@/config/systemDict";
import apiClient from "@/config/apiClient";

const getReplacementsList = (params) => {
  return apiClient.get("/api/replacement/", { params });
};

const createReplacement = (data) => {
  return apiClient.post("/api/replacement/", data);
};

const editReplacement = (data, id) => {
  return apiClient.put(`/api/replacement/${id}/`, data);
};

const getReplacement = (id) => {
  return apiClient.get(`/api/replacement/${id}/`);
};

const deleteReplacement = (id) => {
  return apiClient.delete(`/api/replacement/${id}/`);
};

const getReplacementsTypes = (params) => {
  return apiClient.get(
    `/api/dicts/entry/keyword/${SYSTEM_DICT.ABSENCE_TYPES}/`,
    { params },
  );
};
const getReplacementUsersSimpleList = (params) => {
  return apiClient.get("/api/replacement/users-select/", { params });
};
const getReplacingUsersMyReplacementSimpleList = (params) => {
  return apiClient.get(
    "/api/replacement/my-replacement/replacing-users-select/",
    { params },
  );
};

export {
  createReplacement,
  deleteReplacement,
  editReplacement,
  getReplacement,
  getReplacementsTypes,
  getReplacementsList,
  getReplacementUsersSimpleList,
  getReplacingUsersMyReplacementSimpleList,
};
