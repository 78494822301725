//TODO: PoC implementation, resolver / mapper for apps

type Map = Record<string, boolean>;
const _map: Map = {
  False: false,
  True: true,
};

const APPS = {
  ARCHIVES: _map[process.env.VUE_APP_SWITCH_ARCHIVES],
  CONTACTS: _map[process.env.VUE_APP_SWITCH_CONTACTS],
  CONTRACTORS: _map[process.env.VUE_APP_SWITCH_CONTRACTORS],
  COUNTERS: _map[process.env.VUE_APP_SWITCH_COUNTERS],
  DICTS: _map[process.env.VUE_APP_SWITCH_DICTS],
  DOCUMENTS: _map[process.env.VUE_APP_SWITCH_DOCUMENTS],
  FORMS: _map[process.env.VUE_APP_SWITCH_FORMS],
  GROUPS: _map[process.env.VUE_APP_SWITCH_GROUP],
  ISSUES: _map[process.env.VUE_APP_SWITCH_ISSUES],
  JRWA: _map[process.env.VUE_APP_SWITCH_JRWA],
  LDAP: _map[process.env.VUE_APP_SWITCH_LDAP],
  MAILBOXES: _map[process.env.VUE_APP_SWITCH_MAILBOXES],
  NOTIFICATIONS: _map[process.env.VUE_APP_SWITCH_NOTIFICATIONS],
  PROPOSALS: _map[process.env.VUE_APP_SWITCH_PROPOSALS],
  REGISTERS: _map[process.env.VUE_APP_SWITCH_REGISTERS],
  REPLACEMENTS: _map[process.env.VUE_APP_SWITCH_REPLACEMENT],
  REPOSITORIES: _map[process.env.VUE_APP_SWITCH_REPOSITORIES],
  SAML: _map[process.env.VUE_APP_SWITCH_SAML],
  SEARCH: _map[process.env.VUE_APP_SWITCH_SEARCH],
  WORKFLOWS: _map[process.env.VUE_APP_SWITCH_CAMUNDA],
  UNITS: _map[process.env.VUE_APP_SWITCH_UNITS],
  USERS: _map[process.env.VUE_APP_SWITCH_USERS],
};

export default APPS;
