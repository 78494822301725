<template>
  <Breadcrumbs
    :current="$t('modules.processes')"
    iconComponent="PartitionOutlined"
  />

  <Tabs @change="$scrollToTop">
    <TabPane key="1" :tab="$t('app.active')">
      <ProcessesList
        :params="{ finished: 'False' }"
        customStore="processesActive"
      />
    </TabPane>
    <TabPane key="2" :tab="$t('app.completed')">
      <ProcessesList
        :params="{ finished: 'True' }"
        customStore="processesCompleted"
      />
    </TabPane>
    <TabPane key="3" :tab="$t('app.all')">
      <ProcessesList customStore="processes" showStatus />
    </TabPane>
  </Tabs>
</template>

<script>
import { Breadcrumbs } from "@/components/ui";
import ProcessesList from "./ProcessesList";
import { Tabs } from "ant-design-vue";

export default {
  name: "Processes",
  components: {
    Breadcrumbs,
    ProcessesList,
    Tabs,
    TabPane: Tabs.TabPane,
  },
};
</script>
