<template>
  <Modal
    v-model:visible="cVisible"
    :title="$tc('archive.handovers.createHandoverLists', 1)"
    :okText="$t('archive.handovers.goToHandoverFillForm')"
    :cancelText="$t('app.cancel')"
    :closable="false"
    :okButtonProps="{ htmlType: 'submit', form: 'handover-form' }"
    @cancel="onCancel"
    @ok="openHandoverListsModal"
    width="684px"
  >
    <div class="form-container form-handover">
      <form id="handover-form" @submit.prevent="submit" class="form">
        <EODRadio
          id="handover_type"
          v-model:value="handover.protocol_type"
          :label="$t('archive.handovers.handoverType')"
          :options="handoverType"
          @change="onHandoverType"
          required
        >
          <Radio
            v-for="option in handoverType"
            :key="option.id"
            :value="option.id"
          >
            {{ option.name }}
            <component :is="option.icon" />
          </Radio>
        </EODRadio>
        <EODRadio
          id="handover_documents_category"
          v-model:value="handover.archive_category_type"
          @change="onCategoryChange"
          :label="$t('app.category')"
          :options="categoriesDocumentsTypes"
          :errors="errors.type"
          required
        />
        <EODSelect
          id="unit"
          storeID="handover_unit"
          v-model:value="handover.unit"
          :label="$t('issues.unit')"
          :service="fetchUnits"
          :errors="errors.unit"
          @change="onUnitChange"
          required
        />
      </form>
    </div>
  </Modal>
</template>

<script>
import { CATEGORY, HANDOVER_TYPE } from "@/consts/statuses";
import { EODRadio, EODSelect } from "@/components/inputs";
import {
  FileOutlined,
  PictureOutlined,
  UsbOutlined,
} from "@ant-design/icons-vue";

import { Modal, Radio, Tooltip } from "ant-design-vue";
import { getUnitsFlatSelect } from "@/services/units";

export default {
  name: "AddHandoverModal",
  props: {
    visibleCompleteModal: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    handleRefresh: {
      type: Function,
      default: () => {},
    },
    handoverModel: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      errors: {},
      handoverType: [
        {
          name: this.$t("archive.handovers.handoverTypes.casual"),
          id: HANDOVER_TYPE.CASUAL,
          icon: "FileOutlined",
        },
        {
          name: this.$t("archive.handovers.handoverTypes.ITmedia"),
          id: HANDOVER_TYPE.IT_MEDIA,
          icon: "UsbOutlined",
        },
        {
          name: this.$t("archive.handovers.handoverTypes.photoDocumentation"),
          id: HANDOVER_TYPE.PHOTO_DOCUMENTATION,
          icon: "PictureOutlined",
        },
      ],
      categoriesDocumentsTypes: [
        {
          name: this.$t(
            "archive.handovers.categoriesDocumentsTypes.archivedDocument",
          ),
          id: CATEGORY.ARCHIVE,
        },
        {
          name: this.$t(
            "archive.handovers.categoriesDocumentsTypes.notArchivedDocuments",
          ),
          id: CATEGORY.NOT_ARCHIVE,
        },
      ],
    };
  },
  components: {
    EODSelect,
    Modal,
    Tooltip,
    EODRadio,
    Radio,
    FileOutlined,
    UsbOutlined,
    PictureOutlined,
  },
  computed: {
    cVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
    handover: {
      get() {
        return this.handoverModel;
      },
      set(val) {
        this.$emit("update:handoverModel", val);
      },
    },
  },
  emits: [
    "update:handoverModel",
    "update:visible",
    "update:visibleCompleteModal",
  ],
  methods: {
    fetchUnits(params) {
      return getUnitsFlatSelect(params).then(({ data }) => data);
    },
    onCancel() {
      this.$emit("update:visible", false);
      this.handover.unit = null;
      this.handover.archive_category_type = CATEGORY.ARCHIVE;
      this.handover.protocol_type = HANDOVER_TYPE.CASUAL;
    },
    openHandoverListsModal() {
      if (this.handover.unit) {
        this.$emit("update:visible", false);
        this.$emit("update:visibleCompleteModal", true);
        this.errors = {};
      } else {
        this.errors = { unit: [this.$t("contractors.completeData")] };
      }
    },
    onCategoryChange(val) {
      // prettier-ignore
      this.handover.archive_category_type_display = this.categoriesDocumentsTypes.find(
        (item) => item.id === val.target.value,
      )?.name;
    },
    onHandoverType(val) {
      this.handover.type_display = this.handoverType.find(
        (item) => item.id === val.target.value,
      )?.name;
    },
    onUnitChange(value) {
      this.handover.unit_display = this.$store.state[
        "select_handover_unit"
      ]?.serviceOptions?.find((elem) => elem.id === value)?.unit_symbol;
    },
  },
};
</script>

<style scoped>
.form-container .form {
  width: 30rem;
}
</style>
