<template>
  <Breadcrumbs :current="current" iconComponent="UserSwitchOutlined">
    <EODButtonLink
      :to="{ name: all ? 'ReplacementsAllCreate' : 'ReplacementsCreate' }"
      :title="$t('app.add_', [replacement])"
    />
  </Breadcrumbs>

  <section class="section">
    <BaseTable
      :columns="columns"
      :service="getReplacementsList"
      :customParams="{ all }"
    >
      <template #date_from="{ record }">
        {{ new Date(record.date_from).toLocaleDateString() }}
      </template>
      <template #date_to="{ record }">
        {{ new Date(record.date_to).toLocaleDateString() }}
      </template>
      <template #replaced_user="{ record }">
        {{ record.replaced_user_display }}
      </template>
      <template #replacing_users="{ record }">
        {{ record.replacing_users.map((item) => item.full_name).join(", ") }}
      </template>
      <template #absence_type="{ record }">
        {{ record.absence_type_display }}
      </template>
      <template #actions="{ record }">
        <TableActions
          :record="record"
          :previewRoute="{
            name: all ? 'ReplacementsAllPreview' : 'ReplacementsPreview',
            params: { id: record.id },
          }"
          :editRoute="{
            name: all ? 'ReplacementsAllEdit' : 'ReplacementsEdit',
            params: { id: record.id },
          }"
          :deleteMessage="$t('app.doYouWantToDelete_', [replacement])"
          :modalTitle="$t('app.delete_', [replacement])"
          :deleteService="deleteReplacement"
        >
          <button
            v-if="record?.actions?.cancelable"
            class="action"
            @click="confirmCancel(record.id)"
            :title="$t('app.cancel')"
          >
            <StopOutlined />
          </button>
        </TableActions>
      </template>
    </BaseTable>
  </section>
</template>

<script>
import { BaseTable, TableActions } from "@/components/table";
import { Breadcrumbs, EODButtonLink } from "@/components/ui";
import {
  deleteReplacement,
  getReplacementsList,
} from "@/services/replacements";

import { StopOutlined } from "@ant-design/icons-vue";

export default {
  name: "Replacements",
  props: { all: { type: Boolean, default: false } },
  data() {
    return {
      replacement: this.$tc("replacements.replacement", 2),
      columns: [
        {
          title: this.$t("replacements.from"),
          dataIndex: "date_from",
          sorter: true,
          slots: { customRender: "date_from" },
        },
        {
          title: this.$t("replacements.to"),
          dataIndex: "date_to",
          sorter: true,
          slots: { customRender: "date_to" },
        },
        {
          title: this.$t("replacements.replacedUser"),
          dataIndex: "replaced_user",
          sorter: true,
          slots: { customRender: "replaced_user" },
        },
        {
          title: this.$t("replacements.replacingUsers"),
          dataIndex: "replacing_users",
          sorter: false,
          slots: { customRender: "replacing_users" },
        },
        {
          title: this.$t("replacements.type"),
          dataIndex: "absence_type",
          sorter: true,
          slots: { customRender: "absence_type" },
        },
        {
          title: this.$t("app.actions"),
          dataIndex: "actions",
          slots: { customRender: "actions" },
          fixed: "right",
          width: "150",
        },
      ],
    };
  },
  components: {
    BaseTable,
    Breadcrumbs,
    EODButtonLink,
    StopOutlined,
    TableActions,
  },
  computed: {
    current() {
      return this.all
        ? this.$t("modules.replacementsAll")
        : this.$t("modules.replacements");
    },
  },
  methods: {
    deleteReplacement,
    getReplacementsList,
    confirmCancel(id) {
      this.$store.dispatch("setModal", {
        message: this.$t("app.doYouWantToCancel_", [this.replacement]),
        title: this.$t("app.cancel_", [this.replacement]),
        modalOk: () => this.cancelReplacement(id),
      });
    },
    cancelReplacement(id) {
      this.deleteReplacement(id).then(() => {
        this.$message.success(this.$t("app.success"));
        this.$store.dispatch("closeModal");
        this.$store.dispatch("refreshTable");
      });
    },
  },
};
</script>
