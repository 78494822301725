<template>
  <Modal
    v-model:visible="cVisible"
    :title="this.$t('issues.addDocument')"
    :okText="$t('issues.addDocument')"
    :cancelText="$t('app.cancel')"
    :closable="false"
    @ok="submit"
    @cancel="onCancel"
  >
    <div class="form-container">
      <form id="add-document-form" class="form">
        <p>
          {{ $t("issues.addDocumentInfo") }}
          <span class="font--bold">{{ signature }}</span>
        </p>

        <EODSelect
          id="documents"
          :storeID="selectStoreID"
          v-model:value="data.document"
          v-model:refetch="refetchDocuments"
          :label="$t('issues.document')"
          :placeholder="$t('issues.issueAddDocumentPlaceholder')"
          :service="fetchDocuments"
          :onChange="handleSelectDocument"
          :errors="errors.document"
          required
        />

        <span v-if="selectedDocumentJRWA" class="color--warning">
          <ExclamationCircleOutlined /> {{ $t("issues.JRWAWarning") }}
        </span>

        <EODInput
          id="comment"
          type="textarea"
          v-model:value="data.comment"
          :label="$t('app.comment')"
          :props="{ maxlength: 500 }"
          :errors="errors.comment"
        />
      </form>
    </div>
  </Modal>
</template>

<script>
import { EODInput, EODSelect } from "@/components/inputs";

import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { ISSUE_DOCUMENT_MODEL } from "./IssueDocumentModel";
import { Modal } from "ant-design-vue";
import { addDocument } from "@/services/issues";
import { getDocumentsSelectList } from "@/services/documents";

export default {
  name: "IssueAddDocument",
  props: {
    issueID: {
      type: Number,
      default: null,
    },
    signature: {
      type: String,
      default: "-",
    },
    visible: {
      type: Boolean,
      default: false,
    },
    selectStoreID: {
      type: String,
      default: "issues-add-document",
    },
  },
  data() {
    return {
      data: { ...ISSUE_DOCUMENT_MODEL },
      documentList: [],
      selectedDocumentJRWA: null,
      errors: {},
      refetchDocuments: false,
    };
  },
  components: {
    EODInput,
    EODSelect,
    ExclamationCircleOutlined,
    Modal,
  },
  computed: {
    cVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
  },
  emits: ["update:visible", "update:issueID"],
  methods: {
    submit() {
      const documentData = this.data;
      if (!Array.isArray(documentData.document) && documentData.document) {
        documentData.document = [documentData.document];
      }

      addDocument(documentData, this.issueID)
        .then(() => {
          this.$message.success(this.$t("app.success"));
          this.resetDocument();
          this.refreshTable();
          this.refetchDocuments = true;
          this.cVisible = false;
        })
        .catch((err) => {
          this.errors = err.response.data;
        });
    },
    onCancel() {
      this.resetDocument();
      this.cVisible = false;
      this.$emit("update:issueID", null);
    },
    refreshTable() {
      for (const store of [
        "issues",
        "issuesInProgress",
        "IssueDocumentsTable",
        "issueHistory",
      ]) {
        if (this.$store.hasModule(store)) {
          this.$store.dispatch(`${store}/refreshTable`);
        }
      }

      this.$store.dispatch("refreshTable");
    },
    resetDocument() {
      this.data = { ...ISSUE_DOCUMENT_MODEL };
      this.selectedDocumentJRWA = null;
      this.errors = {};
    },
    fetchDocuments(params) {
      return getDocumentsSelectList({ unassigned: true, ...params }).then(
        ({ data }) => data,
      );
    },
    handleSelectDocument(id) {
      this.selectedDocumentJRWA =
        this.documentList.find((el) => el.id === id)?.jrwa ?? null;
    },
  },
  watch: {
    issueID(val) {
      if (val) {
        this.refetchDocuments = true;
      }
    },
  },
};
</script>
