<template>
  <Breadcrumbs :current="$t('modules.units')" iconComponent="HomeOutlined" />

  <section class="section">
    <BaseTable :columns="columns" :service="getUnitsList" :scroll="{ x: 1000 }">
      <template v-slot:status="{ record }">
        <StatusDot :status="record.status" />
        {{ record.status_display }}
      </template>
      <template v-slot:parent="{ record }">
        {{ record.parent_display || "-" }}
      </template>
      <template v-slot:actions="{ record }">
        <TableActions
          :record="record"
          :previewRoute="{
            name: 'UnitsPreview',
            params: { id: record.id },
          }"
        />
      </template>
    </BaseTable>
  </section>
</template>

<script>
import { BaseTable, StatusDot, TableActions } from "@/components/table";

import { Breadcrumbs } from "@/components/ui";
import { getUnitsList } from "@/services/units";

export default {
  name: "Units",
  data() {
    return {
      columns: [
        {
          title: this.$t("units.symbol"),
          dataIndex: "unit_symbol",
          width: "30%",
        },
        {
          title: this.$t("app.name"),
          dataIndex: "name",
        },
        {
          title: this.$t("app.status"),
          dataIndex: "status",
          slots: { customRender: "status" },
        },
        {
          title: this.$t("units.parent"),
          dataIndex: "parent",
          slots: { customRender: "parent" },
        },
        {
          title: this.$t("app.actions"),
          dataIndex: "actions",
          slots: { customRender: "actions" },
          fixed: "right",
          width: "70",
        },
      ],
    };
  },
  components: {
    BaseTable,
    Breadcrumbs,
    StatusDot,
    TableActions,
  },
  methods: {
    getUnitsList,
  },
};
</script>
