<template>
  <Breadcrumbs
    :current="$t('modules.dictionaries')"
    iconComponent="SortAscendingOutlined"
  >
    <EODButtonLink
      v-if="hasPermissionToAdd"
      :to="{
        name: 'DictionariesCreate',
      }"
      :title="$t('dictionaries.add')"
    />
  </Breadcrumbs>

  <Tabs @change="changeTab">
    <TabPane key="1" :tab="$t('modules.dictionaries')">
      <DictionariesList
        customStore="usersDictionaries"
        :customParams="{ system: false }"
      />
    </TabPane>
    <TabPane key="2">
      <template v-slot:tab>
        {{ $t("dictionaries.systemDictionaries") }}
        <Tooltip
          :title="$t('dictionaries.systemDictionariesInfo')"
          :trigger="['focus', 'hover', 'click']"
        >
          <InfoCircleOutlined />
        </Tooltip>
      </template>
      <DictionariesList
        customStore="systemDictionaries"
        :customParams="{ system: true }"
      />
    </TabPane>
  </Tabs>
</template>

<script>
import { Breadcrumbs, EODButtonLink } from "@/components/ui";
import { Tabs, Tooltip } from "ant-design-vue";

import DictionariesList from "@/views/Dictionaries/DictionariesList";
import { InfoCircleOutlined } from "@ant-design/icons-vue";
import PERMISSIONS from "@/consts/permissions";

export default {
  name: "Dictionaries",
  components: {
    Breadcrumbs,
    DictionariesList,
    EODButtonLink,
    InfoCircleOutlined,
    Tabs,
    TabPane: Tabs.TabPane,
    Tooltip,
  },
  computed: {
    hasPermissionToAdd() {
      return this.$permissions.includes(PERMISSIONS.DICTIONARIES_ADD);
    },
  },
  methods: {
    changeTab(activeTab) {
      window.scrollTo(0, 0);
      if (activeTab === "2") {
        const tooltip = document.querySelector("span.anticon-info-circle");
        tooltip.focus();
      }
    },
  },
};
</script>
