const dictionaries = {
  state: {
    shouldRefetch: false,
    entryID: undefined,
    showEntry: false,
  },
  mutations: {
    SET_SHOULD_REFETCH(state, payload) {
      state.shouldRefetch = payload;
    },
    SET_ENTRY_ID(state, payload) {
      state.entryID = payload;
    },
    SET_SHOW_ENTRY(state, payload) {
      state.showEntry = payload;
    },
  },
  actions: {
    refetchEntries({ commit }) {
      commit("SET_SHOULD_REFETCH", true);
    },
    resetRefetchEntries({ commit }) {
      commit("SET_SHOULD_REFETCH", false);
    },
    setEntryID({ commit }, payload) {
      commit("SET_SHOW_ENTRY", true);
      commit("SET_ENTRY_ID", payload);
    },
    showEntry({ commit }) {
      commit("SET_SHOW_ENTRY", true);
    },
    resetShowEntry({ commit }) {
      commit("SET_SHOW_ENTRY", false);
    },
  },
};

export default dictionaries;
