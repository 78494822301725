import APPS from "@/consts/apps";
import PERMISSIONS from "@/consts/permissions";
import Registers from "@/views/Registry/Registers.vue";
import RegistryForm from "@/views/Registry/RegistryForm.vue";
import RegistryPreview from "@/views/Registry/RegistryPreview.vue";
import i18n from "@/i18n";

const t = i18n.global.t;

const registry = [
  {
    path: "/registry/",
    name: "Registry",
    component: Registers,
    meta: {
      title: `${t("meta.app")} - ${t("modules.registry")}`,
      requiresAuth: true,
      app: APPS.REGISTERS,
    },
  },
  {
    path: "/registry/create/",
    name: "RegistryCreate",
    component: RegistryForm,
    meta: {
      title: `${t("meta.app")} - ${t("app.add_", [t("registry.registry")])}`,
      requiresAuth: true,
      permission: PERMISSIONS.REGISTRY_MANAGE,
      app: APPS.REGISTERS,
    },
  },
  {
    path: "/registry/:id/edit/",
    name: "RegistryEdit",
    component: RegistryForm,
    meta: {
      title: `${t("meta.app")} - ${t("app.edit_", [t("registry.registry")])}`,
      requiresAuth: true,
      permission: PERMISSIONS.REGISTRY_MANAGE,
      app: APPS.REGISTERS,
    },
  },
  {
    path: "/registry/:id/preview/",
    name: "RegistryPreview",
    component: RegistryPreview,
    meta: {
      title: `${t("meta.app")} - ${t("app.preview_", [
        t("registry.registry"),
      ])}`,
      requiresAuth: true,
      app: APPS.REGISTERS,
    },
  },
];

export default registry;
