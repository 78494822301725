import { Ref, ref } from "vue";

import { STATUS } from "@/consts/statuses";
import { TreeNode } from "@/interfaces/common";
import { getDictionaryEntriesSelect } from "@/services/dictionaries";
import { getUnitsSelect } from "@/services/units";

export default function useFormBuilderTreeSelects(
  schemaUI: Ref<any>,
  model: Ref<any>,
  additionalValues: Ref<Record<string, string | number>>,
) {
  const searchValue = ref<Record<string, string>>({});
  const treeComponents = ref(["treeSelect", "unitDelegation", "allUnits"]);
  const treeSelectOptions = ref<Record<string, TreeNode[]>>({});
  const originalTreeValue = ref<Record<string, number[]>>({});

  const getTreeService = (key: string, parent?: number) => {
    // TODO: proper hierarchic select API AWF-1425
    const params = {
      limit: 100,
      parent,
      search_field: searchValue.value[key],
    };

    if (["candidate_units", "all_units"].includes(key)) {
      return getUnitsSelect(params);
    } else {
      return getDictionaryEntriesSelect({
        dictionary: schemaUI.value[key].dictionaryID,
        status: STATUS.ACTIVE,
        ...params,
      });
    }
  };

  const fetchTreeDictionary = (key: string) => {
    const service = getTreeService(key);

    service.then(({ data }) => {
      treeSelectOptions.value[key] = data.results.map((item: any) => {
        return {
          id: item.id,
          pId: item.parent || 0,
          value: item.id,
          title: item.name,
          isLeaf: !item.children,
          disabled: ["candidate_units", "all_units"].includes(key)
            ? false
            : !!item.children,
          additional_value: item.additional_value,
        };
      });

      if (Array.isArray(model.value[key])) {
        originalTreeValue.value[key] = [...model.value[key]];

        if (model.value[key].length > 0) {
          for (const [idx, item] of model.value[key].entries()) {
            if (
              !treeSelectOptions.value[key].find((elem) => elem.id === item) &&
              !searchValue.value[key]
            ) {
              treeSelectOptions.value[key].push({
                id: item,
                pId: 0,
                value: item,
                title: model.value[`${key}_display`][idx],
                isLeaf: true,
              });
            }
          }
        }
      } else {
        if (
          model.value[key] &&
          !treeSelectOptions.value[key].find(
            (item) => item.id === model.value[key],
          ) &&
          !searchValue.value[key]
        ) {
          originalTreeValue.value[key] = model.value[key];
          additionalValues.value[key] = model.value[`${key}_additional_value`];

          treeSelectOptions.value[key].push({
            id: model.value[key],
            pId: 0,
            value: model.value[key],
            title: model.value[`${key}_display`],
            isLeaf: true,
            additional_value: model.value[`${key}_additional_value`],
          });
        }
      }
    });
  };

  const fetchTreeDictionaries = (properties: any) => {
    for (const key of Object.keys(properties)) {
      if (treeComponents.value.includes(schemaUI.value[key].component)) {
        fetchTreeDictionary(key);
      }
    }
  };

  const onLoadData = (treeNode: any, key: string) => {
    const { id: nodeId } = treeNode.dataRef;
    const service = getTreeService(key, nodeId);

    return service.then(({ data }) => {
      const children = data.results.map((item: any) => {
        if (
          (Array.isArray(model.value[key]) &&
            originalTreeValue.value[key].includes(item.id)) ||
          (model.value[key] && item.id === originalTreeValue.value[key])
        ) {
          treeSelectOptions.value[key] = treeSelectOptions.value[key].filter(
            (elem: TreeNode) => elem.id !== item.id,
          );
        }

        return {
          id: item.id,
          pId: item.parent || 0,
          value: item.id,
          title: item.name,
          isLeaf: !item.children,
          disabled: ["candidate_units", "all_units"].includes(key)
            ? false
            : !!item.children,
          additional_value: item.additional_value,
        };
      });

      // prettier-ignore
      treeSelectOptions.value[key] = treeSelectOptions.value[key].concat(
        children,
      );
    });
  };

  const onTreeSelectChange = (val: number, key: string) => {
    searchValue.value[key] = "";
    const getName = (id: number) =>
      treeSelectOptions.value[key].find((elem) => elem.id === id)?.title;

    if (Array.isArray(val)) {
      model.value[`${key}_display`] = val.map(getName);
    } else {
      model.value[`${key}_display`] = getName(val);
      additionalValues.value[key] = treeSelectOptions.value[key].find(
        (elem) => elem.id === val,
      )?.additional_value;
    }
  };

  return {
    fetchTreeDictionaries,
    fetchTreeDictionary,
    getTreeService,
    onLoadData,
    onTreeSelectChange,
    searchValue,
    treeComponents,
    treeSelectOptions,
  };
}
