import { LDAPUserPayload } from "@/interfaces/ldap";
import { Params } from "@/interfaces/common";
import axios from "@/config/apiClient";

const addLDAPUser = (data: LDAPUserPayload) => {
  return axios.post("/api/ldap/populate/", data);
};

const getOUs = () => {
  return axios.get("/api/ldap/ou/");
};

const searchLDAPUsers = (data: { ou: string[] }, params?: Params) => {
  return axios.post("/api/ldap/search/", data, { params });
};

export { addLDAPUser, getOUs, searchLDAPUsers };
