<template>
  <ShareForm
    v-model:perms="perms"
    :errors="errors"
    :viewPerms="viewPerms"
    :editPerms="editPerms"
    :startPerms="startPerms"
    :submitFunction="submitPerms"
    :defaultValues="defaultValues"
  />
  <div class="buttons">
    <Button
      :title="$t('app.save')"
      type="primary"
      form="perms-form"
      htmlType="submit"
      :loading="loading$"
    >
      {{ $t("app.save") }}
    </Button>
  </div>
</template>

<script>
import { Button } from "ant-design-vue";
import ShareForm from "./ShareForm";

export default {
  name: "Share",
  props: {
    id: { type: [Number, String], required: true },
    editService: { type: Function, required: true },
    getService: { type: Function, required: true },
    viewPerms: { type: Boolean, default: true },
    editPerms: { type: Boolean, default: true },
    startPerms: { type: Boolean, default: false },
  },
  data() {
    return {
      perms: {},
      errors: {},
      defaultValues: {},
    };
  },
  components: {
    Button,
    ShareForm,
  },
  created() {
    if (this.id) {
      this.fetchPerms();
    }
  },
  methods: {
    fetchPerms() {
      this.getService(this.id).then(({ data }) => {
        this.defaultValues = data;
        this.perms = Object.entries(data).reduce(
          (acc, [key, val]) => ({
            ...acc,
            [key]: val.map((item) => item.id),
          }),
          {},
        );
      });
    },
    submitPerms() {
      this.loading$ = true;

      this.editService(this.perms, this.id)
        .then(({ data: msg }) => {
          this.$message.success(
            typeof msg === "string" ? msg : this.$t("app.success"),
          );
        })
        .catch((err) => {
          this.errors = err.response.data;
        })
        .finally(() => {
          this.loading$ = false;
        });
    },
  },
};
</script>
