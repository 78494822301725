<template>
  <Breadcrumbs
    :path="[
      { path: { name: 'Notifications' }, title: $t('modules.notifications') },
    ]"
    :current="$t('notifications.preview')"
    iconComponent="MessageOutlined"
  >
    <EODButtonLink
      v-if="hasPermissionsToEdit"
      :to="{ name: 'NotificationsEdit', params: { id: id } }"
      :title="$t('notifications.edit')"
      icon="EditOutlined"
    />
  </Breadcrumbs>

  <Preview
    :oid="id"
    :data="previewData"
    :title="$t('notifications.metaPreview')"
    :noPreviewMsg="$t('notifications.noPreview')"
  />

  <Section :title="$t('notifications.contentPreview')">
    <div class="center">
      <EODEditor
        :preview="template"
        :options="{
          status: false,
          toolbar: false,
        }"
      />
    </div>
  </Section>
</template>

<script>
import { Breadcrumbs, EODButtonLink, Section } from "@/components/ui";
import { EODEditor } from "@/components/inputs";
import PERMISSIONS from "@/consts/permissions";
import { Preview } from "@/components/common";
import { getNotification } from "@/services/notifications";

export default {
  name: "NotificationsPreview",
  data() {
    return {
      id: +this.$route.params.id,
      previewData: [],
      template: null,
    };
  },
  components: {
    Breadcrumbs,
    EODButtonLink,
    EODEditor,
    Preview,
    Section,
  },
  computed: {
    hasPermissionsToEdit() {
      return this.$permissions.includes(PERMISSIONS.NOTIFICATIONS_EDIT);
    },
  },
  mounted() {
    if (this.id) {
      getNotification(this.id).then(({ data }) => {
        this.template = data.template;

        this.previewData = [
          { title: this.$t("app.name"), value: data.name },
          {
            title: this.$t("app.type"),
            value: data.notifications_type_display,
          },
          {
            title: this.$t("app.status"),
            value: data.status_display,
          },
          { title: this.$t("app.title"), value: data.title || "-" },
        ];
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.center {
  display: flex;
  justify-content: center;
}
</style>
