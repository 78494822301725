
import {
  PropType,
  computed,
  defineComponent,
  inject,
  ref,
  toRefs,
  watch,
} from "vue";
import { addDocumentsToVolume, addIssuesToVolume } from "@/services/archive";
import { Alert } from "ant-design-vue";
import { ConfirmModal } from "@/components/modals";
import { EODInput } from "@/components/inputs";
import { MessageApi } from "ant-design-vue/lib/message";
import { store } from "@/store";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "AddToVolumeModal",
  props: {
    id: {
      type: Number,
      default: null,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    issues: {
      type: Array as PropType<number[]>,
      default: () => [] as number[],
    },
    documents: {
      type: Array as PropType<number[]>,
      default: () => [] as number[],
    },
  },
  setup(props, { emit }) {
    const $message = inject<MessageApi>("$message");
    const { t } = useI18n();
    const { documents, id, issues, visible } = toRefs(props);

    const isLoading = ref(false);
    const volume = ref("");
    const volumeErrors = ref<string[]>([]);

    const cVisible = computed({
      get: () => visible.value,
      set: (val) => emit("update:visible", val),
    });

    const submit = async () => {
      if (volume.value === "") {
        volumeErrors.value.push(t("archive.folders.enterVolumeToContinue"));
        return;
      }
      isLoading.value = true;
      if (documents.value.length > 0) {
        await addDocumentsToVolume(
          { documents: documents.value, volume: volume.value },
          id.value,
        ).then(() => {
          $message.success(t("app.success"));
          store.dispatch("folderDocuments/refreshTable");
        });
      }
      if (issues.value.length > 0) {
        await addIssuesToVolume(
          { issues: issues.value, volume: volume.value },
          id.value,
        ).then(() => {
          $message.success(t("app.success"));
          store.dispatch("folderIssues/refreshTable");
        });
      }
      cVisible.value = false;
      volume.value = "";
      isLoading.value = false;
    };

    const onCancel = () => {
      cVisible.value = false;
      clearErrors();
    };

    const clearErrors = () => {
      volumeErrors.value = [];
    };

    watch(volume, clearErrors);

    return {
      cVisible,
      isLoading,
      onCancel,
      submit,
      volume,
      volumeErrors,
    };
  },
  components: { Alert, ConfirmModal, EODInput },
  emits: ["update:visible"],
});
