<template>
  <Modal
    v-model:visible="cVisible"
    :title="null"
    :closable="false"
    :maskClosable="false"
    :footer="null"
  >
    <div class="modal-body">
      <p class="modal-title">
        <VerticalAlignBottomOutlined class="modal-icon" />
        {{ $t("app.noAttachmentPreview") }}
      </p>
      <p class="modal-text">
        {{ $t("app.noAttachmentPreviewInfo") }}
      </p>
    </div>
    <div class="buttons">
      <EODButton :name="$t('app.cancel')" @click="onCancel" class="mr-050" />
      <EODButton
        type="primary"
        :name="$t('app.downloadAttachment')"
        @click="downloadAttachment"
      />
    </div>
  </Modal>
</template>

<script>
import { EODButton } from "@/components/ui";
import { Modal } from "ant-design-vue";
import { VerticalAlignBottomOutlined } from "@ant-design/icons-vue";

export default {
  name: "CannotPreviewModal",
  props: {
    previewFile: String,
    resetAttachmentData: {
      type: Function,
      default: () => {},
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    EODButton,
    Modal,
    VerticalAlignBottomOutlined,
  },
  computed: {
    cVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
  },
  methods: {
    onCancel() {
      this.cVisible = false;
      this.resetAttachmentData();
    },
    downloadAttachment() {
      const link = document.createElement("a");
      link.href = this.previewFile;
      link.download = true;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.onCancel();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/Variables";
.modal-body {
  padding: 0 1rem 0 3rem;
  font-size: 1.2rem;
  .modal-title {
    font-weight: bold;
    position: relative;
    .modal-icon {
      position: absolute;
      top: 0;
      left: -2.5rem;
      font-size: 1.5rem;
      line-height: 2.5rem;
    }
  }
  .modal-text {
    color: $font-secondary;
  }
}
.buttons {
  text-align: right;
  padding: 0 0.5rem;
}
</style>
