import {
  AppstoreAddOutlined,
  BankOutlined,
  BranchesOutlined,
  CalculatorOutlined,
  CalendarOutlined,
  CarryOutOutlined,
  CheckCircleFilled,
  CheckCircleOutlined,
  CheckCircleTwoTone,
  CheckSquareOutlined,
  ControlOutlined,
  CreditCardOutlined,
  CrownOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  FieldBinaryOutlined,
  FieldNumberOutlined,
  FieldTimeOutlined,
  FileMarkdownOutlined,
  FontSizeOutlined,
  HomeOutlined,
  InfoCircleOutlined,
  LineHeightOutlined,
  LineOutlined,
  OrderedListOutlined,
  ProfileOutlined,
  SortAscendingOutlined,
  TagsOutlined,
  TeamOutlined,
  UnorderedListOutlined,
  UpOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons-vue";

export default {
  AppstoreAddOutlined,
  BankOutlined,
  BranchesOutlined,
  CalculatorOutlined,
  CalendarOutlined,
  CarryOutOutlined,
  CheckCircleOutlined,
  CheckSquareOutlined,
  CheckCircleTwoTone,
  CheckCircleFilled,
  ControlOutlined,
  CreditCardOutlined,
  CrownOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  FieldBinaryOutlined,
  FileMarkdownOutlined,
  FieldNumberOutlined,
  FieldTimeOutlined,
  FontSizeOutlined,
  HomeOutlined,
  InfoCircleOutlined,
  LineHeightOutlined,
  LineOutlined,
  OrderedListOutlined,
  ProfileOutlined,
  SortAscendingOutlined,
  TagsOutlined,
  TeamOutlined,
  UnorderedListOutlined,
  UpOutlined,
  UserOutlined,
  UserSwitchOutlined,
};
