import APPS from "@/consts/apps";
import PERMISSIONS from "@/consts/permissions";
import Units from "@/views/Units/Units.vue";
import UnitsPreview from "@/views/Units/UnitsPreview.vue";

const units = [
  {
    path: "/units/",
    name: "Units",
    component: Units,
    meta: {
      title: "EOD - Lista jednostek",
      requiresAuth: true,
      permission: PERMISSIONS.UNITS,
      app: APPS.UNITS,
    },
  },
  {
    path: "/units/:id/preview/",
    name: "UnitsPreview",
    component: UnitsPreview,
    meta: {
      title: "EOD - Podgląd jednostki",
      requiresAuth: true,
      permission: PERMISSIONS.UNITS,
      app: APPS.UNITS,
    },
  },
];

export default units;
