<template>
  <Draggable
    :list="inputList"
    :clone="
      (original) => {
        return {
          ...JSON.parse(JSON.stringify(original)),
          id: cGlobalID++,
          show: false,
        };
      }
    "
    :sort="false"
    :group="{ name: 'tiles', pull: 'clone', put: false }"
  >
    <div class="tile tile__option" v-for="elem in inputList" :key="elem.name">
      <div class="tile__icon"><component :is="elem.icon" /></div>
      <div class="tile__title">{{ elem.name }}</div>
    </div>
  </Draggable>
</template>

<script>
import { VueDraggableNext } from "vue-draggable-next";
import formIcons from "./formIcons";

export default {
  name: "InputSet",
  props: { inputList: Array, globalID: Number },
  components: {
    Draggable: VueDraggableNext,
    ...formIcons,
  },
  computed: {
    cGlobalID: {
      get() {
        return this.globalID;
      },
      set(val) {
        this.$emit("update:globalID", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/Forms";
</style>
