
import { EODRadio, EODSelect } from "@/components/inputs";
import { addLDAPUser, getOUs, searchLDAPUsers } from "@/services/ldap";
import { defineComponent, inject, ref, watch } from "vue";

import APPS from "@/consts/apps";
import { EODButton } from "@/components/ui";
import { LDAPUser } from "@/interfaces/ldap";
import { MessageApi } from "ant-design-vue/lib/message";
import { Modal } from "ant-design-vue";
import { Params } from "@/interfaces/common";
import { getGroupsSimpleList } from "@/services/permissions";
import { getUnitsFlatSelect } from "@/services/units";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";

export default defineComponent({
  name: "AddLDAPUser",
  setup() {
    const $message = inject("$message") as MessageApi;
    const { t } = useI18n();
    const store = useStore();

    const visible = ref(false);
    const errors = ref({});
    const refetchUsers = ref(false);

    const ouOptions = ref([]);
    const ou = ref(null);
    const data = ref({
      username: null,
      roles: [],
      groups: [],
      units: [],
    });

    const searchUsers = (params: Params) => {
      return searchLDAPUsers({ ou: ou.value }, params).then(({ data }) => {
        return {
          pages_number: data.pages_number,
          results: data.results.map((item: LDAPUser) => ({
            id: item.username,
            name: `${item.first_name} ${item.last_name} (${item.email})`,
          })),
        };
      });
    };

    const submit = () => {
      if (!data.value.username) {
        $message.error(t("forms.gui.fillRequiredField_", [t("app.user", 1)]));
        return;
      }

      if (APPS.UNITS && data.value.units.length === 0) {
        $message.error(
          t("forms.gui.fillRequiredField_", [t("permissions.users.unit", 0)]),
        );
        return;
      }

      addLDAPUser(data.value)
        .then(({ data }) => {
          $message.success(data);
          visible.value = false;
          store.dispatch("refreshTable");
        })
        .catch((err) => {
          errors.value = err.response.data;
        });
    };

    const fetchRoles = (params: Params) => {
      return getGroupsSimpleList({ roles: true, ...params }).then(
        ({ data }) => data,
      );
    };

    const fetchUnits = (params: Params) => {
      return getUnitsFlatSelect(params).then(({ data }) => data);
    };

    const fetchGroups = (params: Params) => {
      return getGroupsSimpleList(params).then(({ data }) => data);
    };

    const onOUChange = () => {
      data.value.username = null;
      refetchUsers.value = true;
    };

    watch(visible, (val) => {
      if (val && ouOptions.value.length === 0) {
        getOUs().then(({ data }) => {
          ouOptions.value = Object.entries<Record<string, string[]>>(data).map(
            ([key, value]) => ({
              name: key,
              id: value,
            }),
          );
          ou.value = ouOptions.value?.[0]?.id;
          refetchUsers.value = true;
        });
      }
    });

    return {
      APPS,
      data,
      errors,
      fetchGroups,
      fetchRoles,
      fetchUnits,
      onOUChange,
      ou,
      ouOptions,
      refetchUsers,
      searchUsers,
      submit,
      visible,
    };
  },
  components: { EODButton, EODRadio, EODSelect, Modal },
});
