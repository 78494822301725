const table = {
  state: () => ({
    shouldRefresh: false,
  }),
  mutations: {
    REFRESH_TABLE(state) {
      state.shouldRefresh = true;
    },
    RESET_REFRESH_TABLE(state) {
      state.shouldRefresh = false;
    },
  },
  actions: {
    refreshTable({ commit }) {
      commit("REFRESH_TABLE");
    },
    resetRefreshTable({ commit }) {
      commit("RESET_REFRESH_TABLE");
    },
  },
};

export default table;
