import axiosSession from "@/config/apiClient";

const getNumerationList = (params) => {
  return axiosSession.get("/api/counters/", { params });
};
const getNumerationSelect = (params) => {
  return axiosSession.get("/api/counters/select/", { params });
};
const getNumeration = (id) => {
  return axiosSession.get(`/api/counters/${id}/`);
};

const getNumerationStatuses = () => {
  return axiosSession.get("/api/counters/status/");
};

const createNumeration = (data) => {
  return axiosSession.post("/api/counters/", data);
};

const editNumeration = (data, id) => {
  return axiosSession.put(`/api/counters/${id}/`, data);
};

const deleteNumeration = (id) => {
  return axiosSession.delete(`/api/counters/${id}/`);
};

const getBarcodeTypes = () => {
  return axiosSession.get("/api/counters/barcode/types/");
};

const getBarcodePaperFormat = () => {
  return axiosSession.get("/api/counters/barcode/paper-format/");
};

const getBarcodes = (id, params) => {
  return axiosSession.get(`/api/counters/${id}/barcode/`, {
    responseType: "blob",
    params,
  });
};

const getNumerationUnits = (id, params) => {
  return axiosSession.get(`/api/counters/${id}/units/`, { params });
};

const getBarcodeGenerate = (params) => {
  return axiosSession.get(`/api/counters/barcode/generate/`, {
    responseType: "blob",
    params,
  });
};

export {
  getBarcodeTypes,
  getBarcodes,
  getNumerationList,
  getNumerationSelect,
  getNumeration,
  getNumerationStatuses,
  getNumerationUnits,
  createNumeration,
  editNumeration,
  deleteNumeration,
  getBarcodePaperFormat,
  getBarcodeGenerate,
};
