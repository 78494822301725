<template>
  <Section :title="$t('contractors.sortAccounts')">
    <Draggable class="tiles-container" :list="accounts">
      <div
        class="account-tile"
        v-for="(account, idx) in accounts"
        :key="account.id"
      >
        <strong class="ml-1">{{ idx + 1 }}</strong>
        <span class="ml-1 mr-1">
          {{ account.identifier }} {{ account.identifier ? " : " : "" }}
          {{ account.account_number }}
        </span>
        <span />
      </div>
    </Draggable>
    <EODButton
      type="primary"
      :name="$t('contractors.saveOrder')"
      @click="submit"
      :loading="loading$"
    />
  </Section>
</template>

<script>
import { EODButton, Section } from "@/components/ui";
import { getBankAccountsList, sortAccounts } from "@/services/contractors";

import { VueDraggableNext } from "vue-draggable-next";

export default {
  name: "SortAccounts",
  props: { contractorID: { type: Number, required: true } },
  data() {
    return {
      accounts: [],
    };
  },
  components: { Draggable: VueDraggableNext, EODButton, Section },
  computed: {
    shouldRefresh() {
      return this.$store.state.contractors.refreshSort;
    },
  },
  methods: {
    fetchAccounts() {
      getBankAccountsList({ contractor: this.contractorID, limit: 1000 }).then(
        ({ data }) => {
          this.$store.dispatch("setAccountsCount", data.count);
          this.accounts = data.results;
        },
      );
    },
    submit() {
      this.loading$ = true;

      sortAccounts({ bank_accounts: this.accounts.map((item) => item.id) })
        .then(() => {
          this.$message.success(this.$t("app.success"));
          this.$store.dispatch("bankAccounts/refreshTable");
        })
        .finally(() => {
          this.loading$ = false;
        });
    },
  },
  mounted() {
    this.fetchAccounts();
  },
  watch: {
    shouldRefresh(val) {
      if (val) {
        this.fetchAccounts();
        this.$store.dispatch("resetRefreshSort");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/Variables";

.tiles-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 1rem;
}

.account-tile {
  max-width: 100%;
  width: 500px;
  border: 1px solid $menu-dark-bg;
  border-radius: 4px;
  padding: 0.3rem;
  margin: 0.4rem;
  display: flex;
  justify-content: space-between;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}
</style>
