
import { Attachment, PreviewDataNode } from "@/interfaces/common";
import { Breadcrumbs, EODButton, EODDrawer, Section } from "@/components/ui";
import { DownloadOutlined, EyeOutlined } from "@ant-design/icons-vue";
import { defineComponent, ref } from "vue";
import {
  getDeletedMailboxMessage,
  getMailboxMessage,
} from "@/services/mailboxes";

import { FileViewer } from "@/components/common";
import { Preview } from "@/components/common";
import { Table } from "@/components/table";
import useDrawerHandler from "@/composables/useDrawerHandler";
import { useI18n } from "vue-i18n";
import useMessageProcess from "@/composables/useMessageProcess";

export default defineComponent({
  name: "MessagePreview",
  props: {
    id: { type: Number, required: true },
    mailboxId: { type: String, required: true },
    deleted: { type: Boolean, default: false },
  },
  setup(props) {
    const { t } = useI18n();

    const previewData = ref<PreviewDataNode[]>([]);

    const downloadFileURL = (id: number) => {
      const accessToken = localStorage.getItem("access_token");
      return `/api/mailboxes/${props.mailboxId}/downloads/${id}/?token=${accessToken}`;
    };
    const collapseAttachments = ref(true);
    const attachmentsData = ref<Attachment[]>([]);
    const attachmentColumns = ref([
      {
        title: t("app.name"),
        dataIndex: "filename",
        width: "35%",
        sorter: (a: Attachment, b: Attachment) =>
          a.filename.localeCompare(b.filename),
      },
      {
        title: t("app.description"),
        dataIndex: "description",
        slots: { customRender: "description" },
        width: "35%",
      },
      {
        title: t("app.created"),
        dataIndex: "created_date",
        width: "15%",
      },
      {
        title: t("app.actions"),
        dataIndex: "actions",
        slots: { customRender: "actions" },
        width: "15%",
      },
    ]);

    if (props.id) {
      const service = props.deleted
        ? getDeletedMailboxMessage
        : getMailboxMessage;

      service(props.mailboxId, props.id).then(({ data: { message_data } }) => {
        attachmentsData.value = message_data.attachments_files;
        previewData.value = [
          {
            title: t("mailboxes.messages.subject"),
            value: message_data.headers.subject,
          },
          {
            title: t("mailboxes.messages.from"),
            value: message_data.headers.from,
          },
          {
            title: t("mailboxes.messages.to"),
            value: message_data.headers.to,
          },
          {
            title: t("app.date"),
            value: new Date(message_data.headers.date).toLocaleString(),
          },
          {
            title: t("app.content"),
            value: message_data.msg,
          },
        ];
      });
    }

    return {
      attachmentColumns,
      attachmentsData,
      collapseAttachments,
      downloadFileURL,
      getMailboxMessage,
      previewData,
      ...useDrawerHandler(),
      ...useMessageProcess(),
    };
  },
  components: {
    Breadcrumbs,
    DownloadOutlined,
    EODButton,
    EODDrawer,
    EyeOutlined,
    FileViewer,
    Preview,
    Section,
    Table,
  },
});
