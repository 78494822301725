<template>
  <BaseTable
    :columns="columns"
    :service="getJRWAEntriesList"
    :customParams="{ jrwa_register: this.jrwa_register }"
    v-model:expandedRowKeys="expandedRowKeys"
  >
    <template v-slot:row="{ record }">
      {{ record.key }}
    </template>
    <template v-slot:actions="{ record }">
      <TableActions
        :record="record"
        :showEdit="showEdit"
        :showRemove="showRemove"
        :previewRoute="{
          name: 'JRWAKeyPreview',
          params: { id: record.id, versionID: this.jrwa_register },
        }"
        :editRoute="{
          name: 'JRWAKeyEdit',
          params: { id: record.id, versionID: this.jrwa_register },
        }"
        :deleteMessage="`${$t('app.doYouWantToDelete')} ${$tc(
          'jrwa.symbol',
          1,
        )} &quot;${record.name}&quot;?`"
        :modalTitle="$t('app.delete')"
        :deleteService="deleteJRWAEntry"
      />
    </template>
  </BaseTable>
</template>

<script>
import { BaseTable, TableActions } from "@/components/table";
import { deleteJRWAEntry, getJRWAEntriesList } from "@/services/jrwa";

export default {
  name: "JRWA",
  props: {
    params: Object,
    jrwa_register: null,
    showEdit: { type: Boolean, default: true },
    showRemove: { type: Boolean, default: true },
  },
  data() {
    return {
      expandedRowKeys: [],
      columns: [
        {
          title: this.$tc("jrwa.symbol", 2),
          dataIndex: "row",
          slots: { customRender: "row" },
          width: "25%",
        },
        { title: this.$tc("jrwa.entry", 2), dataIndex: "name", sorter: true },
        {
          title: this.$t("jrwa.category"),
          dataIndex: "archive_category_display",
        },
        {
          title: this.$t("app.actions"),
          dataIndex: "actions",
          slots: { customRender: "actions" },
          fixed: "right",
          width: "120",
        },
      ],
    };
  },
  components: {
    BaseTable,
    TableActions,
  },
  methods: {
    getJRWAEntriesList,
    deleteJRWAEntry,
  },
};
</script>

<style lang="scss">
@import "@/scss/Variables.scss";

.ant-table-row-level-1 {
  background-color: $table-row-level-1;
}
.ant-table-row-level-2 {
  background-color: $table-row-level-2;
}
.ant-table-row-level-3 {
  background-color: $table-row-level-3;
}
</style>
