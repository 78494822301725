import axiosSession from "@/config/apiClient";

const getRepositoryUnits = (params) => {
  return axiosSession.get("/api/repositories/units/", { params });
};

const getFlatUnits = (params) => {
  return axiosSession.get("/api/repositories/units/", { params });
};

const getYearsList = (params) => {
  return axiosSession.get("/api/repositories/years/", { params });
};

const getJRWAList = (params) => {
  return axiosSession.get("/api/repositories/jrwa/", { params });
};

const getResourcesIssues = (params) => {
  return axiosSession.get("/api/repositories/issues/", { params });
};
const getResourcesDocuments = (params) => {
  return axiosSession.get("/api/repositories/documents/", { params });
};
const createFolder = (data) => {
  return axiosSession.post("/api/repositories/folders/", data);
};

const deleteFolder = (id) => {
  return axiosSession.delete(`/api/repositories/folders/${id}/`);
};

const editFolder = (data, id) => {
  return axiosSession.put(`/api/repositories/folders/${id}/`, data);
};

const getFolder = (id) => {
  return axiosSession.get(`/api/repositories/folders/${id}/`);
};

const getFolders = (params) => {
  return axiosSession.get("/api/repositories/folders/", { params });
};

export {
  getFlatUnits,
  getRepositoryUnits,
  getYearsList,
  getJRWAList,
  getResourcesIssues,
  getResourcesDocuments,
  createFolder,
  deleteFolder,
  editFolder,
  getFolder,
  getFolders,
};
