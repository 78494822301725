<template>
  <Modal
    v-model:visible="visible"
    @ok="submit"
    :okText="$t('app.save')"
    :title="$t('contractors.fetchVATInfo')"
    :okButtonProps="{ loading: loading$ }"
  >
    <p>{{ $t("contractors.VATModalInfo") }}</p>

    <div class="mb-1">
      <strong>{{ `${$t("contractors.nip")}: ${nip}` }}</strong>
    </div>

    <strong>{{ $t("contractors.VATStatus") }}</strong>

    <div>
      <CheckCircleOutlined
        v-if="contractor.tax_status"
        class="color--success"
      />
      <CloseCircleOutlined v-else class="color--grey" />
      &nbsp;
      {{ statusInfo }}
    </div>

    <strong class="pt-1">{{ $t("contractors.bankAccountsList") }}</strong>

    <div class="mb-050">
      <InputSearch
        v-model:value="search"
        :placeholder="`${$t('app.search')}...`"
        allowClear
        @search="searchAccounts"
        @change="searchAccounts"
      />
    </div>

    <div class="accounts-list">
      <Checkbox v-model:checked="checkedAll" @change="checkAll">
        {{ checkedAll ? $t("app.uncheckAll") : $t("app.checkAll") }}
      </Checkbox>
      <div v-for="(account, idx) in accounts" :key="idx">
        <Checkbox
          v-model:checked="account.checked"
          :disabled="account.saved"
          @change="() => onChange(account.id, idx)"
        >
          {{ account.account }}
        </Checkbox>
      </div>
    </div>

    <div class="counter">
      {{ $t("app.checked") }}: {{ numberOfChecked }} /
      {{ allAccounts.length }}
    </div>
  </Modal>
</template>

<script>
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons-vue";
import { Checkbox, Input, Modal } from "ant-design-vue";
import { addVATAccounts, getVATAccounts } from "@/services/contractors";

export default {
  name: "AccountsModal",
  props: {
    activate: Boolean,
    id: Number,
    modelData: Object,
    fetchingVAT: Boolean,
  },
  data() {
    return {
      accounts: [],
      allAccounts: [],
      search: "",
      checkedAll: false,
      visible: false,
      nip: "",
    };
  },
  components: {
    CheckCircleOutlined,
    CloseCircleOutlined,
    Checkbox,
    InputSearch: Input.Search,
    Modal,
  },
  computed: {
    numberOfChecked() {
      return this.allAccounts.filter((account) => account.checked).length;
    },
    contractor: {
      get() {
        return this.modelData;
      },
      set(val) {
        this.$emit("update:modelData", val);
      },
    },
    statusInfo() {
      return this.contractor.tax_status
        ? this.$t("contractors.isVATPayer")
        : this.$t("contractors.isNotVATPayer");
    },
    fetching: {
      get() {
        return this.fetchingVAT;
      },
      set(val) {
        return this.$emit("update:fetchingVAT", val);
      },
    },
  },
  emits: ["update:activate", "update:modelData", "update:fetchingVAT"],
  methods: {
    submit() {
      this.loading$ = true;
      const accounts = this.allAccounts
        .filter((account) => account.checked && !account.saved)
        .map((account) => account.account);

      if (accounts.length === 0) {
        this.visible = false;
        return;
      }

      addVATAccounts({ accounts }, this.id)
        .then(() => {
          this.$message.success(this.$t("app.success"));
          this.$store.dispatch("bankAccounts/refreshTable");
          this.$store.dispatch("refreshSort");
          this.visible = false;
        })
        .finally(() => {
          this.loading$ = false;
        });
    },
    fetchVATInfo() {
      this.fetching = true;

      getVATAccounts(this.id)
        .then(({ data }) => {
          this.allAccounts = data.accounts_number.map((item, idx) => ({
            ...item,
            id: idx,
            checked: item.saved,
          }));
          this.accounts = this.allAccounts;
          this.contractor.tax_status = data.tax_status;
          this.contractor.tax_status_update = new Date();
          this.nip = data.nip;
          this.visible = true;
        })
        .finally(() => {
          this.fetching = false;
        });
    },
    onChange(id, idx) {
      this.allAccounts[id].checked = !this.allAccounts[id].checked;
      this.accounts[idx].checked = !this.accounts[idx].checked;
    },
    searchAccounts() {
      this.accounts = this.allAccounts.filter((account) =>
        account.account.includes(this.search),
      );
    },
    checkAll() {
      this.allAccounts = this.allAccounts.map((account) =>
        account.saved
          ? account
          : {
              ...account,
              checked: this.checkedAll,
            },
      );

      this.accounts = this.allAccounts;
    },
  },
  watch: {
    activate(val) {
      if (val) {
        this.fetchVATInfo();
        this.$emit("update:activate", false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.accounts-list {
  max-height: 250px;
  overflow-y: auto;
}

.counter {
  text-align: right;
  margin-top: 0.5rem;
}

.spin {
  display: flex;
  justify-content: center;
}
</style>
