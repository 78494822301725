import axios, { AxiosError } from "axios";

import errorHandler from "@/helpers/errorHandler";
import router from "@/router";
import { store } from "@/store";

const refreshInstance = axios.create();
let isRefreshing = false;
let failedReqQueue: { resolve: Function; reject: Function }[] = [];

const processReqQueue = (error: AxiosError, token: string = null) => {
  failedReqQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedReqQueue = [];
};

// Request interceptor for API calls
axios.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem("access_token");

    config.headers["Authorization"] = "Bearer " + accessToken;
    config.headers["accept-language"] =
      localStorage.getItem("language") || "pl";

    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (response) => {
    if (
      (response.status >= 200 && response.status < 300) ||
      response.status === 304
    ) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    const originalRequest = error.config;
    originalRequest.globalErrorHandler =
      originalRequest.globalErrorHandler !== false;
    const refreshToken = localStorage.getItem("refresh_token");

    if (
      error.response.status === 401 &&
      !originalRequest._retry &&
      refreshToken
    ) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedReqQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return axios.request(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise((resolve, reject) => {
        refreshInstance
          .post("/api/token/refresh/", { refresh: refreshToken })
          .then(({ data }) => {
            localStorage.setItem("access_token", data.access);
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + data.access;
            originalRequest.headers["Authorization"] = "Bearer " + data.access;

            axios.defaults.xsrfCookieName = "csrftoken";
            axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

            store.dispatch("user/add", { root: true });

            processReqQueue(null, data.token);
            resolve(axios(originalRequest));
          })
          .catch((err) => {
            localStorage.removeItem("access_token");
            localStorage.removeItem("refresh_token");
            store.dispatch("user/clear", { root: true });

            router.push({
              name: "Login",
            });
            processReqQueue(err, null);
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    } else if (originalRequest.globalErrorHandler) {
      // TODO: add sentry in production build
      errorHandler(error.response, originalRequest.customErrorHandler);
    }

    return Promise.reject(error);
  },
);

export default axios;
