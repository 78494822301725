<template>
  <Breadcrumbs
    :path="path"
    :current="$t('app.preview_', replacement)"
    iconComponent="UserSwitchOutlined"
  >
    <EODButtonLink
      v-if="actions.editable"
      :to="{
        name: all ? 'ReplacementsAllEdit' : 'ReplacementsEdit',
        params: { id: id },
      }"
      :title="$t('app.edit_', replacement)"
      icon="EditOutlined"
    />
  </Breadcrumbs>

  <Preview
    :oid="id"
    :data="previewData"
    :title="$t('app.preview_', replacement)"
    :noPreviewMsg="$t('app.noPreview_', replacement)"
  />
</template>

<script>
import { Breadcrumbs, EODButtonLink } from "@/components/ui";

import { Preview } from "@/components/common";
import { getReplacement } from "@/services/replacements";

export default {
  name: "ReplacementsPreview",
  props: { all: { type: Boolean, default: false } },
  data() {
    return {
      id: +this.$route.params.id,
      previewData: [],
      replacement: this.$tc("replacements.replacement", 2),
      actions: {},
    };
  },
  components: {
    Breadcrumbs,
    EODButtonLink,
    Preview,
  },
  computed: {
    path() {
      return this.all
        ? [
            {
              path: { name: "ReplacementsAll" },
              title: this.$t("modules.replacementsAll"),
            },
          ]
        : [
            {
              path: { name: "Replacements" },
              title: this.$t("modules.replacements"),
            },
          ];
    },
  },
  created() {
    if (this.id) {
      getReplacement(this.id).then(({ data }) => {
        this.previewData = [
          {
            title: this.$t("replacements.from"),
            value: new Date(data.date_from).toLocaleString(),
          },
          {
            title: this.$t("replacements.to"),
            value: new Date(data.date_to).toLocaleString(),
          },
          {
            title: this.$t("replacements.replacedUser"),
            value: data.replaced_user_display,
          },
          {
            title: this.$t("replacements.replacingUsers"),
            value: data.replacing_users.map((item) => item.full_name),
          },
          {
            title: this.$t("replacements.type"),
            value: data.absence_type_display,
          },
          {
            title: this.$t("replacements.reason"),
            value: data.reason,
          },
        ];
        this.actions = data.actions;
      });
    }
  },
};
</script>
