<template>
  <Breadcrumbs
    :path="[
      { path: { name: 'Contractors' }, title: $t('modules.contractors') },
    ]"
    :current="title"
    iconComponent="TeamOutlined"
  >
    <span v-if="verified" class="color--success span--no-wrap">
      <CheckCircleOutlined />
      &nbsp;
      {{ $t("contractors.verified") }}
    </span>
    <span v-else class="color--grey span--no-wrap">
      <CloseCircleOutlined />
      &nbsp;
      {{ $t("contractors.unverified") }}
    </span>
    <EODButtonLink
      v-if="hasPermissionToEdit"
      :to="{ name: 'ContractorsEdit', params: { id } }"
      :title="$t('contractors.edit')"
      icon="EditOutlined"
    />
  </Breadcrumbs>

  <Tabs @change="$scrollToTop">
    <TabPane key="1" :tab="$t('contractors.basicInfo')">
      <div class="drawer-container">
        <div class="drawer-content">
          <Preview
            :oid="id"
            :data="contractorPreviewData"
            :title="$t('contractors.basicInfo')"
            :noPreviewMsg="$t('contractors.noPreview')"
            :collapsable="true"
            v-model:collapsed="collapse.contractor"
          />

          <Preview
            v-if="addressPreviewData.length > 0"
            :oid="id"
            :data="addressPreviewData"
            :title="$t('contractors.address')"
            :noPreviewMsg="$t('contractors.noPreview')"
            :collapsable="true"
            v-model:collapsed="collapse.address"
            :twoColumn="true"
          />

          <Preview
            v-if="supplyPreviewData.length > 0"
            :oid="id"
            :data="supplyPreviewData"
            :title="$t('contractors.supply')"
            :noPreviewMsg="$t('contractors.noPreview')"
            :collapsable="true"
            v-model:collapsed="collapse.supply"
            :twoColumn="true"
          />

          <Section
            v-if="attachmentsData.length > 0"
            :title="$t('app.attachments')"
            :collapsable="true"
            v-model:collapsed="collapse.attachments"
          >
            <Table :columns="columns" :data-source="attachmentsData">
              <template v-slot:description="{ record }">
                {{ record.description || " - " }}
              </template>
              <template v-slot:actions="{ record }">
                <div class="actions">
                  <button
                    v-if="/(pdf)$/.test(record.filename)"
                    class="action"
                    @click="handlePreview(downloadContractorFileURL(record.id))"
                    :title="$t('app.preview')"
                  >
                    <EyeOutlined />
                  </button>
                  <a
                    class="action"
                    :href="downloadContractorFileURL(record.id)"
                    :title="$t('app.downloadFile')"
                  >
                    <DownloadOutlined />
                  </a>
                </div>
              </template>
            </Table>
          </Section>

          <Preview
            v-if="featuresPreviewData.length > 0"
            :oid="id"
            :data="featuresPreviewData"
            :title="$t('contractors.features')"
            :noPreviewMsg="$t('contractors.noPreview')"
            :collapsable="true"
            v-model:collapsed="collapse.features"
          />
        </div>
        <EODDrawer :visible="showPreview" :handle="handleDrawer">
          <FileViewer v-if="renderPdf" :previewFile="previewFile" />
        </EODDrawer>
      </div>
    </TabPane>
    <TabPane
      v-if="hasPermissionToContactData"
      key="2"
      :tab="$t('contractors.contactData')"
    >
      <ContactPreview :contractorID="id" />
      <ContactsList :contractorID="id" :preview="true" />
    </TabPane>
    <TabPane
      v-if="hasPermissionToBankAccounts"
      key="3"
      :tab="$t('contractors.bankAccounts')"
    >
      <BankAccountsPreview :contractorID="id" />
      <BankAccountsList :contractorID="id" :preview="true" />
    </TabPane>
  </Tabs>
</template>

<script>
import {
  Breadcrumbs,
  EODButtonLink,
  EODDrawer,
  Section,
} from "@/components/ui";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DownloadOutlined,
  EyeOutlined,
} from "@ant-design/icons-vue";
import {
  downloadContractorFileURL,
  getContractor,
} from "@/services/contractors";

import BankAccountsList from "./BankAccountsList";
import BankAccountsPreview from "./BankAccountsPreview";
import ContactPreview from "./ContactPreview";
import ContactsList from "./ContactsList";
import { FileViewer } from "@/components/common";
import PERMISSIONS from "@/consts/permissions";
import { Preview } from "@/components/common";
import { Table } from "@/components/table";
import { Tabs } from "ant-design-vue";
import useDrawerHandler from "@/composables/useDrawerHandler";

export default {
  name: "ContractorsPreview",
  props: { id: { type: Number, required: true } },
  setup() {
    return { ...useDrawerHandler() };
  },
  data() {
    return {
      title: "",
      verified: false,
      contractorPreviewData: [],
      addressPreviewData: [],
      supplyPreviewData: [],
      featuresPreviewData: [],
      attachmentsData: [],
      collapse: {
        contractor: false,
        address: false,
        supply: true,
        features: true,
        attachments: true,
      },
      columns: [
        {
          title: this.$t("app.name"),
          dataIndex: "filename",
          width: "35%",
          sorter: (a, b) => a.filename.localeCompare(b.filename),
        },
        {
          title: this.$t("app.description"),
          dataIndex: "description",
          slots: { customRender: "description" },
        },
        {
          title: this.$t("app.created"),
          dataIndex: "created_date",
        },
        {
          title: this.$t("app.actions"),
          dataIndex: "actions",
          slots: { customRender: "actions" },
          width: "15%",
        },
      ],
    };
  },
  components: {
    BankAccountsList,
    BankAccountsPreview,
    Breadcrumbs,
    CheckCircleOutlined,
    CloseCircleOutlined,
    ContactPreview,
    ContactsList,
    DownloadOutlined,
    EODButtonLink,
    EODDrawer,
    EyeOutlined,
    FileViewer,
    Preview,
    Section,
    Table,
    Tabs,
    TabPane: Tabs.TabPane,
  },
  beforeRouteEnter(to, _, next) {
    getContractor(to.params.id)
      .then(({ data }) => {
        next((vm) => {
          vm.$store.dispatch("setContactID", undefined);
          vm.$store.dispatch("setBankAccountID", undefined);

          vm.title = data.name;
          vm.verified = data.verified;

          vm.attachmentsData = data.attachments;

          vm.contractorPreviewData = [
            {
              title: vm.$t("contractors.identifier"),
              value: data.identifier,
            },
            { title: vm.$t("app.name"), value: data.name },
            { title: vm.$t("contractors.shortcut"), value: data.short_name },
            { title: vm.$t("app.status"), value: data.status_display },
            {
              title: vm.$t("contractors.class"),
              value: data.contractor_class_display,
            },
            {
              title: vm.$t("contractors.taxCountry"),
              value: data.country_tax_display,
            },
            { title: vm.$t("contractors.nip"), value: data.nip },
            {
              title: vm.$t("contractors.nipCountry"),
              value: data.country_nip_display,
            },
          ];

          vm.addressPreviewData =
            data.address_detail.length > 0
              ? [
                  [
                    {
                      title: vm.$t("contractors.country"),
                      value: data.address_detail[0].address_country_display,
                    },
                    {
                      title: vm.$t("contractors.district"),
                      value: data.address_detail[0].address_district,
                    },
                    {
                      title: vm.$t("contractors.city"),
                      value: data.address_detail[0].address_city,
                    },
                    {
                      title: vm.$t("contractors.streetName"),
                      value: data.address_detail[0].address_street_name,
                    },
                    {
                      title: vm.$t("contractors.streetSuite"),
                      value: data.address_detail[0].address_street_suite,
                    },
                  ],
                  [
                    {
                      title: vm.$t("contractors.voivodeship"),
                      value: data.address_detail[0].address_state_display,
                    },
                    {
                      title: vm.$t("contractors.municipality"),
                      value: data.address_detail[0].address_municipality,
                    },
                    {
                      title: vm.$t("contractors.postCode"),
                      value: data.address_detail[0].address_post_code,
                    },
                    {
                      title: vm.$t("contractors.streetNumber"),
                      value: data.address_detail[0].address_street_number,
                    },
                    {
                      title: vm.$t("contractors.postOffice"),
                      value: data.address_detail[0].post_office,
                    },
                  ],
                ]
              : [];

          vm.supplyPreviewData =
            data.supply_params.length > 0
              ? [
                  [
                    {
                      title: vm.$t("contractors.paymentConditions"),
                      value: data.supply_params[0].payment_conditions_display,
                    },
                    {
                      title: vm.$t("contractors.tradeCreditType"),
                      value: data.supply_params[0].trade_credit_type_display,
                    },
                    {
                      title: vm.$t("contractors.maximumInvoiceAmount"),
                      value: data.supply_params[0].maximum_invoice_amount,
                    },
                  ],
                  [
                    {
                      title: vm.$t("contractors.currency"),
                      value: data.supply_params[0].currency_display,
                    },
                    {
                      title: vm.$t("contractors.creditAmount"),
                      value: data.supply_params[0].credit_amount,
                    },
                    {
                      title: vm.$t("contractors.discount"),
                      value: data.supply_params[0].discount,
                    },
                  ],
                ]
              : [];

          vm.featuresPreviewData =
            data.additional_features.length > 0
              ? [
                  {
                    title: vm.$t("contractors.auto"),
                    value: data.additional_features[0].is_auto
                      ? vm.$t("app.yes")
                      : vm.$t("app.no"),
                  },
                  {
                    title: vm.$t("contractors.paymentConditions"),
                    value:
                      data.additional_features[0].payment_conditions_display,
                  },
                  {
                    title: vm.$t("contractors.url"),
                    value: data.additional_features[0].url,
                  },
                  {
                    title: vm.$tc("contractors.attribute", 2),
                    value:
                      data.additional_features[0].attributes.length > 0
                        ? data.additional_features[0].attributes.map(
                            (item) => item.value,
                          )
                        : "-",
                  },
                ]
              : [];
        });
      })
      .catch((err) => {
        if (err.response.status === 404) {
          next({ name: "404" });
        }
      });
  },
  computed: {
    hasPermissionToEdit() {
      return this.$permissions.includes(PERMISSIONS.CONTRACTORS_EDIT);
    },
    hasPermissionToContactData() {
      return this.$permissions.includes(PERMISSIONS.CONTRACTORS_CONTACT_DATA);
    },
    hasPermissionToBankAccounts() {
      return this.$permissions.includes(PERMISSIONS.CONTRACTORS_BANK_ACCOUNTS);
    },
  },
  methods: {
    downloadContractorFileURL,
  },
};
</script>
