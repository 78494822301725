<template>
  <Breadcrumbs :current="$t('modules.teams')">
    <EODButtonLink
      v-if="hasPermissionToAdd"
      :to="{
        name: 'TeamsCreate',
      }"
      :title="$t('permissions.teams.add')"
    />
  </Breadcrumbs>

  <section class="section">
    <BaseTable :columns="columns" :service="getGroupsList">
      <template v-slot:actions="{ record }">
        <TableActions
          :record="record"
          :previewRoute="{
            name: 'TeamsPreview',
            params: { id: record.id },
          }"
          :editRoute="{
            name: 'TeamsEdit',
            params: { id: record.id },
          }"
          :deleteService="deleteGroup"
          :modalTitle="$t('app.delete_', [$tc('permissions.teams.team', 1)])"
          :deleteMessage="
            $t('app.doYouWantToDelete_', [
              `${$tc('permissions.teams.team', 1)} ${record.name}`,
            ])
          "
        />
      </template>
    </BaseTable>
  </section>
</template>

<script>
import { BaseTable, TableActions } from "@/components/table";
import { Breadcrumbs, EODButtonLink } from "@/components/ui";
import { deleteGroup, getGroupsList } from "@/services/permissions";
import PERMISSIONS from "@/consts/permissions";

export default {
  name: "Teams",
  data() {
    return {
      columns: [
        { title: this.$t("app.name"), dataIndex: "name", sorter: true },
        {
          title: this.$t("permissions.roles.numberOfUsers"),
          dataIndex: "user_count",
          sorter: true,
        },
        {
          title: this.$t("app.actions"),
          dataIndex: "actions",
          slots: { customRender: "actions" },
          fixed: "right",
          width: "120",
        },
      ],
    };
  },
  components: {
    BaseTable,
    Breadcrumbs,
    EODButtonLink,
    TableActions,
  },
  computed: {
    hasPermissionToAdd() {
      return this.$permissions.includes(PERMISSIONS.GROUPS_ADD);
    },
  },
  methods: {
    deleteGroup,
    getGroupsList,
  },
};
</script>
