<template>
  <section class="section">
    <div class="row">
      <div>
        <h3 v-if="title" class="mb-0 mr-1">
          <span class="mr-2">{{ title }}</span>
          <slot name="checkbox"></slot>
        </h3>
        <slot name="breadcrumbs"></slot>
        <span v-if="info" class="color--font-secondary">{{ info }}</span>
      </div>
      <div>
        <slot name="button"></slot>
        <button
          v-if="collapsable"
          @click="isCollapsed = !isCollapsed"
          class="icon-button"
          :title="
            isCollapsed ? $t('forms.gui.uncollapse') : $t('forms.gui.collapse')
          "
        >
          <DownOutlined v-if="isCollapsed" /><UpOutlined v-else />
        </button>
      </div>
    </div>

    <div class="section-container" v-show="!collapsed">
      <slot></slot>
    </div>
  </section>
</template>

<script>
import { DownOutlined, UpOutlined } from "@ant-design/icons-vue";

export default {
  name: "Section",
  props: {
    title: String,
    info: String,
    collapsable: { type: Boolean, default: false },
    collapsed: Boolean,
  },
  components: { DownOutlined, UpOutlined },
  computed: {
    isCollapsed: {
      get() {
        return this.collapsed;
      },
      set(val) {
        this.$emit("update:collapsed", val);
      },
    },
  },
  emits: ["update:collapsed"],
};
</script>

<style lang="scss" scoped>
@import "@/scss/Variables";

.section {
  padding: 0;
  margin: 1rem;
  background-color: $white;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid $background-color;

  & > :first-child {
    display: flex;
    align-items: center;
  }
}

.section-container {
  padding: 1rem 0;
}
</style>
