import APPS from "@/consts/apps";
import PERMISSIONS from "@/consts/permissions";
import Roles from "@/views/Permissions/Roles.vue";
import RolesForm from "@/views/Permissions/RolesForm.vue";
import i18n from "@/i18n";

const t = i18n.global.t;
const tc = i18n.global.tc;

const roles = [
  {
    path: "/roles/",
    name: "Roles",
    component: Roles,
    meta: {
      title: `${t("meta.app")} - ${tc("permissions.roles.roles", 0)}`,
      requiresAuth: true,
      permission: PERMISSIONS.ROLES,
      app: APPS.GROUPS,
    },
  },
  {
    path: "/roles/create/",
    name: "RolesCreate",
    component: RolesForm,
    meta: {
      title: `${t("meta.app")} - ${t("permissions.roles.add")}`,
      requiresAuth: true,
      permission: PERMISSIONS.ROLES_ADD,
      app: APPS.GROUPS,
    },
  },
  {
    path: "/roles/create/:sourceId/",
    name: "RolesCreateFrom",
    component: RolesForm,
    meta: {
      title: `${t("meta.app")} - ${t("permissions.roles.add")}`,
      requiresAuth: true,
      permission: PERMISSIONS.ROLES_ADD,
      app: APPS.GROUPS,
    },
  },
  {
    path: "/roles/:id/edit/",
    name: "RolesEdit",
    component: RolesForm,
    meta: {
      title: `${t("meta.app")} - ${t("permissions.roles.edit")}`,
      requiresAuth: true,
      permission: PERMISSIONS.ROLES_EDIT,
      app: APPS.GROUPS,
    },
  },
  {
    path: "/roles/:id/preview/",
    name: "RolesPreview",
    component: RolesForm,
    props: { preview: true },
    meta: {
      title: `${t("meta.app")} - ${t("permissions.roles.preview")}`,
      requiresAuth: true,
      permission: PERMISSIONS.ROLES,
      app: APPS.GROUPS,
    },
  },
];

export default roles;
