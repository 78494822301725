
import { Badge } from "ant-design-vue";
import { defineComponent } from "vue";
import formIcons from "./formIcons";

export default defineComponent({
  name: "FormTile",
  props: {
    elem: { type: Object, required: true },
    handleSelectedField: { type: Function, required: true },
    removeItem: { type: Function, required: true },
    multiValue: { type: Boolean, default: false },
  },
  setup() {},
  components: {
    Badge,
    ...formIcons,
  },
});
