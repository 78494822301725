import { Handover } from "@/interfaces/handovers";
import { Params } from "@/interfaces/common";
import axios from "@/config/apiClient";

const getHandovers = (params: Params) => {
  return axios.get("/api/archives/archivation-protocols/", { params });
};

const getHandover = (id: number) => {
  return axios.get(`/api/archives/archivation-protocols/${id}/`);
};
const getHandoversSelect = (params: Params) => {
  return axios.get("/api/archives/archivation-protocols/select/", { params });
};

const createHandover = (data: Handover) => {
  return axios.post("/api/archives/archivation-protocols/", data);
};
const deleteHandover = (id: number) => {
  return axios.delete(`/api/archives/archivation-protocols/${id}/`);
};

const generateHandoverLists = (id: number) => {
  return axios.get(`/api/archives/archivation-protocols/${id}/szo.pdf`, {
    responseType: "blob",
  });
};
const patchHandover = (data: any, id: number) => {
  return axios.patch(`/api/archives/archivation-protocols/${id}/`, data);
};

const getFoldersRelatedToHandover = (id: number, params: Params) => {
  return axios.get(`/api/archives/folders/?archivation_protocols=${id}`, {
    params,
  });
};

const addFoldersToHandover = (id: number, data: Handover) => {
  return axios.patch(` /api/archives/archivation-protocols/${id}/`, data);
};

const bulkHandoverGeneration = (ids: number) => {
  return axios.get(`/api/archives/archivation-protocols/${ids}/szo.zip`, {
    responseType: "blob",
  });
};

export {
  getHandovers,
  createHandover,
  deleteHandover,
  generateHandoverLists,
  getHandoversSelect,
  getHandover,
  getFoldersRelatedToHandover,
  addFoldersToHandover,
  bulkHandoverGeneration,
  patchHandover,
};
