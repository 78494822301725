import PERMISSIONS from "@/consts/permissions";
import i18n from "@/i18n";

const t = i18n.global.t;
const tc = i18n.global.tc;

const config = [
  {
    key: "Document",
    permission: PERMISSIONS.DOCUMENTS_ADD,
    title: t("documents.add"),
    icon: "SnippetsOutlined",
    route: { name: "DocumentsCreate" },
  },
  {
    key: "Replacement",
    permission: PERMISSIONS.REPLACEMENTS_OWN,
    title: t("app.add_", [tc("replacements.replacement", 2)]),
    icon: "UserSwitchOutlined",
    route: { name: "ReplacementsCreate" },
  },
  {
    key: "Contractor",
    permission: PERMISSIONS.CONTRACTORS_ADD,
    title: t("contractors.add"),
    icon: "TeamOutlined",
    route: { name: "ContractorsCreate" },
  },
  {
    key: "Notification",
    permission: PERMISSIONS.NOTIFICATIONS_ADD,
    title: t("notifications.add"),
    icon: "MessageOutlined",
    route: { name: "NotificationsCreate" },
  },
  {
    key: "Deployment",
    permission: PERMISSIONS.DEPLOYMENTS_ADD,
    title: t("deployments.add"),
    icon: "SyncOutlined",
    route: { name: "DeploymentCreate" },
  },
  {
    key: "FormsCreate",
    permission: PERMISSIONS.FORMS_ADD,
    title: t("forms.add"),
    icon: "FormOutlined",
    route: { name: "FormsCreate" },
  },
  {
    key: "Dictionary",
    permission: PERMISSIONS.DICTIONARIES_ADD,
    title: t("dictionaries.add"),
    icon: "SortAscendingOutlined",
    route: { name: "DictionariesCreate" },
  },
  {
    key: "Numeration",
    permission: PERMISSIONS.NUMERATION_ADD,
    title: t("app.add_", [tc("numeration.numeration", 2)]),
    icon: "OrderedListOutlined",
    route: { name: "NumerationCreate" },
  },
];

export { config };
