<template>
  <div class="separator">
    <h2 v-if="title">{{ title }}</h2>
    <hr v-if="showLine" />
  </div>
</template>

<script>
export default {
  name: "EODSeparator",
  props: { title: String, showLine: { type: Boolean, default: true } },
};
</script>

<style lang="scss" scoped>
@import "@/scss/Variables";

.separator {
  width: 100%;
  margin: 1rem 0;
  text-align: left;

  h2 {
    margin-bottom: 0;
  }

  hr {
    color: $primary;
  }
}
</style>
