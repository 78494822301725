import axiosSession from "@/config/apiClient";

const getRegistry = (id) => {
  return axiosSession.get(`/api/registers/${id}/`);
};

const getRegistryDocuments = (params, id) => {
  return axiosSession.get(`/api/registers/${id}/documents/`, { params });
};

const getRegistryList = (params) => {
  return axiosSession.get("/api/registers/", { params });
};

const createRegistry = (data) => {
  return axiosSession.post("/api/registers/", data);
};

const deleteRegistry = (id) => {
  return axiosSession.delete(`/api/registers/${id}/`);
};

const editRegistry = (data, id) => {
  return axiosSession.put(`/api/registers/${id}/`, data);
};

const getRegistryPerms = (id) => {
  return axiosSession.get(`/api/registers/${id}/assignee-perm/`);
};

const editRegistryPerms = (data, id) => {
  return axiosSession.post(`/api/registers/${id}/assignee-perm/`, data);
};

export {
  createRegistry,
  deleteRegistry,
  editRegistry,
  editRegistryPerms,
  getRegistry,
  getRegistryPerms,
  getRegistryList,
  getRegistryDocuments,
};
