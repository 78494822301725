<template>
  <template v-if="record.type === RESULT_TYPES.DOCUMENTS">
    <router-link
      :to="{
        name: 'DocumentsPreview',
        params: { id: record.document_id },
      }"
      :title="record.document_name"
    >
      <h1 class="results-name">
        <span v-html="record.document_name" class="mr-050" />
        <SnippetsOutlined />
      </h1>
    </router-link>
    <p class="color--font-secondary">
      <span v-html="record.document_date" />
      <template v-if="record.document_type !== ''">
        |
        <span v-html="record.document_type" />
      </template>
      <template v-if="record.document_attachment_count !== ''">
        |
        <PaperClipOutlined />
        {{ record.document_attachment_count }}
      </template>
    </p>
    <p v-if="hasOccurrence" class="color--font-secondary">
      {{ $t("search.occurrence") }}: {{ record.occurrence.join(", ") }}
    </p>
    <span class="color--font-secondary" v-html="record.example" />
  </template>
  <template v-else-if="record.type === RESULT_TYPES.ATTACHMENTS">
    <button
      class="results-name mb-1"
      @click="
        openDrawer(record.attachment_id, record.attachment_name_extension)
      "
    >
      <span v-html="record.attachment_name_extension" class="mr-050" />
      <PaperClipOutlined />
    </button>
    <p>
      <span v-if="record.document_name !== ''">
        <SnippetsOutlined />
        {{ record.document_name }}
      </span>
    </p>
    <p v-if="hasOccurrence" class="color--font-secondary">
      {{ $t("search.occurrence") }}: {{ record.occurrence.join(", ") }}
    </p>
    <span class="color--font-secondary" v-html="record.example" />
  </template>
  <template v-else-if="record.type === RESULT_TYPES.ISSUES">
    <router-link
      :to="{
        name: 'IssuePreview',
        params: { id: record.issue_id },
      }"
      :title="record.issue_signature"
    >
      <h1 class="results-name">
        <span v-html="record.issue_signature" class="mr-050" />
        <FolderOutlined />
      </h1>
    </router-link>
    <p class="color--font-secondary">
      <span v-html="record.issue_initation_date" />
      |
      <span
        v-if="record.issue_content !== ''"
        class="color--font-secondary"
        v-html="record.issue_content"
      />
      <span v-else>-</span>
      | <SnippetsOutlined /> {{ record.documents_count }}
    </p>
    <p v-if="hasOccurrence" class="color--font-secondary">
      {{ $t("search.occurrence") }}: {{ record.occurrence.join(", ") }}
    </p>
    <span class="color--font-secondary" v-html="record.example" />
  </template>
  <template v-else-if="record.type === RESULT_TYPES.PROCESSES">
    <router-link
      :to="{
        name: 'ProcessPreview',
        params: { id: record.process_id },
      }"
      :title="record.deployment_name"
    >
      <h1 class="results-name">
        <span v-html="record.deployment_name" class="mr-050" />
        <PartitionOutlined />
      </h1>
    </router-link>
    <p class="color--font-secondary">
      {{ new Date(record.created).toLocaleString() }}
      &nbsp;|&nbsp;
      {{ record.active ? $t("status.active") : $t("search.processCompleted") }}
      <span v-if="record.document_name !== ''">
        &nbsp;|&nbsp;
        <SnippetsOutlined />
        {{ record.document_name }}
      </span>
    </p>
    <p v-if="hasOccurrence" class="color--font-secondary">
      {{ $t("search.occurrence") }}: {{ record.occurrence.join(", ") }}
    </p>
    <p>
      <span class="color--font-secondary" v-html="record.example" />
    </p>
  </template>
</template>

<script>
import {
  FolderOutlined,
  PaperClipOutlined,
  PartitionOutlined,
  SnippetsOutlined,
} from "@ant-design/icons-vue";

import { RESULT_TYPES } from "./searchConst";

export default {
  name: "Result",
  props: {
    record: {
      type: Object,
      require: true,
    },
    openDrawer: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return { RESULT_TYPES };
  },
  components: {
    FolderOutlined,
    PaperClipOutlined,
    PartitionOutlined,
    SnippetsOutlined,
  },
  computed: {
    hasOccurrence() {
      return this.record.occurrence.length > 0;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/Variables";

.results-name {
  color: $primary;
  padding: 0;
  border: none;
  background-color: transparent;
  font-size: 1.5em;
  cursor: pointer;
  &:focus {
    outline: 0;
  }
}
</style>
