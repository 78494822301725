<template>
  <SelectModal
    v-model:visible="cVisible"
    v-model:selectedOption="selectedDeployment"
    :title="selectDeploymentModalTitle"
    :body="selectDeploymentModalText"
    :okText="$t('app.run')"
    :service="FETCH_DEPLOYMENTS_SELECT"
    :onSubmit="handleStartDeployment"
    :onCancel="closeSelectDeploymentModal"
    :selectId="selectId"
    :isLoading="isLoading"
  />
</template>
<script>
import {
  FETCH_DEPLOYMENTS_SELECT,
  HANDLE_START_DEPLOYMENT,
} from "@/views/Deployments/deploymentsConst";

import { SelectModal } from "@/components/modals";

export default {
  name: "StartDeploymentForDocModal",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    documentsId: Array,
    documentName: String,
    selectId: {
      type: String,
      default: "deploymentsToStartDoc",
    },
  },
  data() {
    return {
      selectedDeployment: null,
      isLoading: false,
    };
  },
  components: {
    SelectModal,
  },
  computed: {
    cVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
    selectDeploymentModalText() {
      if (this.documentName === "") {
        return this.$tc("documents.assignMultipleDocuments_", [
          this.documentsId.length,
        ]);
      } else {
        return this.$tc("documents.assignDocumentTo_", [this.documentName]);
      }
    },
    selectDeploymentModalTitle() {
      if (this.documentName === "") {
        return this.$t("documents.assignDocumentsTo");
      } else {
        return this.$t("processes.selectDeployment");
      }
    },
  },
  methods: {
    FETCH_DEPLOYMENTS_SELECT,
    closeSelectDeploymentModal() {
      this.cVisible = false;
      this.selectedDeployment = null;
      this.isLoading = false;
    },
    async handleStartDeployment() {
      this.isLoading = true;
      await HANDLE_START_DEPLOYMENT(
        this.documentsId,
        this.selectedDeployment,
        this.closeSelectDeploymentModal,
      );
      this.isLoading = false;
    },
  },
};
</script>
