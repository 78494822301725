<template>
  <Preview
    v-if="contactID"
    :oid="contactID"
    :data="contactData"
    :title="$t('contractors.contactData')"
    :noPreviewMsg="$t('contractors.noPreview')"
    :twoColumn="true"
  />
</template>

<script>
import { Preview } from "@/components/common";
import { getContact } from "@/services/contractors";

export default {
  name: "ContactPreview",
  data() {
    return {
      contactData: [],
    };
  },
  components: { Preview },
  computed: {
    contactID() {
      return this.$store.state.contractors.contactID;
    },
  },
  methods: {
    fetchContact() {
      getContact(this.contactID).then(({ data }) => {
        this.contactData = [
          [
            {
              title: this.$t("contractors.firstName"),
              value: data.first_name,
            },
            {
              title: this.$t("contractors.phone"),
              value: data.phone || "-",
            },
            {
              title: this.$t("contractors.fax"),
              value: data.fax || "-",
            },
          ],
          [
            {
              title: this.$t("contractors.lastName"),
              value: data.last_name,
            },
            {
              title: this.$t("contractors.email"),
              value: data.email || "-",
            },
          ],
        ];
      });
    },
  },
  mounted() {
    if (this.contactID) {
      this.fetchContact();
    }
  },
  watch: {
    contactID() {
      this.fetchContact();
    },
  },
};
</script>
