
import { LeftOutlined, RightOutlined } from "@ant-design/icons-vue";
import { defineComponent, ref } from "vue";
import { Carousel } from "ant-design-vue";
import { getDeploymentsSelect } from "@/services/deployments";
import { startDeploymentFromDashboard } from "@/services/documents";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "QuickStartProcesses",
  props: {
    isMobile: { type: Boolean, default: false },
  },
  setup(props) {
    const router = useRouter();
    const settings = {
      arrows: true,
      dots: false,
      autoplay: false,
      slidesToShow: props.isMobile ? 2 : 3,
      rows: props.isMobile ? 1 : 2,
      slidesPerRow: 1,
    };
    const processes = ref([]);
    const colorClass = ref({
      "0": "process--green",
      "1": "process--blue",
      "2": "process--navy-blue",
      "3": "process--pink",
      "4": "process--brown",
    });

    const fetchDeployments = () => {
      getDeploymentsSelect({}).then(({ data }) => {
        processes.value = data.results;
      });
    };
    fetchDeployments();

    const goToTask = (processId: any) => {
      startDeploymentFromDashboard(processId).then(({ data }) => {
        router.push({
          name: "TaskForm",
          params: { id: data.task_id },
        });
      });
    };

    return {
      settings,
      processes,
      fetchDeployments,
      colorClass,
      goToTask,
    };
  },
  components: {
    Carousel,
    LeftOutlined,
    RightOutlined,
  },
});
