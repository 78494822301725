<template>
  <section class="section pt-050">
    <div class="filters">
      <EODSelect
        id="unit"
        v-model:value="filters.unit"
        :placeholder="$t('issues.unit')"
        :service="fetchUnits"
      />
      <EODSelect
        id="category"
        v-model:value="filters.archive_category"
        :placeholder="$t('archive.folders.category')"
        :service="fetchCategories"
      />
      <EODSelect
        id="status"
        v-model:value="filters.status"
        :placeholder="$t('app.status')"
        :options="folderStatuses"
      />

      <EODButton
        class="mr-1 mt-050"
        :name="$t('app.reset')"
        @click="handleFilterReset"
      />
      <EODButton
        class="mt-050"
        type="primary"
        :name="$t('app.filter')"
        @click="handleFilter"
      />
    </div>
  </section>

  <section class="section">
    <BaseTable
      :service="(params) => getFolders({ ...params, ...filters })"
      :columns="columns"
      :customStore="customStore"
    >
      <template #archive_category="{ record }">
        {{ record.archive_category_display }}
      </template>
      <template #edge_dates="{ record }">
        {{
          `${getYear(record.edge_date_from)} - ${getYear(record.edge_date_to)}`
        }}
      </template>
      <template #status="{ record }">
        <StatusDot
          :status="record.status"
          :colorsMap="{
            3: 'color--success',
            2: 'color--error',
            1: 'color--grey',
          }"
        />
        {{ record.status_display }}
      </template>
      <template v-slot:actions="{ record }">
        <TableActions
          :record="record"
          :customStore="customStore"
          :previewRoute="{
            name: 'FoldersPreview',
            params: { id: record.id },
          }"
          :editRoute="{
            name: 'FoldersEdit',
            params: { id: record.id },
          }"
          :deleteMessage="
            $t('app.doYouWantToDelete_', [$tc('archive.folders.folder', 0)])
          "
          :modalTitle="$t('app.delete_', [$tc('archive.folders.folder', 0)])"
          :deleteService="deleteFolder"
        >
          <GenerateFolderToFile :id="record.id" />
        </TableActions>
      </template>
    </BaseTable>
  </section>
</template>

<script>
import { BaseTable, StatusDot, TableActions } from "@/components/table";
import {
  deleteFolder,
  getFolderStatuses,
  getFolders,
} from "@/services/archive";

import { EODButton } from "@/components/ui";
import { EODSelect } from "@/components/inputs";
import GenerateFolderToFile from "@/components/archive/GenerateFolderToFile";
import SYSTEM_DICT from "@/config/systemDict";
import dynamicTable from "@/store/modules/dynamicTable";
import { getDictionaryEntriesSelect } from "@/services/dictionaries";
import { getEmployeeUnits } from "@/services/units";

export default {
  name: "Folders",
  data() {
    return {
      customStore: "foldersTable",
      filters: {
        unit: null,
        archive_category: null,
        status: null,
      },
      folderStatuses: [],
      columns: [
        {
          title: this.$t("archive.folders.actSymbol"),
          dataIndex: "act_symbol",
          sorter: true,
        },
        {
          title: this.$t("archive.folders.category"),
          dataIndex: "archive_category",
          sorter: true,
          slots: { customRender: "archive_category" },
        },
        {
          title: this.$t("archive.folders.titleGridShort"),
          dataIndex: "description",
          sorter: true,
        },
        {
          title: this.$t("archive.folders.numberOfVolumes"),
          dataIndex: "number_of_volumes",
          sorter: true,
        },
        {
          title: this.$t("archive.folders.edgeDates"),
          slots: { customRender: "edge_dates" },
        },
        {
          title: this.$t("app.status"),
          dataIndex: "status",
          sorter: true,
          slots: { customRender: "status" },
        },
        {
          title: this.$t("app.actions"),
          dataIndex: "actions",
          slots: { customRender: "actions" },
          fixed: "right",
          width: "180",
        },
      ],
    };
  },
  components: {
    BaseTable,
    EODButton,
    EODSelect,
    StatusDot,
    TableActions,
    GenerateFolderToFile,
  },
  created() {
    if (!this.$store.hasModule(this.customStore)) {
      this.$store.registerModule(this.customStore, dynamicTable);
    }

    getFolderStatuses().then(({ data }) => {
      this.folderStatuses = Object.entries(data).map(([key, value]) => ({
        id: key,
        name: value,
      }));
    });
  },
  methods: {
    deleteFolder,
    getFolders,
    handleFilter() {
      this.$store.dispatch(`${this.customStore}/refreshTable`);
    },
    handleFilterReset() {
      this.filters = {
        unit: null,
        archive_category: null,
        status: null,
      };
      this.handleFilter();
    },
    fetchCategories(params) {
      return getDictionaryEntriesSelect({
        ...params,
        keyword: SYSTEM_DICT.JRWA_ARCHIVE_CATEGORY,
      }).then(({ data }) => data);
    },
    fetchUnits(params) {
      return getEmployeeUnits(params).then(({ data }) => data);
    },
    getYear(date) {
      return date === null ? "" : new Date(date).getFullYear();
    },
  },
};
</script>
