import axiosSession from "@/config/apiClient";

const getUnitsList = (params) => {
  return axiosSession.get("/api/units/", { params });
};

const getUnitsSelect = (params) => {
  return axiosSession.get("/api/units/select/", { params });
};

const getUnitsStatuses = () => {
  return axiosSession.get("/api/units/statuses/");
};

const getUnit = (id) => {
  return axiosSession.get(`/api/units/${id}/`);
};

const getEmployeeUnits = (params) => {
  return axiosSession.get("/api/units/select-employment/", { params });
};
const getUnitsFlatSelect = (params) => {
  return axiosSession.get("/api/units/select/flat/", { params });
};
const getUnitsInIssuesFilterSelect = (params) => {
  return axiosSession.get("/api/units/select/filter/in-issues/", { params });
};
export {
  getEmployeeUnits,
  getUnit,
  getUnitsList,
  getUnitsStatuses,
  getUnitsSelect,
  getUnitsFlatSelect,
  getUnitsInIssuesFilterSelect,
};
