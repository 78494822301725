<template>
  <div class="form-container">
    <form id="perms-form" class="form" @submit.prevent="submitFunction">
      <template v-for="perm in permTypes" :key="perm">
        <div class="input-container" v-if="showPerms[perm]">
          <span class="label">
            {{ labels[perm] }}
          </span>

          <EODSelect
            :id="`perms-${perm}-users`"
            storeID="share-perms-users"
            v-model:value="cPerms[`users_${perm}`]"
            mode="multiple"
            :placeholder="$tc('app.user', 2)"
            :service="fetchUsers"
            :defaultValue="defaultValues[`users_${perm}`]"
            :errors="errors[`users_${perm}`]"
            :disabled="disabled"
          />

          <EODSelect
            :id="`perms-${perm}-groups`"
            storeID="share-perms-groups"
            v-model:value="cPerms[`groups_${perm}`]"
            mode="multiple"
            :placeholder="$t('modules.teams')"
            :service="fetchGroups"
            :defaultValue="defaultValues[`groups_${perm}`]"
            :errors="errors[`groups_${perm}`]"
            :disabled="disabled"
          />
        </div>
      </template>
    </form>
  </div>
</template>

<script>
import {
  getGroupsSimpleList,
  getUsersSimpleList,
} from "@/services/permissions";

import { EODSelect } from "@/components/inputs";

export default {
  name: "ShareForm",
  props: {
    perms: {
      type: Object,
      required: true,
    },
    errors: { type: Object, default: () => ({}) },
    viewPerms: { type: Boolean, default: true },
    editPerms: { type: Boolean, default: true },
    startPerms: { type: Boolean, default: false },
    submitFunction: { type: Function, required: true },
    defaultValues: Object,
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      permTypes: ["can_view", "can_change", "can_start"],
      labels: {
        can_view: this.$t("app.shareToRead"),
        can_change: this.$t("app.shareToEdit"),
        can_start: this.$t("deployments.permsLabel"),
      },
    };
  },
  components: {
    EODSelect,
  },
  computed: {
    cPerms: {
      get() {
        return this.perms;
      },
      set(val) {
        this.$emit("update:perms", val);
      },
    },
    showPerms() {
      return {
        can_view: this.viewPerms,
        can_change: this.editPerms,
        can_start: this.startPerms,
      };
    },
  },
  emits: ["update:perms"],
  methods: {
    fetchUsers(params) {
      return getUsersSimpleList(params).then(({ data }) => ({
        pages_number: data.pages_number,
        results: data.results.map((item) => ({
          id: item.id,
          name: item.full_name,
        })),
      }));
    },
    fetchGroups(params) {
      return getGroupsSimpleList(params).then(({ data }) => data);
    },
  },
};
</script>
