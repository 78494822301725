<template>
  <section class="section pt-050">
    <div class="filters">
      <span class="filter-label mr-050">{{ $t("tasks.deployment") }}:</span>
      <EODSelect
        id="deployment"
        storeID="deployment"
        v-model:value="deployment"
        :placeholder="$t('processes.selectDeployment')"
        :service="fetchDeployments"
      />
    </div>
  </section>

  <section class="section">
    <BaseTable
      :columns="columns"
      :service="getProcessesList"
      :customParams="{ ...params, deployment }"
      :customStore="customStore"
    >
      <template v-slot:processId="{ record }">
        <Tooltip :title="record.process_id">
          <InfoCircleOutlined />
        </Tooltip>
      </template>
      <template v-slot:numeration="{ record }">
        {{ record.value || "-" }}
      </template>
      <template v-slot:document_name="{ record }">
        {{ record?.document?.name || "-" }}
      </template>
      <template v-slot:created_by="{ record }">
        {{ record?.created_by?.full_name || "-" }}
      </template>
      <template v-slot:startTime="{ record }">
        {{ new Date(record.created).toLocaleString() }}
      </template>
      <template #state="{ record }">
        <StatusDot :status="record.finished ? 2 : 1" />
        {{
          record.finished
            ? $t("search.processCompleted")
            : $t("processes.active")
        }}
      </template>
      <template v-slot:actions="{ record }">
        <TableActions
          :record="record"
          :previewRoute="{
            name: 'ProcessPreview',
            params: { id: record.process_id },
          }"
          :showEdit="false"
          :showRemove="false"
        >
          <!-- TODO: add grid action serializer? -->
          <router-link
            class="action"
            :to="{
              name: 'ProcessPreview',
              params: { id: record.process_id },
            }"
            :title="$t('app.preview')"
          >
            <EyeOutlined />
          </router-link>
          <PrintBarcodes v-if="record.value" :record="record" />
        </TableActions>
      </template>
    </BaseTable>
  </section>
</template>

<script>
import { BaseTable, StatusDot, TableActions } from "@/components/table";
import { EyeOutlined, InfoCircleOutlined } from "@ant-design/icons-vue";

import { EODSelect } from "@/components/inputs";
import PrintBarcodes from "@/components/numeration/PrintBarcodes";
import { Tooltip } from "ant-design-vue";
import dynamicTable from "@/store/modules/dynamicTable";
import { getDeploymentsSelect } from "@/services/deployments";
import { getProcessesList } from "@/services/deployments";

export default {
  name: "ProcessesList",
  props: {
    params: Object,
    customStore: {
      type: [Boolean, String],
      default: false,
    },
    showStatus: Boolean,
  },
  data() {
    return {
      deployment: null,
      ACTIVE: "ACTIVE",
      columns: [
        {
          dataIndex: "processId",
          slots: { customRender: "processId" },
          width: "5%",
        },
        {
          title: this.$t("processes.number"),
          dataIndex: "value",
          sorter: true,
          slots: { customRender: "numeration" },
        },
        {
          title: this.$t("processes.documentName"),
          dataIndex: "document__name",
          sorter: true,
          slots: { customRender: "document_name" },
        },
        {
          title: this.$t("processes.createdBy"),
          dataIndex: "created_by__last_name",
          sorter: true,
          slots: { customRender: "created_by" },
        },
        {
          title: this.$t("app.created"),
          dataIndex: "created",
          sorter: true,
          slots: { customRender: "startTime" },
        },
        {
          title: this.$t("app.actions"),
          dataIndex: "actions",
          slots: { customRender: "actions" },
          width: "15%",
        },
      ],
    };
  },
  components: {
    BaseTable,
    EODSelect,
    EyeOutlined,
    StatusDot,
    TableActions,
    Tooltip,
    InfoCircleOutlined,
    PrintBarcodes,
  },
  created() {
    if (!this.$store.hasModule(this.customStore)) {
      this.$store.registerModule(this.customStore, dynamicTable);
    }

    if (this.showStatus) {
      this.columns.splice(3, 0, {
        title: this.$t("app.status"),
        dataIndex: "finished",
        sorter: true,
        slots: { customRender: "state" },
      });
    }
  },
  methods: {
    getProcessesList,
    fetchDeployments(params) {
      return getDeploymentsSelect({ all_versions: true, ...params }).then(
        ({ data }) => {
          return {
            ...data,
            results: data.results.map((item) => ({
              name:
                item.version !== 1
                  ? `${item.name} - ${this.$t("app.version")} ${item.version}`
                  : item.name,
              id: item.id,
            })),
          };
        },
      );
    },
  },
};
</script>
