import apiClient from "@/config/apiClient";

const searchList = (params) => {
  return apiClient.get(`/api/search/result/`, { params });
};

const searchSimple = (params) => {
  return apiClient.post(`/api/search/simple/`, params);
};

const searchAdvanced = (params) => {
  return apiClient.post(`/api/search/advanced/`, params);
};

const getFilterFieldList = (id) => {
  return apiClient.get(`/api/search/deployment/${id}/filter-field/`);
};

export { searchList, searchSimple, searchAdvanced, getFilterFieldList };
