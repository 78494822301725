import { CustomErrorHandler, Params } from "@/interfaces/common";

import { Comment } from "@/interfaces/tasks";
import SYSTEM_DICT from "@/config/systemDict";
import apiClient from "@/config/apiClient";

const tasks = "/api/camunda/task";

// TODO: change all anys to proper types
const assignTask = (data: any, id: string) => {
  return apiClient.post(`${tasks}/${id}/assignee/`, data);
};

const completeTask = (
  data: any,
  id: string,
  customErrorHandler: CustomErrorHandler,
) => {
  return apiClient.post(`${tasks}/${id}/complete/`, data, {
    // @ts-ignore
    customErrorHandler,
  });
};

const claimTask = (data: any, id: string) => {
  return apiClient.post(`${tasks}/${id}/claim/`, data);
};

const unclaimTask = (data: any, id: string) => {
  return apiClient.post(`${tasks}/${id}/unclaim/`, data);
};

const getTask = (id: string) => {
  return apiClient.get(`${tasks}/${id}/`);
};

const getTasksList = (params: Params) => {
  return apiClient.get(`${tasks}s/`, { params });
};

const getTasksOverDueCount = () => {
  return apiClient.get(`${tasks}s/count/?overdue=true`);
};

const getCompletedTasksList = (params: Params) => {
  return apiClient.get(`${tasks}s/?history=true`, { params });
};

const getForm = (id: string) => {
  return apiClient.get(`${tasks}/${id}/form/`);
};

const addComment = (data: Comment, id: string) => {
  return apiClient.post(`${tasks}/${id}/comments/`, data);
};

const editComment = (data: Comment, commentId: number) => {
  return apiClient.put(`/api/camunda/comment/${commentId}/`, data);
};

const deleteComment = (id: number) => {
  return apiClient.delete(`/api/camunda/comment/${id}/`);
};

const getComments = (id: string, params: Params) => {
  return apiClient.get(`${tasks}/${id}/comments/`, { params });
};

const getProcessComments = (id: string, params: Params) => {
  return apiClient.get(`/api/camunda/process/${id}/comments/`, { params });
};

const getCommentTypes = () => {
  return apiClient.get("/api/camunda/comments/types/");
};

const saveTask = (
  data: any,
  id: string,
  customErrorHandler: CustomErrorHandler,
) => {
  return apiClient.post(`${tasks}/${id}/save-form/`, data, {
    // @ts-ignore
    customErrorHandler,
  });
};

const getPriorityOption = (params: Params) => {
  return apiClient.get(
    `/api/dicts/entry/keyword/${SYSTEM_DICT.TASK_PRIORITY}/`,
    { params },
  );
};

export {
  addComment,
  assignTask,
  claimTask,
  completeTask,
  deleteComment,
  editComment,
  getCommentTypes,
  getComments,
  getCompletedTasksList,
  getForm,
  getPriorityOption,
  getProcessComments,
  getTask,
  getTasksList,
  getTasksOverDueCount,
  saveTask,
  unclaimTask,
};
