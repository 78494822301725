import { toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "input-container" }
const _hoisted_2 = { class: "input-wrap" }
const _hoisted_3 = {
  key: 0,
  class: "color--error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputMask = _resolveComponent("InputMask")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("label", {
      for: _ctx.id,
      class: "label"
    }, [
      _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
      (_ctx.required)
        ? (_openBlock(), _createBlock("span", {
            key: 0,
            class: "color--error",
            title: _ctx.$t('app.fieldRequired')
          }, "  * ", 8, ["title"]))
        : _createCommentVNode("", true)
    ], 8, ["for"]),
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_InputMask, {
        modelValue: _ctx.cValue,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.cValue = $event)),
        mask: _ctx.mask,
        unmask: "",
        autoClear: false,
        placeholder: _ctx.placeholder,
        disabled: _ctx.disabled,
        required: _ctx.required
      }, null, 8, ["modelValue", "mask", "placeholder", "disabled", "required"]),
      _renderSlot(_ctx.$slots, "default")
    ]),
    (_ctx.errors)
      ? (_openBlock(), _createBlock("span", _hoisted_3, _toDisplayString(_ctx.errors[0]), 1))
      : _createCommentVNode("", true)
  ]))
}