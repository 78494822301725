<template>
  <!-- noPrevieMsg won't be needed after all old components will be restyled -->
  <Section
    :title="oid ? title : noPreviewMsg"
    :collapsable="collapsable"
    v-model:collapsed="isCollapsed"
  >
    <div class="preview">
      <div class="two-column-row" v-if="twoColumn">
        <template v-for="(column, index) in data" :key="index">
          <div class="column">
            <PreviewSegment
              v-for="(item, idx) in column"
              :key="idx"
              :item="item"
            />
          </div>
        </template>
      </div>

      <template v-else>
        <PreviewSegment
          v-for="(item, index) in data"
          :key="index"
          :item="item"
        />
      </template>
    </div>
    <slot></slot>
  </Section>
</template>

<script>
import PreviewSegment from "./PreviewSegment";
import { Section } from "@/components/ui";

export default {
  name: "Preview",
  props: {
    oid: [Number, String],
    data: Array,
    title: String,
    noPreviewMsg: String,
    collapsable: { type: Boolean, default: false },
    collapsed: Boolean,
    twoColumn: { type: Boolean, default: false },
  },
  components: { PreviewSegment, Section },
  computed: {
    isCollapsed: {
      get() {
        return this.collapsed;
      },
      set(val) {
        this.$emit("update:collapsed", val);
      },
    },
  },
  emits: ["update:collapsed"],
};
</script>

<style scoped lang="scss">
.preview {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 15%;

  .two-column-row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;

    .column {
      display: flex;
      align-items: center;
      flex-direction: column;
      min-width: 50%;
    }
  }
}
</style>
