
import {
  FolderViewOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons-vue";

import { defineComponent, ref } from "vue";
import { getFolderDescription, getListFolderIssues } from "@/services/archive";

export default defineComponent({
  name: "GenerateFolderToFile",
  props: {
    preview: { type: Boolean, default: false },
    id: { type: [String, Number], required: true },
  },
  setup(props) {
    const isGenerateFolderDescription = ref(null);

    const generateFolderDescription = () => {
      isGenerateFolderDescription.value = true;
      downloadFile();
    };

    const generateListFolderIssues = () => {
      isGenerateFolderDescription.value = false;
      downloadFile();
    };

    const downloadFile = () => {
      const service = isGenerateFolderDescription.value
        ? getFolderDescription
        : getListFolderIssues;
      service(props.id).then(({ data }) =>
        window.open(URL.createObjectURL(data)),
      );
    };

    return {
      generateFolderDescription,
      generateListFolderIssues,
      downloadFile,
    };
  },
  components: { FolderViewOutlined, UnorderedListOutlined },
});
