<template>
  <nav class="breadcrumbs">
    <Breadcrumb>
      <BreadcrumbItem>
        <router-link
          class="breadcrumb"
          :to="{ name: 'Home' }"
          :title="$t('modules.home')"
        >
          <HomeOutlined />
        </router-link>
      </BreadcrumbItem>
      <template v-for="(item, index) in path" :key="item.title">
        <BreadcrumbItem v-if="item.title !== ''">
          <router-link class="breadcrumb" :to="item.path" :title="item.title">
            <component v-if="index === 0" :is="icon" />
            {{ item.title }}
          </router-link>
        </BreadcrumbItem>
      </template>
      <BreadcrumbItem v-if="current !== ''" :title="current">
        <component v-if="path.length === 0 && icon" class="mr-025" :is="icon" />
        <slot name="current">{{ current }}</slot>
      </BreadcrumbItem>
    </Breadcrumb>

    <div class="row">
      <h2 class="breadcrumb-title" :title="current">
        <Cancel
          v-if="path.length !== 0"
          :goTo="() => $router.go(-1)"
          :bodyContent="cancelModalBodyContent"
          :ask="ask"
        />
        <slot name="title">{{ current }}</slot>
      </h2>

      <div class="button-row">
        <slot></slot>
      </div>
    </div>
    <slot name="summary"></slot>
  </nav>
</template>

<script>
import {
  CarryOutOutlined,
  ContainerOutlined,
  DownOutlined,
  FileOutlined,
  FileTextOutlined,
  FolderOutlined,
  FormOutlined,
  HomeOutlined,
  IdcardOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  MessageOutlined,
  OrderedListOutlined,
  PartitionOutlined,
  ProfileOutlined,
  SnippetsOutlined,
  SortAscendingOutlined,
  SyncOutlined,
  TeamOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons-vue";

import { Breadcrumb } from "ant-design-vue";
import Cancel from "./Cancel";

export default {
  name: "Breadcrumbs",
  props: {
    // ex. [{ path: { name: "Home" }, title: "Home" }], if title is empty item won't render
    path: {
      type: Array,
      default: () => [],
    },
    current: String,
    iconComponent: {
      type: String,
      default: "",
    },
    what: String,
  },
  data() {
    return {
      id: this.$route.params?.id,
      icon: this.iconComponent,
    };
  },
  components: {
    Breadcrumb,
    BreadcrumbItem: Breadcrumb.Item,
    CarryOutOutlined,
    Cancel,
    ContainerOutlined,
    DownOutlined,
    FileOutlined,
    FileTextOutlined,
    FolderOutlined,
    FormOutlined,
    HomeOutlined,
    IdcardOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    MessageOutlined,
    PartitionOutlined,
    ProfileOutlined,
    SnippetsOutlined,
    SortAscendingOutlined,
    SyncOutlined,
    TeamOutlined,
    UserOutlined,
    UserSwitchOutlined,
    OrderedListOutlined,
  },
  computed: {
    cancelModalBodyContent() {
      return this.$t(
        `app.${this.id ? "doYouWantToCancelEdit" : "doYouWantToCancelAdd"}`,
        { object: this.what },
      );
    },
    ask() {
      return !!this.what;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/Variables";

.breadcrumbs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
  background-color: $white;
  margin-top: 2px;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  box-sizing: border-box;
  min-height: 7rem; // FIXME: fix spacing problem
  z-index: 2;
  box-shadow: 0px 10px 10px 0px $box-shadow-color;
}

.ant-breadcrumb {
  width: 100%;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-breadcrumb a:focus,
.ant-breadcrumb > span:last-child {
  color: $primary;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;

  .button-row {
    display: flex;
    align-items: center;
  }
}

.breadcrumb:focus {
  text-decoration: underline;
}

.breadcrumb-title {
  margin-top: 0.5rem;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media only screen and (max-width: 768px) {
  .row {
    flex-wrap: wrap;
    justify-content: center;
  }
}
</style>
