<template>
  <Breadcrumbs :current="$t('modules.users')" iconComponent="UserOutlined">
    <AddLDAPUser v-if="APPS.LDAP" />
  </Breadcrumbs>

  <section class="section">
    <BaseTable :columns="columns" :service="getUsersList" :scroll="{ x: 1500 }">
      <template v-slot:last_name="{ record }">
        {{ record.full_name }}
      </template>
      <template v-slot:roles="{ record }">
        {{ record.roles.map((e) => e.name).join(", ") }}
      </template>
      <template v-slot:units="{ record }">
        {{ record.units.map((e) => e.name).join(", ") }}
      </template>
      <template v-slot:source="{ record }">
        {{ record.source }}
      </template>
      <template v-slot:actions="{ record }">
        <TableActions
          :record="record"
          :previewRoute="{
            name: 'UsersPreview',
            params: { id: record.id },
          }"
          :editRoute="{
            name: 'UsersEdit',
            params: { id: record.id },
          }"
        />
      </template>

      <template v-slot:superior="{ record }">
        <div class="center big-font">
          <UserOutlined
            :title="getSupervisedUnits(record)"
            v-if="record.superior_of_units?.length"
          />
        </div>
      </template>
    </BaseTable>
  </section>
</template>

<script>
import { BaseTable, TableActions } from "@/components/table";

import APPS from "@/consts/apps";
import AddLDAPUser from "@/components/users/AddLDAPUser";
import { Breadcrumbs } from "@/components/ui";
import { UserOutlined } from "@ant-design/icons-vue";
import { getUsersList } from "@/services/permissions";

export default {
  name: "Users",
  data() {
    return {
      APPS,
      columns: [
        {
          title: this.$t("permissions.users.fullName"),
          dataIndex: "last_name",
          slots: { customRender: "last_name" },
          sorter: true,
        },
        { title: "Email", dataIndex: "email", sorter: true },
        {
          //TODO: refactor backend to return proper date
          title: this.$t("permissions.users.registered"),
          dataIndex: "registered_at",
          sorter: true,
        },
        {
          title: this.$t("permissions.users.superior"),
          dataIndex: "units",
          slots: { customRender: "superior" },
        },
        {
          title: this.$t("permissions.users.unit"),
          dataIndex: "units__name",
          sorter: true,
          slots: { customRender: "units" },
        },
        {
          title: this.$t("modules.roles"),
          dataIndex: "roles",
          slots: { customRender: "roles" },
          width: "100",
        },
        {
          title: this.$t("permissions.users.source"),
          dataIndex: "source",
          slots: { customRender: "source" },
          width: "100",
        },
        {
          title: this.$t("app.actions"),
          dataIndex: "actions",
          slots: { customRender: "actions" },
          fixed: "right",
          width: "100",
        },
      ],
    };
  },
  components: {
    AddLDAPUser,
    BaseTable,
    Breadcrumbs,
    TableActions,
    UserOutlined,
  },
  methods: {
    getUsersList,
    getSupervisedUnits(record) {
      return record.units
        .filter((e) => record.superior_of_units.includes(e.id))
        .map((e) => e.name)
        .join("\n");
    },
  },
};
</script>

<style lang="scss" scoped>
.center {
  text-align: center;
}

.big-font {
  font-size: 200%;
}
</style>
