<template>
  <Breadcrumbs
    :path="[{ path: { name: 'Units' }, title: $t('modules.units') }]"
    :current="$t('units.preview')"
    iconComponent="HomeOutlined"
  />

  <Preview
    :oid="id"
    :data="previewData"
    :title="$t('units.preview')"
    :noPreviewMsg="$t('units.noPreview')"
  />
</template>

<script>
import { Breadcrumbs } from "@/components/ui";
import { Preview } from "@/components/common";
import { getUnit } from "@/services/units";

export default {
  name: "UnitsPreview",
  data() {
    return {
      id: +this.$route.params?.id,
      previewData: [],
    };
  },
  components: { Breadcrumbs, Preview },
  mounted() {
    if (this.id) {
      getUnit(this.id).then(({ data }) => {
        this.previewData = [
          {
            title: this.$t("units.symbol"),
            value: data.unit_symbol,
          },
          {
            title: this.$t("app.name"),
            value: data.name,
          },
          {
            title: this.$t("app.status"),
            value: data.status_display,
          },
          {
            title: this.$t("units.parent"),
            value: data.parent_display || "-",
          },
        ];
      });
    }
  },
};
</script>
