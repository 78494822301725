<template>
  <Breadcrumbs
    :current="$t('modules.documents')"
    iconComponent="FileTextOutlined"
  >
    <Button
      @click="unleashEdward"
      :title="$t('documents.scansImport')"
      :loading="loading$"
    >
      {{ $t("documents.scansImport") }}
    </Button>

    <EODButtonLink
      v-if="hasPermissionToAdd"
      :title="$t('documents.add')"
      :to="{ name: 'DocumentsCreate' }"
    />
  </Breadcrumbs>

  <Tabs @change="$scrollToTop">
    <TabPane key="1" :tab="$t('app.all')">
      <DocumentsList customStore="documents" />
    </TabPane>
    <TabPane key="2" :tab="$t('documents.external')">
      <DocumentsList
        :params="{ only_my_documents: false }"
        customStore="documentsExternal"
      />
    </TabPane>
    <TabPane key="3" :tab="$t('documents.own')">
      <DocumentsList
        :params="{ only_my_documents: true }"
        customStore="documentsOwn"
      />
    </TabPane>
  </Tabs>
</template>

<script>
import { Breadcrumbs, EODButtonLink } from "@/components/ui";
import { Button } from "ant-design-vue";
import DocumentsList from "./DocumentsList";
import PERMISSIONS from "@/consts/permissions";
import { Tabs } from "ant-design-vue";
import { edward } from "@/services/documents/";

export default {
  name: "Documents",
  components: {
    Breadcrumbs,
    Button,
    DocumentsList,
    EODButtonLink,
    Tabs,
    TabPane: Tabs.TabPane,
  },
  computed: {
    hasPermissionToAdd() {
      return this.$permissions.includes(PERMISSIONS.DOCUMENTS_ADD);
    },
  },
  methods: {
    unleashEdward() {
      this.loading$ = true;
      edward()
        .then(({ data }) => {
          this.$message.success(data);
        })
        .finally(() => {
          this.loading$ = false;
        });
    },
  },
};
</script>
