<template>
  <div v-if="!folderID" class="no-content">
    <img src="@/img/empty-img-default.png" :alt="$t('repository.noFolder')" />
    <p>
      {{ $t("repository.noFolder") }}
    </p>
  </div>
  <div v-else class="tile-view">
    <div class="header">
      <SortPanel :sortLabel="sortLabel" :handleSort="handleSort" />
      <div class="results">{{ $t("repository.results") }}: {{ total }}</div>
    </div>
    <div v-if="items.length" class="content">
      <EODCards :items="items" />
    </div>
    <div v-else>{{ $t("repository.noData") }}</div>
    <div class="footer">
      <Pagination
        v-model:current="page"
        :total="total"
        :pageSize="limit"
        :show-total="(total) => `${total} ${$t('app.results')}`"
        show-quick-jumper
        @change="handlePagination"
      />
    </div>
  </div>
</template>

<script>
import EODCards from "@/components/repository/EODCards";
import { Pagination } from "ant-design-vue";
import { SORT_FIELDS_FOLDERS } from "@/consts/statuses";
import SortPanel from "./SortPanel.vue";
import { getDocumentsList } from "@/services/documents";

export default {
  name: "FoldersList",
  data() {
    return {
      total: 0,
      page: 1,
      limit: 9,
      ordering: SORT_FIELDS_FOLDERS.CREATED_REVERSE,
      offset: null,
      sortLabel: this.$t("repository.oldFirst"),
      items: [],
    };
  },
  components: {
    EODCards,
    Pagination,
    SortPanel,
  },
  computed: {
    folderID() {
      return this.$store.getters["repository/folderID"];
    },
  },
  methods: {
    getDocumentsList,
    fetchResouces() {
      this.getDocumentsList({
        limit: this.limit,
        page: this.page,
        ordering: this.ordering,
        repository_folder: this.folderID,
      }).then(({ data }) => {
        this.items = data.results;
        this.total = data.count;
      });
    },
    handleSort() {
      if (this.ordering === SORT_FIELDS_FOLDERS.CREATED_REVERSE) {
        this.ordering = SORT_FIELDS_FOLDERS.CREATED;
        this.sortLabel = this.$t("repository.newFirst");
      } else {
        this.ordering = SORT_FIELDS_FOLDERS.CREATED_REVERSE;
        this.sortLabel = this.$t("repository.oldFirst");
      }

      this.fetchResouces();
    },
    handlePagination(page, pageSize) {
      this.offset = (page - 1) * pageSize;

      this.fetchResouces();
    },
  },
  watch: {
    folderID(val) {
      if (val) {
        this.page = 1;
        this.fetchResouces();
      }
    },
  },
};
</script>
