import APPS from "@/consts/apps";
import PERMISSIONS from "@/consts/permissions";
import Search from "@/views/Search/Search.vue";
import i18n from "@/i18n";

const t = i18n.global.t;

const search = [
  {
    path: "/search/",
    name: "Search",
    component: Search,
    meta: {
      title: `${t("meta.app")} - ${t("search.searching")}`,
      requiresAuth: true,
      permission: PERMISSIONS.SEARCH,
      app: APPS.SEARCH,
    },
  },
  {
    path: "/search/:searchId/",
    name: "SearchResult",
    component: Search,
    meta: {
      title: `${t("meta.app")} - ${t("search.searching")}`,
      requiresAuth: true,
      permission: PERMISSIONS.SEARCH,
      app: APPS.SEARCH,
    },
  },
];

export default search;
