
import "@/scss/Global.scss";

import { ConfigProvider } from "ant-design-vue";
import { RouteLocation } from "vue-router";
import Skeleton from "@/components/layout/Skeleton.vue";
import { defineComponent } from "vue";
import enGB from "ant-design-vue/es/locale/en_GB";
import plPL from "ant-design-vue/es/locale/pl_PL";

export default defineComponent({
  name: "App",
  data() {
    return {
      locale: this.initLang(),
    };
  },
  components: { ConfigProvider, Skeleton },
  methods: {
    setLanguage(lang: string) {
      if (lang) {
        this.locale = lang === "en" ? enGB : plPL;
      }
    },
    initLang() {
      const lang = localStorage.getItem("language");

      if (lang) {
        return lang === "en" ? enGB : plPL;
      } else {
        return plPL;
      }
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to: RouteLocation) {
        document.title = (to.meta.title as string) || "EOD";
      },
    },
  },
});
