<template>
  <div class="input-container">
    <label :for="id" class="label">
      {{ label }}
      <span
        v-if="required"
        class="color--error"
        :title="$t('app.fieldRequired')"
      >
        &nbsp;*
      </span>
    </label>
    <div class="input-wrap">
      <RadioGroup
        :id="id"
        v-model:value="cValue"
        :required="required"
        @invalid="onInvalid"
        :disabled="disabled"
        :form="form"
        @change="onChange"
      >
        <slot>
          <Radio v-for="option in options" :key="option.id" :value="option.id">
            {{ option.name }}
          </Radio>
        </slot>
      </RadioGroup>
      <slot name="tooltip" />
    </div>
    <span class="color--error" v-if="errors">
      {{ errors[0] }}
    </span>
  </div>
</template>

<script>
import { Radio } from "ant-design-vue";

export default {
  name: "EODRadio",
  props: {
    id: String,
    value: [String, Boolean, Number, Array],
    required: Boolean,
    options: Array,
    label: String,
    errors: Array,
    disabled: Boolean,
    form: String,
    onChange: {
      type: Function,
      default: () => {},
    },
  },
  components: {
    Radio,
    RadioGroup: Radio.Group,
  },
  computed: {
    cValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("update:value", newValue);
      },
    },
  },
  emits: ["update:value", "invalid"],
  methods: {
    onInvalid(event) {
      this.$emit("invalid", event);
    },
  },
};
</script>
