import apiClient from "@/config/apiClient";

const getMailboxList = (params) => {
  return apiClient.get("/api/mailboxes/", { params });
};

const createMailbox = (data) => {
  return apiClient.post("/api/mailboxes/", data);
};

const editMailbox = (data, id) => {
  return apiClient.put(`/api/mailboxes/${id}/`, data);
};

const getMailbox = (id) => {
  return apiClient.get(`/api/mailboxes/${id}/`);
};

const getMailboxMessageList = (id, params) => {
  return apiClient.get(`/api/mailboxes/${id}/messages/`, { params });
};

const getDeletedMailboxMessageList = (id, params) => {
  return apiClient.get(`/api/mailboxes/${id}/deleted/`, { params });
};

const deleteMailbox = (id) => {
  return apiClient.delete(`/api/mailboxes/${id}/`);
};

const getMailboxPerms = (id) => {
  return apiClient.get(`/api/mailboxes/${id}/assign-perm/`);
};

const editMailboxPerms = (data, id) => {
  return apiClient.put(`/api/mailboxes/${id}/assign-perm/`, data);
};

const getMailboxMessage = (name, id) => {
  return apiClient.get(`/api/mailboxes/${name}/messages/${id}/`);
};

const deleteMailboxMessage = (name, id) => {
  return apiClient.delete(`/api/mailboxes/${name}/messages/${id}/`);
};

const processMailboxMessage = (name, id) => {
  return apiClient.get(`/api/mailboxes/${name}/messages/${id}/process/`);
};

const getDeletedMailboxMessage = (name, id) => {
  return apiClient.get(`/api/mailboxes/${name}/deleted/${id}/`);
};

export {
  createMailbox,
  deleteMailbox,
  editMailbox,
  getDeletedMailboxMessage,
  getMailbox,
  getMailboxList,
  getMailboxMessageList,
  getDeletedMailboxMessageList,
  getMailboxPerms,
  editMailboxPerms,
  getMailboxMessage,
  deleteMailboxMessage,
  processMailboxMessage,
};
