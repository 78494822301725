/**
 * Returns date string of length 10 in ISO-8601 date format
 * @param {String | Date} fullDate Input date
 * @returns {String} Date string, ex. "2020-02-02"
 */
function getDate(fullDate: string | Date) {
  const date = new Date(fullDate);
  return `${date.getFullYear()}-${(
    "0" + (date.getMonth() + 1).toString()
  ).substr(-2)}-${("0" + date.getDate().toString()).substr(-2)}`;
}

export default getDate;
