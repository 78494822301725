<template>
  <Breadcrumbs
    :path="[
      { path: { name: 'Deployments' }, title: $t('modules.deployments') },
    ]"
    :current="`${title} ${$tc('deployments.deployment', 0)}`"
    iconComponent="SyncOutlined"
    :what="$tc('deployments.deployment', 0)"
  >
    <EODButton
      v-if="id && hasExportPermission"
      :name="$t('deployments.export')"
      @click="exportDeployment([id])"
    />
  </Breadcrumbs>

  <Tabs v-model:activeKey="tab" @change="$scrollToTop">
    <TabPane key="basic" :tab="$t('deployments.basicInformation')">
      <BasicInformation
        :id="id"
        v-model:deploymentData="data"
        :defaultForm="defaultForm"
        :defaultCategory="defaultCategory"
        v-model:tab="tab"
      />
    </TabPane>
    <TabPane key="configuration" :disabled="!id">
      <template v-slot:tab>
        <!-- TODO: debug tooltip -->
        <Tooltip v-if="!id" :title="$t('deployments.BPMNInfo')">
          {{ $t("deployments.deploymentConfiguration") }}
        </Tooltip>
        <template v-else>
          {{ $t("deployments.deploymentConfiguration") }}
        </template>
      </template>
      <ConfigureDeployment v-if="data.id" :id="id" :deployment="data" />
    </TabPane>
  </Tabs>
</template>

<script>
import { Breadcrumbs, EODButton } from "@/components/ui";
import { Tabs, Tooltip } from "ant-design-vue";
import { computed, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import BasicInformation from "./BasicInformation";
import ConfigureDeployment from "./ConfigureDeployment";
import { STATUS } from "@/consts/statuses";
import getDefaultValue from "@/helpers/getDefaultValue";
import { getDeployment } from "@/services/deployments";
import useExportDeployments from "@/composables/useExportDeployments";
import { useI18n } from "vue-i18n";

export default {
  name: "DeploymentsForm",
  props: { id: { type: Number, default: null } },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();

    const data = ref({
      name: "",
      version: "",
      camunda_key: "",
      status: STATUS.INACTIVE,
      description: "",
      bpmn: "",
      available_from: "",
      available_to: "",
      form: undefined,
      category: undefined,
    });
    const defaultForm = ref(null);
    const defaultCategory = ref(null);
    const tab = ref((+route.params?.id && route.query.tab) || "basic");

    const title = computed(() =>
      props.id ? t("app.editActivity") : t("app.addition"),
    );

    watch(tab, (val) => {
      router.replace({
        name: "DeploymentEdit",
        params: { id: props.id },
        query: { tab: val },
      });
    });

    return {
      data,
      defaultForm,
      defaultCategory,
      tab,
      title,
      ...useExportDeployments(),
    };
  },
  components: {
    BasicInformation,
    Breadcrumbs,
    ConfigureDeployment,
    EODButton,
    Tabs,
    Tooltip,
    TabPane: Tabs.TabPane,
  },
  beforeRouteEnter(to, _, next) {
    if (to.name === "DeploymentCreate") {
      next();
    } else {
      getDeployment(to.params.id)
        .then(({ data }) => {
          next((vm) => {
            vm.data = data;
            vm.defaultForm = getDefaultValue(data, "form");
            vm.defaultCategory = getDefaultValue(data, "category");
          });
        })
        .catch((err) => {
          if (err.response.status === 404) {
            next({ name: "404" });
          }
        });
    }
  },
};
</script>
