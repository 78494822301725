<template>
  <Breadcrumbs
    :path="[
      {
        path: { name: 'Archive', query: { tab: 'folders' } },
        title: $t('modules.archive'),
      },
    ]"
    :current="$t('archive.folders.meta.preview')"
    iconComponent="ContainerOutlined"
  >
    <GenerateFolderToFile :id="id" :preview="true" />
    <EODButtonLink
      :to="{ name: 'FoldersEdit', params: { id } }"
      :title="$t('app.edit_', [$tc('archive.folders.folder', 0)])"
      icon="EditOutlined"
    />
  </Breadcrumbs>

  <Preview
    :oid="id"
    :data="previewData"
    :title="$t('archive.folders.meta.preview')"
    :noPreviewMsg="$t('app.noPreview_', [$tc('archive.folders.folder', 1)])"
  />

  <FoldersContent v-if="id" v-model:activeKey="activeKey" :id="id" preview />
</template>

<script>
import { Breadcrumbs, EODButtonLink } from "@/components/ui";
import FoldersContent from "@/components/archive/FoldersContent";
import GenerateFolderToFile from "@/components/archive/GenerateFolderToFile";
import { Preview } from "@/components/common";
import { getFolder } from "@/services/archive";

export default {
  name: "FoldersPreview",
  props: { id: { type: String, required: true } },
  data() {
    return {
      previewData: [],
      activeKey: "issues",
    };
  },
  components: {
    Breadcrumbs,
    EODButtonLink,
    FoldersContent,
    Preview,
    GenerateFolderToFile,
  },
  beforeRouteEnter(to, _, next) {
    getFolder(to.params.id)
      .then(({ data }) => {
        next((vm) => {
          vm.previewData = [
            { title: vm.$t("issues.unit"), value: data.unit_display },
            { title: vm.$t("modules.jrwa"), value: data.jrwa_display },
            {
              title: vm.$t("archive.folders.category"),
              value: data.archive_category_display,
            },
            {
              title: vm.$t("archive.folders.actSymbol"),
              value: data.act_symbol,
            },
            { title: vm.$t("archive.folders.title"), value: data.title },
            {
              title: vm.$t("archive.folders.description"),
              value: data.description,
            },
            {
              title: vm.$t("archive.folders.yearFrom"),
              value:
                data.edge_date_from === null
                  ? ""
                  : new Date(data.edge_date_from).getFullYear(),
            },
            {
              title: vm.$t("archive.folders.yearTo"),
              value:
                data.edge_date_to === null
                  ? ""
                  : new Date(data.edge_date_to).getFullYear(),
            },
            {
              title: vm.$t("archive.folders.numberOfVolumes"),
              value: data.number_of_volumes,
            },
            {
              title: vm.$t("archive.folders.archiveSignature"),
              value: data.archive_signature,
            },
          ];
        });
      })
      .catch((err) => {
        if (err.response.status === 404) {
          next({ name: "404" });
        }
      });
  },
};
</script>
