import SYSTEM_DICT from "@/config/systemDict";
import apiClient from "@/config/apiClient";

const activateJRWA = (id) => {
  return apiClient.put(`/api/jrwa/${id}/activate/`);
};

const createJRWA = (data) => {
  return apiClient.post(`/api/jrwa/`, data);
};

const deleteJRWA = (id) => {
  return apiClient.delete(`/api/jrwa/${id}/`);
};

const deleteJRWAEntry = (id) => {
  return apiClient.delete(`/api/jrwa/entry/${id}/`);
};

const editJRWA = (data, id) => {
  return apiClient.put(`/api/jrwa/${id}/`, data);
};

const getJRWA = (id) => {
  return apiClient.get(`/api/jrwa/${id}/`);
};

const getJRWAList = (params) => {
  return apiClient.get("/api/jrwa/", { params });
};

const getJRWAEntriesList = (params) => {
  return apiClient.get("/api/jrwa/entry/", { params });
};

const getJRWAEntries = (id) => {
  return apiClient.get(`/api/jrwa/entry/${id}/`);
};

const generateJRWAPDF = (id) => {
  return apiClient.get(`/api/jrwa/${id}/report.pdf`, {
    responseType: "blob",
  });
};

const createJRWAEntriesList = (params) => {
  return apiClient.post("/api/jrwa/entry/", params);
};

const editJRWAEntriesList = (params, id) => {
  return apiClient.put(`/api/jrwa/entry/${id}/`, params);
};

const getArchiveCategoryList = (params) => {
  return apiClient.get(
    `/api/dicts/entry/keyword/${SYSTEM_DICT.JRWA_ARCHIVE_CATEGORY}/`,
    { params },
  );
};

const getJRWAEntriesKey = (parent) => {
  return apiClient.post("/api/jrwa/entry/get-key/", { parent });
};

const getJRWAEntriesSelectList = (params) => {
  return apiClient.get("/api/jrwa/entry/select/", { params });
};

export {
  activateJRWA,
  createJRWA,
  deleteJRWA,
  deleteJRWAEntry,
  editJRWA,
  getJRWA,
  getJRWAList,
  getJRWAEntriesList,
  getJRWAEntries,
  generateJRWAPDF,
  createJRWAEntriesList,
  editJRWAEntriesList,
  getArchiveCategoryList,
  getJRWAEntriesKey,
  getJRWAEntriesSelectList,
};
