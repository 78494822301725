
import {
  Pagination as AntPagination,
  Checkbox,
  Input,
  Modal,
  Tabs,
} from "ant-design-vue";
import { addDocumentsToFolder, addIssuesToFolder } from "@/services/archive";
import { computed, defineComponent, inject, ref, toRefs, watch } from "vue";
import { Document } from "@/interfaces/documents";
import { EODButton } from "@/components/ui";
import EODCards from "@/components/repository/EODCards.vue";
import { Issue } from "@/interfaces/issues";
import { MessageApi } from "ant-design-vue/lib/message";
import { Pagination } from "@/interfaces/archive";
import { getDocumentsList } from "@/services/documents";
import { getIssuesList } from "@/services/issues";
import router from "@/router";
import { store } from "@/store";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "CompleteFolderModal",
  props: {
    id: {
      type: Number,
      default: null,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    unit: {
      type: String,
      default: "",
    },
    jrwa: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const $message = inject<MessageApi>("$message");
    const { t } = useI18n();
    const { id, visible } = toRefs(props);

    const searchField = ref<string>("");
    const issues = ref<Issue[]>([]);
    const selectedIssues = ref<number[]>([]);
    const activeKey = ref<string>("issues");
    const defaultPagination = ref<Pagination>({
      total: 0,
      page: 1,
      limit: 10,
      offset: null,
      search_field: "",
    });
    const issuePagination = ref<Pagination>({ ...defaultPagination.value });
    const documents = ref<Document[]>([]);
    const selectedDocuments = ref<number[]>([]);
    const documentPagination = ref<Pagination>({
      ...defaultPagination.value,
    });
    const allItemsCount = ref<{ issues: number; documents: number }>({
      issues: 0,
      documents: 0,
    });
    const isLoading = ref<boolean>(false);

    const cVisible = computed({
      get: () => visible.value,
      set: (val) => emit("update:visible", val),
    });

    const checkedAllIssuesOnPage = computed(() =>
      issues.value.every((issue) => selectedIssues.value.includes(issue.id)),
    );

    const checkedAllDocumentsOnPage = computed(() =>
      documents.value.every((document) =>
        selectedDocuments.value.includes(document.id),
      ),
    );

    const checkedSomeIssuesOnPage = computed(() =>
      issues.value.some((issue) => selectedIssues.value.includes(issue.id)),
    );

    const checkedSomeDocumentsOnPage = computed(() =>
      documents.value.some((document) =>
        selectedDocuments.value.includes(document.id),
      ),
    );

    const shouldRefetch = computed(() => {
      return store.state.archive.shouldRefetch;
    });

    watch(shouldRefetch, (val) => {
      if (val) {
        fetchDocuments();
        fetchIssues();
        store.dispatch("resetRefetchEntries");
      }
    });

    const submit = async () => {
      isLoading.value = true;
      const gotoDestination =
        selectedDocuments.value.length > 0 && selectedIssues.value.length < 1
          ? "documents"
          : "issues";
      if (selectedIssues.value.length > 0) {
        await addIssuesToFolder(
          { issues: selectedIssues.value },
          id.value,
        ).then(() => {
          $message.success(t("app.success"));
          selectedIssues.value = [];
          documentPagination.value = defaultPagination.value;
          fetchIssues();
          store.dispatch("folderIssues/refreshTable");
        });
      }
      if (selectedDocuments.value.length > 0) {
        await addDocumentsToFolder(
          { documents: selectedDocuments.value },
          id.value,
        ).then(() => {
          $message.success(t("app.success"));
          selectedDocuments.value = [];
          documentPagination.value = defaultPagination.value;
          fetchDocuments();
          if (store.getters["folderDocuments/checkIsFetched"]) {
            store.dispatch("folderDocuments/refreshTable");
          }
        });
      }
      cVisible.value = false;
      isLoading.value = false;
      emit("update:activeKeyContent", gotoDestination);
      await router.replace({ hash: "#folders-content" });
    };

    const onCancel = () => {
      cVisible.value = false;
    };

    const fetchDocuments = () => {
      getDocumentsList({
        limit: documentPagination.value.limit,
        page: documentPagination.value.page,
        folder: id.value,
        search_field: documentPagination.value.search_field,
      }).then(({ data }) => {
        documents.value = data.results;
        documentPagination.value.total = data.count;
        if (documentPagination.value.search_field === "") {
          allItemsCount.value.documents = data.count;
        }
      });
    };

    const fetchIssues = () => {
      getIssuesList({
        limit: issuePagination.value.limit,
        page: issuePagination.value.page,
        folder: id.value,
        search_field: issuePagination.value.search_field,
      }).then(({ data }) => {
        issues.value = data.results.map((item: Issue) => ({
          ...item,
          type: "issue",
        }));
        issuePagination.value.total = data.count;
        if (issuePagination.value.search_field === "") {
          allItemsCount.value.issues = data.count;
        }
      });
    };

    const onChecked = (
      e: any,
      item: { id: number },
      selectedItemsArray: number[],
    ) => {
      if (e.target.checked) {
        selectedItemsArray.push(item.id);
      } else {
        selectedItemsArray.splice(selectedItemsArray.indexOf(item.id), 1);
      }
    };

    const onCheckAllIssues = (e: any) => {
      let issuesOnPage = issues.value.map((item) => item.id);
      if (e.target.checked) {
        selectedIssues.value = selectedIssues.value.concat(
          issuesOnPage.filter((item) => !selectedIssues.value.includes(item)),
        );
      } else {
        issuesOnPage.forEach((item) => {
          selectedIssues.value.splice(selectedIssues.value.indexOf(item), 1);
        });
      }
    };

    const onCheckAllDocuments = (e: any) => {
      const documentsOnPage = documents.value.map((item) => item.id);
      if (e.target.checked) {
        selectedDocuments.value = selectedDocuments.value.concat(
          documentsOnPage.filter(
            (item) => !selectedDocuments.value.includes(item),
          ),
        );
      } else {
        documentsOnPage.forEach((item) => {
          selectedDocuments.value.splice(
            selectedDocuments.value.indexOf(item),
            1,
          );
        });
      }
    };

    const handleIssuePagination = (page: number, pageSize: number) => {
      issuePagination.value.offset = (page - 1) * pageSize;
      fetchIssues();
    };

    const handleDocumentPagination = (page: number, pageSize: number) => {
      documentPagination.value.offset = (page - 1) * pageSize;
      fetchDocuments();
    };

    const handleSearch = () => {
      switch (activeKey.value) {
        case "issues": {
          if (issuePagination.value.search_field === searchField.value) {
            return;
          }
          issuePagination.value.search_field = searchField.value;
          issuePagination.value.limit = 10;
          issuePagination.value.page = 1;
          fetchIssues();
          break;
        }
        case "documents": {
          if (documentPagination.value.search_field === searchField.value) {
            return;
          }
          documentPagination.value.search_field = searchField.value;
          documentPagination.value.limit = 10;
          documentPagination.value.page = 1;
          fetchDocuments();
        }
      }
    };

    const changeTab = (activeTab: string) => {
      switch (activeTab) {
        case "issues": {
          searchField.value = issuePagination.value.search_field;
          break;
        }
        case "documents": {
          searchField.value = documentPagination.value.search_field;
        }
      }
    };

    fetchDocuments();
    fetchIssues();

    return {
      activeKey,
      allItemsCount,
      changeTab,
      checkedAllIssuesOnPage,
      checkedAllDocumentsOnPage,
      checkedSomeIssuesOnPage,
      checkedSomeDocumentsOnPage,
      cVisible,
      documents,
      documentPagination,
      fetchDocuments,
      fetchIssues,
      handleDocumentPagination,
      handleIssuePagination,
      handleSearch,
      isLoading,
      issues,
      issuePagination,
      onCancel,
      onChecked,
      onCheckAllIssues,
      onCheckAllDocuments,
      searchField,
      selectedDocuments,
      selectedIssues,
      submit,
    };
  },
  components: {
    Checkbox,
    EODButton,
    EODCards,
    Input,
    Modal,
    AntPagination,
    Tabs,
    TabPane: Tabs.TabPane,
  },
  emits: ["update:visible", "update:activeKeyContent"],
});
