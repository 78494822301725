<template>
  <Section :title="$t('contractors.contactData')">
    <BaseTable
      :columns="columns"
      :service="getContactsList"
      :customParams="{ contractor: contractorID }"
    >
      <template v-slot:button v-if="!preview">
        <EODButton
          v-if="hasPermissionToAdd"
          type="primary"
          :name="$t('contractors.addContact')"
          @click="openContactForm(null)"
          icon="PlusOutlined"
        />
      </template>
      <template #email="{ record }">
        <a
          v-if="record.email"
          :href="`mailto:${record.email}`"
          :title="$t('contractors.sendEmail')"
        >
          {{ record.email }}
        </a>
        <span v-else>-</span>
      </template>
      <template v-slot:actions="{ record }">
        <TableActions
          :record="record"
          :deleteMessage="$t('contractors.doYouWantToDeleteContact')"
          :modalTitle="$t('contractors.deleteContact')"
          :deleteService="deleteContact"
          @delete-success="onDeleteSuccess"
          :showPreview="preview"
          :showEdit="!preview"
          :showRemove="!preview"
        >
          <template v-slot:preview>
            <button
              v-if="record?.actions?.viewable"
              class="action"
              :title="$t('app.preview')"
              @click="openContactForm(record.id)"
            >
              <EyeOutlined />
            </button>
          </template>
          <template v-slot:edit>
            <button
              v-if="record?.actions?.editable"
              class="action"
              :title="$t('app.edit')"
              @click="openContactForm(record.id)"
            >
              <EditOutlined />
            </button>
          </template>
        </TableActions>
      </template>
    </BaseTable>
  </Section>
</template>

<script>
import { BaseTable, TableActions } from "@/components/table";
import { EODButton, Section } from "@/components/ui";
import { EditOutlined, EyeOutlined } from "@ant-design/icons-vue";
import { deleteContact, getContactsList } from "@/services/contractors";

import PERMISSIONS from "@/consts/permissions";

export default {
  name: "ContactsList",
  props: {
    contractorID: Number,
    preview: { type: Boolean, default: false },
    showContactForm: Boolean,
  },
  data() {
    return {
      columns: [
        {
          title: this.$t("contractors.firstName"),
          dataIndex: "first_name",
          sorter: true,
          ellipsis: true,
        },
        {
          title: this.$t("contractors.lastName"),
          dataIndex: "last_name",
          sorter: true,
          ellipsis: true,
        },
        {
          title: this.$t("contractors.email"),
          dataIndex: "email",
          sorter: true,
          ellipsis: true,
          slots: { customRender: "email" },
        },
        {
          title: this.$t("contractors.phone"),
          dataIndex: "phone",
          sorter: true,
          ellipsis: true,
        },
        {
          title: this.$t("app.actions"),
          dataIndex: "actions",
          slots: { customRender: "actions" },
          fixed: "right",
          width: "100",
        },
      ],
    };
  },
  components: {
    BaseTable,
    EODButton,
    EditOutlined,
    EyeOutlined,
    Section,
    TableActions,
  },
  computed: {
    hasPermissionToAdd() {
      return this.$permissions.includes(
        PERMISSIONS.CONTRACTORS_CONTACT_DATA_ADD,
      );
    },
  },
  methods: {
    deleteContact,
    getContactsList,
    async openContactForm(id) {
      await this.$emit("update:showContactForm", true);
      this.$store.dispatch("setContactID", id);
      await this.$router.replace({ hash: "#contact-form" });
    },
    onDeleteSuccess() {
      this.$store.dispatch("setContactID", undefined);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/Contractors";
</style>
