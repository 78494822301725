import { AxiosResponse } from "axios";
import i18n from "@/i18n";
import { message } from "ant-design-vue";
import router from "@/router";

const t = i18n.global.t;

type CustomErrorHandler = (
  response: AxiosResponse,
  errorMessage?: string,
) => string;

export default function errorHandler(
  response: AxiosResponse,
  customErrorHandler?: CustomErrorHandler,
) {
  let errorMessage = "";

  switch (response.status) {
    case 400: {
      const errors = response.data;
      const msg =
        (typeof errors === "string" ? errors : null) ||
        (Array.isArray(errors)
          ? typeof errors[0] === "string"
            ? errors
            : null
          : null);
      errorMessage = errors.non_field_errors || msg;
      break;
    }
    case 403: {
      router.push({ name: "403" });
      errorMessage = response.data.detail || response.data;
      break;
    }
    case 404: {
      errorMessage = response.data.detail;
      break;
    }
    default: {
      break;
    }
  }

  if (customErrorHandler) {
    errorMessage = customErrorHandler(response, errorMessage);
  }

  if (response.status !== 401) {
    message.error(errorMessage || t("app.error"));
  }
}
