<template>
  <Section :title="$t('dictionaries.entries.entries')">
    <BaseTable
      :columns="columns"
      :service="getDictionaryEntries"
      :customParams="{ dictionary: dictID }"
      :customStore="customStore"
      v-model:expandedRowKeys="expandedRowKeys"
    >
      <template v-slot:button v-if="!preview">
        <EODButton
          v-if="hasDictEntriesAddPermission"
          type="primary"
          :name="$t('dictionaries.entries.add')"
          @click="addEntry"
          icon="PlusOutlined"
        />
      </template>
      <template v-slot:entry_type="{ record }">
        {{ record.entry_type_display }}
      </template>
      <template v-slot:additional_value="{ record }">
        {{ record.additional_value || "-" }}
      </template>
      <template v-slot:status="{ record }">
        <StatusDot :status="record.status" />{{ record.status_display }}
      </template>
      <template v-slot:actions="{ record }">
        <TableActions
          :record="record"
          :deleteMessage="
            $tc('dictionaries.entries.deleteInfo_', [record.name])
          "
          :modalTitle="$t('dictionaries.entries.delete')"
          :deleteService="deleteDictionaryEntry"
          @delete-success="onDeleteSuccess"
          :showPreview="preview"
          :showEdit="!preview"
          :showRemove="!preview"
          :customStore="customStore"
        >
          <template v-slot:preview>
            <button
              v-if="record?.actions?.viewable"
              class="action"
              :title="$t('app.preview')"
              @click="setEntryID(record)"
            >
              <EyeOutlined />
            </button>
          </template>
          <template v-slot:edit>
            <button
              v-if="record?.actions?.editable"
              class="action"
              :title="$t('app.edit')"
              @click="setEntryID(record)"
            >
              <EditOutlined />
            </button>
          </template>
        </TableActions>
      </template>
    </BaseTable>
  </Section>
</template>

<script>
import { BaseTable, StatusDot, TableActions } from "@/components/table";
import { EODButton, Section } from "@/components/ui";
import { EditOutlined, EyeOutlined } from "@ant-design/icons-vue";
import {
  deleteDictionaryEntry,
  getDictionaryEntries,
} from "@/services/dictionaries";

import PERMISSIONS from "@/consts/permissions";
import dynamicTable from "@/store/modules/dynamicTable";

export default {
  name: "DictionaryEntries",
  props: {
    dictID: Number,
    preview: { type: Boolean, default: false },
    customStore: String,
  },
  data() {
    return {
      expandedRowKeys: [],
      columns: [
        { title: this.$t("app.name"), dataIndex: "name", sorter: true },
        {
          title: this.$t("dictionaries.entries.entryType"),
          dataIndex: "entry_type",
          sorter: true,
          slots: { customRender: "entry_type" },
        },
        {
          title: this.$t("app.additionalValue"),
          dataIndex: "additional_value",
          sorter: true,
          slots: { customRender: "additional_value" },
        },
        {
          title: this.$t("app.status"),
          dataIndex: "status",
          sorter: true,
          slots: { customRender: "status" },
        },
        {
          title: this.$t("app.actions"),
          dataIndex: "actions",
          slots: { customRender: "actions" },
          fixed: "right",
          width: "100",
        },
      ],
    };
  },
  components: {
    BaseTable,
    EODButton,
    EditOutlined,
    EyeOutlined,
    Section,
    StatusDot,
    TableActions,
  },
  computed: {
    hasDictEntriesAddPermission() {
      return this.$permissions.includes(PERMISSIONS.DICTIONARIES_ENTRY_ADD);
    },
  },
  created() {
    if (this.customStore && !this.$store.hasModule(this.customStore)) {
      this.$store.registerModule(this.customStore, dynamicTable);
    }
  },
  methods: {
    deleteDictionaryEntry,
    getDictionaryEntries,
    setEntryID(record) {
      this.$store.dispatch("setEntryID", record.id);
      this.$router.replace({ hash: "#entry" });
    },
    addEntry() {
      this.$store.dispatch("setEntryID", undefined);
      this.$router.replace({ hash: "#entry" });
    },
    onDeleteSuccess() {
      this.$store.dispatch("setEntryID", undefined);
      this.$store.dispatch("refetchEntries");
    },
  },
};
</script>
