const dynamicTable = {
  namespaced: true,
  state: () => ({
    shouldRefresh: false,
    data: null,
    params: null,
    isFetched: false,
  }),
  getters: {
    checkIsFetched: (state) => state.isFetched,
    shouldRefresh: (state) => state.shouldRefresh,
    getData(state) {
      return state.data;
    },
  },
  mutations: {
    REFRESH(state) {
      state.shouldRefresh = true;
    },
    RESET_REFRESH(state) {
      state.shouldRefresh = false;
    },
    SET_DATA: (state, { data, params }) => {
      state.data = data;
      state.params = params;
    },
    SET_IS_FETCH(state) {
      state.isFetched = true;
    },
  },
  actions: {
    refreshTable({ commit }) {
      commit("REFRESH");
    },
    resetRefreshTable({ commit }) {
      commit("RESET_REFRESH");
    },
    setData({ commit }, { data, params }) {
      commit("SET_DATA", { data, params });
    },
    setIsFetch({ commit }) {
      commit("SET_IS_FETCH");
    },
  },
};

export default dynamicTable;
