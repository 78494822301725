
import {
  AuditOutlined,
  BulbOutlined,
  CheckCircleOutlined,
  MessageOutlined,
} from "@ant-design/icons-vue";
import { defineComponent, ref } from "vue";

import { Tag } from "ant-design-vue";

export default defineComponent({
  name: "TypeTag",
  props: {
    commentType: { type: Number, required: true },
    commentTypeDisplay: { type: String, required: true },
  },
  setup() {
    const colorsMap = ref({
      "1": "default",
      "2": "orange",
      "3": "blue",
      "4": "purple",
    });
    const iconsMap = ref({
      "1": "MessageOutlined",
      "2": "BulbOutlined",
      "3": "AuditOutlined",
      "4": "CheckCircleOutlined",
    });

    return { colorsMap, iconsMap };
  },
  components: {
    AuditOutlined,
    BulbOutlined,
    MessageOutlined,
    CheckCircleOutlined,
    Tag,
  },
});
