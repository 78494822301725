import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "drawer-container" }
const _hoisted_2 = { class: "drawer-content" }
const _hoisted_3 = { class: "actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EODButton = _resolveComponent("EODButton")
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")
  const _component_Preview = _resolveComponent("Preview")
  const _component_EyeOutlined = _resolveComponent("EyeOutlined")
  const _component_DownloadOutlined = _resolveComponent("DownloadOutlined")
  const _component_Table = _resolveComponent("Table")
  const _component_Section = _resolveComponent("Section")
  const _component_FileViewer = _resolveComponent("FileViewer")
  const _component_EODDrawer = _resolveComponent("EODDrawer")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_Breadcrumbs, {
      path: [{ path: { name: 'Mailboxes' }, title: _ctx.$t('modules.mailboxes') }],
      current: _ctx.$t('mailboxes.meta.messagePreview'),
      iconComponent: "MailOutlined"
    }, {
      default: _withCtx(() => [
        (!_ctx.deleted)
          ? (_openBlock(), _createBlock(_component_EODButton, {
              key: 0,
              name: _ctx.$t('mailboxes.messages.createDocument'),
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleConfirm(_ctx.mailboxId, _ctx.id, true))),
              icon: "FileTextOutlined"
            }, null, 8, ["name"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["path", "current"]),
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode(_component_Preview, {
          oid: _ctx.id,
          data: _ctx.previewData,
          title: _ctx.$tc('mailboxes.messages.message', 1),
          noPreviewMsg: _ctx.$t('mailboxes.messages.noPreview')
        }, null, 8, ["oid", "data", "title", "noPreviewMsg"]),
        (_ctx.attachmentsData.length > 0)
          ? (_openBlock(), _createBlock(_component_Section, {
              key: 0,
              title: _ctx.$t('app.attachments'),
              collapsable: true,
              collapsed: _ctx.collapseAttachments,
              "onUpdate:collapsed": _cache[2] || (_cache[2] = ($event: any) => (_ctx.collapseAttachments = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Table, {
                  columns: _ctx.attachmentColumns,
                  "data-source": _ctx.attachmentsData
                }, {
                  description: _withCtx(({ record }) => [
                    _createTextVNode(_toDisplayString(record.description || " - "), 1)
                  ]),
                  actions: _withCtx(({ record }) => [
                    _createVNode("div", _hoisted_3, [
                      (/(pdf)$/.test(record.filename))
                        ? (_openBlock(), _createBlock("button", {
                            key: 0,
                            class: "action",
                            onClick: ($event: any) => (_ctx.handlePreview(_ctx.downloadFileURL(record.id))),
                            title: _ctx.$t('app.preview')
                          }, [
                            _createVNode(_component_EyeOutlined)
                          ], 8, ["onClick", "title"]))
                        : _createCommentVNode("", true),
                      _createVNode("a", {
                        class: "action",
                        href: _ctx.downloadFileURL(record.id),
                        title: _ctx.$t('app.downloadFile')
                      }, [
                        _createVNode(_component_DownloadOutlined)
                      ], 8, ["href", "title"])
                    ])
                  ]),
                  _: 1
                }, 8, ["columns", "data-source"])
              ]),
              _: 1
            }, 8, ["title", "collapsed"]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_EODDrawer, {
        visible: _ctx.showPreview,
        handle: _ctx.handleDrawer
      }, {
        default: _withCtx(() => [
          (_ctx.renderPdf)
            ? (_openBlock(), _createBlock(_component_FileViewer, {
                key: 0,
                previewFile: _ctx.previewFile
              }, null, 8, ["previewFile"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["visible", "handle"])
    ])
  ], 64))
}