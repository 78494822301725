import APPS from "@/consts/apps";
import PERMISSIONS from "@/consts/permissions";
import Users from "@/views/Permissions/Users.vue";
import UsersForm from "@/views/Permissions/UsersForm.vue";
import UsersPreview from "@/views/Permissions/UsersPreview.vue";

const users = [
  {
    path: "/users/",
    name: "Users",
    component: Users,
    meta: {
      title: "EOD - Użytkownicy",
      requiresAuth: true,
      permission: PERMISSIONS.USERS,
      app: APPS.USERS,
    },
  },
  {
    path: "/users/:id/edit/",
    name: "UsersEdit",
    component: UsersForm,
    meta: {
      title: "EOD - Edycja użytkownika",
      requiresAuth: true,
      permission: PERMISSIONS.USERS_EDIT,
      app: APPS.USERS,
    },
  },
  {
    path: "/users/:id/preview/",
    name: "UsersPreview",
    component: UsersPreview,
    meta: {
      title: "EOD - Podgląd użytkownika",
      requiresAuth: true,
      permission: PERMISSIONS.USERS,
      app: APPS.USERS,
    },
  },
];

export default users;
