import { ref } from "vue";

export default function useDrawerHandler() {
  const previewFile = ref("");
  const showPreview = ref(false);
  const renderPdf = ref(false);

  const handlePreview = (fileURL: string) => {
    previewFile.value = fileURL;
    showPreview.value = true;
    setTimeout(() => {
      renderPdf.value = true;
    }, 400);
  };
  const handleDrawer = () => {
    showPreview.value = !showPreview.value;
    if (showPreview.value) {
      setTimeout(() => {
        renderPdf.value = true;
      }, 400);
    } else {
      renderPdf.value = false;
    }
  };

  return { handleDrawer, handlePreview, previewFile, renderPdf, showPreview };
}
