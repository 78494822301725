<template>
  <Modal
    v-model:visible="cVisible"
    :title="
      repositoryID
        ? $t('repository.folderTitleEdit')
        : $t('repository.folderTitleAdd')
    "
    :okText="
      repositoryID ? $t('app.saveChanges') : $t('repository.createFolder')
    "
    :cancelText="$t('app.cancel')"
    :closable="false"
    @ok="submit"
    @cancel="onCancel"
    destroyOnClose
  >
    <div class="form-container">
      <form id="folder-form" @submit.prevent="submit" class="form">
        <EODInput
          required
          id="name"
          v-model:value="data.name"
          :label="$t('repository.name')"
          :props="{ maxlength: 250 }"
          :errors="errors.name"
        />

        <h3>
          {{ $t("repository.isPublicTitle") }}
        </h3>

        <Checkbox v-model:checked="data.is_public">
          {{ $t("repository.isPublic") }}
        </Checkbox>

        <EODSelect
          id="units"
          storeID="units"
          v-model:value="data.units"
          :label="$t('issues.unit')"
          :service="fetchUnits"
          :defaultValue="defaultValues.units"
          :errors="errors.units"
          mode="multiple"
        />

        <EODSelect
          id="teams"
          storeID="teams"
          v-model:value="data.teams"
          :label="$t('modules.teams')"
          :service="fetchTeams"
          :defaultValue="defaultValues.teams"
          :errors="errors.teams"
          mode="multiple"
        />

        <EODSelect
          id="users"
          v-model:value="data.users"
          :label="$t('app.user')"
          :service="fetchUsers"
          :defaultValue="defaultValues.users"
          :errors="errors.user"
          mode="multiple"
        />
      </form>
    </div>
  </Modal>
</template>

<script>
import { EODInput, EODSelect } from "@/components/inputs";
import { createFolder, editFolder, getFolder } from "@/services/repository";
import {
  getGroupsSimpleList,
  getUsersSimpleList,
} from "@/services/permissions";

import { Checkbox } from "ant-design-vue";
import { Modal } from "ant-design-vue";
import { getFlatUnits } from "@/services/repository";

export default {
  name: "FolderForm",
  props: {
    repositoryID: {
      type: Number,
      default: null,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      data: {
        name: null,
        users: [],
        teams: [],
        units: [],
        is_public: false,
      },
      defaultValues: {
        users: null,
        teams: null,
        units: null,
      },
      errors: {},
    };
  },
  components: {
    Modal,
    Checkbox,
    EODInput,
    EODSelect,
  },
  computed: {
    cVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
  },
  emits: ["update:visible", "update:repositoryID"],
  methods: {
    onCancel() {
      this.$emit("update:repositoryID", null);
      this.$emit("update:visible", false);
      this.resetData();
    },
    resetData() {
      this.data = {
        name: null,
        users: [],
        teams: [],
        units: [],
        is_public: false,
      };
      this.errors = {};
    },
    submit() {
      this.loading$ = true;
      const service = this.repositoryID ? editFolder : createFolder;

      service(this.data, this.repositoryID)
        .then(() => {
          this.$message.success(this.$t("app.success"));
          this.$emit("update:visible", false);
          this.$emit("update:repositoryID", null);
          this.$store.dispatch("repository/refetch", { root: true });
          this.resetData();
        })
        .catch((err) => {
          this.errors = err.response.data;

          this.$store.dispatch("repository/resetFolder");
        })
        .finally(() => {
          this.loading$ = false;
        });
    },
    fetchTeams(params) {
      return getGroupsSimpleList(params).then(({ data }) => data);
    },
    fetchUnits(params) {
      return getFlatUnits(params).then(({ data }) => data);
    },
    fetchUsers(params) {
      return getUsersSimpleList(params).then(({ data }) => ({
        ...data,
        results: data.results
          .filter((elem) => elem.id !== this.owner?.id)
          .map((elem) => ({
            id: elem.id,
            name: elem.full_name,
            title: elem.email,
          })),
      }));
    },
  },
  watch: {
    repositoryID(val) {
      if (val) {
        getFolder(val).then(({ data }) => {
          this.data = data;

          data.users = data.users.map((item) => ({
            ...item,
            name: item.full_name,
          }));

          for (const key of ["units", "teams", "users"]) {
            this.defaultValues[key] = data[key];
            this.data[key] = data[key].map((elem) => elem.id);
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  align-items: flex-start;
}
</style>
