import APPS from "@/consts/apps";
import Dictionaries from "@/views/Dictionaries/Dictionaries.vue";
import DictionariesForm from "@/views/Dictionaries/DictionariesForm.vue";
import DictionariesPreview from "@/views/Dictionaries/DictionariesPreview.vue";
import PERMISSIONS from "@/consts/permissions";

const dictionaries = [
  {
    path: "/dictionaries/",
    name: "Dictionaries",
    component: Dictionaries,
    meta: {
      title: "EOD - Lista słowników",
      requiresAuth: true,
      permission: PERMISSIONS.DICTIONARIES,
      app: APPS.DICTS,
    },
  },
  {
    path: "/dictionaries/create/",
    name: "DictionariesCreate",
    component: DictionariesForm,
    meta: {
      title: "EOD - Dodawanie słownika",
      requiresAuth: true,
      permission: PERMISSIONS.DICTIONARIES_ADD,
      app: APPS.DICTS,
    },
  },
  {
    path: "/dictionaries/:id/edit/",
    name: "DictionariesEdit",
    component: DictionariesForm,
    meta: {
      title: "EOD - Edycja słownika",
      requiresAuth: true,
      permission: PERMISSIONS.DICTIONARIES_EDIT,
      app: APPS.DICTS,
    },
  },
  {
    path: "/dictionaries/:id/preview/",
    name: "DictionariesPreview",
    component: DictionariesPreview,
    meta: {
      title: "EOD - Podgląd słownika",
      requiresAuth: true,
      permission: PERMISSIONS.DICTIONARIES,
      app: APPS.DICTS,
    },
  },
];

export default dictionaries;
