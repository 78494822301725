<template>
  <Preview
    :oid="entryID"
    :data="previewData"
    :title="`${$t('app.preview')}: ${title}`"
    :noPreviewMsg="$t('dictionaries.entries.noPreview')"
  />
</template>

<script>
import { Preview } from "@/components/common";
import { getDictionaryEntry } from "@/services/dictionaries";

export default {
  name: "DictionaryEntriesPreview",
  data() {
    return {
      previewData: [],
      title: "",
    };
  },
  components: { Preview },
  computed: {
    entryID() {
      return this.$store.state.dictionaries.entryID;
    },
  },
  methods: {
    fetchEntry() {
      getDictionaryEntry(this.entryID).then(({ data }) => {
        this.title = data.name;
        this.previewData = [
          { title: this.$t("app.name"), value: data.name },
          {
            title: this.$t("dictionaries.entries.entryType"),
            value: data.entry_type_display,
          },
          { title: this.$t("app.value"), value: data.value || "-" },
          {
            title: this.$t("app.status"),
            value: data.status_display,
          },
          {
            title: this.$t("app.description"),
            value: data.description || "-",
          },
          {
            title: this.$t("dictionaries.entries.dictionary"),
            value: data.dictionary_display,
          },
          {
            title: this.$t("dictionaries.entries.parent"),
            value: data.parent_display || "-",
          },
        ];
      });
    },
  },
  mounted() {
    if (this.entryID) {
      this.fetchEntry();
    }
  },
  watch: {
    entryID() {
      this.fetchEntry();
    },
  },
};
</script>
