<template>
  <Alert
    class="ml-1 mr-1 mt-2"
    v-if="spinning"
    :message="$t('jrwa.importProgress')"
    type="info"
    showIcon
  />

  <Spin :spinning="spinning" size="large">
    <Section :title="title">
      <div class="form-container">
        <form @submit.prevent="submit" class="form">
          <EODRadio
            v-if="!dictID"
            id="source"
            required
            :label="$t('app.type')"
            v-model:value="data.source"
            :options="sourceOptions"
            :errors="errors.source"
          />

          <FileUploader
            :maxFiles="1"
            v-if="!dictID && data.source === 'imported'"
            :fileURL="attachmentURL"
            v-model:fileList="attachments"
            :customColumns="attachmentsColumns"
            requiredFormat
          >
            <template v-slot:filePreview />
          </FileUploader>

          <EODInput
            id="name"
            v-model:value="data.name"
            :label="$t('app.name')"
            required
            :maxlength="250"
            :errors="errors.name"
            :disabled="data.system"
          />

          <EODRadio
            id="status"
            v-model:value="data.status"
            required
            :errors="errors.status"
            :label="$t('app.status')"
            :options="statusOptions"
            :disabled="data.system"
          />

          <EODInput
            id="description"
            v-model:value="data.description"
            :label="$t('app.description')"
            :maxlength="500"
            type="textarea"
            :errors="errors.description"
            :disabled="data.system"
          />

          <div class="buttons">
            <Button
              htmlType="submit"
              :type="dictID ? 'primary' : 'default'"
              :title="buttonTitle"
              :loading="loading$"
            >
              {{ buttonTitle }}
            </Button>
            <Button
              v-if="!dictID"
              type="primary"
              :title="$t('app.continue')"
              @click="setGoToAddEntry"
              :loading="loading$"
            >
              {{ $t("app.continue") }}
            </Button>
          </div>
        </form>
      </div>
    </Section>
  </Spin>
</template>

<script>
import { Alert, Button, Spin } from "ant-design-vue";
import { EODInput, EODRadio, FileUploader } from "@/components/inputs";
import {
  createDictionary,
  editDictionary,
  getDictionary,
  getDictionaryStatuses,
} from "@/services/dictionaries";

import { STATUS } from "@/consts/statuses";
import { Section } from "@/components/ui";

export default {
  name: "DictionariesFormComponent",
  props: { dictID: Number },
  data() {
    return {
      statusOptions: [],
      data: {
        name: "",
        status: STATUS.INACTIVE,
        description: "",
        source: "new",
      },
      errors: {},
      goToAddEntry: false,
      attachments: [],
      attachmentURL: "/api/dicts/attachments/",
      attachmentsColumns: [
        {
          title: this.$t("app.name"),
          dataIndex: "filename",
          width: "35%",
        },
        {
          title: this.$t("app.created"),
          dataIndex: "created_date",
          width: "30%",
        },
        {
          title: this.$t("app.actions"),
          dataIndex: "actions",
          slots: { customRender: "actions" },
          width: "20%",
        },
      ],
      sourceOptions: [
        { id: "new", name: this.$t("jrwa.typeOptions.new") },
        { id: "imported", name: this.$t("jrwa.typeOptions.import") },
      ],
      spinning: false,
    };
  },
  components: {
    Alert,
    Button,
    EODInput,
    EODRadio,
    FileUploader,
    Section,
    Spin,
  },
  computed: {
    buttonTitle() {
      return this.dictID
        ? this.$t("app.saveChanges")
        : this.$t("app.saveAndLeave");
    },
    title() {
      return this.dictID
        ? this.$t("dictionaries.info")
        : this.$t("dictionaries.add");
    },
    importStatus() {
      return this.$store.state.notifications.importStatus;
    },
  },
  created() {
    getDictionaryStatuses().then(({ data }) => {
      this.statusOptions = Object.entries(data).map(([key, value]) => ({
        name: value,
        id: +key,
      }));
    });

    this.fetchDictionary();
  },
  emits: ["dict-name", "dict-id"],
  methods: {
    fetchDictionary() {
      if (this.dictID) {
        getDictionary(this.dictID).then(({ data }) => {
          this.data = data;
          this.$emit("dict-name", data.name);
        });
      }
    },
    submit() {
      this.loading$ = true;

      const service = this.dictID ? editDictionary : createDictionary;

      const form = { ...this.data };
      if (!this.dictID && this.attachments.length > 0) {
        form.attachment = this.attachments[0].id;
      }

      service(form, this.dictID)
        .then(({ data }) => {
          if (!this.dictID) {
            if (this.goToAddEntry) {
              this.$emit("dict-id", data.id);
              this.goToAddEntry = false;

              if (form.source === "imported") {
                this.$store.dispatch("notifications/setImportStatus", "");
                this.spinning = true;
              } else {
                this.$store.dispatch("showEntry");
                this.$router.replace({
                  name: "DictionariesEdit",
                  params: { id: data.id },
                  hash: "#entry",
                });
              }
            } else {
              this.$router.push({ name: "Dictionaries" });
            }
          }
          this.$message.success(
            form.source === "imported"
              ? this.$t("dictionaries.importInfo")
              : this.$t("app.success"),
          );
        })
        .catch((err) => {
          this.errors = err.response.data;
        })
        .finally(() => {
          this.loading$ = false;
        });
    },
    setGoToAddEntry() {
      this.goToAddEntry = true;
      this.submit();
    },
  },
  watch: {
    "data.name"() {
      this.errors.name = [];
    },
    importStatus(val) {
      if (val === "success") {
        this.spinning = false;
        this.$store.dispatch("showEntry");
        this.$router.replace({
          name: "DictionariesEdit",
          params: { id: this.dictID },
          hash: "#entry",
        });
      } else if (val === "error") {
        this.spinning = false;
        this.$emit("dict-id", null);
        this.$message.error(this.$t("dictionaries.importError"));
      }
    },
  },
};
</script>
