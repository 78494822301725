<template>
  <!-- TODO: current has to be capitalized in EN version -->
  <Breadcrumbs
    :path="[{ path: { name: 'Numeration' }, title: $t('modules.numeration') }]"
    :current="$t('app.preview_', [$tc('numeration.numeration', 1)])"
    iconComponent="OrderedListOutlined"
  >
    <EODButtonLink
      v-if="$permissions.includes('counters.change_counter_custom')"
      :to="{ name: 'NumerationEdit', params: { id } }"
      :title="$t('app.edit', [$tc('numeration.numeration', 2)])"
      icon="EditOutlined"
    />
  </Breadcrumbs>

  <Preview
    :oid="id"
    :data="previewData"
    :title="$t('app.preview_', [$tc('numeration.numeration', 1)])"
    :noPreviewMsg="$t('app.noPreview_', [$tc('numeration.numeration', 1)])"
  />

  <NumerationUnits v-if="id && isUnitCounter" :id="id" />
</template>

<script>
import { Breadcrumbs, EODButtonLink } from "@/components/ui";

import NumerationUnits from "./NumerationUnits.vue";
import { Preview } from "@/components/common";
import { getNumeration } from "@/services/numeration";

export default {
  name: "NumerationPreview",
  props: { id: { type: String, required: true } },
  data() {
    return {
      previewData: [],
      isUnitCounter: false,
    };
  },
  components: { Breadcrumbs, EODButtonLink, NumerationUnits, Preview },
  beforeRouteEnter(to, _, next) {
    getNumeration(to.params.id)
      .then(({ data }) => {
        next((vm) => {
          vm.isUnitCounter = data.is_unit_counter;

          vm.previewData = [
            { title: vm.$t("app.name"), value: data.name },
            {
              title: vm.$t("app.status"),
              value: data.status_display,
            },
            { title: vm.$t("numeration.format"), value: data.text_format },
            {
              title: vm.$t("numeration.formatPreview"),
              value: data.counter_text_value,
            },
            {
              title: vm.$t("numeration.counter"),
              value: data.is_unit_counter ? "-" : data.next_free_value,
            },
            {
              title: vm.$t("numeration.inUse"),
              value: data.in_use ? vm.$t("app.yes") : vm.$t("app.no"),
            },
          ];
        });
      })
      .catch((err) => {
        if (err.response.status === 404) {
          next({ name: "404" });
        }
      });
  },
};
</script>
