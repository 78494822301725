<template>
  <Breadcrumbs
    :path="[
      { path: { name: 'JRWA' }, title: $t('modules.jrwa') },
      {
        path: { name: 'JRWAPreview', params: { id: jrwa_register } },
        title: $t('jrwa.meta.preview'),
      },
    ]"
    :current="$t('jrwa.meta.previewEntry')"
    iconComponent="SortAscendingOutlined"
  >
    <EODButtonLink
      :to="{
        name: 'JRWAKeyEdit',
        params: { id: id, versionID: jrwa_register },
      }"
      :title="$t('jrwa.meta.edit')"
      icon="EditOutlined"
      v-if="actions?.editable"
    />
  </Breadcrumbs>

  <Preview
    :oid="id"
    :data="previewData"
    :title="$t('jrwa.meta.previewEntry')"
    :noPreviewMsg="$t('jrwa.noEntryPreview')"
  />
</template>

<script>
import { Breadcrumbs, EODButtonLink } from "@/components/ui";

import { Preview } from "@/components/common";
import { getJRWAEntries } from "@/services/jrwa";

export default {
  name: "JRWAPreview",
  data() {
    return {
      id: +this.$route.params?.id,
      jrwa_register: +this.$route.params?.versionID,
      previewData: [],
      actions: null,
    };
  },
  components: {
    Breadcrumbs,
    EODButtonLink,
    Preview,
  },
  created() {
    if (this.id) {
      this.fetchJRWA();
    }
  },
  methods: {
    fetchJRWA() {
      getJRWAEntries(this.id).then(({ data }) => {
        this.actions = data.actions;
        this.previewData = [
          { title: this.$t("jrwa.symbol"), value: data.key },
          { title: this.$tc("jrwa.entry"), value: data.name },
          {
            title: this.$t("jrwa.entriesForm.archiveCategoryMark"),
            value: data.archive_category_display,
          },
          { title: this.$t("jrwa.comments"), value: data.comments },
        ];
      });
    },
  },
};
</script>
