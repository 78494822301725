<template>
  <Modal
    v-model:visible="isOpen"
    :title="$t('numeration.barcodes.printing')"
    @ok="submit"
    :okButtonProps="{ loading: loading$ }"
  >
    <div class="form-container">
      <form @submit.prevent="submit" class="form">
        <EODSelect
          id="barcode_type"
          :label="$t('numeration.barcodes.type')"
          v-model:value="data.barcode_type"
          :options="barcodeTypeOptions"
          required
          :errors="errors.barcode_type"
        />

        <EODSelect
          id="barcode_paper_format"
          :label="$t('numeration.barcodes.paper_format')"
          v-model:value="data.paper_format"
          :options="paperFormatOptions"
          required
          :errors="errors.barcode_type"
        />

        <EODInput
          v-if="printOptions"
          id="counter_from"
          v-model:value="data.counter_from"
          :label="$t('numeration.barcodes.from')"
          type="number"
          required
          :min="1"
          :errors="errors.counter_from"
        />

        <EODInput
          v-if="printOptions"
          id="count"
          type="number"
          v-model:value="count"
          :label="$t('numeration.barcodes.count')"
          :min="1"
          required
          :errors="errors.count"
        />

        <EODInput
          v-if="printOptions"
          id="counter_to"
          v-model:value="data.counter_to"
          :label="$t('numeration.barcodes.to')"
          type="number"
          required
          :min="1"
          disabled
          :errors="errors.counter_to"
        />
      </form>
    </div>
  </Modal>
</template>

<script>
import { BARCODE_PAPER_FORMAT, BARCODE_TYPE } from "@/consts/statuses";
import { EODInput, EODSelect } from "@/components/inputs";
import {
  getBarcodeGenerate,
  getBarcodePaperFormat,
  getBarcodeTypes,
  getBarcodes,
} from "@/services/numeration";
import { Modal } from "ant-design-vue";

export default {
  name: "Barcodes",
  props: {
    visible: { type: Boolean, required: true },
    record: { type: Object, required: true },
    printOptions: { type: Boolean, default: false },
  },
  data() {
    return {
      data: {
        barcode_type: BARCODE_TYPE.CODE128,
        counter_from: 1,
        counter_to: 1,
        paper_format: BARCODE_PAPER_FORMAT.DEFAULT,
      },
      count: 1,
      barcodeTypeOptions: [],
      paperFormatOptions: [],
      errors: {},
    };
  },
  components: { EODInput, EODSelect, Modal },
  computed: {
    isOpen: {
      get() {
        return this.visible;
      },
      set(value) {
        this.$emit("update:visible", value);
      },
    },
    difference() {
      return this.data.counter_to - this.data.counter_from;
    },
    isValid() {
      return this.difference > 0;
    },
  },
  created() {
    if (
      this.$permissions.includes("counters.generate_barcode_counter_custom")
    ) {
      // TOOD: store those values in store
      getBarcodeTypes().then(({ data }) => {
        this.barcodeTypeOptions = data.map((item) => ({
          id: item[1],
          name: item[2],
        }));
      });

      getBarcodePaperFormat().then(({ data }) => {
        this.paperFormatOptions = Object.entries(data).map(([key, value]) => ({
          name: value,
          id: +key,
        }));
      });
    }
  },
  methods: {
    submit() {
      this.loading$ = true;

      const id = this.record.counter ?? this.record.id;
      const value =
        this.record.value ??
        this.record.counter_value ??
        this.record.numeration;

      const barcodes = this.printOptions
        ? getBarcodes(id, { ...this.data, unit: this.record?.unit?.id })
        : getBarcodeGenerate({
            ...this.data,
            value: value,
            unit: this.record?.unit?.id,
          });

      barcodes
        .then(({ data }) => {
          window.open(URL.createObjectURL(data));
          this.$emit("update:visible", false);
        })
        .catch((err) => {
          this.errors = err.response.data;
        })
        .finally(() => {
          this.loading$ = false;
        });
    },
  },
  watch: {
    record(value) {
      this.data.counter_from = value.next_free_value;
      this.data.counter_to = value.next_free_value;
    },
    count(value) {
      this.data.counter_to =
        value > 0
          ? parseInt(this.data.counter_from) + parseInt(value) - 1
          : null;
    },
    "data.counter_from": function (val) {
      this.data.counter_to = parseInt(val) + parseInt(this.count) - 1;
    },
  },
};
</script>
