import APPS from "@/consts/apps";
import Notifications from "@/views/Notifications/Notifications.vue";
import NotificationsForm from "@/views/Notifications/NotificationsForm.vue";
import NotificationsPreview from "@/views/Notifications/NotificationsPreview.vue";
import OwnNotifications from "@/views/Notifications/OwnNotifications.vue";
import PERMISSIONS from "@/consts/permissions";
import i18n from "@/i18n";

const t = i18n.global.t;

const notifications = [
  {
    path: "/notifications/",
    name: "Notifications",
    component: Notifications,
    meta: {
      title: `${t("meta.app")} - ${t("notifications.meta.list")}`,
      requiresAuth: true,
      permission: PERMISSIONS.NOTIFICATIONS,
      app: APPS.NOTIFICATIONS,
    },
  },
  {
    path: "/notifications/own/",
    name: "OwnNotifications",
    component: OwnNotifications,
    meta: {
      title: `${t("meta.app")} - ${t("notifications.myNotifications")}`,
      requiresAuth: true,
      app: APPS.NOTIFICATIONS,
    },
  },
  {
    path: "/notifications/create/",
    name: "NotificationsCreate",
    component: NotificationsForm,
    meta: {
      title: `${t("meta.app")} - ${t("notifications.meta.add")}`,
      requiresAuth: true,
      permission: PERMISSIONS.NOTIFICATIONS_ADD,
      app: APPS.NOTIFICATIONS,
    },
  },
  {
    path: "/notifications/:id/edit/",
    name: "NotificationsEdit",
    component: NotificationsForm,
    meta: {
      title: `${t("meta.app")} - ${t("notifications.meta.edit")}`,
      requiresAuth: true,
      permission: PERMISSIONS.NOTIFICATIONS_EDIT,
      app: APPS.NOTIFICATIONS,
    },
  },
  {
    path: "/notifications/:id/preview/",
    name: "NotificationsPreview",
    component: NotificationsPreview,
    meta: {
      title: `${t("meta.app")} - ${t("notifications.preview")}`,
      requiresAuth: true,
      permission: PERMISSIONS.NOTIFICATIONS,
      app: APPS.NOTIFICATIONS,
    },
  },
];

export default notifications;
