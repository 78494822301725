import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "section" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EODButtonLink = _resolveComponent("EODButtonLink")
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")
  const _component_TableActions = _resolveComponent("TableActions")
  const _component_BaseTable = _resolveComponent("BaseTable")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_Breadcrumbs, {
      current: _ctx.$t('modules.contacts'),
      iconComponent: "TeamOutlined"
    }, {
      default: _withCtx(() => [
        (_ctx.$permissions.includes(_ctx.PERMISSIONS.CONTACTS_ADD))
          ? (_openBlock(), _createBlock(_component_EODButtonLink, {
              key: 0,
              to: {
        name: 'ContactsCreate',
      },
              title: _ctx.$t('app.add_', [_ctx.$tc('contacts.contact', 1)]),
              class: "ml-1"
            }, null, 8, ["title"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["current"]),
    _createVNode("section", _hoisted_1, [
      _createVNode(_component_BaseTable, {
        columns: _ctx.columns,
        service: _ctx.getContacts
      }, {
        address: _withCtx(({ record }) => [
          _createTextVNode(_toDisplayString(record.street ||
          record.street_number ||
          record.apartment_number ||
          record.postal_code ||
          record.city
            ? `${record.street} ${record.street_number}${
                record.apartment_number ? `/${record.apartment_number}` : ""
              }, ${record.postal_code} ${record.city}`
            : "-"), 1)
        ]),
        actions: _withCtx(({ record }) => [
          _createVNode(_component_TableActions, {
            record: record,
            previewRoute: { name: 'ContactsPreview', params: { id: record.id } },
            editRoute: { name: 'ContactsEdit', params: { id: record.id } },
            deleteMessage: 
            _ctx.$t('app.doYouWantToDelete_', [
              _ctx.$tc('contacts.contact', 1),
              record.name,
            ])
          ,
            modalTitle: _ctx.$t('app.delete_', [_ctx.$tc('contacts.contact', 1)]),
            deleteService: _ctx.deleteContact
          }, null, 8, ["record", "previewRoute", "editRoute", "deleteMessage", "modalTitle", "deleteService"])
        ]),
        _: 1
      }, 8, ["columns", "service"])
    ])
  ], 64))
}