<template>
  <Breadcrumbs
    :path="[{ path: { name: 'Numeration' }, title: $t('modules.numeration') }]"
    :current="title"
    iconComponent="OrderedListOutlined"
    :what="$tc('numeration.numeration', 1)"
  >
    <EODButton
      v-if="id && $permissions.includes('counters.delete_counter_custom')"
      class="ml-1"
      type="danger"
      ghost
      @click="confirmDelete"
      icon="DeleteOutlined"
      :name="$t('app.delete_', [$tc('numeration.numeration', 2)])"
    />
  </Breadcrumbs>

  <Section :title="title">
    <div class="form-container">
      <form
        id="numeration-form"
        @submit.prevent="submit"
        class="two-column-form"
      >
        <div class="column">
          <EODInput
            id="name"
            v-model:value="data.name"
            :label="$t('app.name')"
            :maxlength="250"
            :errors="errors.name"
            required
          />
          <EODInput
            id="text_format"
            v-model:value="data.text_format"
            :label="$t('numeration.format')"
            :errors="errors.text_format"
            required
            pattern="^(([a-zA-Z/\-\.\d])|(#d|#D|#m|#M|#r|#R|#J))*#n(([a-zA-Z/\-\.\d])|(#d|#D|#m|#M|#r|#R|#J))*$"
            :disabled="data.in_use"
          />

          <p class="numeration-preview">
            <strong>
              {{ $t("app.preview_", [$tc("numeration.numeration", 1)]) }}:
            </strong>
            &nbsp;
            {{ numerationPreview }}
          </p>

          <EODInput
            id="next_free_value"
            v-model:value="data.next_free_value"
            :label="$t('numeration.startNumerationFrom')"
            type="number"
            :errors="errors.next_free_value"
            required
            :min="1"
            :disabled="data.in_use || disableNextFreeValue"
          />

          <EODRadio
            id="status"
            v-model:value="data.status"
            required
            :errors="errors.status"
            :label="$t('app.status')"
            :options="statusOptions"
          />
        </div>

        <div class="column">
          <h3 class="info-title">
            {{ $t("numeration.numerationComponents") }}:
          </h3>

          <ul class="additional-info">
            <li>
              {{ $t("numeration.info.title") }}
            </li>
            <li>
              #n - {{ $t("numeration.info.n") }}
              <strong>{{ $t("numeration.info.required") }} </strong>
            </li>
            <li>#d - {{ $t("numeration.info.d") }}</li>
            <li>#D - {{ $t("numeration.info.D") }}</li>
            <li>#m - {{ $t("numeration.info.m") }}</li>
            <li>#M - {{ $t("numeration.info.M") }}</li>
            <li>#r - {{ $t("numeration.info.r") }}</li>
            <li>#R - {{ $t("numeration.info.R") }}</li>
            <li>#J - {{ $t("numeration.info.J") }}</li>
            <li>{{ $t("numeration.info.jrwa") }}</li>
          </ul>
        </div>
      </form>

      <Button
        htmlType="submit"
        form="numeration-form"
        type="primary"
        :title="$t('app.save')"
        :loading="loading$"
      >
        {{ buttonTitle }}
      </Button>
    </div>
  </Section>

  <NumerationUnits v-if="id && data.is_unit_counter" :id="id" />
</template>

<script>
import { Breadcrumbs, EODButton, Section } from "@/components/ui";
import { EODInput, EODRadio } from "@/components/inputs";
import {
  createNumeration,
  deleteNumeration,
  editNumeration,
  getNumeration,
  getNumerationStatuses,
} from "@/services/numeration";

import { Button } from "ant-design-vue";
import NumerationUnits from "./NumerationUnits";
import { STATUS } from "@/consts/statuses";

export default {
  name: "NumerationForm",
  props: { id: { type: String, default: null } },
  data() {
    return {
      statusOptions: [],
      data: {
        name: "",
        status: STATUS.ACTIVE,
        next_free_value: 1,
        text_format: "",
        in_use: false,
      },
      // prettier-ignore
      formatRegex: /^(([a-zA-Z/\-.\d])|(#d|#D|#m|#M|#r|#R|#J))*#n(([a-zA-Z/\-.\d])|(#d|#D|#m|#M|#r|#R|#J))*$/,
      date: new Date(),
      errors: {},
      numerationName: "",
      disableNextFreeValue: false,
    };
  },
  components: {
    Breadcrumbs,
    Button,
    EODButton,
    EODInput,
    EODRadio,
    NumerationUnits,
    Section,
  },
  beforeRouteEnter(to, _, next) {
    if (to.name === "NumerationCreate") {
      next();
    } else {
      getNumeration(to.params.id)
        .then(({ data }) => {
          next((vm) => {
            vm.data = data;
            vm.numerationName = data.name;
            vm.disableNextFreeValue = data.text_format.includes("#J");
          });
        })
        .catch((err) => {
          if (err.response.status === 404) {
            next({ name: "404" });
          }
        });
    }
  },
  computed: {
    title() {
      return `${this.id ? this.$t("app.editActivity") : this.$t("app.addition")}
        ${this.$tc("numeration.numeration", 1)}`;
    },
    buttonTitle() {
      return this.id ? this.$t("app.saveChanges") : this.$t("app.save");
    },
    numerationPreview() {
      return this.data.text_format.match(this.formatRegex)
        ? this.data.text_format
            .replace("#n", this.data.next_free_value)
            .replace(/#d/g, this.date.getDate())
            .replace(/#D/g, ("0" + this.date.getDate().toString()).substr(-2))
            .replace(/#m/g, this.date.getMonth() + 1)
            .replace(
              /#M/g,
              ("0" + (this.date.getMonth() + 1).toString()).substr(-2),
            )
            .replace(/#R/g, this.date.getFullYear())
            .replace(/#r/g, this.date.getFullYear().toString().substr(-2))
        : this.$t("numeration.enterValidFormat");
    },
  },
  created() {
    getNumerationStatuses().then(({ data }) => {
      this.statusOptions = Object.entries(data).map(([key, value]) => ({
        name: value,
        id: +key,
      }));
    });
  },
  methods: {
    submit() {
      this.loading$ = true;

      const service = this.id ? editNumeration : createNumeration;

      service(this.data, this.id)
        .then(() => {
          this.$router.push({ name: "Numeration" });
          this.$message.success(this.$t("app.success"));
        })
        .catch((err) => {
          this.errors = err.response.data;
        })
        .finally(() => {
          this.loading$ = false;
        });
    },
    confirmDelete() {
      this.$store.dispatch("setModal", {
        title: this.$t("numeration.delete"),
        message: `${this.$t("app.doYouWantToDelete")} ${this.$t(
          "numeration.numeration",
        )} "${this.numerationName}"?`,
        modalOk: this.deleteThis,
      });
    },
    deleteThis() {
      deleteNumeration(this.id).then(() => {
        this.$message.success(this.$t("app.success"));
        this.$store.dispatch("closeModal");
        this.$router.replace({ name: "Numeration" });
      });
    },
  },
  watch: {
    "data.text_format"(val) {
      if (val.includes("#J")) {
        this.disableNextFreeValue = true;
        this.data.next_free_value = 1;
      } else {
        this.disableNextFreeValue = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.numeration-preview {
  padding-top: 1rem;
  display: flex;
  width: 100%;
}

.info-title {
  display: flex;
  width: 100%;
  padding-left: 1rem;
}
</style>
