const operationOptionsFilters = {
  eq: [
    "input",
    "date",
    "dictSelect",
    "simpleSelect",
    "systemSelect",
    "treeSelect",
    "number",
    "textarea",
    "radio",
    "numeration",
    "jrwa",
    "dueDate",
    "prioritySelect",
    "currentDate",
    "calc",
    "currentUser",
    "allUnits",
    "usersUnits",
  ],
  neq: [
    "input",
    "date",
    "dictSelect",
    "simpleSelect",
    "systemSelect",
    "treeSelect",
    "number",
    "textarea",
    "radio",
    "numeration",
    "jrwa",
    "dueDate",
    "prioritySelect",
    "currentDate",
    "calc",
    "currentUser",
    "allUnits",
    "usersUnits",
  ],
  le: [
    "date",
    "daterange",
    "number",
    "numeration",
    "dueDate",
    "currentDate",
    "calc",
  ],
  leq: ["date", "number", "numeration", "dueDate", "currentDate", "calc"],
  ge: [
    "date",
    "daterange",
    "number",
    "numeration",
    "dueDate",
    "currentDate",
    "calc",
  ],
  geq: ["date", "number", "numeration", "dueDate", "currentDate", "calc"],
  inc: [
    "input",
    "daterange",
    "multiSelect",
    "textarea",
    "checkbox",
    "contractors",
    "numeration",
    "userDelegation",
    "groupDelegation",
    "unitDelegation",
    "contacts",
  ],
  dec: [
    "input",
    "daterange",
    "multiSelect",
    "textarea",
    "checkbox",
    "contractors",
    "numeration",
    "userDelegation",
    "groupDelegation",
    "unitDelegation",
    "contacts",
  ],
  some: [
    "dictSelect",
    "systemSelect",
    "prioritySelect",
    "treeSelect",
    "jrwa",
    "currentUser",
    "allUnits",
    "usersUnits",
    "radio",
    "checkbox",
    "simpleSelect",
  ],
};

export default operationOptionsFilters;
