<template>
  <Breadcrumbs
    :current="$t('modules.repository')"
    iconComponent="ContainerOutlined"
  >
    <FolderForm
      v-model:visible="showFolderModal"
      v-model:repositoryID="selectedFolder"
    />

    <EODButton
      v-if="activeTab === REPOSITORY_TYPES.OWN && hasPermissionToManage"
      type="primary"
      icon="PlusOutlined"
      :name="$t('repository.createFolder')"
      @click="openFolderForm"
    />
  </Breadcrumbs>

  <Tabs
    v-model:activeKey="activeTab"
    @change="$scrollToTop"
    class="repository-tabs"
  >
    <TabPane :key="REPOSITORY_TYPES.JRWA" :tab="$t('repository.basedOnJRWA')">
      <Section :title="$t('repository.basedOnJRWA')">
        <template v-slot:breadcrumbs>
          <Breadcrumb>
            <BreadcrumbItem v-if="selectedFilter.unit">
              <button
                class="breadcrumb-button"
                @click="changeExpandedKeys(selectedFilter.unit.keyToExpand)"
              >
                {{ selectedFilter.unit.unitName }}
              </button>
            </BreadcrumbItem>
            <BreadcrumbItem v-if="selectedFilter.year">
              <button
                class="breadcrumb-button"
                @click="changeExpandedKeys(selectedFilter.year.keyToExpand)"
              >
                {{ selectedFilter.year.year }}
              </button>
            </BreadcrumbItem>
            <BreadcrumbItem v-if="selectedFilter.jrwa !== null">
              <button
                class="breadcrumb-button"
                @click="changeExpandedKeys(selectedFilter.jrwa.keyToExpand)"
              >
                {{
                  selectedFilter.jrwa.jrwa
                    ? $t("repository.resourcesWithJRWA")
                    : $t("repository.documentsWithoutJRWA")
                }}
              </button>
            </BreadcrumbItem>
            <BreadcrumbItem v-if="selectedFilter.jrwaName">
              <button
                class="breadcrumb-button"
                @click="changeExpandedKeys(selectedFilter.jrwaName.keyToExpand)"
              >
                {{ selectedFilter.jrwaName.jrwaName }}
              </button>
            </BreadcrumbItem>
            <BreadcrumbItem v-if="selectedFilter.issues !== null">
              {{
                selectedFilter.issues.issues
                  ? $t("modules.issues")
                  : $t("repository.noIssueDocuments")
              }}
            </BreadcrumbItem>
          </Breadcrumb>
        </template>
        <div class="repository">
          <FilterJRWA
            v-model:selectedFilter="selectedFilter"
            v-model:expandedKeys="expandedKeys"
            v-model:greyOutIssue="greyOutIssue"
          />
          <RepositoryList
            :filters="queryFilters"
            v-model:greyOutIssue="greyOutIssue"
          />
        </div>
      </Section>
    </TabPane>
    <TabPane :key="REPOSITORY_TYPES.OWN" :tab="$t('repository.ownStructure')">
      <Section :title="$t('repository.ownStructure')">
        <template v-slot:breadcrumbs>
          <Breadcrumb>
            <BreadcrumbItem v-if="folderID">
              <button class="breadcrumb-button">
                {{ folderName }}
              </button>
            </BreadcrumbItem>
          </Breadcrumb>
        </template>
        <template v-if="hasPermissionToManage" v-slot:button>
          <Dropdown v-if="folderID">
            <div class="repository-dropdown">
              <MoreOutlined />
            </div>
            <template v-slot:overlay>
              <Menu>
                <MenuItem key="0">
                  <div @click="handleFolderEdit">
                    <EditOutlined />
                    {{ $t("repository.editFolder") }}
                  </div>
                </MenuItem>
                <MenuDivider />
                <MenuItem key="1">
                  <div @click="handleConfirmDelete" class="color--error">
                    <DeleteOutlined />
                    {{ $t("repository.deleteFolder") }}
                  </div>
                </MenuItem>
              </Menu>
            </template>
          </Dropdown>
        </template>
        <div class="repository">
          <FilterFolders />
          <FoldersList :filters="queryFilters" />
        </div>
      </Section>
    </TabPane>
  </Tabs>
</template>

<script>
import { Breadcrumb, Dropdown, Menu, Tabs } from "ant-design-vue";
import { Breadcrumbs, EODButton, Section } from "@/components/ui";
import {
  DeleteOutlined,
  EditOutlined,
  MoreOutlined,
} from "@ant-design/icons-vue";

import FilterFolders from "./FilterFolders";
import FilterJRWA from "@/views/Repository/FilterJRWA";
import FolderForm from "./FolderForm";
import FoldersList from "./FoldersList";
import PERMISSIONS from "@/consts/permissions";
import { REPOSITORY_TYPES } from "@/consts/statuses";
import RepositoryList from "./RepositoryList";
import { deleteFolder } from "@/services/repository";

export default {
  name: "Repository",
  data() {
    return {
      REPOSITORY_TYPES,
      activeTab: REPOSITORY_TYPES.JRWA,
      expandedKeys: [],
      showFolderModal: false,
      selectedFolder: null,
      greyOutIssue: false,
      selectedFilter: {
        unit: null,
        year: null,
        jrwa: null,
        jrwaName: null,
        issues: null,
      },
      queryFilters: {
        unit: null,
        year: null,
        jrwa: null,
      },
    };
  },
  components: {
    Breadcrumb,
    BreadcrumbItem: Breadcrumb.Item,
    Breadcrumbs,
    Dropdown,
    DeleteOutlined,
    EODButton,
    EditOutlined,
    FilterFolders,
    FilterJRWA,
    FoldersList,
    FolderForm,
    Menu,
    MenuItem: Menu.Item,
    MenuDivider: Menu.Divider,
    MoreOutlined,
    RepositoryList,
    Section,
    Tabs,
    TabPane: Tabs.TabPane,
  },
  computed: {
    folderID() {
      return this.$store.getters["repository/folderID"];
    },
    folderName() {
      return this.$store.getters["repository/folderName"];
    },
    hasPermissionToManage() {
      return this.$permissions.includes(PERMISSIONS.REPOSITORY_MANAGE);
    },
  },
  methods: {
    changeExpandedKeys(keys) {
      this.expandedKeys = keys;
      this.selectedFilter = {
        unit: this.selectedFilter.unit,
        year: keys.length < 2 ? null : this.selectedFilter.year,
        jrwa: keys.length < 3 ? null : this.selectedFilter.jrwa,
        jrwaName: keys.length < 4 ? null : this.selectedFilter.jrwaName,
        issues: keys.length < 5 ? null : this.selectedFilter.issues,
      };
    },
    openFolderForm() {
      this.showFolderModal = true;
    },
    handleFolderEdit() {
      this.selectedFolder = this.folderID;
      this.showFolderModal = true;
    },
    handleConfirmDelete() {
      this.$store.dispatch("setModal", {
        modalOk: () => this.handleDelete(this.folderID),
        title: this.$t("app.confirmation"),
        message: `${this.$t("app.doYouWantToDelete")}?`,
      });
    },
    handleDelete(fileID) {
      deleteFolder(fileID).then(() => {
        this.$message.success(this.$t("app.success"));
        this.$store.dispatch("closeModal");
        this.$store.dispatch("repository/refetch", { root: true });
      });
      this.$store.dispatch("repository/resetFolder");
    },
  },
  watch: {
    selectedFilter(val) {
      // TODO: add condition specifying passed value to make breadcrumbs work properly
      if (val?.jrwa?.jrwa && val?.jrwaName === null) {
        this.queryFilters.jrwa = "all";
      } else if (val?.jrwa?.jrwa === false) {
        this.queryFilters.jrwa = "null";
      } else {
        this.queryFilters.jrwa = val?.jrwaName?.jrwaId;
      }
      this.queryFilters.unit = val?.unit?.unitId;
      this.queryFilters.year = val?.year?.year;
      this.queryFilters.type = val?.type;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/Variables.scss";

.repository {
  display: flex;
  min-height: 60vh;
}

:v-deep(.section-container) {
  padding: 0;
}

.breadcrumb-button {
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;

  &:focus,
  &:hover {
    outline: none;
    text-decoration: underline;
    color: $primary;
  }
}

.filter {
  z-index: 1;
}

.repository-dropdown {
  &:hover,
  &:focus {
    cursor: pointer;
    color: $primary;
  }
}
</style>
