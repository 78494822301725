<template>
  <Section class="section" :title="title">
    <div class="form-container">
      <form @submit.prevent="ask" class="form">
        <EODInput
          id="name"
          v-model:value="data.name"
          :label="$t('app.name')"
          required
          :maxlength="250"
          :errors="errors.name"
          :disabled="data.system"
        />

        <EODRadio
          id="status"
          v-model:value="data.status"
          required
          :label="$t('app.status')"
          :options="statusOptions"
          :errors="errors.status"
        />

        <EODSelect
          id="entry_type"
          v-model:value="data.entry_type"
          required
          :label="$t('dictionaries.entries.entryType')"
          :options="entryTypeOptions"
          :errors="errors.entry_type"
          :disabled="data.system"
        />

        <div class="input-container" v-if="showAdditionalValue">
          <label for="additional_value" class="label">
            {{ $t("app.additionalValue") }}
          </label>

          <Input
            v-if="isText"
            id="additional_value"
            v-model:value="data.additional_value"
            :placeholder="this.$t('app.additionalValue')"
            :maxlength="500"
            :disabled="data.system"
          />
          <Input
            v-else-if="isNumber"
            type="number"
            id="additional_value"
            v-model:value="data.additional_value"
            :placeholder="this.$t('app.additionalValue')"
            decimalSeparator=","
            step="any"
            :disabled="data.system"
          />
          <EODDatePicker
            v-else-if="isDate"
            id="additional_value"
            v-model:value="data.additional_value"
            :placeholder="$t('dictionaries.entries.chooseDate')"
            dateOnly
            :disabled="data.system"
          />

          <span class="color--error" v-if="errors.additional_value">
            {{ errors.additional_value[0] }}
          </span>
        </div>

        <EODTreeSelect
          id="parent"
          v-model:value="data.parent"
          v-model:treeExpandedKeys="treeExpandedKeys"
          :label="$t('dictionaries.entries.parent')"
          :treeData="entries"
          :onLoadData="onLoadData"
          @search="fetchDictionaryEntries"
          @change="onTreeSelectChange"
          v-model:searchValue="searchValue"
          :disabled="data.system"
          :errors="errors.parent"
        />

        <EODInput
          id="description"
          v-model:value="data.description"
          :label="$t('app.description')"
          :maxlength="500"
          type="textarea"
          :errors="errors.description"
          :disabled="data.system"
        />

        <div class="buttons">
          <EODButton
            v-if="entryID && hasDictEntriesDeletePermission"
            type="danger"
            ghost
            :name="$t('app.delete')"
            @click="confirmDelete"
            icon="DeleteOutlined"
            :disabled="data.system"
          />

          <EODButton
            htmlType="submit"
            type="primary"
            :name="buttonTitle"
            :loading="loading$"
          />
        </div>
      </form>
    </div>
  </Section>
</template>

<script>
import { ENTRY_TYPE, STATUS } from "@/consts/statuses";
import { EODButton, Section } from "@/components/ui";
import {
  EODDatePicker,
  EODInput,
  EODRadio,
  EODSelect,
  EODTreeSelect,
} from "@/components/inputs";
import {
  createDictionaryEntry,
  deleteDictionaryEntry,
  editDictionaryEntry,
  getDictionaryEntries,
  getDictionaryEntry,
  getDictionaryEntryStatuses,
  getDictionaryEntryTypes,
} from "@/services/dictionaries";

import { Input } from "ant-design-vue";
import PERMISSIONS from "@/consts/permissions";

const defaultData = {
  name: "",
  additional_value: "",
  entry_type: ENTRY_TYPE.EMPTY,
  status: STATUS.ACTIVE,
  description: "",
  parent: null,
  dictionary: undefined,
};

export default {
  name: "DictionaryEntriesForm",
  props: { dictID: { type: Number, required: true }, customStore: String },
  data() {
    return {
      statusOptions: [],
      entryTypeOptions: [],
      treeExpandedKeys: [],
      data: { ...defaultData },
      originalStatus: STATUS.ACTIVE,
      errors: {},
      entries: [],
      goToAddEntry: false,
      searchValue: "",
    };
  },
  components: {
    EODDatePicker,
    EODButton,
    EODInput,
    EODRadio,
    EODSelect,
    EODTreeSelect,
    Input,
    Section,
  },
  computed: {
    buttonTitle() {
      return this.entryID ? this.$t("app.saveChanges") : this.$t("app.add");
    },
    title() {
      return this.entryID
        ? this.$t("dictionaries.entries.edit")
        : this.$t("dictionaries.entries.addToDictionary");
    },
    showAdditionalValue() {
      return this.data.entry_type !== ENTRY_TYPE.EMPTY;
    },
    isNumber() {
      return (
        this.data.entry_type === ENTRY_TYPE.INT ||
        this.data.entry_type === ENTRY_TYPE.FLOAT
      );
    },
    isText() {
      return this.data.entry_type === ENTRY_TYPE.TEXT;
    },
    isDate() {
      return this.data.entry_type === ENTRY_TYPE.DATE;
    },
    dictionaryName() {
      return this.id
        ? this.data.dictionary_display
        : this.dictID
        ? this.$t("dictionaries.entries.dictionary")
        : "";
    },
    shouldRefetch() {
      return this.$store.state.dictionaries.shouldRefetch;
    },
    entryID() {
      return this.$store.state.dictionaries.entryID;
    },
    hasDictEntriesDeletePermission() {
      return this.$permissions.includes(PERMISSIONS.DICTIONARIES_ENTRY_DELETE);
    },
  },
  created() {
    this.data.dictionary = this.dictID;

    getDictionaryEntryStatuses().then(({ data }) => {
      this.statusOptions = Object.entries(data).map(([key, value]) => ({
        name: value,
        id: +key,
      }));
    });

    getDictionaryEntryTypes().then(({ data }) => {
      this.entryTypeOptions = Object.entries(data).map(([key, value]) => ({
        name: value,
        id: +key,
      }));
    });

    this.fetchEntry();
  },
  methods: {
    async fetchEntry() {
      if (this.entryID) {
        await getDictionaryEntry(this.entryID).then(({ data }) => {
          this.data = data;
          this.originalStatus = data.status;
        });
      } else {
        this.data = { ...defaultData };
        this.data.dictionary = this.dictID;
      }

      this.errors = {};
      this.fetchDictionaryEntries();
    },
    ask() {
      if (
        this.entryID &&
        this.data.children &&
        this.data.status === STATUS.INACTIVE &&
        this.data.status !== this.originalStatus
      ) {
        this.$store.dispatch("setModal", {
          message: this.$t("dictionaries.entries.statusModalMessage"),
          title: this.$t("dictionaries.entries.statusModalTitle"),
          modalOk: this.submit,
        });
      } else {
        this.submit();
      }
    },
    submit() {
      this.loading$ = true;

      const service = this.entryID
        ? editDictionaryEntry
        : createDictionaryEntry;

      if (!this.data.parent) {
        this.data.parent = null;
      }

      service(this.data, this.entryID)
        .then(() => {
          this.$store.dispatch(`${this.customStore}/refreshTable`);
          this.$store.dispatch("setEntryID", undefined);
          this.$store.dispatch("refetchEntries");
          this.$store.dispatch("closeModal");
          this.$message.success(this.$t("app.success"));
          this.errors = {};
        })
        .catch((err) => {
          this.errors = err.response.data;
        })
        .finally(() => {
          this.loading$ = false;
        });
    },
    fetchDictionaryEntries() {
      this.treeExpandedKeys = [];

      getDictionaryEntries({
        dictionary: this.dictID,
        search_field: this.searchValue,
      }).then(({ data: { results } }) => {
        this.entries = results.map((item) => ({
          id: item.id,
          pId: item.parent || 0,
          value: item.id,
          title: item.name,
          isLeaf: !item.children,
          disabled: item.status === STATUS.INACTIVE || item.id === this.entryID,
        }));

        if (
          this.data.parent &&
          !this.entries.find((item) => item.id === this.data.parent) &&
          !this.searchValue
        ) {
          this.entries.push({
            id: this.data.parent,
            pId: 0,
            value: this.data.parent,
            title: this.data.parent_display,
            isLeaf: true,
          });
        }
      });
    },
    onLoadData(treeNode) {
      const { id: nodeId } = treeNode.dataRef;
      return getDictionaryEntries({
        dictionary: this.dictID,
        parent: nodeId,
      }).then(({ data: { results } }) => {
        const children = results.map((item) => {
          if (item.id === this.data.parent) {
            this.entries = this.entries.filter(
              (elem) => elem.id !== this.data.parent,
            );
          }

          return {
            id: item.id,
            pId: item.parent || 0,
            value: item.id,
            title: item.name,
            isLeaf: !item.children,
            disabled:
              item.status === STATUS.INACTIVE || item.id === this.entryID,
          };
        });

        this.entries = this.entries.concat(children);
      });
    },
    onTreeSelectChange(value) {
      this.searchValue = "";
      this.data.parent_display = this.entries.find(
        (elem) => elem.id === value,
      )?.title;
    },
    confirmDelete() {
      this.$store.dispatch("setModal", {
        message: `${this.$t("app.doYouWantToDelete")} ${this.$tc(
          "dictionaries.entries.entry",
        )} "${this.data.name}"?`,
        title: this.$t("dictionaries.entries.delete"),
        modalOk: this.deleteEntry,
      });
    },
    deleteEntry() {
      deleteDictionaryEntry(this.entryID).then(() => {
        this.$message.success(this.$t("app.success"));
        this.$store.dispatch("closeModal");
        this.$store.dispatch(`${this.customStore}/refreshTable`);
        this.$store.dispatch("setEntryID", undefined);
      });
    },
  },
  watch: {
    dictID(value) {
      this.data.dictionary = value;
    },
    entryID(val) {
      this.searchValue = "";

      if (val !== undefined) {
        this.fetchEntry();
      } else {
        this.data = { ...defaultData };
        this.data.dictionary = this.dictID;
        this.errors = {};
      }
    },
    shouldRefetch(val) {
      this.entries = [];
      if (val) {
        this.fetchEntry();
        this.$store.dispatch("resetRefetchEntries");
      }
    },
  },
};
</script>
