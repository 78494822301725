<template>
  <Breadcrumbs
    :path="[{ path: { name: 'JRWA' }, title: $t('modules.jrwa') }]"
    :current="title"
    iconComponent="SortAscendingOutlined"
    what="JRWA"
  >
    <Button
      v-if="id"
      @click="generatePDF"
      :title="$t('jrwa.generatePDF')"
      :loading="loading$"
    >
      {{ $t("jrwa.generatePDF") }}
    </Button>
  </Breadcrumbs>

  <Alert
    class="ml-1 mr-1 mt-2"
    v-if="spinning"
    :message="$t('jrwa.importProgress')"
    type="info"
    showIcon
  />

  <Spin :spinning="spinning" size="large">
    <Section :title="$t('jrwa.info')">
      <template v-slot:button v-if="id">
        <EODButtonLink
          v-if="hasJRWAEntryAddPermission"
          :to="{ name: 'JRWAKeyCreate', params: { versionID: this.id } }"
          :title="$t('jrwa.meta.addEntry')"
        />
      </template>
      <div class="form-container">
        <form @submit.prevent="submit" class="form">
          <EODSelect
            v-if="!id"
            id="type"
            required
            :label="$t('app.type')"
            v-model:value="type"
            :options="typeOptions"
            :errors="errors.typeOptions"
          />

          <EODSelect
            v-if="!id && type === 'existing'"
            id="based_on"
            required
            :label="$t('jrwa.jrwa')"
            v-model:value="data.based_on"
            :service="fetchJRWAList"
            :errors="errors.based_on"
          />

          <FileUploader
            :maxFiles="1"
            v-if="!id && type === 'imported'"
            :fileURL="attachmentURL"
            v-model:fileList="attachments"
            :customColumns="attachmentsColumns"
            requiredFormat
          >
            <template v-slot:filePreview />
          </FileUploader>

          <EODInput
            id="name"
            v-model:value="data.name"
            :label="$t('app.name')"
            required
            :errors="errors.name"
          />

          <EODInput
            id="description"
            type="textarea"
            v-model:value="data.description"
            :label="$t('app.description')"
            :maxlength="500"
            :errors="errors.comments"
          />

          <div class="buttons">
            <Button
              htmlType="submit"
              :type="id ? 'primary' : 'default'"
              :title="action"
              :loading="loading$"
            >
              {{ action }}
            </Button>
            <Button
              v-if="!id && hasJRWAEditPermission"
              type="primary"
              :title="$t('app.continue')"
              @click="saveAndEdit"
              :loading="loading$"
            >
              {{ $t("app.continue") }}
            </Button>
          </div>
        </form>
      </div>
    </Section>
  </Spin>

  <section
    class="section"
    v-if="
      ((data.source !== 'imported' && id) ||
        data.import_status === 'COMPLETED') &&
      hasJRWAEntryPermission
    "
  >
    <JRWAEntries :jrwa_register="id" />
  </section>
</template>

<script>
import { Alert, Button, Spin } from "ant-design-vue";
import { Breadcrumbs, EODButtonLink, Section } from "@/components/ui";
import { EODInput, EODSelect, FileUploader } from "@/components/inputs";
import {
  createJRWA,
  editJRWA,
  generateJRWAPDF,
  getJRWA,
  getJRWAList,
} from "@/services/jrwa";

import JRWAEntries from "@/views/JRWA/JRWAEntries";
import PERMISSIONS from "@/consts/permissions";

export default {
  name: "JRWAForm",
  data() {
    return {
      data: {
        based_on: null,
        name: "",
        status: null,
        import_status: null,
        import_status_display: null,
        source: null,
        description: "",
        attachment: null,
      },
      type: "new",
      attachments: [],
      attachmentURL: "/api/jrwa/attachments/",
      attachmentsColumns: [
        {
          title: this.$t("app.name"),
          dataIndex: "filename",
          width: "35%",
        },
        {
          title: this.$t("app.created"),
          dataIndex: "created_date",
          width: "30%",
        },
        {
          title: this.$t("app.actions"),
          dataIndex: "actions",
          slots: { customRender: "actions" },
          width: "20%",
        },
      ],
      typeOptions: [
        { id: "new", name: this.$t("jrwa.typeOptions.new") },
        { id: "existing", name: this.$t("jrwa.typeOptions.existing") },
        { id: "imported", name: this.$t("jrwa.typeOptions.import") },
      ],
      errors: {},
      id: +this.$route.params?.id,
      goEdit: false,
      spinning: false,
    };
  },
  components: {
    Alert,
    Breadcrumbs,
    Button,
    EODButtonLink,
    EODInput,
    EODSelect,
    JRWAEntries,
    Section,
    FileUploader,
    Spin,
  },
  computed: {
    action() {
      return this.id ? this.$t("app.saveChanges") : this.$t("app.saveAndLeave");
    },
    title() {
      return this.id ? this.$t("jrwa.meta.edit") : this.$t("jrwa.meta.add");
    },
    hasJRWAEntryPermission() {
      return this.$permissions.includes(PERMISSIONS.JRWA_ENTRY);
    },
    hasJRWAEntryAddPermission() {
      return this.$permissions.includes(PERMISSIONS.JRWA_ENTRY_ADD);
    },
    hasJRWAEditPermission() {
      return this.$permissions.includes(PERMISSIONS.JRWA_EDIT);
    },
    importStatus() {
      return this.$store.state.notifications.importStatus;
    },
  },
  created() {
    if (this.id) {
      this.fetchJRWA();
    }
  },
  methods: {
    handleTypeChange() {
      if (this.type !== "existing") {
        this.data.based_on = null;
      }
    },
    submit() {
      this.loading$ = true;

      const service = this.id ? editJRWA : createJRWA;

      const form = this.data;

      form.source = this.type;

      if (!this.id && this.attachments.length > 0) {
        form.attachment = this.attachments[0].id;
      }

      service(form, this.id)
        .then(({ data }) => {
          if (this.goEdit) {
            if (form.source === "imported") {
              this.$store.dispatch("notifications/setImportStatus", "");
              this.spinning = true;
            } else {
              this.$router.replace({
                name: "JRWAEdit",
                params: { id: data.id },
              });
            }

            this.id = data.id;
            this.goEdit = false;
          } else {
            this.$router.push({ name: "JRWA" });
          }
          this.$message.success(
            form.source === "imported"
              ? this.$t("jrwa.jrwaImportInfo")
              : this.$t("app.success"),
          );
        })
        .catch((err) => {
          this.errors = err.response.data;
        })
        .finally(() => {
          this.loading$ = false;
        });
    },
    fetchJRWA() {
      getJRWA(this.id).then(({ data }) => {
        this.data = data;
      });
    },
    fetchJRWAList(params) {
      return getJRWAList(params).then(({ data }) => ({
        ...data,
        results: data.results.map((item) => ({
          id: item.id,
          name: item.name,
        })),
      }));
    },
    generatePDF() {
      this.loading$ = true;
      generateJRWAPDF(this.id)
        .then(({ data }) => {
          window.open(URL.createObjectURL(data));
        })
        .finally(() => {
          this.loading$ = false;
        });
    },
    saveAndEdit() {
      this.goEdit = true;
      this.submit();
    },
  },
  watch: {
    type() {
      this.handleTypeChange();
    },
    importStatus(val) {
      if (val === "success") {
        this.spinning = false;
        this.$router.replace({
          name: "JRWAEdit",
          params: { id: this.id },
        });
      } else if (val === "error") {
        this.spinning = false;
        this.id = null;
        this.$message.error(this.$t("jrwa.importError"));
      }
    },
  },
};
</script>
